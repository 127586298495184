import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProductGroupHighlight from './ProductGroupHighlight';
import { HeartsBackground, ProductGroupHighlightContainer, WhyWeLoveImg, WhyWeLoveSectionContainer } from '../../../Style/ProductGroupLandingStyles'
import { why_we_love_product } from '../../../assets/landing'

export default function ProductGroupHighlightsSection({ highlights }) {
    function renderHeartsSection() {
        return (
            <Grid item md={6} xs={12}>
                <HeartsBackground>
                    <WhyWeLoveImg src={why_we_love_product} alt="Why we love this product" />
                </HeartsBackground>
            </Grid>
        )
    }

    function renderHighlightBullets() {
        return (
            <Grid item md={6} xs={12}>
                <ProductGroupHighlightContainer>
                    {highlights.map(highlight => {
                        return <ProductGroupHighlight highlight={highlight} />
                    })}
                </ProductGroupHighlightContainer>
            </Grid>
        )
    }

    return (
        <WhyWeLoveSectionContainer>
            <Grid container>
                {renderHeartsSection()}
                {renderHighlightBullets()}
            </Grid>
        </WhyWeLoveSectionContainer>
    )
}