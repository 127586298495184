import React from "react";
import {
    ModalContainer,
    ModalBody as Body,
    // ModalTitle as Title,
} from '../../../Style';
import { creatingOrderBox } from '../../../assets/miscellaneous'

export default function CreatingOrderModal() {
    return (
        <ModalContainer>
            <Body>
                <div className="text-center">
                    <img src={creatingOrderBox} className="pulse animated-super-slow infinite delay1" alt="Loading" />
                </div>
                <div className="interview-copy">
                    <b>Processing your order...</b><br />
                Please do not close or navigate away from window.
            </div>
            </Body>
        </ModalContainer>
    )
}