import React from 'react';
import {
    ScienceCarouselSection as CarouselSection,
    H2Amaranth as H2
} from '../../../Style';
import Carousel from 'react-multi-carousel';

const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1500 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1500, min: 991 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 991, min: 0 },
        items: 1,
    },
}

export const CardCarousel = ({
    style,
    title,
    subtitle,
    isWide,
    cards,
    still,
    id
}) => {
    if(cards) {    
        return (
            <CarouselSection style={style} id={id}>
                <H2>{title}</H2>
                {subtitle}
                <Carousel
                    containerClass={`testimonial-card-container${isWide ? " testimonial-card-container-centered" : ""}`}
                    dotListClass="testimonial-card-dot-list"
                    responsive={responsive}
                    partialVisible={true}
                    autoPlay={!still}
                    autoPlaySpeed={6000}
                    infinite={true}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    className="testimonial-card-carousel"
                >
                    {cards}
                </Carousel>
            </CarouselSection>
        )
    }
    else {
        return null;
    }
}