//#region imports
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { referral_present } from '../../../assets/miscellaneous'
import { Body1 } from '../../../Style/DesignSystem/Typography';
import { colors } from '../../../Style/Colors';
import { autoMarginTB, mediaQueries } from '../../../Style/Common';
import { getCouponSpan } from '../../../actions';
import { timelyGreeting } from '../../../helpers/conversational'
//#endregion

//#region styles
const Banner = styled.div`
    position: relative;
    z-index: 1;
    width: 100vw;
    background-color: ${colors.btnGreen.text};
    border-bottom: 4px solid ${colors.textGreen};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 16px 8px;
    min-height: 60px;
    -webkit-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    opacity: 0;
    cursor: pointer;
    font-weight: bold;
    ${mediaQueries(610)`
        min-height: 80px;
    `}
    ${props => props.withCoupon && css`
        opacity: 1;
    `}
    & :hover {
        text-decoration: underline;
    }
    & :after {
        content: '';
        position: absolute;
        top: calc(-1 * 4px);
        left: calc(-1 * 4px);
        height: calc(100% + 4px * 2);
        width: calc(100% + 4px * 2);
        background: linear-gradient(60deg, #f5b166, #74d599, #2d96fe, #f5e070);
        border-radius: calc(2 * 4px);
        z-index: -1;
        animation: animatedgradient 12s ease alternate infinite;
        background-size: 300% 300%;
    }
`

const Present = styled.img`
    ${{ ...autoMarginTB }};
    height: 40px;
    width: 40px;
`

const Text = styled(Body1)`
    ${{ ...autoMarginTB }};
    color: #fff;
    padding: 8px;
    ${mediaQueries("md")`
        text-align: center;
    `}
`

const Strong = styled.span`
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
    white-space: nowrap; // Ensure "redeem" and "now" don't appear on separate lines
`
//#endregion 

//#region main
export default function LandingBanner({
    coupon,
    utm_campaign,
    utm_content,
    isEmployeePerk,
    hideCouponAlert,
    handleCTAClick,
    customerName,
    isSignedIn,
    isMobile
}) {
    const [couponCopy, setCouponCopy] = useState()

    if (coupon && !couponCopy && !isSignedIn) {
        setCouponCopy(
            getCouponSpan({
                coupon,
                utm_content,
                utm_campaign,
                isEmployeePerk
            })
        )
    }

    function renderImg() {
        if (!hideCouponAlert && !!couponCopy) return <Present src={referral_present} alt="" />
    }

    function renderText() {
        if (!hideCouponAlert && !isSignedIn && couponCopy) {
            return (
                <Text>
                    {couponCopy}{" "}
                    <Strong>Redeem Now</Strong><br/>
                    LIMITED TIME WAREHOUSE SALE 3/24 - 3/26 9am - 5pm PICKUP ONLY at 301 Chicon St. Unit B Austin, TX
                </Text>
            )
        } else if (isSignedIn) {
            return (
                <Text>
                    {timelyGreeting(customerName)}{renderBreak()}
                    <Strong>View Dashboard</Strong>
                </Text>

            )
        } 
    }

    function renderBreak() {
        if (isMobile) {
            return <br />
        } else {
            return " • "
        }
    }

    return (
        <Banner withCoupon={!!(couponCopy || isSignedIn) && !hideCouponAlert} onClick={handleCTAClick}>
            {renderImg()}
            {renderText()}
        </Banner>
    )
}
//#endregion