// TODO: Could be cool to have some *random* greetings/conversational messaging here that doesn't require a call to Lex 

export function timelyGreeting(customerName) {
        const now = new Date();
        const hours = now.getHours();

    const greeting = () => {
        if (hours > 16) return "Good Evening"
        else if (hours > 11) return "Good Afternoon"
        return "Good Morning"
    }

    if (customerName)
        return greeting() + `, ${customerName}!`;
    else
        return greeting();
}