import React from 'react';
import { ProductCheckmarkRow } from '../Bundles';
import { bundles } from '../../../data';
import {
    LandingBundle as Bundle,
    LandingBundleImg as BundleImg,
    TestimonialCard as Card,
    PublicationsClickableCard as ClickableCard
} from '../../../Style';
import { CardCarousel } from '../Miscellaneous/CardCarousel';

export default function HomePageBundles({ isTablet, history }) {
    function MobileContent() {
        const cards = Object.entries(bundles).map(entry => {
            const bundleId = entry[0], bundle = entry[1];
            const { icon, title, displayProductTypes } = bundle;
            const productRows = displayProductTypes.map(productType => {
                return <ProductCheckmarkRow key={productType} productType={productType} landing={true} />
            })
            return (
                <Card style={{ width: 260 }}>
                    <ClickableCard key={title} href={(`/products/bundles/${bundleId}`)}>
                        <div className="text-center">
                            <BundleImg src={icon} alt={title} />
                            <h4 className="landing-bundle-header">{title}</h4>
                        </div>
                        <div className="landing-bundle-checkmark-container">
                            {productRows}
                        </div>
                    </ClickableCard>
                </Card>
            )
        })

        return <CardCarousel cards={cards} />
    }
    
    function DesktopContent() {
        return Object.entries(bundles).map(entry => {
            const bundleId = entry[0], bundle = entry[1];
            const { icon, title, displayProductTypes } = bundle;
            const productRows = displayProductTypes.map(productType => {
                return <ProductCheckmarkRow key={productType} productType={productType} landing={true} />
            })
            return (
                <Bundle key={title} onClick={() => history.push(`/products/bundles/${bundleId}`)}>
                    <BundleImg src={icon} alt={title} />
                    <h4 className="landing-bundle-header">{title}</h4>
                    <div className="landing-bundle-checkmark-container">
                        {productRows}
                    </div>
                </Bundle>
            )
        })
    }

    return isTablet ? <MobileContent /> : <DesktopContent />;
}