import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Logger } from '../../helpers/Logger';
const logger = new Logger();

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleAutocomplete(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxfUv7ztZbH3J6qRbdVT2ZOOzuS9rFaH8&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    async function geocodeByAddress(address) {
        const geocoder = new window.google.maps.Geocoder();
        const OK = window.google.maps.GeocoderStatus.OK;

        return new Promise((resolve, reject) => {
            geocoder.geocode({ address }, (results, status) => {
                if (status !== OK) {
                    reject(status);
                }
                resolve(results);
            });
        });
    };

    function splitAndSetValues(place) {
        try {
            const { description, structured_formatting } = place;
            const { main_text, secondary_text } = structured_formatting;
            const line1 = main_text;
            const city = secondary_text.split(", ")[0];
            const state = secondary_text.split(", ")[1];
            setValue(line1)
            geocodeByAddress(description).then(results => {
                const zipcode = results[0].address_components.filter(c => c.types.includes("postal_code"))[0].short_name;
                const addressComponents = [
                    { line1 },
                    { city },
                    { state },
                    { zipcode }
                ]
                addressComponents.forEach(component => {
                    Object.entries(component).forEach(entry => {
                        props.setValue(entry[0], entry[1])
                    })
                })
            }).catch(error => logger.error(error));
        } catch (err) {
            logger.error(err)
        }
    }

    return (
        <Autocomplete
            style={{ width: props.isXS ? "100%" : 260, display: "inline-block" }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            disableClearable
            filterSelectedOptions
            value={value}
            onChange={(event, place) => {
                setOptions(place ? [place, ...options] : options);
                if (place) splitAndSetValues(place);
            }}
            onInputChange={(event, newInputValue) => {
                if (newInputValue || event.keycode === 46 || event.keycode === 8) {
                    setInputValue(newInputValue);
                    setValue(newInputValue);
                    props.setValue("line1", newInputValue)
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label="Address" variant="outlined" />
            )}
            renderOption={(option) => {
                if (option.structured_formatting) {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );
                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }
            }}
        />
    );
}