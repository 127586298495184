import React from 'react';
import { TestimonialCard } from './'
import { TestimonialCarouselSubtitleContainer as SubtitleContainer } from '../../../Style';
import { CardCarousel } from '../Miscellaneous/CardCarousel';

export const TestimonialCardCarousel = ({
    style,
    testimonials
}) => {
    if(testimonials) {
        const { title, entries, subtitle, isWide } = testimonials;
        const testimonialCards = entries.map((testimonial, i) => {
            const { quote, src, name } = testimonial;
            return (
                <TestimonialCard key={`testimonial-card-${i}`} quote={quote} src={src} name={name} isWide={isWide} />
            )
        })

        function renderSubtitle() {
            if(subtitle) {
                return (
                    <SubtitleContainer>
                        <p>{subtitle}</p>
                    </SubtitleContainer>
                )
            }
        }
    
        return (
            <CardCarousel
                subtitle={renderSubtitle()}
                title={title}
                cards={testimonialCards}
                style={style}
                isWide={isWide}
            />
        )
    }
    else {
        return null;
    }
}