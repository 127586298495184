import React from 'react';
import { formatProductType, getUnits } from '../../../actions';
import { LineItemRow } from '../../../Style/OrderSummaryStyles';

export default function ProductLineItem({ productGroup }) {
    const { brandName, displayUnits, pricePerArticle, articles, productType, articleType, groupName } = productGroup;
    const formattedType = formatProductType(productType);
    const singleUnit = getUnits(articleType, 1, true);
    const priceDollars = pricePerArticle / 100;
    return (
        <LineItemRow key={groupName} checkout_summary={true}>
            <div>
                <div className="price-details-product-name"><b>{formattedType}</b> {brandName}</div>
                <div className="price-details-pricing-breakdown">({articles} x ${(priceDollars).toFixed(2)} per {!!displayUnits ? displayUnits : singleUnit})</div>
            </div>
            <div className="price-details-line-price">
                ${(priceDollars * articles).toFixed(2)}
            </div>
        </LineItemRow>
    )
}