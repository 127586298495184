export default {
    "family": [
        {
            "gender": "Male",
            "age": 27,
            "staysHome": false,
            "isolated": false,
            "type": "Adult"
        }
    ],
    "pets": {
        "Cat": 0,
        "Dog": 0
    },
    "bathrooms": 1,
    "trashPreferences": [
        {
            "type": "Kitchen",
            "frequency": 1,
            "bags": 1
        },
        {
            "type": "Recycling",
            "frequency": 0,
            "bags": 1
        },
        {
            "type": "Small",
            "frequency": 0,
            "bags": 1
        },
        {
            "type": "Outdoor",
            "frequency": 0,
            "bags": 1
        },
        {
            "type": "Compost",
            "frequency": 0,
            "bags": 1
        }
    ],
    "cookingFrequency": 1,
    "dishwashing": {
        "frequency": 1,
        "products": [

        ]
    },
    "housekeeping": 1,
    "laundry": {
        "frequency": 1
    },
    "tierPreferences": [
        "SupplyDropPick"
    ]
}