//#region imports
import React, { Component, Fragment } from 'react';
import { formatProductType, getProductGroups, getLanding, getUser } from "../../../actions";
import { connect } from 'react-redux';
import { LandingNav } from '../../Common/Navs';
import Footer from '../../Common/Footer'
import { BetterWay } from '../../Common/Landing';
import { BundleProductGroup, FAQ, Loading } from '../../Common';
import { Logger } from '../../../helpers/Logger';
import ProductGroupDetails from './ProductGroupDetails';
import ProductGroupDescriptionSection from './ProductGroupDescriptionSection';
import ProductGroupDetailsTabs from './ProductGroupDetailsTabs';
import ProductGroupHighlightsSection from './ProductGroupHighlightsSection';
import mockProductGroup from '../../../data/mock/MockProductGroup';
import mockFAQs from '../../../data/mock/MockFAQs';
import { defaultZipcodes } from '../../../data/DefaultZipcodes';
import {
    BundleLandingProductGroups,
    LandingContainer,
    LandingMain,
    PageDivider,
    LandingFAQsContainer as FAQsContainer
} from '../../../Style';
import { ProductGroupsLandingBetterWayContainer, ProductGroupsLandingFAQBody as FAQsBody, OtherOfferingsContainer } from '../../../Style/ProductGroupLandingStyles'
import { Heading2 } from '../../../Style/DesignSystem/Typography';
import { featureFlags } from '../../../config';
import { Auth } from "aws-amplify";
import Analytics from "../../../analytics";
import * as Sentry from '@sentry/browser';
import NotFound from '../../Main/404';
import SimpleContinueBar from '../../Common/Buttons/SimpleContinueBar';
import LandingBanner from '../../Common/Landing/LandingBanner';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');
const logger = new Logger();
//#endregion

export class ProductGroupLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.changeProductPage = this.changeProductPage.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.setIdentityBreadcrumb = this.setIdentityBreadcrumb.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
    }

    componentDidMount() {
        const { match: { params: { groupName } }, location } = this.props;
        const { search, state } = location;

        if (state) {
            if (state.selectedProductGroup)
                this.setState({ selectedProductGroup: state.selectedProductGroup })
            if (state.signUpData)
                this.setState({ signUpData: state.signUpData, ...state.signUpData });
        }

        window.addEventListener('resize', this.handleResize, false);
        this.handleResize();

        this.props.getProductGroups();

        this.setState({ selectedGroupName: groupName })

        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            Analytics.setUser(data.username);
            this.props.getLanding(search);
            this.props.getUser();
        }).catch(err => {
            this.props.getLanding(search);
            this.setIdentityBreadcrumb();
        })

    }

    componentDidUpdate() {
        const { productGroups, productGroupsError, landingData, user, getUserError } = this.props;
        const { selectedGroupName } = this.state;
        if (productGroups && !this.state.productGroups && !this.state.productGroupsError && selectedGroupName) {
            const productGroupsMap = {};
            const productTypesMap = {};
            productGroups.forEach(productGroup => {
                const { groupName, productType, available } = productGroup;
                // Set all productTypes to productGroupsMap
                productGroupsMap[groupName] = productGroup;

                // Set available productGroups of the same productType to an array 
                if (available && groupName !== selectedGroupName) {
                    if (productTypesMap[productType]) {
                        productTypesMap[productType].push(productGroup);
                    } else {
                        productTypesMap[productType] = [productGroup]
                    }
                }
            })
            const selectedProductGroup = productGroupsMap[selectedGroupName];
            if (selectedProductGroup) {
                this.setState({ productGroups, productGroupsMap, selectedProductGroup, productTypesMap })
            } else if (!prod && selectedGroupName === "mock") {
                this.setState({ productGroups, productGroupsMap, selectedProductGroup: mockProductGroup, productTypesMap })
            } else {
                const error = new Error("ProductGroup not present in productGroupsMap");
                logger.error(error)
                this.setState({ productGroupsError: error })
            }
        }

        if (this.props.productGroupsMap && !this.state.productGroupsMap) {
            this.setState({ productGroupsMap: this.props.productGroupsMap })
        }

        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError })
        }

        if (landingData && !this.state.landingData) {
            this.setState({ landingData, ...landingData })
        }

        const { match: { params } } = this.props;
        if (selectedGroupName && params.groupName !== selectedGroupName) {
            this.setState({ selectedGroupName: params.groupName, productGroups: undefined })
        }

        if (user && !this.state.user)
            this.setState({ user, customerName: user?.customer?.firstName })

        if (getUserError && !this.state.getUserError)
            this.setState({ getUserError })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    handleResize(e) {
        const { isMobile, isTablet } = this.state;
        if (window.innerWidth < 768 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 767 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 992 && !isTablet) {
            this.setState({ isTablet: true })
        }
        if (window.innerWidth >= 991 && isTablet) {
            this.setState({ isTablet: false })
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    toggleMenu() {
        const { isShowingMenu } = this.state;
        this.setState({ isShowingMenu: !isShowingMenu });
    }

    changeProductPage(productGroup) {
        const { productGroupsMap, signUpData } = this.state;
        this.props.history.push({
            pathname: `/products/${productGroup.groupName}`, state: {
                selectedProductGroup: productGroup,
                productGroupsMap,
                signUpData
            }, search: window.location.search
        })
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            sdCredit,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                sdCredit,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/our-promise';

            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    renderNav() {
        const { aptCampaign, isTablet, isShowingMenu, isMobile, utm_campaign, isSignedIn, productGroupsError } = this.state;
        if (!productGroupsError) {
            const buttonText = isSignedIn ? "My Dashboard" : "Get Started";
            return (
                <LandingNav
                    history={this.props.history}
                    isTablet={isTablet}
                    isMobile={isMobile}
                    isSignedIn={isSignedIn}
                    aptCampaign={aptCampaign}
                    next={this.onGetStartedClick}
                    toggleMenu={this.toggleMenu}
                    isShowingMenu={isShowingMenu}
                    utm_campaign={utm_campaign}
                    buttonText={buttonText}
                />
            )
        }
    }

    renderLoading() {
        if (!this.state.selectedProductGroup && !this.state.productGroupsError) {
            return <Loading />
        }
    }

    renderDescription() {
        // return first highlight for that productGroup if it exists else description w/ blue patterned bg
        const { selectedProductGroup } = this.state;
        return (
            <Fragment>
                <PageDivider isReverse={true} />
                <ProductGroupDescriptionSection productGroup={selectedProductGroup} />
            </Fragment>
        )
    }

    renderDetailsTabs() {
        // return longDescription, ingredients && useInstructions for that productGroup if they exist
        // + fixed images
        const { selectedProductGroup } = this.state;
        const { longDescription, useInstructions, ingredients } = selectedProductGroup;
        if (longDescription || useInstructions || ingredients) {
            return (
                <ProductGroupDetailsTabs ingredients={ingredients} useInstructions={useInstructions} longDescription={longDescription} />
            )
        }
    }

    renderHighlights() {
        const { selectedProductGroup } = this.state;
        // return left panel hearts background + main heart image,
        // right panel: highlights for that productGroup bulleted (pink checkmarks)
        if (selectedProductGroup.highlights && selectedProductGroup.highlights.length) {
            return (
                <Fragment>
                    <ProductGroupHighlightsSection highlights={selectedProductGroup.highlights} />
                    <PageDivider style={{ position: "relative", zIndex: 3 }} />
                </Fragment>
            )
        }
    }

    renderFAQs() {
        // return FAQs for that productGroup if they exist
        // use same basic layout as other FAQ sections
        const { selectedProductGroup, selectedGroupName } = this.state;
        if ((selectedProductGroup.faqs && selectedProductGroup.faqs.length) || (!prod && selectedGroupName === "mock" && mockFAQs)) {
            const faqs = !prod && selectedGroupName === "mock" && mockFAQs ? mockFAQs : selectedProductGroup.faqs;
            return (
                <FAQsContainer>
                    <Heading2>FAQs</Heading2>
                    <FAQsBody>
                        {Object.values(faqs).map(faq => {
                            const { title, bodyHtml } = faq;
                            return <FAQ title={title} bodyHtml={bodyHtml} />
                        })}
                    </FAQsBody>
                </FAQsContainer>
            )
        }
    }

    renderOtherOfferings() {
        // return other product groups of the selected productType
        // use cardImages from bundle landing pages
        const { selectedProductGroup, productTypesMap } = this.state;
        if (productTypesMap) {
            const { productType } = selectedProductGroup;
            const productTypeOfferings = productTypesMap[productType]
            if (productTypeOfferings && productTypeOfferings.length) {
                const formattedProductType = formatProductType(productType);

                return (
                    <OtherOfferingsContainer>
                        <Heading2>Other {formattedProductType} We Offer</Heading2>
                        <BundleLandingProductGroups>
                            {productTypeOfferings.slice(0, 3).map(productGroup => {
                                return <BundleProductGroup productGroup={productGroup} onclick={() => this.changeProductPage(productGroup)} />
                            })}
                        </BundleLandingProductGroups>
                        <PageDivider isReverse={true} />
                    </OtherOfferingsContainer>
                )
            }
        }
    }

    renderBetterWay() {
        const { isSignedIn } = this.state;
        return (
            <ProductGroupsLandingBetterWayContainer>
                <BetterWay
                    isRelative={true}
                    isSignedIn={isSignedIn}
                    ctaCopy="Get Started"
                    handleCTAClick={this.onGetStartedClick}
                />
            </ProductGroupsLandingBetterWayContainer>
        )
    }

    renderFixedCTA() {
        if (!this.state.isSignedIn)
            return <SimpleContinueBar next={this.onGetStartedClick} />
    }

    renderBanner() {
        const {
            customerName,
            isEmployeePerk,
            isMobile,
            isSignedIn,
            newCoupon,
            utm_campaign,
            utm_content,
        } = this.state;
        return (
            <LandingBanner
                coupon={newCoupon}
                utm_campaign={utm_campaign}
                utm_content={utm_content}
                isEmployeePerk={isEmployeePerk}
                hideCouponAlert={!newCoupon}
                handleCTAClick={this.onGetStartedClick}
                isSignedIn={isSignedIn}
                customerName={customerName}
                isMobile={isMobile}
            />
        )
    }

    renderMain() {
        const {
            selectedProductGroup,
            newCoupon,
            productGroupsError
        } = this.state;

        if (selectedProductGroup && !productGroupsError) {
            return (
                <LandingContainer>
                    {this.renderBanner()}
                    <ProductGroupDetails productGroup={selectedProductGroup} coupon={newCoupon} />
                    {this.renderDescription()}
                    {this.renderDetailsTabs()}
                    {this.renderHighlights()}
                    {this.renderFAQs()}
                    {this.renderOtherOfferings()}
                    {this.renderBetterWay()}
                    {this.renderFixedCTA()}
                    <Footer history={this.props.history} />
                </LandingContainer>
            )
        }
    }

    renderError() {
        const { productGroupsError } = this.state;
        if (productGroupsError) {
            console.log(productGroupsError)
            return <NotFound history={this.props.history} />
        }
    }

    renderFooter() {
        return <Footer history={this.props.history} landing={true} />
    }

    renderSuccessAndLoading() {
        if (!this.state.productGroupsError) {
            return (
                <LandingMain>
                    {this.renderNav()}
                    {this.renderLoading()}
                    {this.renderMain()}
                </LandingMain>
            )
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderSuccessAndLoading()}
                {this.renderError()}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError } = state.user;
    const { productGroups, productGroupsError, productGroupsMap } = state.product;
    const { landingData, gettingLandingData, landingError } = state.order;
    return { productGroups, productGroupsError, productGroupsMap, landingData, gettingLandingData, landingError, user, getUserError };
}

export default connect(mapStateToProps, {
    getProductGroups,
    getLanding,
    getUser
})(ProductGroupLanding);