import React from 'react';
import { pink_check } from '../../../assets/miscellaneous'
import { ProductGroupHighlightWrapper, ProductGroupHighlightText } from '../../../Style/ProductGroupLandingStyles'

export default function ProductGroupHighlight({ highlight }) {
    return (
        <ProductGroupHighlightWrapper>
            <img src={pink_check} alt="check" />
            <ProductGroupHighlightText>{highlight}</ProductGroupHighlightText>
        </ProductGroupHighlightWrapper>
    )
}