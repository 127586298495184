import customer from './MockCustomer';
import manifest from './MockManifest';
import roundedProducts from './MockRounder';

// TODO: Update with new fields

export default {
    preferredDeliveryDates: ["2021-01-06", "2021-01-13", "2021-01-20", "2021-01-27", "2021-02-03", "2021-02-10", "2021-02-17"],
    customer,
    manifest,
    roundedProducts
}