import React from 'react';
import { LineItemRow, LineItemGrandTotalTitle, LineItemGrandTotalValue } from '../../../Style/OrderSummaryStyles';

export default function GrandTotalLineItem({ title, value, checkout_summary }) {
    return (
        <LineItemRow checkout_summary={checkout_summary}>
            <LineItemGrandTotalTitle checkout_summary={checkout_summary}>{title}:</LineItemGrandTotalTitle>
            <LineItemGrandTotalValue checkout_summary={checkout_summary}>{value}</LineItemGrandTotalValue>
        </LineItemRow>
    )
}