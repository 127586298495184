import styled, { css } from 'styled-components';
import { hearts_bg } from '../assets/backgrounds';
import { autoMarginLR, mediaQueries } from './Common';
import { Body1, Heading2 } from './DesignSystem/Typography';
import { conversationalPatternedBackground } from './'
import { colors } from './Colors';
import {  FlexColCenter, FlexRowStart } from './Flex';

export const ProductGroupImgWrapper = styled.div`
    text-align: center;
`

export const ProductGroupImg = styled.img`
    background: #F5F7FA;
    max-width: 100%;
    border-radius: 4px;
    width: auto;
    height: auto;
    ${mediaQueries("md")`
        margin-bottom: 16px;
    `}
`

export const HeartsBackground = styled.div`
    background-image: url(${hearts_bg});
    background-repeat: repeat;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const WhyWeLoveImg = styled.img`
    max-width: 100%;
    width: auto;
    height: auto;
`

export const ProductGroupDescriptionSectionContainer = styled.div`
    padding: 80px 20px 40px;
`

export const ProductGroupQuoteHeading = styled(Heading2)`
    ${{ ...autoMarginLR }}
    width: 100%;
    max-width: 1100px;
    text-align: left;
`

export const ProductGroupDetailsTabsContainer = styled.div`
    position: relative;
    height: 481px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQueries("md")`
        display: block;
        height: auto;
    `}
`

export const ProductGroupDetailsTabsWrapper = styled.div`
    background: ${colors.pureWhite};
    width: 500px;
    max-width: 100%;
    ${mediaQueries("md")`
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 120px;
    `}
`

const ProductGroupDetailsPositionedImg = styled.img`
    position: absolute;
`

export const ProductGroupDetailsPositionedImg1 = styled(ProductGroupDetailsPositionedImg)`
    left: 0;
    -webkit-transform: rotate(90deg) scale(1.5); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(90deg) scale(1.5); /* IE 9 */
    transform: rotate(90deg) scale(1.5);
    ${mediaQueries("md")`
        display: none;
    `}
`

export const ProductGroupDetailsPositionedImg2 = styled(ProductGroupDetailsPositionedImg)`
    right: 0;
    -webkit-transform: rotate(90deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(90deg); /* IE 9 */
    transform: rotate(90deg);
    ${mediaQueries("md")`
        right: -40px;
        bottom: -240px;
        -webkit-transform: rotate(270deg); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(270deg); /* IE 9 */
        transform: rotate(270deg);
    `}
`

export const ProductGroupHighlightContainer = styled.div`
    ${{ ...autoMarginLR }};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px 12px;
`

export const ProductGroupHighlightWrapper = styled.div`
    width: 460px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
`

export const ProductGroupHighlightText = styled(Body1)`
    padding-left: 8px;
    margin-bottom: 0;
`

export const ProductGroupsLandingBetterWayContainer = styled.div`
    background: ${conversationalPatternedBackground("#E0EFFF")};
    padding-top: 240px;
`

export const ProductGroupsLandingFAQBody = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const WhyWeLoveSectionContainer = styled.div`
    position: relative;
    z-index: 2;
    ${mediaQueries("md")`
        padding-bottom: 40px;
    `}
`

export const ProductGroupLandingDetailImg = styled.img`
    height: 25px;
    width: 25px;
    margin-right: 8px;
`

export const ProductGroupBundleRowContainer = styled.div`
    margin-top: 20px;
`

export const OtherOfferingsContainer = styled.div`
    padding-top: 20px;
`

export const ProductGroupPricingContainer = styled(FlexRowStart)`
    margin-top: 20px;
    flex-wrap: wrap;
`

export const ProductGroupPriceWrapper = styled.div`
    display: inline-block;
    padding: 12px;
    margin-right: 8px;
    ${props => props.withBackground && css`
        background: #F5F7FA;
        border-radius: 4px;
    `}
`

export const ProductGroupPriceHeading = styled.h3`
    font-family: "Muli", sans-serif;
    font-size: 28px;
    display: inline;
    ${props => props.strikethrough && css`
        text-decoration: line-through;
    `}
`

export const ProductGroupPriceBody = styled.p`
    margin-bottom: 0;
    text-align: right;
`

export const ProductGroupPriceDollarSign = styled.sup`
    font-weight: normal;
    top: -0.25em;
`

export const ProductGroupDiscountTextBody = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    padding: 12px;
    & > p {
        margin-bottom: 0;
    }
    & > h3 {
        margin-bottom: 0;
        color: ${colors.textGreen};
        text-align: left;
    }
`

export const ProductGroupPriceTextWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
`

export const ProductGroupDisplayUnitsWrapper = styled(FlexColCenter)`
    padding: 12px;
    height: fit-content;
`