import React from 'react';
import { Subtitle2 } from '../../../../Style/DesignSystem/Typography';
import { ManifestBundleHeader as Main, BundleHeaderBody } from '../../../../Style/Manifest';

export const ManifestBundleHeader = ({
    title,
    bundleShortName,
    isDashboard
}) => {
    return (
        <Main isDashboard={isDashboard} className={`${bundleShortName}-bundle-header`}>
            <BundleHeaderBody isDashboard={isDashboard}>
                <Subtitle2 className="no-margin">{title}</Subtitle2>
            </BundleHeaderBody>
        </Main>
    )
}