export default {
    "productSets": {
        "TrashBagsCompost": {
            "preferred": {
                "groupName": "trash-bags-compost-glad-lemon",
                "proposedArticles": 20,
                "articlesPerDay": 0.0
            },
            "additional": [
            ]
        },
        "HandSoap": {
            "preferred": {
                "groupName": "hand-soap-soapbox-deep-moisture-coconut-milk-and-sandlewood",
                "proposedArticles": 1,
                "articlesPerDay": 0.010967741935483872
            },
            "additional": [
                {
                    "groupName": "hand-soap-mrs-myers-basil",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.010967741935483872
                },
                {
                    "groupName": "hand-soap-dial-gold",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.01741935483870968
                },
                {
                    "groupName": "hand-soap-puracy-natural-lavender-vanilla-refill",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.001935483870967742
                },
                {
                    "groupName": "hand-soap-puracy-natural-cucumber-mint",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.010967741935483872
                },
                {
                    "groupName": "hand-soap-soapbox-soothing-moisture-lavender-&-marshmallow-root",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.010967741935483872
                },
                {
                    "groupName": "hand-soap-softsoap-soothing-aloe-vera",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.01741935483870968
                },
                {
                    "groupName": "hand-soap-puracy-natural-lavender-vanilla",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.010967741935483872
                }
            ]
        },
        "SurfaceCleaner": {
            "preferred": {
                "groupName": "surface-cleaner-mrs-myers-multi-surface-lemon-verbena",
                "proposedArticles": 1,
                "articlesPerDay": 0.003870967741935484
            },
            "additional": [
                {
                    "groupName": "surface-cleaner-seventh-generation-professional-lemongrass-citrus",
                    "proposedArticles": 70,
                    "articlesPerDay": 2.2580645161290325
                },
                {
                    "groupName": "surface-cleaner-pledge-antibacterial-cleaner-fresh-citrus",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.003870967741935484
                },
                {
                    "groupName": "surface-cleaner-puracy-disinfecting-surface-free-and-clear",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.001935483870967742
                },
                {
                    "groupName": "surface-cleaner-fantastik-disinfectant",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                },
                {
                    "groupName": "surface-cleaner-clorox-clean-up-disinfectant-cleaner-with-bleach",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                },
                {
                    "groupName": "surface-cleaner-lysol-all-purpose-disinfecting-lemon-breeze",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                },
                {
                    "groupName": "surface-cleaner-method-all-purpose-french-lavender",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.001935483870967742
                },
                {
                    "groupName": "disinfecting-wipes-clorox-crisp-lemon",
                    "proposedArticles": 70,
                    "articlesPerDay": 2.2580645161290325
                },
                {
                    "groupName": "surface-cleaner-formula-409-antibacterial-kitchen-lemon-scent",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                },
                {
                    "groupName": "surface-cleaner-clorox-clean-up-and-bleach-original",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                },
                {
                    "groupName": "surface-cleaner-puracy-natural-multi-surface-green-tea-and-lime",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.001935483870967742
                }
            ]
        },
        "FaceMasksSurgical": {
            "preferred": {
                "groupName": "masks-armbrust-usa-made-disposable-surgical-mask-american-blue",
                "proposedArticles": 50,
                "articlesPerDay": 0.4838709677419355
            },
            "additional": [
            ]
        },
        "DishSoap": {
            "preferred": {
                "groupName": "dish-soap-mrs-meyers-lemon-verbena",
                "proposedArticles": 1,
                "articlesPerDay": 0.0035483870967741938
            },
            "additional": [
                {
                    "groupName": "dish-soap-puracy-natural-green-tea-&-lime-refill",
                    "proposedArticles": 1,
                    "articlesPerDay": 9.67741935483871E-4
                },
                {
                    "groupName": "dish-soap-puracy-natural-citrus-&-sea-salt",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0035483870967741938
                },
                {
                    "groupName": "dish-soap-puracy-natural-green-tea-and-lime",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0035483870967741938
                },
                {
                    "groupName": "dish-soap-puracy-natural-citrus-&-sea-salt-refill",
                    "proposedArticles": 1,
                    "articlesPerDay": 9.67741935483871E-4
                },
                {
                    "groupName": "dish-soap-seventh-generation-free-&-clear",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0022580645161290325
                },
                {
                    "groupName": "dish-soap-dawn-ultra-original",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0029032258064516127
                },
                {
                    "groupName": "dish-soap-palmolive-ultra-strength-original-scent",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0029032258064516127
                }
            ]
        },
        "HandSanitizer": {
            "preferred": {
                "groupName": "hand-sanitizer-soapbox-light-citrus-scent",
                "proposedArticles": 1,
                "articlesPerDay": 0.016129032258064516
            },
            "additional": [
                {
                    "groupName": "hand-sanitizer-lxr-biotech-advanced-spray",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.03225806451612903
                }
            ]
        },
        "LaundryDetergent": {
            "preferred": {
                "groupName": "laundry-detergent-tide-high-efficiency-2x-original-scent",
                "proposedArticles": 1,
                "articlesPerDay": 0.004032258064516129
            },
            "additional": [
                {
                    "groupName": "laundry-detergent-tide-high-efficiency-free-gentle",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.004451612903225806
                },
                {
                    "groupName": "laundry-detergent-puracy-natural-liquid-free-and-clear",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0016129032258064516
                }
            ]
        },
        "TrashBagsKitchen": {
            "preferred": {
                "groupName": "trash-bags-kitchen-glad-forceflex-drawstring",
                "proposedArticles": 40,
                "articlesPerDay": 0.12903225806451613
            },
            "additional": [
            ]
        },
        "TrashBagsOutdoor": {
            "preferred": {
                "groupName": "trash-bags-outdoor-glad-forceflex-plus-drawstring",
                "proposedArticles": 25,
                "articlesPerDay": 0.0
            },
            "additional": [
                {
                    "groupName": "trash-bags-outdoor-glad-strong-lawn-and-leaf-quick-tie",
                    "proposedArticles": 12,
                    "articlesPerDay": 0.0
                }
            ]
        },
        "ToiletPaper": {
            "preferred": {
                "groupName": "toilet-paper-charmin-ultra-soft-mega",
                "proposedArticles": 6,
                "articlesPerDay": 0.11
            },
            "additional": [
                {
                    "groupName": "toilet-paper-angel-soft-mega-rolls",
                    "proposedArticles": 6,
                    "articlesPerDay": 0.08258064516129032
                },
                {
                    "groupName": "toilet-paper-charmin-ultra-strong-mega",
                    "proposedArticles": 6,
                    "articlesPerDay": 0.10161290322580645
                },
                {
                    "groupName": "toilet-paper-cottonelle-ultra-clean-care-mega",
                    "proposedArticles": 6,
                    "articlesPerDay": 0.0996774193548387
                },
                {
                    "groupName": "toilet-paper-angel-soft-double-rolls",
                    "proposedArticles": 4,
                    "articlesPerDay": 0.1338709677419355
                },
                {
                    "groupName": "toilet-paper-caboo-super-soft-bamboo-double-rolls",
                    "proposedArticles": 4,
                    "articlesPerDay": 0.1361290322580645
                },
                {
                    "groupName": "toilet-paper-cottonelle-ultra-cleancare-double-rolls",
                    "proposedArticles": 8,
                    "articlesPerDay": 0.1993548387096774
                },
                {
                    "groupName": "toilet-paper-charmin-ultra-strong-doubles",
                    "proposedArticles": 6,
                    "articlesPerDay": 0.20290322580645162
                }
            ]
        },
        "Bleach": {
            "preferred": {
                "groupName": "bleach-clorox-concentrated-disinfecting",
                "proposedArticles": 1,
                "articlesPerDay": 0.0029032258064516127
            },
            "additional": [
            ]
        },
        "TrashBagsSmall": {
            "preferred": {
                "groupName": "trash-bags-small-glad-febreze-fresh-quick-tie",
                "proposedArticles": 26,
                "articlesPerDay": 0.0
            },
            "additional": [
            ]
        },
        "TrashBagsRecycling": {
            "preferred": {
                "groupName": "trash-bags-recycle-glad-blue-drawstring",
                "proposedArticles": 45,
                "articlesPerDay": 0.0
            },
            "additional": [
            ]
        },
        "DishwasherDetergent": {
            "preferred": {
                "groupName": "dishwasher-detergent-finish-powerball-super-charged",
                "proposedArticles": 1,
                "articlesPerDay": 0.0022580645161290325
            },
            "additional": [
                {
                    "groupName": "dishwasher-detergent-seventh-generation-powerful-clean-lemon-scent",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0032258064516129032
                },
                {
                    "groupName": "dishwasher-detergent-cascade-complete-gel-fresh-scent-fl-oz",
                    "proposedArticles": 75,
                    "articlesPerDay": 0.13064516129032258
                },
                {
                    "groupName": "dishwasher-detergent-cascade-complete-gel-fresh-scent",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0029032258064516127
                },
                {
                    "groupName": "dishwasher-detergent-cascade-action-pacs-with-dawn-fresh-scent",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0022580645161290325
                },
                {
                    "groupName": "dishwasher-detergent-puracy-natural-packs-free-clear",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0025806451612903226
                }
            ]
        },
        "FacialTissue": {
            "preferred": {
                "groupName": "facial-tissue-kleenex-anti-viral",
                "proposedArticles": 1,
                "articlesPerDay": 0.0067741935483870966
            },
            "additional": [
                {
                    "groupName": "facial-tissue-kleenex-ultrasoft-cube",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0067741935483870966
                },
                {
                    "groupName": "facial-tissue-puffs-cube-plus-lotion",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.00870967741935484
                },
                {
                    "groupName": "facial-tissue-caboo-soft-sustainable-bamboo",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.005483870967741936
                },
                {
                    "groupName": "facial-tissue-kleenex-ultrasoft-flat",
                    "proposedArticles": 1,
                    "articlesPerDay": 0.0032258064516129032
                }
            ]
        },
        "DryerSheets": {
            "preferred": {
                "groupName": "dryer-sheets-bounce-outdoor-fresh",
                "proposedArticles": 34,
                "articlesPerDay": 0.12903225806451613
            },
            "additional": [
            ]
        },
        "HandWipes": {
            "preferred": {
                "groupName": "hand-wipes-handzies-free-and-clear",
                "proposedArticles": 24,
                "articlesPerDay": 0.7741935483870968
            },
            "additional": [
            ]
        },
        "PaperTowels": {
            "preferred": {
                "groupName": "paper-towels-bounty-select-a-size-double",
                "proposedArticles": 3,
                "articlesPerDay": 0.0732258064516129
            },
            "additional": [
                {
                    "groupName": "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                    "proposedArticles": 3,
                    "articlesPerDay": 0.08774193548387098
                },
                {
                    "groupName": "paper-towels-viva-signature-cloth-choose-a-sheet",
                    "proposedArticles": 3,
                    "articlesPerDay": 0.0996774193548387
                },
                {
                    "groupName": "paper-towels-caboo-sustainable-bamboo",
                    "proposedArticles": 4,
                    "articlesPerDay": 0.12903225806451613
                },
                {
                    "groupName": "paper-towels-bounty-select-a-size",
                    "proposedArticles": 4,
                    "articlesPerDay": 0.1167741935483871
                }
            ]
        }
    }
}