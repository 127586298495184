import React, {Component, Fragment} from 'react';
import * as images from '../../assets';
import { MetaTags, Nav, OutageBanner} from '../Common';
import { featureFlags } from '../../config';
import { Link } from 'react-router-dom';
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

class AvailabilityWaitlist extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isZipValid: true,
            isEmailValid: true,
            zipcode: "",
            email: ""
        };
        this.back = this.back.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.setDeliveryDate)
    }

    componentDidMount() {
        if(prod) {
          window.rdt('track', 'ViewContent')
        }
        if (this.props.location && this.props.location.state && this.props.location.state.signUpData)
            this.setSignUpData(this.props.location.state.signUpData)
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData, ...signUpData })
            this.setStateForKeyValue(signUpData);
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        this.props.history.goBack();
    }

    renderSubheader(){
        const { confirmed, zipcode, signUpData } = this.state;
        if (!confirmed) {
            return (
                <div>
                    <h2 className="gate-header">We're sorry</h2>
                    <p className="interview-copy small">Supply Drop isn't currently available{featureFlags.block_signups ? "" : " in your area"}. Enter your email below and we'll let you know when we are.</p>
                    {zipcode && !featureFlags.block_signups && <Link to={{ pathname: "/check-availability", state:{signUpData}}}>Change zipcode</Link>}
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf0hky9BDZs3pjEJ-uECvUOJbfpFXAhM2t6qfFMHUwVIZx1Mg/viewform?embedded=true" title="Supply Drop Waitlist Form" width="100%" height="1300px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            )
        } else {
            return (
                <h2 className="gate-header">Thanks, we'll be in touch!</h2>
            )
        }
    }

    renderImg() {
        return <div className="interview-header-img"><img src={images["availability_denied"]} alt="Availability" /></div>
    }   

    render() {
        const {zipcode, approved} = this.state;
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Check Availability" />
                <OutageBanner />
                <Nav pathname="/" spaceTopLg={zipcode && approved} back={this.back} />
                <div className="interview-outer">
                    <div className="interview-inner fadeInRight animated-fast text-center">
                        {this.renderImg()}
                        {this.renderSubheader()}
                    </div>
                </div>
            </Fragment>			
        )
    }
    
}

export default AvailabilityWaitlist;
