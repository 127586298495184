import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import withTracker from '../analytics/withTracker';

import Magiclink from '../components/Main/Magiclink';
import CheckAvailability from '../components/AvailabilityGate/CheckAvailability';
import AvailabilitySuccess from '../components/AvailabilityGate/AvailabilitySuccess';
import BundleLanding from '../components/Landing/BundleLanding';
import ProductGroupLanding from '../components/Landing/ProductGroup/ProductGroupLanding';
import AvailabilityWaitlist from '../components/AvailabilityGate/AvailabilityWaitlist';
import OurPromise from '../components/Main/OurPromise';
import Science from '../components/Landing/Science';
import About from '../components/Landing/About';
// Everyday
import Household from '../components/Interview/Everyday/Household';
import Bathrooms from '../components/Interview/Everyday/Bathrooms';
import TrashPreferences from '../components/Interview/Everyday/TrashPreferences';
import TierPreferences from '../components/Interview/Everyday/TierPreferences';

// Kitchen
import Cooking from '../components/Interview/Kitchen/Cooking';
import DishwashingFrequency from '../components/Interview/Kitchen/DishwashingFrequency';
import Cleaning from '../components/Interview/Kitchen/Cleaning';

import LaundryFrequency from '../components/Interview/Laundry/LaundryFrequency';
import Recommendation from '../components/Interview/Recommendation';
import Checkout from '../components/Checkout/Checkout';
import OrderComplete from '../components/Checkout/OrderComplete';
import Dashboard from '../components/Dashboard/Dashboard';
import Account from '../components/Dashboard/Account';
import Receipt from '../components/Dashboard/Receipt';
import FAQs from '../components/Dashboard/FAQs';
import OrderHistory from '../components/Dashboard/OrderHistory';
import Login from '../components/Main/Login';
import TraditionalLogin from '../components/Main/TraditionalLogin';

export default function ControlRoutes() {
    return (
        <Fragment>
            {/* Science */}
            <Route path="/about" component={withTracker(About)} exact />
            <Route path="/science" component={withTracker(Science)} exact />

            {/* Products */}
            <Route path="/products/:groupName" component={withTracker(ProductGroupLanding)} exact />
            <Route path="/products/bundles/:bundleId" component={withTracker(BundleLanding)} exact />

            {/* Availability */}
            <Route path="/check-availability" component={withTracker(CheckAvailability)} exact />
            <Route path="/check-availability/success" component={withTracker(AvailabilitySuccess)} exact />
            <Route path="/check-availability/waitlist" component={withTracker(AvailabilityWaitlist)} exact />

            {/* Interview Questions */}
            <Route path="/our-promise" component={withTracker(OurPromise)} exact />
            <Route path="/start/1" component={withTracker(Household)} exact />
            <Route path="/start/2" component={withTracker(Bathrooms)} exact />
            <Route path="/start/3" component={withTracker(TrashPreferences)} exact />
            <Route path="/kitchen/1" component={withTracker(Cooking)} exact />
            <Route path="/kitchen/2" component={withTracker(DishwashingFrequency)} exact />
            <Route path="/kitchen/3" component={withTracker(Cleaning)} exact />
            <Route path="/laundry/1" component={withTracker(LaundryFrequency)} exact />
            <Route path="/preference" component={withTracker(TierPreferences)} exact />

            {/* Checkout */}
            <Route path="/recommendation" component={withTracker(Recommendation)} exact />
            <Route path="/checkout" component={withTracker(Checkout)} exact />
            <Route path="/ordercomplete" component={withTracker(OrderComplete)} exact />

            {/* Dashboard */}
            <Route path="/dashboard" component={withTracker(Dashboard)} exact />
            <Route path="/dashboard/orders" component={withTracker(OrderHistory)} exact />
            <Route path="/dashboard/account" component={withTracker(Account)} exact />
            <Route path="/dashboard/account/enroll_employee/:employerSlug" component={withTracker(Account)} exact />
            <Route path="/dashboard/order/:orderId" component={withTracker(Receipt)} exact />
            <Route path="/dashboard/faqs" component={withTracker(FAQs)} />

            {/* Login */}
            <Route path="/login" component={withTracker(Login)} exact />
            <Route path="/login/classic" component={withTracker(TraditionalLogin)} exact />
            <Route path="/magiclink" component={withTracker(Magiclink)} exact />
        </Fragment>
    )
}