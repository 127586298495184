import styled, { css } from 'styled-components';
import { colors } from './Colors'
import { mediaQueries } from './Common';
import { Body2, Subtitle1 } from './DesignSystem/Typography';

export const CheckoutSummaryHeaderBlock = styled.div`
    background: #FAFBFE;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const CartSummaryContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 9px;
    margin-top: -28px;
    margin-bottom: -28px;
    margin-right: -28px;
    padding: 28px 15px;
    ${props => props.checkout_summary && css`
        padding: 0 0 20px 0;
    `}
    ${props => !props.orderComplete && css`
        border-left: 1px solid ${colors.borderLight};
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    `}
    ${mediaQueries("md")`
        margin-left: auto;
        margin-right: auto;
        width: 600px;
        max-width: 95vw;
        background: #FFF;
    `}
`

export const LineItemsContainer = styled.div`
    background: ${colors.pureWhite};
    border-radius: 8px;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    width: 320px;
    overflow-y: scroll;
    max-height: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    ${props => !props.isModal && css`
        border: none;
        box-shadow: none;
        margin-right: auto;
        margin-left: auto;
        ${props.checkout_summary && css`
            width: 100%;
        `}
    `}
`

export const LineItemRow = styled.div`
    display: flex;
    padding: 12px 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
    }
    ${props => props.checkout_summary && css`
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 20px;
    `}
`

const LineItemCopy = styled.div`
    font-size: 14px;
    text-align: right;
    ${props => props.coupon && css`
        color: ${colors.textGreen};
    `}
    `

export const LineItemSubtotalTitle = styled(LineItemCopy)`
    font-weight: bold;
    flex: calc(2/3);
    ${props => props.checkout_summary && css`
        text-align: left;
    `}
`

export const LineItemSubtotalValue = styled(LineItemCopy)`
    flex: calc(1/3);
`

export const LineItemGrandTotalTitle = styled(LineItemSubtotalTitle)`
    font-size: 18px;
`

export const LineItemGrandTotalValue = styled(LineItemSubtotalValue)`
    font-size: 18px;
`

export const ProductImgWrapper = styled.div`
    height: 60px;
    width: 60px;
    overflow: visible;
    position: relative;
    & :hover {
        border-radius: 500px;
        background: #F2F3F7;
    }
`

export const ProductImg = styled.img`
    height: 60px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    position: relative;
`

export const OrderSummaryHeaderTitle = styled(Subtitle1)`
    margin-bottom: 0;
    padding-left: 20px;
`

export const ViewDetailsLink = styled(Body2)`
    padding-left: 20px;
    color: ${colors.btnBlue.idle};
    font-weight: normal;
`

export const OrderSummaryDivider = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    background: ${colors.borderLight};
    height: 2px;
    width: calc(100% - 40px);
`