import React from 'react';
import {
	MegaMenuContainer as Container,
	MegaMenuCol as Col,
	MegaMenuImgContainer as ImgContainer,
	MegaMenuImg as Img,
	MegaMenuTitle as Title,
	MegaMenuItem as Item,
} from '../../../Style';
import { bundles } from '../../../data';
import BundlePageLink from './BundlePageLink'

export const MegaMenu = ({ history, setOpen, spaceTop }) => {
	const { search } = window.location;

	return (
		<Container onMouseEnter={() => setOpen(true)} onMouseLeave={() => { setOpen(false) }} spaceTop={spaceTop}>
			{
				Object.entries(bundles).map(entry => {
					const bundleId = entry[0], values = entry[1];
					const { title, hero, productTypes } = values;
					const productTypesArr = [];
					productTypes.forEach(productType => {
						if (productType !== "DryerSheets" && productType !== "TrashBagsCompost") {
							productTypesArr.push(<Item key={productType}><BundlePageLink productType={productType} bundleId={bundleId} /></Item>)
						}
					})
					return (
						<Col key={bundleId}>
							<ImgContainer onClick={() => history.push({ pathname: `/products/bundles/${bundleId}`, search })}>
								<Img src={hero} alt={title} title={title} />
							</ImgContainer>
							<Title onClick={() => history.push({ pathname: `/products/bundles/${bundleId}`, search })}>{title}</Title>
							{productTypesArr}
						</Col>
					)
				})
			}
		</Container>
	)
}