import React, { Component } from 'react';
import { Loading as Main } from '../Common/'
import { LoadingContainer } from "../../Style";

export default class Loading extends Component {
    render() {
        return (
            <LoadingContainer>
                <Main />
            </LoadingContainer>
        )
    }
}