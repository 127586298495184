import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import * as images from '../../assets';
import '../../Style/landing.css';
import Footer from '../Common/Footer'
import {
    LandingNav,
    ProductDetail,
    DocModal,
    MetaTags,
    FAQ,
    ProductCheckmarkRow,
    TestimonialCardCarousel,
    BetterWay,
    LandingTwoUpSection,
    Hero,
    HowItWorksSection
} from '../Common';
import {
    getLanding,
    getProductGroups,
    getFAQs,
    parseSearchParams,
    postProspect,
    getUser
} from '../../actions';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import {
    LandingMain as Main,
    LandingContainer,
    LandingRow as Row,
    LandingSection as Section,
    LandingSectionBody as SectionBody,
    LandingBundle as Bundle,
    LandingBundleImg as BundleImg,
    H2Amaranth as H2,
    LandingCTA as CTA,
    LandingFAQsContainer as FAQsContainer,
    LandingFAQsBody as FAQsBody,
    PageDivider,
    conversationalPatternedBackground,
    HomeHeroPattern,
    HomeBlockCTA,
    flexCenter,
    LocaleurCoverImg
} from '../../Style';
import { bundles, getCouponCampaign, localeurTestimonials, defaultZipcodes } from '../../data';
import { featureFlags } from '../../config';

class Localeur extends Component {
    constructor(props) {
        super(props);

        this.state = {
            utm_campaign: 'coupon_localeur'
        };

        this.handleResize = this.handleResize.bind(this);
        this.handleSearchParams = this.handleSearchParams.bind(this);
        this.setFromSearchParams = this.setFromSearchParams.bind(this);
        this.setIdentityBreadcrumb = this.setIdentityBreadcrumb.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);

        this.props.getProductGroups();
        this.props.getFAQs();

        this.handleResize();

        const { search } = window.location;
        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            this.props.getUser();
            Analytics.setUser(data.username);
            this.handleSearchParams(search)
        }).catch(err => {
            this.handleSearchParams(search)
            this.setIdentityBreadcrumb();
        })
    }

    componentDidUpdate() {
        const { productGroups, landingData, landingError } = this.props;
        if (productGroups && !this.state.productGroups) {
            const productGroupsMap = {};
            productGroups.forEach(pg => {
                productGroupsMap[pg.groupName] = pg;
            })
            this.setState({ productGroups, productGroupsMap });
        }

        if (landingError && this.state.landingError) {
            this.setState({ landingError });
            Sentry.captureException(landingError);
        }

        if (landingData && !this.state.landingData) {
            this.setState({ landingData, ...landingData })
        }
    }

    setFromSearchParams(search) {
        const { searchData } = parseSearchParams(search);
        if(searchData) {
            const { utm_campaign, utm_content, utm_source, employer, fbclid, rc } = searchData;
            this.setState({ utm_campaign, utm_content, utm_source, employerSlug: employer, fbclid, rc });
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    handleSearchParams(search) {
        if (!search) {
            this.redirectWithParams()
        } else {
            const { searchData } = parseSearchParams(search);
            if(searchData) {
                // For Localeur Email campaign
                const { rc } = searchData;
                if(!rc)
                    search += '&rc=localeur'

                this.props.getLanding(search);
                this.setFromSearchParams(search);
            }
        }
    }

    redirectWithParams() {
        const campaignData = getCouponCampaign('localeur');

        if (campaignData) {
            const {
                utm_campaign,
                utm_medium,
                utm_content,
                utm_source,
                rc
            } = campaignData
            const search = `?utm_campaign=${utm_campaign}&utm_content=${utm_content}&utm_source=${utm_source}&utm_medium=${utm_medium}&rc=${rc}`;
            this.props.getLanding(search);
            this.props.history.push({ pathname: "/localeur", search })
        }
    }

    handleResize(e) {
        const { isMobile, isTablet } = this.state;
        if (window.innerWidth < 768 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 767 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 992 && !isTablet) {
            this.setState({ isTablet: true })
        }
        if (window.innerWidth >= 991 && isTablet) {
            this.setState({ isTablet: false })
        }
    }

    toggleMenu() {
        this.setState({ isShowingMenu: !this.state.isShowingMenu })
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/our-promise';

            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    renderFixedNav() {
        const { aptCampaign, zipcode, isTablet, isShowingMenu, isMobile, utm_campaign, isSignedIn } = this.state;
        const buttonText = isSignedIn ? "My Dashboard" : "Get Started";
        return (
            <LandingNav
                history={this.props.history}
                isTablet={isTablet}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                aptCampaign={aptCampaign}
                spaceTop={zipcode}
                buttonText={buttonText}
                next={this.onGetStartedClick}
                toggleMenu={this.toggleMenu}
                isShowingMenu={isShowingMenu}
                utm_campaign={utm_campaign}
            />
        )
    }

    renderProductDetail() {
        const { showProduct } = this.state;
        if (showProduct) {
            return (
                <ProductDetail productGroup={showProduct} close={() => this.setState({ showProduct: undefined })} />
            )
        }
    }

    renderCovidResponse() {
        const { showCovidResponse } = this.state;
        if (showCovidResponse) {
            return (
                <DocModal doc="covid" hide={() => this.setState({ showCovidResponse: false })} />
            )
        }
    }

    renderHeroSection() {
        const {
            isEmployeePerk,
            isMobile,
            isSignedIn,
            isTablet,
            newCoupon,
            signUpData,
            utm_campaign,
            utm_content
        } = this.state;
        const { history } = this.props;
        const positionedImages = [
            <HomeHeroPattern src={images.home_hero_pattern} alt="" isHome={true} />
        ]

        return (
            <Hero
                ctaCopy="Get Started"
                coupon={newCoupon}
                header="We help when you travel, they help when you're home."
                heroImg={{
                    alt: "Supply Drop Hero Image - Localeur",
                    src: images.joah_hero
                }}
                handleCTAClick={this.onGetStartedClick}
                history={history}
                isEmployeePerk={isEmployeePerk}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                isTablet={isTablet}
                positionedImages={positionedImages}
                signUpData={signUpData}
                subheader="Time at home has never been more important. Find out why Localeur loves the Smart Subscription with Supply Drop."
                utm_campaign={utm_campaign}
                utm_content={utm_content}
            />
        )
    }

    renderAboutAustin() {
        const { isTablet } = this.state;
        const secondaryBtn = (
            <div className="text-left space-top">
                <HomeBlockCTA onClick={this.onGetStartedClick} id="home-block-cta">Get Started</HomeBlockCTA>
            </div>
        )

        const overflowStyles = isTablet ? {
            width: "calc(100% + 64px)",
            marginLeft: -32,
            marginTop: 20,
            maxWidth: "none",
            height: "100%"
        } : {
            position: "absolute",
            right: 12
        }

        const body = (
            <Fragment>
                Localeur launched in Austin back in 2013 to help you experience local wherever you travel, but we've never lost sight of the city that shaped us. Supply Drop—founded by two Austin entrepreneurs—is precisely the type of locally born, locally attuned startup that we can get behind. Not only does the team behind Supply Drop know tech; they also know that hopping onto I-35 or Mopac to drive to Wal-Mart, Target or Costco isn't the perfect way to spend a day in Austin. You'd much rather be outside enjoying the weather, listening to music or having a beer. This is why the Smart Subscription is something we're into.
                <br /><br />
                <b>Here's what you need to know:</b> Fill out a simple quiz and Supply Drop immediately starts learning exactly what home essentials you need. Whether it's for your kitchen, for cleaning up or for doing laundry, Supply Drop delivers the products that are right for you - right to your doorstep. We know you've grown over-dependent on Amazon during the pandemic, but Supply Drop is here to help without missing a beat or sacrificing on your budget or time. 
            </Fragment>
        )

        const imgContainerStyles = isTablet ? {
            height: "auto",
            margin: "-50px 0 -32px",
            maxHeight: 690,
            maxWidth: 500
        } : {
            height: "calc(100% + 40px)",
            margin: "-32px 0",
            minHeight: 1000,
            position: "relative"
        }

        return (
            <LandingTwoUpSection
                header="We know a little about Austin"
                body={body}
                bodyContainerStyle={{
                    padding: "12px 12px 72px 12px"
                }}
                mainImg={{
                    alt: "Unboxing a magical experience",
                    src: images.joah_unboxing,
                }}
                isReverse={!isTablet}
                isRaised={true}
                raisedSectionStyles={{
                    background: conversationalPatternedBackground("rgb(229, 242, 255)"),
                    minHeight: "100%",
                    paddingTop: isTablet ? 0 : 'auto',
                    overflowY: "hidden",
                    zIndex: 2,
                    ...flexCenter
                }}
                cta={secondaryBtn}
                overflowStyles={overflowStyles}
                isOverflowX={!isTablet}
                imgContainerStyles={imgContainerStyles}
            />
        )
    }

    renderBundles() {
        try {
            const rows = Object.entries(bundles).map(entry => {
                const bundleId = entry[0], bundle = entry[1];
                const { icon, title, displayProductTypes } = bundle;
                const productRows = displayProductTypes.map(productType => {
                    return <ProductCheckmarkRow key={productType} productType={productType} landing={true} />
                })
                return (
                    <Bundle key={title} onClick={() => this.props.history.push(`/products/bundles/${bundleId}`)}>

                        <BundleImg src={icon} alt={title} />
                        <h4 className="landing-bundle-header">{title}</h4>
                        <div className="landing-bundle-checkmark-container">
                            {productRows}
                        </div>
                    </Bundle>
                )
            })
            return (
                <Fragment>
                    <H2>Everything your household needs</H2>
                    {rows}
                    <Row>
                        <CTA onClick={this.onGetStartedClick}>Build your custom plan</CTA>
                    </Row>
                </Fragment>
            )
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    renderHowItWorks() {
        return <HowItWorksSection />
    }

    renderTestimonialsAndBetterWay() {
        const { isSignedIn } = this.state;
        return (
            <div style={{ background: conversationalPatternedBackground("#E0EFFF") }}>
                <TestimonialCardCarousel testimonials={localeurTestimonials} style={{ paddingTop: 80, paddingBottom: 80 }} />
                <BetterWay
                    signUpData={this.state.signUpData}
                    isRelative={true}
                    pathname='/our-promise'
                    handleCTAClick={this.onGetStartedClick}
                    isSignedIn={isSignedIn}
                    ctaCopy="Get Started"
                />
            </div>
        )
    }

    renderFAQs() {
        const { FAQs, FAQError } = this.state;
        let FAQMap = [];
        if (FAQs && !FAQError) {
            Object.entries(FAQs.FAQs).forEach(entry => {
                const title = entry[0];
                const bodyHtml = entry[1].bodyHtml;
                if (entry[1].category === "New Members") {
                    FAQMap.push(<FAQ key={title} title={title} bodyHtml={bodyHtml} />)
                }
            })
        } else {
            const faqList = [
                {
                    title: "Is Supply Drop available in my area?",
                    bodyHtml: `<div>We are currently offering this magical experience only to certain zip codes in Austin, TX. Just click Get Started and enter your zip code to see if we are in your area or to sign-up for our wait list so we can alert you when we are in your area.</div>`
                },
                {
                    title: "Is shipping always free?",
                    bodyHtml: `<div>Yes, shipping is always free for orders over $20. If you need only a small amount of supplies on a regular basis we will automatically adjust your plan to deliver them every other month. When we prepare your monthly drop we will also look ahead and if you won’t need much the following month, we’ll go ahead and ship those supplies as well. This saves you money and it is also better for the planet.</div>`
                },
                {
                    title: "Can I change products or adjust quantities?",
                    bodyHtml: `<div>Of course! Text us or visit your dashboard and we’ll show you more options for you to choose from. You can make adjustments to the brands or the quantities you can get. It’s also easy to skip a month if you’ve got too much.</div>`
                },
                {
                    title: "How does the cost compare to my grocery store?",
                    bodyHtml: `<div>Our costs are comparable to shopping online or at your local store but without the hassle! There are also no hidden delivery fees or tips.</div>`
                },
                {
                    title: "Why do you need my mobile number and what do you use it for?",
                    bodyHtml: `
                        <Fragment>
                            <div>Your mobile number is used to verify your identity and keep your account secure. We’ll also text you when we are preparing a new drop for you so that you can review and make any adjustments. It’s easy and convenient. We may also text you to make sure you are happy with your drop, for example if you change the type of paper towel you get we may text you asking if you are happy with the change. But we’ll never spam you. We hate spam. </div><br />
                        <div>You can also text us, and because you’ve verified your number, we know it’s you. You can let us know you’re going out of town, having guests, got a new puppy, or are running low on something, and we'll make any adjustments for you!</div>
                        </Fragment>
                    `
                },
                {
                    title: "Can I pause or cancel anytime?",
                    bodyHtml: `<div>Of course, but we think you‘ll be so delighted that you won't want to! For more info visit <a class="pointer" href="https://supplydrop.com/dashboard/account">My Account</u> in your dashboard.</div>`
                },
                {
                    title: "Do you use my information for anything else?",
                    bodyHtml: `<div>Privacy is important to us. We hate spam and will never sell your personal information.</div>`
                },
                {
                    title: "What is your response to COVID-19?",
                    bodyHtml: `<div>Read our response to COVID-19 <a class="pointer" href="https://supplydrop.com/covid.html">here.</u></div>`
                },
                {
                    title: "How can I contact you?",
                    bodyHtml: `<div>
                        Existing customers can text us at <a href="tel:30389">30389</a>. Not a customer yet? You can email us at <a href="mailto:care@supplydrop.com">care@supplydrop.com</a> or give us a call at <a href="tel:+15125373009">(512) 537-3009</a>. We look forward to hearing from you!
                    </div>`
                },
            ]
            FAQMap = faqList.map(faq => {
                const { title, bodyHtml } = faq;
                return <FAQ key={title} title={title} bodyHtml={bodyHtml} />
            })
        }
        return (
            <FAQsContainer id="faqs">
                <H2 style={{ marginBottom: 28 }}>FAQs</H2>
                <FAQsBody>
                    {FAQMap}
                </FAQsBody>
            </FAQsContainer>
        )
    }

    render() {
        // Only being used for carousel:
        // const { isSignedIn, productGroupsMap, carousel } = this.state;
        return (
            <Main>
                <MetaTags
                    title="Supply Drop - Get Your Favorite Brands Delivered When You Need Them"
                    description="Try Supply Drop’s Smart Subscription today! We deliver household supplies you use every day based on your unique usage. Best prices. Free delivery."
                    robots="follow"
                />
                {this.renderFixedNav()}
                {this.renderProductDetail()}
                {this.renderCovidResponse()}
                <LandingContainer id="landing-container">
                    {this.renderHeroSection()}
                    {this.renderAboutAustin()}
                    <PageDivider isColored={true} isReverse={true} style={{ marginTop: -120, zIndex: 3, position: 'relative' }} />
                    <Section noBorder={true}>
                        <SectionBody style={{ paddingTop: 76 }}>
                            {this.renderBundles()}
                        </SectionBody>
                    </Section>
                    <LocaleurCoverImg />
                    {this.renderHowItWorks()}
                    <PageDivider isReverse={true} />
                    {this.renderTestimonialsAndBetterWay()}
                    <PageDivider />
                    <Section noBorder={true}>
                        <SectionBody noPadding={true}>
                            {this.renderFAQs()}
                        </SectionBody>
                    </Section>
                    <Section noBorder={true}>
                        <SectionBody noPadding={true} className="text-center">
                            <img src={images.founded_in_austin} alt="Founded in Austin in 2019" />
                        </SectionBody>
                    </Section>
                </LandingContainer>
                <Footer
                    history={this.props.history}
                    landing={true}
                />
            </Main>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError, postProspectSuccess, postProspectError, postingProspect } = state.user;
    const { landingData, gettingLandingData, landingError } = state.order;
    const { FAQs, FAQError, gettingFAQs } = state.FAQs;
    const { productGroups, productGroupsError } = state.product;
    return {
        landingData,
        gettingLandingData,
        landingError,
        productGroups,
        productGroupsError,
        FAQs,
        FAQError,
        gettingFAQs,
        postProspectSuccess,
        postProspectError,
        postingProspect,
        user,
        getUserError
    };
}

export default connect(mapStateToProps, {
    getLanding,
    getProductGroups,
    getFAQs,
    postProspect,
    getUser
})(Localeur);
