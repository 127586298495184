import React, { Component, Fragment } from 'react';
import { sortEveryday } from '../../../actions';
import { bundles } from '../../../data';
import { ManifestBundleHeader } from '../Manifest/ManifestBundleHeader';
import { SummaryTestimonials, ValueProps } from '../';
import * as images from '../../../assets';
import {
    round, getGrandTotal, getDiscountDollars,
    getCreditDollars, getDiscountString, getRemainingReferral,
    getDiscountDollarsEmployeePerk
} from '../../../helpers/pricing';
import { CustomerReferralInfo } from "../../Common"
import { shippingChargeCents, freeShippingMinCents } from '../../../data/ShippingCharges';
import CheckoutSummaryTitleSection from './CheckoutSummaryTitleSection'
import { CartSummaryContainer, LineItemsContainer, ViewDetailsLink, OrderSummaryDivider as HR } from '../../../Style/OrderSummaryStyles';
import OrderSummaryProductLineItem from './OrderSummaryProductLineItem';
import OrderSummaryProductCarousel from './OrderSummaryProductCarousel';
import SubtotalLineItem from './SubtotalLineItem';
import GrandTotalLineItem from './GrandTotalLineItem';
import { ProductDetail } from '../Modals';

export class OrderSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.setSubtotal = this.setSubtotal.bind(this);
        this.toggleBundle = this.toggleBundle.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
        this.showProductDetails = this.showProductDetails.bind(this);
        this.showProductDetailsModal = this.showProductDetailsModal.bind(this);
        this.clearProductDetails = this.clearProductDetails.bind(this);
    }

    componentDidUpdate() {
        const { tax } = this.props;
        if (tax && !this.state.tax) {
            this.setState({ tax });
        }
    }

    displayBundles() {
        const { manifestBundles } = this.props;
        if (manifestBundles) {
            let totals = 0
            const rows = [];
            Object.entries(manifestBundles).forEach(entry => {
                const bundleShortName = entry[0];
                const { productGroups, subtotal } = entry[1];
                if (subtotal) {
                    rows.push(
                        <Fragment key={entry}>
                            {this.renderBundleHeader(bundleShortName, subtotal)}
                            {this.renderProductGroupRows(productGroups, bundleShortName)}
                        </Fragment>
                    )
                    totals += subtotal;
                }
            })
            this.setSubtotal(totals)
            return rows;
        } else {
            return <Fragment />
        }
    }

    setSubtotal(subtotal) {
        if (subtotal !== this.state.subtotal) {
            this.setState({ subtotal });
        }
    }

    toggleBundle(bundleShortName) {
        this.setState({ [`hide_${bundleShortName}`]: !this.state[[`hide_${bundleShortName}`]] })
    }

    showProductDetails(productGroup) {
        this.setState({ hoveredProductGroup: productGroup })
    }

    showProductDetailsModal(productGroup) {
        this.setState({ selectedProductGroup: productGroup })
    }

    clearProductDetails() {
        this.setState({ hoveredProductGroup: undefined })
    }
    
    clearSelectedProductGroup() {
        this.setState({  selectedProductGroup: undefined })
    }

    renderBundleHeader(bundleShortName, subtotal) {
        const { checkout_summary } = this.props;
        if (!checkout_summary) {
            return (
                <ManifestBundleHeader
                    title={bundles[bundleShortName].title}
                    bundleShortName={bundleShortName}
                    subtotal={subtotal}
                    onHeaderClick={() => this.toggleBundle(bundleShortName)}
                    hideBundle={this.state[`hide_${bundleShortName}`]}
                    isDetailsView={true}
                />
            )
        }
    }

    renderProductGroupRows(productGroups, bundleShortName) {
        const { checkout_summary, isModal } = this.props;
        if (!this.state[`hide_${bundleShortName}`]) {
            const sortedProductGroups = sortEveryday(Object.values(productGroups));
            if (!checkout_summary || isModal) {
                return (
                    <div className="price-details-products-container">
                        {sortedProductGroups.map(productGroup => <OrderSummaryProductLineItem productGroup={productGroup} />)}
                    </div>
                )
            } else {
                return (
                    <OrderSummaryProductCarousel
                        productGroups={sortedProductGroups}
                        showProductDetails={this.showProductDetails}
                        showProductDetailsModal={this.showProductDetailsModal}
                        clearProductDetails={this.clearProductDetails}
                    />
                )
            }
        }
    }

    renderCoupon() {
        const { coupon, newCoupon, isEmployeePerk, employer, sdCredit, checkout_summary, employeePerkCoupon, usesRemaining, maxSdCredit } = this.props;
        const { subtotal } = this.state;
        if (subtotal) {
            let discountDollars = getDiscountDollars(coupon, newCoupon, subtotal / 100);
            let discountDollarsEmployeePerk = getDiscountDollarsEmployeePerk(employeePerkCoupon, subtotal - discountDollars);
            let discountCredit = getCreditDollars(sdCredit, subtotal / 100, (discountDollars+discountDollarsEmployeePerk), maxSdCredit)
            let title = "Discount: "
            let remainingReferralBalance = getRemainingReferral(sdCredit, discountCredit)
            let creditTitle = remainingReferralBalance ? `Supply Drop Credit (Remaining: $${round(remainingReferralBalance).toFixed(2)}):`
                : `Supply Drop Credit:`

            const discountString = getDiscountString(newCoupon, true, subtotal/100, usesRemaining);
            const showCreditDiscount = sdCredit ? true : false;
            let showEmployeePerkDiscount = false; //can be changed in if block below
            const showCouponDiscount = newCoupon ? true : false;
            if (isEmployeePerk && employer) {
                title = `${employer.displayName} pays `
                showEmployeePerkDiscount = true
            }
            if (discountString)
                title += discountString;
            return (
                <div>
                    {showCouponDiscount &&
                        <SubtotalLineItem
                            title={title}
                            checkout_summary={checkout_summary}
                            value={`-$${round(discountDollars).toFixed(2)}`}
                            coupon={true}
                        />
                    }
                    {showEmployeePerkDiscount &&
                        <SubtotalLineItem
                            title={title}
                            checkout_summary={checkout_summary}
                            value={`-$${round(discountDollarsEmployeePerk).toFixed(2)}`}
                            coupon={true}
                        />
                    }
                    {showCreditDiscount && 
                        <SubtotalLineItem
                            title={creditTitle}
                            checkout_summary={checkout_summary}
                            value={`-$${round(discountCredit).toFixed(2)}`}
                            coupon={true}
                            clickFx={() => this.setState({ showInfo: true })}
                        />
                    }
                    {!checkout_summary && <hr className="price-details-hr" />}
                </div>
            )    
        }
    }

    renderTax() {
        const { tax, checkout_summary } = this.props;
        if (tax) {
            return (
                <Fragment>
                    <SubtotalLineItem
                        checkout_summary={checkout_summary}
                        title="Tax:"
                        value={`$${(tax / 100).toFixed(2)}`}
                    />
                    {!checkout_summary && <hr className="price-details-hr" />}
                </Fragment>
            )
        }
    }

    renderSubtotal() {
        const { subtotal } = this.state;
        const { checkout_summary } = this.props;
        if (subtotal) {
            return (
                <Fragment>
                    <SubtotalLineItem
                        title="Subtotal:"
                        value={`$${(subtotal / 100).toFixed(2)}`}
                        checkout_summary={checkout_summary}
                    />
                    {!checkout_summary && <hr className="price-details-hr" />}
                </Fragment>
            )
        }
    }

    renderTotals() {
        const { subtotal } = this.state;
        return (subtotal ?
            <Fragment>
                {this.renderSubtotal()}
                {this.renderShippingCharges()}
                {this.renderCoupon()}
                {this.renderTax()}
                {this.renderGrandTotal()}
            </Fragment>
            :
            <Fragment>
                {this.renderGrandTotal()}
            </Fragment>

        )
    }

    renderShippingCharges() {
        const { subtotal } = this.state
        const { chargeShipping, checkout_summary } = this.props;
        let chargeString = "Free";
        if (chargeShipping && shippingChargeCents && subtotal < freeShippingMinCents) {
            chargeString = `$${(shippingChargeCents / 100).toFixed(2)}`
        }
        return (
            <Fragment>
                <SubtotalLineItem
                    title="Shipping:"
                    value={chargeString}
                    checkout_summary={checkout_summary}
                />
                {!checkout_summary && <hr className="price-details-hr" />}
            </Fragment>
        )
    }

    renderGrandTotal() {
        const {
            chargeShipping,
            coupon,
            newCoupon,
            sdCredit,
            tax,
            checkout_summary,
            employeePerkCoupon,
            maxSdCredit
        } = this.props;

        const { subtotal } = this.state;
        if (subtotal !== undefined) {
            const { label, total, error } = getGrandTotal(subtotal, tax, coupon, newCoupon, employeePerkCoupon, sdCredit, chargeShipping, maxSdCredit);
            if (!error) {
                return (
                    <GrandTotalLineItem
                        title={label}
                        value={`$${total.toFixed(2)}`}
                        checkout_summary={checkout_summary}
                    />
                )
            }
        }
    }

    renderDidYouKnow() {
        const { didYouKnow } = this.props;
        if (didYouKnow) {
            return didYouKnow
        }
    }

    renderValueProps() {
        const { showValueProps, isModal, checkout_summary, isMobile } = this.props;
        if (showValueProps && !isModal) {
            return (
                <Fragment>
                    {checkout_summary && <HR />}
                    <ValueProps isCheckout={true} checkout_summary={checkout_summary} />
                    {checkout_summary && <HR />}
                    <SummaryTestimonials checkout_summary={checkout_summary} isMobile={isMobile} />
                </Fragment>
            )
        }
    }

    renderInfo() {
        const { showInfo } = this.state;
        if (showInfo) {
            return (
                <CustomerReferralInfo
                    close={() => this.setState({ showInfo: false })}
                    next={() => this.setState({ showInfo: false })}
                />
            )
        }
    }

    renderMainHeader() {
        const { checkout_summary, isModal, close } = this.props;
        const { hoveredProductGroup } = this.state;
        if (!checkout_summary || isModal) {
            return (
                <div className={`price-details-header${isModal ? "" : " not-modal"}`}>
                    <div className="price-details-header-text">Order Summary</div>
                    <img className={isModal ? "price-details-close" : "hide"} onClick={close} src={images.btn_close_x} alt="close" />
                </div>
            )
        } else {
            return (
                <CheckoutSummaryTitleSection hoveredProductGroup={hoveredProductGroup} />
            )
        }
    }

    renderLineItems() {
        const { isModal, checkout_summary } = this.props;
        return (
            <LineItemsContainer isModal={isModal} checkout_summary={checkout_summary}>
                {this.renderMainHeader()}
                {this.displayBundles()}
                {this.renderTotals()}
                {this.renderViewDetailsLink()}
            </LineItemsContainer>
        )
    }

    renderViewDetailsLink() {
        const { checkout_summary, viewModal, isModal } = this.props;
        if (checkout_summary && !isModal) {
            return <ViewDetailsLink className="link" onClick={viewModal}>View Details</ViewDetailsLink>
        }
    }

    renderMain() {
        return (
            <Fragment>
                {this.renderProductDetailsModal()}
                {this.renderInfo()}
                {this.renderLineItems()}
                {this.renderDidYouKnow()}
                {this.renderValueProps()}
            </Fragment>
        )
    }

    renderProductDetailsModal() {
        const { selectedProductGroup } = this.state;
        const { isModal } = this.props;
        if (selectedProductGroup && !isModal) {
            return <ProductDetail productGroup={selectedProductGroup} close={() => this.setState({ selectedProductGroup: undefined })} />
        }
    }

    render() {
        const { isModal, orderComplete, checkout_summary } = this.props;
        if (isModal) {
            return (
                <div className="modal-container white">
                    {this.renderMain()}
                </div>
            )
        } else {
            return (
                <CartSummaryContainer orderComplete={orderComplete} checkout_summary={checkout_summary}>
                    {this.renderMain()}
                </CartSummaryContainer>
            )
        }
    }
}