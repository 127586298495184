import React from 'react';
import { Link } from 'react-router-dom';
import { formatProductType, getBundlesSearchString } from '../../../actions';
import { bundles } from '../../../data';

export default function BundlePageLink({ productType, bundleId }) {
    const title = productType ? formatProductType(productType) : bundles[bundleId]?.title;
    return (
        <Link
            to={{
                pathname: `/products/bundles/${bundleId}`,
                search: getBundlesSearchString(productType)
            }}
            title={title}
        >
            {title}
        </Link>
    )
}