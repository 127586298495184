//#region imports
import React from 'react';
import {
    FooterPoliciesContainer,
    FooterPolicy
} from '../../../Style/Footer'
//#endregion

//#region main
export default function FooterPolicies({ showTerms, showPrivacyPolicy, newFooter }) {
    //#region init state
    const policies = [];
    if (!newFooter) {
        policies.push(
            <FooterPolicy>
                <a href="/#faqs" title="FAQs">FAQ</a>
            </FooterPolicy>,
            <FooterPolicy>
                <a href="https://blog.supplydrop.com" title="Blog" target="_blank" rel="noopener noreferrer">Blog</a>
            </FooterPolicy>
        )
    }

    policies.push(
        <FooterPolicy newFooter={newFooter} onClick={showTerms}>Terms of Use</FooterPolicy>,
        <FooterPolicy newFooter={newFooter} onClick={showPrivacyPolicy}>Privacy Policy</FooterPolicy>
    )

    if (newFooter) {
        policies.push(
            <FooterPolicy newFooter={true}>
                <a href="/#faqs-contact-us" title="Contact Us">Contact Us</a>
            </FooterPolicy>
        )
    }
    //#endregion

    //#region render
    return <FooterPoliciesContainer newFooter={newFooter}>{policies}</FooterPoliciesContainer>
    //#endregion
}
//#endregion