import React from 'react';

export default function SimpleContinueBar({ next }) {
    return (
        <div className="fixed-continue-btn-container">
            <div className="fixed-continue-btn">
                <div className="fixed-continue-col text-center">
                    <div className="interview-continue-wrapper no-margin">
                        <button id="next-btn" className="interview-continue-button no-margin" onClick={next}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}