//#region imports
import React, { useState } from 'react';
import { bundles } from '../../../data';
import styled, { css } from 'styled-components';
import { Body1, Heading6 } from '../../../Style/DesignSystem/Typography';
import { autoMarginLR, mediaQueries } from '../../../Style';
import { Row, Col } from '../../../Style/Bootstrap'
import { HashLink } from 'react-router-hash-link';
import BundlePageLink from '../Navs/BundlePageLink';
//#endregion

//#region data
const types = {
    link: "LINK",
    bundle_link: "BUNDLE_LINK",
    tooltip: "TOOLTIP"
}

// const pricingLinks = {
//     "Best Price Guarantee": "Compare us to your local grocery and online stores!",
//     "Free Shipping": "You read that right, get FREE shipping on every order (over $20).",
//     "Cancel Anytime": "Skip a delivery, change your items, or cancel anytime."
// }

const sections = [
    {
        slug: "why_sd",
        heading: "Why Supply Drop?",
        links: [
            {
                title: "The Science of Supply Drop",
                pathname: "/science",
                type: types.link
            }
        ]
    },
    {
        slug: "products",
        heading: "Products",
        links: Object.entries(bundles).map(([bundleId, bundle]) => {
            return {
                title: bundle.title,
                bundleId,
                type: types.bundle_link

            }
        })
    },
    // {
    //     slug: "pricing",
    //     heading: "Pricing",
    //     links: Object.entries(pricingLinks).map(([key, value]) => {
    //         return {
    //             title: key,
    //             tooltipCopy: value,
    //             type: types.tooltip
    //         }
    //     })
    // },
    {
        slug: "company",
        heading: "Company",
        links: [
            {
                title: "About Us",
                pathname: "/about",
                type: types.link
            },
            {
                title: "Leadership",
                pathname: "/about",
                hash: "leadership",
                type: types.link
            },
            {
                title: "Investor Relations",
                pathname: "/about",
                hash: "investors",
                type: types.link
            },
            {
                title: "News",
                pathname: "/about",
                hash: "news",
                type: types.link
            },
            {
                title: "Blog",
                pathname: "/blog",
                type: types.link
            },
        ]
    }
]
//#endregion

//#region styles
const Container = styled.div`
    ${{ ...autoMarginLR }};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 788px;
    ${mediaQueries("md")`
        flex-direction: column;
        width: 100%;
        max-width: 450px;
    `}

`
const HeadingRow = styled(Row)`
    width: 100%;
    /* display: flex; */
    justify-content: space-between;
    cursor: default;
    ${mediaQueries("md")`
        cursor: pointer;
    `}
`
const Heading = styled(Heading6)`
    font-size: 14px !important;
    margin-bottom: 16px;
`
const LinkRow = styled(Row)`
    ${mediaQueries("md")`
        display: none;
    `}
    ${props => props.isActive && css`
        display: block !important;
    `}  
`
const Link = styled(Body1)`
    font-size: 14px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    > * {
        color: inherit;
        &:hover {
            color: inherit;
        }
    }
`
const Chevron = styled.div`
    display: none;
    ${mediaQueries("md")`
        display: block;
        transition: all ease-in-out .5s;
        &:after {
            transition: all ease-in-out .5s;
            margin-left: auto;
            text-align: right;
            line-height: 50%;
            font-size: 32px;
            font-weight: bold;
            content: "›";
            margin-left: -12px;
        }
    `}
    ${props => props.isActive && css`
        transform: rotate(90deg);
    `}
`

const Section = styled(Col)`
    ${mediaQueries("md")`
        margin-bottom: 16px;
    `}
`
//#endregion

//#region main
export default function FooterPageLinks({ history }) {
    //#region init state
    const [state, setState] = useState({ pricing_active: true });
    const { search } = window.location
    //#endregion

    //#region event handlers
    const handleTooltipHover = tooltipCopy =>
        setState(prevState => ({ ...prevState, showTooltip: tooltipCopy }))

    const handleTooltipClick = tooltipCopy =>
        setState(prevState => ({ ...prevState, showTooltip: state.showTooltip ? null : tooltipCopy }))

    const hideTooltip = () =>
        setState(prevState => ({ ...prevState, showTooltip: false }))

    const toggleSection = slug =>
        setState(prevState =>({ ...prevState, [`${slug}_active`]: !state[`${slug}_active`] }))
    //#endregion

    //#region render
    return (
        <Container>
            {sections.map(s => {
                const { heading, links, slug } = s;
                return (
                    <Section key={heading}>
                        <HeadingRow onClick={() => toggleSection(slug)}>
                            <Heading>{heading}</Heading><Chevron isActive={state[`${slug}_active`]} />
                        </HeadingRow>
                        {
                            // #region links
                            links.map(l => {
                                const { pathname, hash, tooltipCopy, type, title, bundleId } = l;

                                if (type === types.link) {
                                    return (
                                        <LinkRow isActive={state[`${slug}_active`]}>
                                            <HashLink to={{ pathname, hash, search }}>
                                                <Link className={state[`${slug}_active`] && "animated fadeInDown"}>
                                                    {title}
                                                </Link>
                                            </HashLink>
                                        </LinkRow>
                                    )
                                } else if (type === types.bundle_link) {
                                        return (
                                            <LinkRow isActive={state[`${slug}_active`]}>
                                                <Link className={state[`${slug}_active`] && "animated fadeInDown"}>
                                                    <BundlePageLink bundleId={bundleId} />
                                                </Link>
                                            </LinkRow>
                                        )
                                } else {
                                    return (
                                        <LinkRow isActive={state[`${slug}_active`]}>
                                                <Link
                                                    className={state[`${slug}_active`] && "animated fadeInDown"}
                                                    onClick={() => handleTooltipClick(tooltipCopy)}
                                                    onMouseOver={() => handleTooltipHover(tooltipCopy)}
                                                    onMouseOut={hideTooltip}
                                                >
                                                    {title}
                                                </Link>
                                        </LinkRow>
                                    )
                                }
                            })
                            // #endregion
                        }
                    </Section>
                )
            })}
        </Container>
    )
    //#endregion
}
//#endregion