import React, { Component } from 'react';
import Menu from './Subcomponents/Menu';
import {
    Loading,
    MetaTags,
    PhoneBanner
} from '../Common';
import PhoneVerification from '../Common/Connected/PhoneVerification';
import Analytics from "../../analytics";
import PastOrder from './Subcomponents/PastOrder';
import { connect } from 'react-redux';
import { Row, Col } from '../../Style/Bootstrap';
import {
    clearOrdersState,
    createGlobalAuthError,
    getMagicLink,
    getOrders,
    getProductGroups,
    getUser
} from '../../actions';
import * as Sentry from '@sentry/browser';
import { signIn } from './SignIn';
import { signOut } from '../../helpers/SignOut';

class SupplyDrops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderGroups: {},
            section: 0,
            dashboardData: {}
        };

        this.handleSignedInUser = this.handleSignedInUser.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this);
        this.setProductsMap = this.setProductsMap.bind(this);
    }

    componentDidMount() {
        const { history, location } = this.props;
        signIn(location.search, this.props.history).then(user => {
            this.handleSignedInUser(user);
            this.getDashboardData();
        }).catch(({ error, magicLinkParams }) => {
            console.error(error)
            if (error && error.name === 'AuthError')
                this.props.createGlobalAuthError('signed in user Auth error')
            if (magicLinkParams) {
                const { u, s, channel } = magicLinkParams;
                this.props.getMagicLink(null, window.location.pathname, channel, u);
                this.setState({ magicLinkPath: `u=${u}&s=${s}` })
            } else
                signOut(history, true)
        })
    }

    componentDidUpdate() {
        const {
            productGroups,
            signedInAuthErr,
            nonSignedInAuthErr,
            history,
            magicLink,
            magicLinkErr,
            gettingMagicLink
        } = this.props;

        const {
            user,
            loading,
            dashboardData,
            orders,
            globalError,
            magicLinkPath
        } = this.state;

        if (!orders && this.props.orders) {
            this.setState({ orders: this.props.orders });
        }

        if (!user && this.props.user) {
            dashboardData.user = this.props.user;
            this.setState({ user: this.props.user, customer: this.props.user.customer, dashboardData });
        }

        if (user && orders && loading) {
            this.setState({ loading: false });
        }
        if ((!user || !orders) && !loading)
            this.setState({ loading: true })

        if (productGroups && !this.state.productGroups) {
            this.setState({ productGroups });
            this.setProductsMap(productGroups)
        }

        if ((signedInAuthErr || nonSignedInAuthErr) && !globalError) {
            this.setState({ globalError: true });
            signOut(history, true)
        }

        if ((gettingMagicLink || magicLink) && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push(`/magiclink?${magicLinkPath}`);
        }
        
        if (magicLinkErr && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push("/login");
        }
    }

    setProductsMap(productGroups) {
        try {
            const productGroupsMap = {};
            productGroups.forEach(productGroup => {
                const { groupName } = productGroup;
                productGroupsMap[groupName] = productGroup;
            })
            this.setState({ productGroupsMap });
        } catch (err) {
            Sentry.captureEvent(err);
            Sentry.captureException(new Error('Could not map ProductGroups'))
        }
    }

    handleSignedInUser(user) {
        try {
            const { location } = this.props;
            if (user.attributes && !user.attributes.phone_number_verified && (!location.state || !location.state.dashboardData || !location.state.dashboardData.verified)) {
                this.setState({ isUnverified: true })
            }
            Analytics.setUser(user.username);
            this.setState({ signedIn: true })
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
    }

    getDashboardData() {
        try {
            const { location } = this.props;
            if (location && location.state && location.state.dashboardData) {
                const { dashboardData } = location.state;
                const { user, productGroupsMap, orders } = dashboardData;

                if (user)
                    this.setState({ user, customer: user.customer })
                else
                    this.props.getUser();

                if (productGroupsMap)
                    this.setState({ productGroupsMap })
                else
                    this.props.getProductGroups();

                if (orders)
                    this.setState({ orders })
                else
                    this.props.getOrders();

                this.setState({ dashboardData })
            } else {
                throw new Error("No location state data");
            }
        } catch (err) {
            if (err.message !== "No location state data") {
                console.error(err);
                Sentry.captureException(err);
            }
            this.props.getUser();
            this.props.getProductGroups();
            this.props.getOrders();
        }
    }

    renderOrders() {
        const { isUnverified, orders, limitExceeded, customer, productsMap } = this.state;
        if (orders && orders.length) {
            const rows = orders.map(order => {
                const { orderId, expectedDeliveryDate } = order;
                return ({
                    [expectedDeliveryDate]:
                        <PastOrder
                            key={orderId}
                            order={order}
                            customer={customer}
                            productsMap={productsMap}
                        />
                })
            })

            rows.sort((a, b) => {
                if (Object.keys(a)[0] > Object.keys(b)[0]) { return -1; }
                if (Object.keys(a)[0] < Object.keys(b)[0]) { return 1; }
                return 0;
            });

            const sortedRows = rows.map(row => {
                return Object.values(row)[0];
            })

            return (
                <div className="col-12">
                    <div className="space-bottom lg">
                        <div className="container">
                            <div className={`order-history-header${isUnverified && !limitExceeded ? " with-banner" : " "}`}>Order History</div>
                        </div>
                        <div className="order container">
                            <Row className="order-row top">
                                <Col xs={6} className="text-left order-label bold">Delivery Date</Col>
                                <Col xs={6} className="text-right order-label bold">Total</Col>
                            </Row>
                            {sortedRows}
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    renderPhoneBanner() {
        const { isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && customer && !limitExceeded) {
            return <PhoneBanner next={() => this.setState({ showVerificationModal: true })} />
        }
    }

    renderPhoneModal() {
        const { showVerificationModal, isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && showVerificationModal && customer && !limitExceeded) {
            return (
                <PhoneVerification
                    isModal={true}
                    limitExceeded={() => this.setState({ limitExceeded: true, showVerificationModal: false })}
                    email={customer.email}
                    phone={customer.phone}
                    setToVerified={() => this.setState({ isUnverified: false })}
                    close={() => this.setState({ showVerificationModal: false })}
                />
            )
        }
    }

    renderMain() {
        const { signedIn } = this.state;

        if (signedIn) {
            return (
                <div className="dashboard-body-container">
                    {this.renderPhoneBanner()}
                    {this.renderPhoneModal()}
                    <div className="dashboard-orders flex-wrap">
                        {this.renderOrders()}
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    render() {
        const { isUnverified, dashboardData } = this.state;
        return (
            <div className="no-cloud">
                <MetaTags title="Supply Drop - Dashboard - Past Deliveries" />
                <Menu history={this.props.history} dashboardData={{ ...dashboardData, verified: !isUnverified }} />
                {this.renderMain()}
                {/* <Footer /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError, magicLink, magicLinkErr, gettingMagicLink } = state.user;
    const { productGroups, productGroupsError } = state.product;
    const { orders, getOrdersError, } = state.order;
    const { signedInAuthErr, nonSignedInAuthErr } = state.globalErrors;
    return {
        user,
        orders,
        getOrdersError,
        getUserError,
        productGroups,
        productGroupsError,
        signedInAuthErr,
        nonSignedInAuthErr,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    };
}

export default connect(mapStateToProps, {
    clearOrdersState,
    createGlobalAuthError,
    getMagicLink,
    getOrders,
    getProductGroups,
    getUser
})(SupplyDrops);
