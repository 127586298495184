import React, { lazy, Suspense } from 'react';
import Loading from '../components/Landing/Loading';
import { Route } from 'react-router-dom';
import withTracker from '../analytics/withTracker';
import { Logger } from '../helpers/Logger';

const logger = new Logger();

const Magiclink = lazy(() => import('../components/Main/Magiclink'));
const CheckAvailability = lazy(() => import('../components/AvailabilityGate/CheckAvailability'));
const AvailabilitySuccess = lazy(() => import('../components/AvailabilityGate/AvailabilitySuccess'));
const BundleLanding = lazy(() => import('../components/Landing/BundleLanding'));
const ProductGroupLanding = lazy(() => import('../components/Landing/ProductGroup/ProductGroupLanding'));
const AvailabilityWaitlist = lazy(() => import('../components/AvailabilityGate/AvailabilityWaitlist'));
const OurPromise = lazy(() => import('../components/Main/OurPromise'));
const Science = lazy(() => import('../components/Landing/Science'));
const About = lazy(() => import('../components/Landing/About'));
// Everyday
const Household = lazy(() => import('../components/Interview/Everyday/Household'));
const Bathrooms = lazy(() => import('../components/Interview/Everyday/Bathrooms'));
const TrashPreferences = lazy(() => import('../components/Interview/Everyday/TrashPreferences'));
const TierPreferences = lazy(() => import('../components/Interview/Everyday/TierPreferences'));

// Kitchen
const Cooking = lazy(() => import('../components/Interview/Kitchen/Cooking'));
const DishwashingFrequency = lazy(() => import('../components/Interview/Kitchen/DishwashingFrequency'));
const Cleaning = lazy(() => import('../components/Interview/Kitchen/Cleaning'));

const LaundryFrequency = lazy(() => import('../components/Interview/Laundry/LaundryFrequency'));
const Recommendation = lazy(() => import('../components/Interview/Recommendation'));
const Checkout = lazy(() => import('../components/Checkout/Checkout'));
const OrderComplete = lazy(() => import('../components/Checkout/OrderComplete'));
const Dashboard = lazy(() => import('../components/Dashboard/Dashboard'));
const Account = lazy(() => import('../components/Dashboard/Account'));
const Receipt = lazy(() => import('../components/Dashboard/Receipt'));
const FAQs = lazy(() => import('../components/Dashboard/FAQs'));
const OrderHistory = lazy(() => import('../components/Dashboard/OrderHistory'));
const Login = lazy(() => import('../components/Main/Login'));
const TraditionalLogin = lazy(() => import('../components/Main/TraditionalLogin'));

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        logger.error(error)
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <p>Loading failed! Please reload.</p>;
        }

        return this.props.children;
    }
}

export default function LazyRoutes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={Loading}>
                {/* Science */}
                <Route path="/about" component={withTracker(About)} exact />
                <Route path="/science" component={withTracker(Science)} exact />

                {/* Products */}
                <Route path="/products/:groupName" component={withTracker(ProductGroupLanding)} exact />
                <Route path="/products/bundles/:bundleId" component={withTracker(BundleLanding)} exact />

                {/* Availability */}
                <Route path="/check-availability" component={withTracker(CheckAvailability)} exact />
                <Route path="/check-availability/success" component={withTracker(AvailabilitySuccess)} exact />
                <Route path="/check-availability/waitlist" component={withTracker(AvailabilityWaitlist)} exact />

                {/* Interview Questions */}
                <Route path="/our-promise" component={withTracker(OurPromise)} exact />
                <Route path="/start/1" component={withTracker(Household)} exact />
                <Route path="/start/2" component={withTracker(Bathrooms)} exact />
                <Route path="/start/3" component={withTracker(TrashPreferences)} exact />
                <Route path="/kitchen/1" component={withTracker(Cooking)} exact />
                <Route path="/kitchen/2" component={withTracker(DishwashingFrequency)} exact />
                <Route path="/kitchen/3" component={withTracker(Cleaning)} exact />
                <Route path="/laundry/1" component={withTracker(LaundryFrequency)} exact />
                <Route path="/preference" component={withTracker(TierPreferences)} exact />

                {/* Checkout */}
                <Route path="/recommendation" component={withTracker(Recommendation)} exact />
                <Route path="/checkout" component={withTracker(Checkout)} exact />
                <Route path="/ordercomplete" component={withTracker(OrderComplete)} exact />

                {/* Dashboard */}
                <Route path="/dashboard" component={withTracker(Dashboard)} exact />
                <Route path="/dashboard/orders" component={withTracker(OrderHistory)} exact />
                <Route path="/dashboard/account" component={withTracker(Account)} exact />
                <Route path="/dashboard/account/enroll_employee/:employerSlug" component={withTracker(Account)} exact />
                <Route path="/dashboard/order/:orderId" component={withTracker(Receipt)} exact />
                <Route path="/dashboard/faqs" component={withTracker(FAQs)} />

                {/* Login */}
                <Route path="/login" component={withTracker(Login)} exact />
                <Route path="/login/classic" component={withTracker(TraditionalLogin)} exact />
                <Route path="/magiclink" component={withTracker(Magiclink)} exact />
            </Suspense>
        </ErrorBoundary>
    )
}