import { SET_FIREWORKS_SEEN, SET_FIREWORKS_UNSEEN } from '../actions/types';

const INITIAL_STATE = {
    hasSeenFireworks: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_FIREWORKS_SEEN:
            return { hasSeenFireworks: true }
        case SET_FIREWORKS_UNSEEN:
            return { hasSeenFireworks: false }
        default:
            return state;
    }
};