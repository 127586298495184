import styled, { css } from 'styled-components';
import { colors } from './Colors';
import { autoMarginLR, doubleShadow, mediaQueries } from './Common';
import { flexCenter, flexRow, flexSpaceAround } from './Flex';

export const ManifestBundleHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 15px;
    border-bottom: 1px solid #DFE0EB;
    overflow: hidden;
    ${props => props.isDashboard && css`
        margin-left: -28px;
    `}
    ${mediaQueries("md")`
        margin-left: 0;
    `}
`

export const BundleHeaderBody = styled.div`
    ${{ ...autoMarginLR }}
    width: calc(100% - 30px);
    max-width: 570px;
    ${props => props.isDashboard && css`
        ${mediaQueries(1200)`
            max-width: 500px;
        `}
    `}
`

export const ManifestBundleHeaderImg = styled.img`
    height: 16px;
    width: auto;
`

export const RecommendationBody = styled.div`
    ${{ ...autoMarginLR }};
    background: #fff;
    min-height: 100vh;
    width: 1000px;
    max-width: 100%;
    box-shadow: ${doubleShadow};
    ${props => props.spaceTop && css`
        margin-top: ${props.spaceTop + 12}px;
    `}
`

export const ManifestBundleBody = styled.div`
    width: 100%;
`

export const ManifestBundleProductContainer = styled.div`

`

export const ManifestBundleProductBody = styled.div`
    padding-left: 12px;
    ${mediaQueries("md")`
        padding-left: 0;
    `}
`

export const ManifestBundleProductMain = styled.div`
    ${{ ...autoMarginLR, ...flexCenter }};
    flex-direction: row;
    width: 600px;
    max-width: 100%;
    padding: 20px 15px;
    text-align: center;
    & > div {
        width: 100%;
    }
    ${props => props.isDrawer && css`
        flex-direction: column;
    `}
    ${mediaQueries("md")`
        flex-direction: column;
    `}
    ${props => props.isDashboard && css`
        ${mediaQueries(1200)`
            width: 500px;
        `}
    `}
`

export const ManifestBundleHR = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
    height: 1px;
    background: #C9CBDA;
    border: none;
    display: block;
    width: calc(100% - 30px);
    max-width: 570px;
    ${props => props.isDashboard && css`
        ${mediaQueries(1200)`
            max-width: 500px;
        `}
    `}
`

export const ProductDropdownContainer = styled.div`
    margin: 20px 0px;
    & > div {
        width: 100%;
    }
`

export const ProductDisplayName = styled.p`
    width: 100%;
    text-align: left;
    margin-bottom: 0;
    padding-right: 4px;
`

export const SuggestedProductGroupContainer = styled.div`
    ${{ ...flexRow, ...flexSpaceAround }};
    width: 100%;
    & > div {
        cursor: pointer;
    }
`

export const SuggestedProductGroupPaper = styled.div`
    border-radius: 12px !important;
    padding: 8px;
    ${mediaQueries("md")`
        padding: 4px;
    `}
`

export const SuggestedProductGroupsContainer = styled.div`
    text-align: center;
    margin: 20px auto;
    width: 100%;
    max-width: 600px;
`

export const SuggestedProductGroupLabel = styled.p`
    ${{ ...autoMarginLR }};
    color: ${colors.primary.main};
    font-size: 12px;
    width: 125px;
    margin-bottom: 0;
    ${mediaQueries("md")`
        width: 105px;
    `}
`

export const RecommendationHeaderSection = styled.div`
    margin: 32px auto;
    width: calc(100% - 30px);
    max-width: 570px;
`

export const UndoLinkDivider = styled.hr`
    width: 111px;
    height: 1px;
    background: ${colors.primary.main};
    margin-top: 0;
    margin-bottom: 0;
    border-top: none;
    border-bottom: none;
`

export const UndoCopy = styled.p`
    margin-top: 20px;
    margin-bottom: 20px;
`