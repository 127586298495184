import React, {Component, Fragment} from 'react';
import * as Sentry from '@sentry/browser';
import * as images from '../../assets';
import { connect } from 'react-redux';
import '../../Style/signin.css';
import {
    Nav,
    ContinueButton,
    SingleChoiceModal,
    OutageBanner
} from '../Common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {validateEmail} from '../../actions';
import {
    getMagicLink,
    clearCustomerState,
    formatPhone,
    reverseFormatPhone
} from '../../actions';
import { Logger } from '../../helpers/Logger';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const logger = new Logger();

class SignIn extends Component {
    constructor(props){
        super(props);
        this.state = {
            // For testing:
            // errorMessage: "Oops, something went wrong! Please sign in again and retry.",
            channel: 'sms',
            moreInfoCopy: (<div>Passwords are so 2000. You don't have to mess with them anymore, instead we'll send you a secure login link via text message or email.<br/></div>)
        };

        this.requestLink = this.requestLink.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.validateField = this.validateField.bind(this);
        this.clearCustomerState = this.clearCustomerState.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
        this.setToEmployeePerk = this.setToEmployeePerk.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    componentDidMount() {
        if(this.props.location && this.props.location.state)
            this.setStateForKeyValue(this.props.location.state);
    }

    setStateForKeyValue(data) {
        try {
            logger.log(data)
            const { isEmployeePerk, employer } = data;
            if(isEmployeePerk) {
                this.setToEmployeePerk(employer)
            }
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if(value !== undefined) {
                    this.setState({[key]: value})
                }
            })

        } catch(err) {
            console.error(err);
        }
    }

    handleSnackbarClose() {
        this.setState({ errorMessage: undefined })
    }

    setToEmployeePerk(employer) {
        try {
            const { employerSlug } = employer;
            if(employerSlug)
                this.setState({ pathname: `/dashboard/account/enroll_employee/${employerSlug}` })
            else throw new Error('Employer slug is missing from employer')
        } catch(err) {
            logger.error(err)
        }
    }

    handleKeydown(e) {
        const {showInfo, email, channel, displayNumber} = this.state;
        if(e.keyCode && e.keyCode === 13) {
            if((email || displayNumber) && channel && !showInfo) {
                if(displayNumber && channel === "sms") {
                    const phone = formatPhone(displayNumber); 
                    const isPhoneInvalid = !isValidPhoneNumber(phone);
                    this.setState({ isPhoneInvalid, phone });
                } else this.setState({ isEmailInvalid: !validateEmail(email) });
                this.requestLink();
            }
        }
        if(e.keyCode && (e.keyCode === 13 || e.keyCode === 27)) {
            if(showInfo) {
                this.setState({showInfo: false})
            }
        }
    }

    clearCustomerState() {
        this.props.clearCustomerState();
        this.setState({loading: false});
    }

    validateField (name, value) {
        try {
            switch (name) {
                case 'email':
                    this.setState({ isEmailInvalid: !validateEmail(value) });
                    break;
                case 'phone':
                    const phone = formatPhone(value); 
                    const isPhoneInvalid = !isValidPhoneNumber(phone);
                    this.setState({ isPhoneInvalid, phone });
                    break;
                default:
                    console.error(name, value, 'invalid')
                    break;
            }
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
        }
    }

    componentDidUpdate() {
        const {magicLinkErr} = this.props;

        if(magicLinkErr && this.state.errorMessage) {
            Sentry.captureException(magicLinkErr);
            console.error(magicLinkErr);
            this.setState({ errorMessage: "We are unable to create a magiclink at the moment. Please contact care@supplydrop.com for further assistance." })
        }
        if(magicLinkErr && this.state.loading) {
            this.setState({loading: false})
        }
    }

    requestLink() {
        const {loading, email, channel, phone, isEmailInvalid, isPhoneInvalid, pathname} = this.state;
        if(!loading) {
            if(channel === "email" && !isEmailInvalid) {
                this.setState({loading: true})
                this.props.getMagicLink(email, pathname ? pathname : '/dashboard', channel)
            } else if (!isPhoneInvalid) {
                this.setState({loading: true})
                this.props.getMagicLink(null, pathname ? pathname : '/dashboard', channel, null, phone)
            }
        }
    }

    renderChannel() {
        const {channel} = this.state;
        const smsChecked = channel === "sms" ? true : false;

        return (
            <div>
                <p className="interview-copy signin-tagline">
                    How would you like to receive your magic link?
                </p>
                <div className="signin-checkboxes-container">
                    <div className="signin-checkbox-container" id="sms-signin-container" onClick={() => this.setState({channel: "sms"})}>
                       <label className="label-radio">Text Message
                            <input type="radio" defaultChecked={smsChecked} name="radio"/>
                            <span className="radio-check"></span>
                        </label>
                    </div>
                    <div className="signin-checkbox-container" onClick={() => this.setState({channel: "email"})}>
                        <label className="label-radio">Email
                            <input type="radio" name="radio"/>
                            <span className="radio-check"></span>
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    renderInfo() {
		const {showInfo, moreInfoCopy} = this.state;
        if(showInfo) {
            return (
                <SingleChoiceModal
                    title="Login to your Supply Drop"
                    body={moreInfoCopy}
                    buttonCopy="Go Back"
                    close={() => this.setState({showInfo: false})}
                    next={() => this.setState({showInfo: false})}
                />
            )
        }
    }
    
    renderSentSuccessfully() {
        const {magicLink} = this.props;
        const {channel} = this.state;
        if(magicLink) {
            return (
                <div className="signin-subtext">
                    We've sent a link to your {channel === "sms" ? "phone" : "email"} that will allow you to login.
                    <div><span className="span-link" onClick={this.clearCustomerState}>Click here</span> if you need to request a new link.</div>
                </div>
            )
        }
    }

    renderLoading() {
        const {gettingMagicLink} = this.props;
        if(gettingMagicLink) {
            return (
                <div className="signin-subtext">
                    Working our magic...
                </div>
            )
        }
    }

    renderSignUp() {
        const {noUser} = this.props;
        const {email, phone, channel} = this.state;

        if(noUser) {
            return (
                <div className="signin-subtext">
                    Hmm... we don't have <b>{channel === "sms" && phone ? reverseFormatPhone(phone) : channel === "email" && email ? email : 'you'}</b> in our system.<br/>
                    Would you like to <span className="span-link" onClick={() => this.props.history.push({pathname:'/our-promise', search: window.location.search})}>sign up</span> or <span className="span-link" onClick={this.clearCustomerState}>try entering your {channel === "sms" ? "phone" : "email"} again?</span>
                </div>
            )
        }
    }

    renderError() {
        const {magicLink, magicLinkErr} = this.props;
        if(magicLinkErr && !magicLink) {
            return (
                <div className="signin-subtext">
                    We've encountered an error whle creating your login link.
                    <div><span className="span-link" onClick={this.clearCustomerState}>Click here</span> to try again.</div>
                </div>
            )
        }
    }

    renderInput() {
        const {channel, email, isPhoneInvalid} = this.state;
        if(channel === "email") {
            return (
                <input
                    autoFocus
                    autoComplete="email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email address"
                    className="gate-input"
                    onChange={(e) => this.setState({email: e.target.value})}
                />
            )
        } else {
            return (
                <input
                    autoFocus
                    autoComplete="home tel-national"
                    className={`gate-input${isPhoneInvalid ? ' invalid' : ''}`}
                    placeholder="(512) 555-1234"
                    name="phone"
                    type="tel"
                    onChange={(e) => {
                      this.setState({displayNumber: e.target.value});
                    }}
                    onBlur={(e) => this.validateField('phone', e.target.value)}
                />
            )
        }
    }

    renderMain() {
        const {magicLink, magicLinkErr, noUser} = this.props;
        const {errMessage, email, channel, displayNumber, loading} = this.state;
        if(!magicLink && !magicLinkErr && !noUser) {
            return (
                <div>
                    <div>
                        {this.renderInput()}
                    </div>
                    {this.renderChannel()}
                    <Alert severity="warning" role="alert" className={errMessage ? "signin-modal-alert" : "hide"}>{errMessage}</Alert>
                    <ContinueButton disabled={(!email && channel === "email") || (!displayNumber && channel === "sms") || loading} next={this.requestLink} text="Send Link" pressText="press" keyText="enter"/>
                </div>
            )
        }
    }

    renderErrorSnackbar() {
        const { errorMessage } = this.state;
        const vertical = 'top', horizontal = 'center';
        return (
            <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!errorMessage}
                onClose={this.handleSnackbarClose}
                key={'snackbar-' + vertical + horizontal}
            >
                <Alert severity="error" role="alert" onClose={this.handleSnackbarClose}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        )
    }

    render() {
        return (
            <Fragment>
                <OutageBanner />
                <Nav pathname="/" back={this.props.history.goBack} />
                {this.renderInfo()}
                {this.renderErrorSnackbar()}
                <div className="interview-outer">
                    <div className="interview-nav-container">
                        <div className="interview-top-row signin">
                            <div></div>
                            <img src={images.info} onClick={() => this.setState({showInfo: true})} className="interview-icon interview-info-icon" alt="Info" />
                        </div>
                    </div>
                    <div className="interview-inner text-center">
                        <div><img id="login-img" src={images.login} alt="Security" /></div>
                        <h3 className="gate-header">Your Account</h3>
                        {this.renderSentSuccessfully()}
                        {this.renderLoading()}
                        {this.renderMain()}
                        {this.renderError()}
                        {this.renderSignUp()}
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {magicLink, magicLinkErr, noUser, gettingMagicLink} = state.user;
    return {magicLink, magicLinkErr, noUser, gettingMagicLink};
}

export default connect(mapStateToProps, {
    getMagicLink,
    clearCustomerState
})(SignIn);
