import React from 'react';
import { LineItemRow, LineItemSubtotalTitle, LineItemSubtotalValue } from '../../../Style/OrderSummaryStyles';

export default function SubtotalLineItem({ coupon, title, value, checkout_summary, clickFx }) {
    return (
        <LineItemRow coupon={coupon} checkout_summary={checkout_summary} onClick={clickFx ? clickFx : null}>
            <LineItemSubtotalTitle checkout_summary={checkout_summary} coupon={coupon}>{title}</LineItemSubtotalTitle>
            <LineItemSubtotalValue checkout_summary={checkout_summary} coupon={coupon}>{value}</LineItemSubtotalValue>
        </LineItemRow>
    )
}