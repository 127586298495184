import React from 'react';
import { ProductImgWrapper, ProductImg } from '../../../Style/OrderSummaryStyles';

export default function OrderSummaryProductImg({
    productGroup,
    showProductDetails,
    clearProductDetails,
    showProductDetailsModal
}) {
    return (
        <ProductImgWrapper onClick={() => showProductDetailsModal(productGroup)} onMouseOut={clearProductDetails} onMouseOver={() => showProductDetails(productGroup)}>
            <ProductImg src={productGroup.imageUrl} alt={productGroup.brandName + " " + productGroup.displayName} />
        </ProductImgWrapper>
    )
}