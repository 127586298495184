import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Landing/Home';
import NotFound from './components/Main/404';
import Localeur from './components/Landing/Localeur';
import ControlRoutes from './routes/ControlRoutes';
import LazyRoutes from './routes/LazyRoutes';
import Magiclink from './components/Main/Magiclink';
import CheckAvailability from './components/AvailabilityGate/CheckAvailability';
import AvailabilitySuccess from './components/AvailabilityGate/AvailabilitySuccess';
import BundleLanding from './components/Landing/BundleLanding';
import ProductGroupLanding from './components/Landing/ProductGroup/ProductGroupLanding';
import AvailabilityWaitlist from './components/AvailabilityGate/AvailabilityWaitlist';
import OurPromise from './components/Main/OurPromise';
import Science from './components/Landing/Science';
import About from './components/Landing/About';
// Everyday
import Household from './components/Interview/Everyday/Household';
import Bathrooms from './components/Interview/Everyday/Bathrooms';
import TrashPreferences from './components/Interview/Everyday/TrashPreferences';
import TierPreferences from './components/Interview/Everyday/TierPreferences';

// Kitchen
import Cooking from './components/Interview/Kitchen/Cooking';
import DishwashingFrequency from './components/Interview/Kitchen/DishwashingFrequency';
import Cleaning from './components/Interview/Kitchen/Cleaning';

import LaundryFrequency from './components/Interview/Laundry/LaundryFrequency';
import Recommendation from './components/Interview/Recommendation';
import Checkout from './components/Checkout/Checkout';
import OrderComplete from './components/Checkout/OrderComplete';
import Dashboard from './components/Dashboard/Dashboard';
import Account from './components/Dashboard/Account';
import Receipt from './components/Dashboard/Receipt';
import FAQs from './components/Dashboard/FAQs';
import OrderHistory from './components/Dashboard/OrderHistory';
import Login from './components/Main/Login';
import TraditionalLogin from './components/Main/TraditionalLogin';
import { connect } from 'react-redux';
import { clearGlobalErrors } from './actions';

import keys, { featureFlags } from './config';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import withTracker from './analytics/withTracker';

import Amplify from 'aws-amplify';
import awsconfig from './aws-config';

import { StripeProvider } from 'react-stripe-elements';
import { init as ContentfulInit } from './contentful';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './Style/DesignSystem/Theme';

import './Style/animate.css';
import './Style/checkout.css';
import './Style/dashboard.css';
import './Style/flex.css';
import './Style/interview.css';
import './Style/modifiers.css';
import './Style/order.css';
import './Style/style.css';

import Analytics from '@aws-amplify/analytics';
import ScrollToTop from './helpers/ScrollToTop';

// Lazy-Loaded Pages

Amplify.configure(awsconfig);
Analytics.configure({
  Auth: {
    identityPoolId: awsconfig.identityPoolId,
    region: awsconfig.region
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
  },
  AWSPinpoint: {
    appId: keys.pinpointAppId,
    region: 'us-east-1',
    mandatorySignIn: false,
  }
});

Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: 'pageView',
  // OPTIONAL, the attributes of the event, you can either pass an object or a function 
  // which allows you to define dynamic attributes
  attributes: {
    attr: 'attr'
  },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  type: 'SPA',
  // OPTIONAL, the service provider, by default is the AWS Pinpoint
  provider: 'AWSPinpoint',
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  }
});

const fontAwesome = (
  <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet" />
);

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {};
  }
  componentDidUpdate() {
    if (this.props.signedInAuthErr || this.props.nonSignedInAuthErr) {
      this.props.clearGlobalErrors();
    }
  }

  renderLazyOrControlRoutes() {
    if (featureFlags.useLazyRoutes) {
      return <LazyRoutes />
    } else {
      return <ControlRoutes />
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <ThemeProvider theme={theme}>
            <div id="cloud-intro">
              {fontAwesome}
              <StripeProvider apiKey={keys.stripeKey}>
                <Switch>
                  {/* Home */}
                  <Route path="/" component={withTracker(Home)} exact />
                  <Route path="/landing/:campaign" component={withTracker(Home)} exact />
                  <Route path="/customerref/:id" component={withTracker(Home)} exact />
                  <Route path="/ref/:id" component={withTracker(Home)} exact />
                  <Route path="/employer/:employerSlug" component={withTracker(Home)} exact />
                  <Route path="/employee_perk/:employerSlug" component={withTracker(Home)} exact />
                  <Route path="/coupon/:couponSlug" component={withTracker(Home)} exact />

                  {/* Employee perk */}
                  <Route path="/ojo" component={withTracker(Home)} exact />
                  <Route path="/sde" component={withTracker(Home)} exact />

                  {/* Marketing campaigns */}
                  <Route path="/localeur" component={withTracker(Localeur)} exact />
                  <Route path="/sdn" component={withTracker(Home)} exact />
                  <Route path="/standarddomain" component={withTracker(Home)} exact />
                  <Route path="/wpk" component={withTracker(Home)} exact />
                  <Route path="/waterspark" component={withTracker(Home)} exact />
                  <Route path="/westlake" component={withTracker(Home)} exact />
                  <Route path="/westlake_qr" component={withTracker(Home)} exact />
                  <Route path="/gables-terrace" component={withTracker(Home)} exact />
                  <Route path="/gables-terrace-oct" component={withTracker(Home)} exact />
                  <Route path="/the-w" component={withTracker(Home)} exact />
                  <Route path="/the-w-oct" component={withTracker(Home)} exact />
                  <Route path="/bowie" component={withTracker(Home)} exact />
                  <Route path="/bowie-oct" component={withTracker(Home)} exact />
                  <Route path="/northshore" component={withTracker(Home)} exact />
                  <Route path="/northshore-oct" component={withTracker(Home)} exact />
                  <Route path="/gables-republic-square" component={withTracker(Home)} exact />
                  <Route path="/gables-republic-square-oct" component={withTracker(Home)} exact />
                  <Route path="/belterra" component={withTracker(Home)} exact />
                  <Route path="/belterra-oct" component={withTracker(Home)} exact />
                  <Route path="/grimes-property-group" component={withTracker(Home)} exact />
                  <Route path="/grimes-property-group-oct" component={withTracker(Home)} exact />
                  <Route path="/ashton" component={withTracker(Home)} exact />
                  <Route path="/ashton-oct" component={withTracker(Home)} exact />
                  <Route path="/whitley" component={withTracker(Home)} exact />
                  <Route path="/whitley-oct" component={withTracker(Home)} exact />
                  <Route path="/lenox-boardwalk" component={withTracker(Home)} exact />
                  <Route path="/lenox-boardwalk-oct" component={withTracker(Home)} exact />
                  <Route path="/independent" component={withTracker(Home)} exact />
                  <Route path="/independent-oct" component={withTracker(Home)} exact />
                  <Route path="/independent" component={withTracker(Home)} exact />
                  <Route path="/austonian-oct" component={withTracker(Home)} exact />
                  <Route path="/austonian" component={withTracker(Home)} exact />
                  <Route path="/four-seasons-oct" component={withTracker(Home)} exact />
                  <Route path="/four-seasons" component={withTracker(Home)} exact />
                  <Route path="/towers-townlake-oct" component={withTracker(Home)} exact />
                  <Route path="/towers-townlake" component={withTracker(Home)} exact />
                  <Route path="/walsh-oct" component={withTracker(Home)} exact />
                  <Route path="/walsh" component={withTracker(Home)} exact />
                  <Route path="/westerly-360-oct" component={withTracker(Home)} exact />
                  <Route path="/westerly-360" component={withTracker(Home)} exact />
                  <Route path="/windsor-lake-oct" component={withTracker(Home)} exact />
                  <Route path="/windsor-lake" component={withTracker(Home)} exact />
                  <Route path="/monarch-oct" component={withTracker(Home)} exact />
                  <Route path="/monarch" component={withTracker(Home)} exact />
                  <Route path="/gift-bag-oct" component={withTracker(Home)} exact />
                  <Route path="/brookdale-westlake-hills-oct" component={withTracker(Home)} exact />
                  <Route path="/brookdale-westlake-hills" component={withTracker(Home)} exact />
                  <Route path="/hunters-chase-oct" component={withTracker(Home)} exact />
                  <Route path="/hunters-chase" component={withTracker(Home)} exact />
                  <Route path="/brookdale-gaines-ranch-oct" component={withTracker(Home)} exact />
                  <Route path="/brookdale-gaines-ranch" component={withTracker(Home)} exact />
                  <Route path="/muse-oct" component={withTracker(Home)} exact />
                  <Route path="/muse" component={withTracker(Home)} exact />
                  <Route path="/5th-street-commons-oct" component={withTracker(Home)} exact />
                  <Route path="/5th-street-commons" component={withTracker(Home)} exact />
                  <Route path="/edison-oct" component={withTracker(Home)} exact />
                  <Route path="/edison" component={withTracker(Home)} exact />
                  <Route path="/eleven-oct" component={withTracker(Home)} exact />
                  <Route path="/eleven" component={withTracker(Home)} exact />
                  <Route path="/3500-westlake-oct" component={withTracker(Home)} exact />
                  <Route path="/3500-westlake" component={withTracker(Home)} exact />
                  <Route path="/timbercreek-oct" component={withTracker(Home)} exact />
                  <Route path="/timbercreek" component={withTracker(Home)} exact />
                  <Route path="/windsor-south-lamar-oct" component={withTracker(Home)} exact />
                  <Route path="/windsor-south-lamar" component={withTracker(Home)} exact />
                  <Route path="/five-two" component={withTracker(Home)} exact />
                  <Route path="/five-two-oct" component={withTracker(Home)} exact />
                  <Route path="/round-top" component={withTracker(Home)} exact />
                  <Route path="/round-top-oct" component={withTracker(Home)} exact />
                  <Route path="/e6" component={withTracker(Home)} exact />
                  <Route path="/e6-nov" component={withTracker(Home)} exact />
                  <Route path="/tx" component={withTracker(Home)} exact />
                  <Route path="/DEC" component={withTracker(Home)} exact />
                  <Route path="/about" component={withTracker(About)} exact />
                  <Route path="/science" component={withTracker(Science)} exact />

                  {/* Products */}
                  <Route path="/products/:groupName" component={withTracker(ProductGroupLanding)} exact />
                  <Route path="/products/bundles/:bundleId" component={withTracker(BundleLanding)} exact />

                  {/* Availability */}
                  <Route path="/check-availability" component={withTracker(CheckAvailability)} exact />
                  <Route path="/check-availability/success" component={withTracker(AvailabilitySuccess)} exact />
                  <Route path="/check-availability/waitlist" component={withTracker(AvailabilityWaitlist)} exact />

                  {/* Interview Questions */}
                  <Route path="/our-promise" component={withTracker(OurPromise)} exact />
                  <Route path="/start/1" component={withTracker(Household)} exact />
                  <Route path="/start/2" component={withTracker(Bathrooms)} exact />
                  <Route path="/start/3" component={withTracker(TrashPreferences)} exact />
                  <Route path="/kitchen/1" component={withTracker(Cooking)} exact />
                  <Route path="/kitchen/2" component={withTracker(DishwashingFrequency)} exact />
                  <Route path="/kitchen/3" component={withTracker(Cleaning)} exact />
                  <Route path="/laundry/1" component={withTracker(LaundryFrequency)} exact />
                  <Route path="/preference" component={withTracker(TierPreferences)} exact />

                  {/* Checkout */}
                  <Route path="/recommendation" component={withTracker(Recommendation)} exact />
                  <Route path="/checkout" component={withTracker(Checkout)} exact />
                  <Route path="/ordercomplete" component={withTracker(OrderComplete)} exact />

                  {/* Dashboard */}
                  <Route path="/dashboard" component={withTracker(Dashboard)} exact />
                  <Route path="/dashboard/orders" component={withTracker(OrderHistory)} exact />
                  <Route path="/dashboard/account" component={withTracker(Account)} exact />
                  <Route path="/dashboard/account/enroll_employee/:employerSlug" component={withTracker(Account)} exact />
                  <Route path="/dashboard/order/:orderId" component={withTracker(Receipt)} exact />
                  <Route path="/dashboard/faqs" component={withTracker(FAQs)} />

                  {/* Login */}
                  <Route path="/login" component={withTracker(Login)} exact />
                  <Route path="/login/classic" component={withTracker(TraditionalLogin)} exact />
                  <Route path="/magiclink" component={withTracker(Magiclink)} exact />
                  {/* 404 */}
                  <Route component={NotFound} />
                </Switch>
              </StripeProvider>
            </div>
          </ThemeProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

// Google Ad Conversion tracking
if (window.gtag) {
  window.gtag('config', keys.gaAdTrackingId);
}

const tagManagerArgs = {
  gtmId: keys.gtmId
}

TagManager.initialize(tagManagerArgs)
window.sd_google_optimize = {}

ReactGA.initialize(keys.gaTrackingId);

const analyticsUserType = sessionStorage.getItem('analytics_user_type')

// Disable for datadog bots and Supply Drop employees (we can set this manually in dev console or via admin tools)
// dimension1 is used to filter out by UserType in GA custom views
if (analyticsUserType !== undefined) {
  ReactGA.set({ dimension1: analyticsUserType })
} else if (window._DATADOG_SYNTHETICS_BROWSER !== undefined) {
  ReactGA.set({ dimension1: "browser_bot" })
}
// enhanced e-commerce
ReactGA.plugin.require('ec');


// Datadog realtime user measurement only for real users
if (window.DD_RUM && window._DATADOG_SYNTHETICS_BROWSER === undefined) {
  window.DD_RUM.init(keys.dataDogRUM);
}

// contentful initalization of media queries for resizing images per device
ContentfulInit();

const mapStateToProps = (state) => {
  const { signedInAuthErr, nonSignedInAuthErr } = state.globalErrors;
  return { signedInAuthErr, nonSignedInAuthErr };
}

export default connect(mapStateToProps, {
  clearGlobalErrors
})(App);
