import * as Sentry from '@sentry/browser';
import { Logger } from './Logger'
import { shippingChargeCents, freeShippingMinCents } from '../data/ShippingCharges';
const logger = new Logger();

export function getGrandTotal(subtotalCents, tax, coupon, newCoupon, employeePerkCoupon, sdCredit, chargeShipping, maxSdCredit) {
    try {
        let total = 0, label;

        if (tax !== undefined) {
            label = "Total"
            total += (tax / 100)
        } else {
            label = "Pre-Tax Total"
        }

        const subtotalDollars = subtotalCents / 100;
        const shippingDollars = chargeShipping && subtotalCents < freeShippingMinCents ? shippingChargeCents / 100 : 0;
        const discountDollars = getDiscountDollars(coupon, newCoupon, subtotalDollars);
        const discountDollarsEmployeePerk = employeePerkCoupon ? getDiscountDollarsEmployeePerk(employeePerkCoupon, subtotalDollars - discountDollars) : 0;
        const discountCredit = getCreditDollars(sdCredit, subtotalDollars, discountDollars, maxSdCredit)
        total += round(subtotalDollars) + round(shippingDollars) - round(discountDollars) - round(discountDollarsEmployeePerk)- round(discountCredit)
        //Handle floating point numbers in JavaScript
        total = Math.max(0, total)

        return { total, label, discountCredit, discountDollars, subtotalDollars, shippingDollars, discountDollarsEmployeePerk }
    } catch (error) {
        logger.error(error, new Error('Could not getGrandTotal()'), null, null, { subtotalCents, tax, coupon, newCoupon })
        return { error }
    }
}

export function getDiscountDollars(coupon, newCoupon, subtotalDollars) {
    if (newCoupon) {
        try {
            const { discount } = newCoupon;
            const { type, percentOff, amountOffCents, subtotalThresholdCents } = discount;
            if (type === "PercentDiscount") {
                return floatingPointDiscountPercents(percentOff / 100 * subtotalDollars);
            } else if (type === "PercentCappedDiscount") {
                return Math.min(floatingPointDiscountPercents(percentOff / 100 * subtotalDollars), amountOffCents / 100);
            } else {
                const amountOffDollars = amountOffCents / 100;
                if (subtotalThresholdCents) {
                    const subtotalThresholdDollars = subtotalThresholdCents / 100;
                    if (subtotalDollars >= subtotalThresholdDollars) {
                        if (amountOffDollars < subtotalDollars) {
                            return amountOffDollars;
                        } else {
                            return subtotalDollars;
                        }
                    } else {
                        return 0
                    }
                } else {
                    if (amountOffDollars < subtotalDollars) {
                        return amountOffDollars;
                    } else {
                        return subtotalDollars;
                    }
                }
            }
        } catch (err) {
            logger.error(err, new Error('Unable to surface discount from newCoupon on OrderSummary'));
            return 0;
        }
    } else if (coupon && !isNaN(coupon.percentDiscount)) {
        return coupon.percentDiscount / 100 * subtotalDollars
    } else {
        return 0;
    }
}

export function getDiscountDollarsEmployeePerk(newCouponEmployeePerk, subtotalDollars) {
    if (newCouponEmployeePerk) {
        try {
            const { discount } = newCouponEmployeePerk;
            const { amountOffCents } = discount;
            //TODO subtotal needs to be after newcoupon
            const amountOffDollars = amountOffCents / 100;
            if (amountOffDollars < subtotalDollars) {
                return amountOffDollars;
            } else {
                return subtotalDollars;
            }
        } catch (err) {
            logger.error(err, new Error('Unable to surface discount from newCouponEmployeePerk on OrderSummary'));
            return 0;
        }
    } else {
        return 0;
    }
}

export function getCreditDollars(sdCredit, subtotalDollars, discountDollars, maxSdCredit) {
    if (sdCredit) {
        try {
            const sdCreditToApply = maxSdCredit ? Math.min(maxSdCredit,sdCredit) : sdCredit
            return Math.min(sdCreditToApply / 100, round(subtotalDollars) - round(discountDollars))
        } catch (err) {
            logger.error(err, new Error('Unable to surface discount from stCredit on OrderSummary'));
            return 0;
        }
    } else {
        return 0
    }
}

export function getRemainingReferral(sdCredit, discountCredit) {
    if (sdCredit) {
        try {
            return Math.max(0, round(sdCredit / 100) - round(discountCredit))
        } catch (err) {
            logger.error(err, new Error('Unable to surface discount from stCredit on OrderSummary'));
            return 0;
        }
    } else {
        return 0
    }
}

export const round = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
}

export const floatingPointDiscountPercents = (num) => {
    let parseFloatTwo = parseFloat(num).toFixed(2);
    let parseFloatThree = parseFloat(num).toFixed(3);
    let diffInFloats = Math.abs((parseFloatTwo - parseFloatThree)).toFixed(3)

    try {
        if (parseFloat(diffInFloats) === 0.005) {
            let subtractFloat = parseFloatThree - 0.005
            return subtractFloat;
        } else {
            return num;
        }
    } catch (err) {
        console.error(err);
        return num
    }
}

export function getDiscountString(newCoupon, capitalized, subtotalDollars, usesRemaining) {
    try {
        if (newCoupon) {
            const { discount, duration } = newCoupon;
            const { type, amountOffCents, percentOff, subtotalThresholdCents } = discount;
            const { repeats } = duration
            if (type === "PercentDiscount") {
                // return `(${percentOff}% off)*:`;
                // removed asterisk until terms are ready
                if (repeats) {
                    return `$${percentOff}% off your next ${usesRemaining ? usesRemaining : repeats} ${(usesRemaining && usesRemaining === 1) ? "delivery" : "deliveries"} `
                } else {
                    return capitalized ? `${percentOff}% off` : `(${percentOff}% off):`
                }
            } else if (type === "PercentCappedDiscount") {
                return `(${percentOff}% off up to $${(amountOffCents / 100).toFixed(2)}):`
            } else {
                // TODO: 
                // add asterisk when terms are ready
                const amountOffDollars = amountOffCents / 100;
                // Check for decimal place values.
                // If int, display as int
                const digits = amountOffDollars % 1 === 0 ? 0 : 2;
                if (subtotalThresholdCents) {
                    if ((subtotalDollars * 100) >= subtotalThresholdCents) {
                        return `$${amountOffDollars.toFixed(digits)} off your next ${usesRemaining ? usesRemaining : repeats} ${(usesRemaining && usesRemaining === 1) ? "delivery" : "deliveries"} `
                    } else {
                        const additionalAmountNeeded = round((subtotalThresholdCents / 100 )- subtotalDollars).toFixed(2)
                        return `To get your $${amountOffDollars.toFixed(digits)} credit add $${additionalAmountNeeded}`
                    }
                } else {
                    return `${capitalized ? "Up" : "up"} to $${(amountOffDollars).toFixed(digits)}:`
                }
            }
        }
    } catch (err) {
        console.error(err);
        Sentry.captureException(new Error('Failed to create Employer Discount string'))
        return '';
    }
}

export function getSubtotalFromManifestBundles(manifestBundles) {
    try {
        let subtotalCents = 0;

        Object.values(manifestBundles).forEach(bundle => {
            subtotalCents += bundle.subtotal;
        })
        return { subtotalCents }
    } catch (error) {
        Sentry.captureEvent(error);
        Sentry.captureException(new Error('Could not set total from manifestBundles'))
        return { error }
    }
}

export function formatTotalToGetTax(total) {
    try {
        const amount = parseInt((parseFloat((total).toFixed(2)) * 100).toFixed(0));
        return { amount };
    } catch (error) {
        Sentry.captureEvent(error);
        Sentry.captureException(new Error('Could not format total for GET tax req body'))
        return { error }
    }
}
