import React, { Component, Fragment } from 'react';
import {
    LandingMain as Main,
    LandingRow as Row,
    HeroBody,
    BundleHeroContainer,
    HeroCopyContainer,
    BundleHero,
    HeroHeader,
    ScienceHeroImg as HeroImg,
    ScienceHeroImgContainer,
    H2Amaranth as H2,
    LandingSubheader as Subheader,
    PageDivider,
    RaisedSection,
    colors,
    ScienceHoneycomb as Honeycomb,
    ScienceHexagons as Hexagons,
    ProcessLoopBody as LoopBody,
    ScienceStructure as Structure,
    ScienceAtom as Atom,
    SciencePattern as Pattern,
    flexCenter,
    LandingSectionMainImgLabel as Label,
    conversationalPatternedBackground
} from '../../Style';
import Footer from '../Common/Footer'
import {
    MetaTags,
    LandingNav,
    ProcessLoop,
    LandingTwoUpSection as Section,
    HouseholdGrouping,
    TestimonialCardCarousel,
    BetterWay
} from '../Common'
import {
    honeycomb,
    home_hexagons,
    science_atom,
    science_hero_desktop,
    science_pattern,
    science_pie,
    science_tailored_phone,
    science_text_us,
    science_stacked_bar,
    science_structure,
    science_hero_mobile
} from '../../assets/science';
import '../../Style/testimonials.css';
import { supplyDropTestimonials } from '../../data/SupplyDropTestimonials';
import { defaultZipcodes } from '../../data/DefaultZipcodes';
import { parseSearchParams, postProspect, getLanding } from '../../actions';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import { Logger } from '../../helpers/Logger';
import { featureFlags } from '../../config';
import { connect } from 'react-redux';
const logger = new Logger();

class Science extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hero: {
                header: `The Science of Supply Drop`,
                cta: {
                    copy: "Create your plan",
                    action: this.onGetStartedClick
                },
                img: science_hero_mobile,
                alt: "The Science of Supply Drop",
                body: (<Fragment>Learn how our unique system helps you <b>save time for what matters</b>, at <b>no extra cost.</b></Fragment>)
            }
        };

        this.handleResize = this.handleResize.bind(this);
        this.setIdentityBreadcrumb = this.setIdentityBreadcrumb.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
        this.setFromSearchParams = this.setFromSearchParams.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    componentDidMount() {
        const { search } = window.location;
        this.handleResize(null, true)
        if (this.props.location && this.props.location.state)
            this.setState({ signUpData: this.props.location.state.signUpData });

        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            Analytics.setUser(data.username);
            if(search) this.setFromSearchParams(search);
            this.props.getLanding(search)
        }).catch(err => {
            this.setIdentityBreadcrumb();
            if(search) this.setFromSearchParams(search);
            this.props.getLanding(search)
        })
    }

    componentDidUpdate() {
        const { landingData, landingError } = this.props;

        if(landingData && !this.state.landingData)
            this.setState({ landingData, ...landingData })

        if(landingError && !this.state.landingError) {
            this.setState({ landingError })
            logger.error(landingError)
        }
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/our-promise';

            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    setFromSearchParams(search) {
        const { searchData, error } = parseSearchParams(search);
        if (!error) {
            const { utm_campaign, utm_content, utm_source, employer, fbclid, rc, refCust } = searchData;

            const referrer = searchData.referrer ? searchData.referrer : document.referrer;

            this.setState({ utm_campaign, utm_content, utm_source, employerSlug: employer, fbclid, rc, refCust, referrer });

            this.props.postProspect({
                campaignId: utm_campaign,
                referrer,
                attributes: {
                    employer,
                    fbclid,
                    rc,
                    refCust,
                    utm_content,
                    utm_source,
                }
            })
        } else {
            logger.error(error)
        }
    }


    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            logger.errorr(err);
        })
    }

    handleResize(e, init) {
        const { isMobile, hero } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            hero.img = science_hero_mobile;
            this.setState({ isMobile: true, hero })
        }
        if (window.innerWidth >= 991 && (isMobile || init)) {
            hero.img = science_hero_desktop;
            this.setState({ isMobile: false })
        }
    }

    toggleMenu() {
        const { isShowingMenu } = this.state;
        this.setState({ isShowingMenu: !isShowingMenu });
    }

    renderHero() {
        const { history } = this.props;
        const { isSignedIn, isMobile, isShowingMenu } = this.state;

        const copy = isMobile ? (
            <Fragment>
                {this.renderHeroCopy()}
                {this.renderHeroImg()}
            </Fragment>
        ) : (
                <Fragment>
                    {this.renderHeroImg()}
                    {this.renderHeroCopy()}
                </Fragment>
            )

        return (
            // TODO: Revisit this when ensuring props are passed "properly" (lol) between landing pages/components on signUpFlow
            // <Row as={BundleHero} hasCoupon={coupon}>
            <Row as={BundleHero} noBorder={true}>
                <LandingNav
                    history={history}
                    isSignedIn={isSignedIn}
                    isTablet={isMobile}
                    isMobile={isMobile}
                    toggleMenu={this.toggleMenu}
                    isShowingMenu={isShowingMenu}
                    next={this.onGetStartedClick}
                />
                <BundleHeroContainer>
                    {copy}
                </BundleHeroContainer>
            </Row>
        )
    }

    renderHeroCopy() {
        const { isMobile, hero } = this.state;
        const { header, body } = hero;
        return (
            <HeroCopyContainer>
                <HeroHeader isNew={true} isMobile={isMobile}>
                    {header}
                </HeroHeader>
                <HeroBody alignLeft={!isMobile} isNew={true} isMobile={isMobile}>
                    {body}
                </HeroBody>
            </HeroCopyContainer>
        )
    }

    renderHeroImg() {
        const { hero } = this.state;
        return (
            <ScienceHeroImgContainer>
                <HeroImg src={hero.img} alt="Science hero image" />
            </ScienceHeroImgContainer>
        )
    }

    renderAboutYou() {
        const { isMobile } = this.state;
        return (
            <Fragment>
                <PageDivider />
                <RaisedSection style={{ zIndex: 2, background: colors.pureWhite}}>
                    <LoopBody>
                        <Honeycomb src={honeycomb} alt="" />
                        <Hexagons src={home_hexagons} alt="" />
                        <H2>Our science is all about you,</H2>
                        <Subheader>(and your home essentials)</Subheader>
                        <ProcessLoop isMobile={isMobile} />
                    </LoopBody>
                </RaisedSection>
            </Fragment>
        )
    }

    renderTailoredForYou() {
        const { isMobile } = this.state;
        const height = isMobile ? "354px" : "484px";
        const positionedImages = [
            <Structure key={"tailoredForYou"} alt="" src={science_structure} isTailoredSection={true} />
        ]
        return (
            <Section
                header="We build a personalized plan"
                body="Take a short quiz to tell us about your household. Based on your answers and leveraging artificial intelligence, we build a unique plan for your home. No household is the same and we appreciate that."
                mainImg={{
                    alt: "Tailored for you",
                    src: science_tailored_phone
                }}
                isRaised={true}
                isOverflowY={true}
                raisedSectionStyles={{
                    zIndex: 3,
                    background: "rgba(245, 250, 255, 0.66)",
                    overflowY: "hidden"
                }}
                overflowContainerStyles={{
                    height,
                    width: "460px"
                }}
                positionedImages={positionedImages}
            />
        )
    }

    renderPredictedNeeds() {
        const { isMobile } = this.state;
        const positionedImages = [
            <Atom key="atom" alt="" src={science_atom} />
        ]

        return (
            <Fragment>
                <Section
                    header="We predict how much you use each month"
                    isReverse={!isMobile}
                    body="We've done a ton of research, but we also know everyone and every household is different. We combine that research, your quiz answers, and our artificial intelligence (which has learned from thousands of households and customer interactions) to create a unique prediction for how much your household consumes every month, for every product. While we predict what you need, you are always in control and can adjust your orders if you would like."
                    subcopy={
                        <HouseholdGrouping />
                    }
                    mainImg={{
                        alt: "Household essentials consumption graph",
                        src: science_pie,
                        label: <Label>Average monthly household essentials spend for a family of 4 and their puppy.</Label>
                    }}
                    isRaised={true}
                    raisedSectionStyles={{
                        background: colors.pureWhite,
                        zIndex: 4,
                        height: isMobile ? "auto" : 650,
                        width: "100%",
                        overflowY: "hidden",
                        ...flexCenter
                    }}
                    positionedImages={positionedImages}
                    isOverflowY={isMobile}
                    isOverflowX={isMobile}
                    overflowContainerStyles={{
                        height: "280px",
                        width: "460px",
                    }}
                    overflowStyles={{
                        top: 48
                    }}
                />
            </Fragment>
        )
    }

    renderPersonalizedPlan() {
        const { isMobile } = this.state;
        const positionedImages = [
            <Pattern key="patern1" alt="" src={science_pattern} />,
            <Pattern key="patern2" alt="" src={science_pattern} isSecond={true} />,
            <Hexagons key='hexagon' alt="" src={home_hexagons} isPersonalizedPlan={true} />
        ]
        return (
            <Section
                header="We deliver just what you need"
                body="Based on our predictions and insights we get from interacting with you, we learn how often you need what products delivered. Every delivery is different and tailored specifically to your needs. While you need TP every month, you may need dish soap every two months and surface cleaner every five months. Your household is unique and so are your deliveries to ensure that you are always stocked with your home essentials."
                mainImg={{
                    alt: "Household essentials consumption graph",
                    src: science_stacked_bar,
                    label: <Label>Average monthly household essentials spend for a family of 4 and their puppy.</Label>
                }}
                isRaised={true}
                raisedSectionStyles={{
                    zIndex: 5,
                    background: "#FFFAF5",
                    minHeight: 600,
                    paddingTop: isMobile ? 40 : 'auto',
                    overflowY: "hidden",
                    ...flexCenter
                }}
                positionedImages={positionedImages}
            />
        )
    }

    renderTextUs() {
        const { isMobile } = this.state;
        const height = isMobile ? "350px" : "494px";
        const width = isMobile ? "452px" : "50%";
        const overflowStyles = isMobile ? { left: 0, right: 0, top: 20 } : { left: 'auto', right: 0, top: 40 };
        const positionedImages = [
            <Structure key={"textUs"} alt="" src={science_structure} />
        ]
        return (
            <Section
                header="We are here for you"
                isReverse={!isMobile}
                body="In emergency need of toilet paper or face masks? Did you get a new puppy (we understand that means going through more paper towels)? Do you want to change products? Our friendly bot is just a text away to help but when you need that human touch, we are always standing by."
                mainImg={{
                    alt: "Superior customer service over text message",
                    src: science_text_us
                }}
                isRaised={true}
                raisedSectionStyles={{
                    background: "#F5FAFF",
                    zIndex: 6
                }}
                isOverflowY={true}
                overflowContainerStyles={{
                    height,
                    width
                }}
                overflowStyles={overflowStyles}
                positionedImages={positionedImages}
            />
        )
    }

    renderTestimonials() {
        return <TestimonialCardCarousel testimonials={supplyDropTestimonials} />
    }

    renderBetterWay() {
        const { isSignedIn } = this.state
        return <BetterWay
                isScience={true}
                signUpData={this.state.signUpData}
                pathname='/our-promise'
                isSignedIn={isSignedIn}
                ctaCopy="Get Started"
                handleCTAClick={this.onGetStartedClick}
            />
    }

    renderPredictedDivider() {
        const { isMobile } = this.state;
        if(!isMobile)
            return <PageDivider isReverse={true} />
    }

    renderTestimonialsAndBetterWay() {
        return (
            <div style={{ background: conversationalPatternedBackground("#FFE5CC") }}>
                {this.renderTestimonials()}
                {this.renderBetterWay()}
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <MetaTags
                    title="About the Science of Supply Drop"
                    description="Learn how Supply Drop's unique system helps you save time for what matters, at no extra cost."
                    robots="follow"
                />
                <Main>
                    {this.renderHero()}
                    {this.renderAboutYou()}
                    {this.renderTailoredForYou()}
                    {this.renderPredictedNeeds()}
                    {this.renderPredictedDivider()}
                    {this.renderPersonalizedPlan()}
                    {this.renderTextUs()}
                    {this.renderTestimonialsAndBetterWay()}
                </Main>
                <Footer history={this.props.history} />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { landingData, gettingLandingData, landingError } = state.order;
    const { postProspectSuccess, postProspectError, postingProspect } = state.user;
    return {
        postProspectSuccess,
        postProspectError,
        postingProspect,
        landingData,
        gettingLandingData,
        landingError
    }
}

export default connect(mapStateToProps, {
    postProspect,
    getLanding
})(Science);
