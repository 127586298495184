import styled, { css } from 'styled-components';
import { FlexRowCenter, flexCol } from './Flex';
import { colors } from './Colors';
import { mediaQueries } from './Common';

export const PublicationsContainer = styled(FlexRowCenter)`
    ${props => props.fill && css`
        background-color: ${props.fill};
    `}
`

export const PublicationsWrapper = styled(FlexRowCenter)`
    margin: 80px auto 20px;
    align-items: center;
    position: relative;
    width: 1110px;
    max-width: 100vw;
    ${props => props.isTablet && css`
        flex-direction: column;
    `}
`

export const PublicationsBody = styled.a`
    ${{ ...flexCol }};
    justify-content: center;
    text-align: center;
    height: 180px;
    width: 290px;
    padding: 12px;
    border-radius: 16px;
    &:hover {
        background-color: #EFF3F6;
        text-decoration: none;
    }
    margin: 24px;
    ${mediaQueries("md")`
        height: 150px;
    `}
`

export const PublicationsText = styled.p`
    padding: 16px 8px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
    color: ${colors.disabledBackground};
    letter-spacing: 0.01em;
    line-height: 140%;
`

export const PublicationsHeartsImg = styled.img`
    width: 150px;
    height: auto;
`

export const PublicationsClickableCard = styled.a`
    &:hover {
        background-color: #EFF3F6;
        text-decoration: none;
        color: inherit;
    }
    color: inherit;
`