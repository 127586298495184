import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { science_pattern } from '../../../assets/science';
import {
    ProductGroupDetailsTabsContainer,
    ProductGroupDetailsPositionedImg1,
    ProductGroupDetailsPositionedImg2,
    ProductGroupDetailsTabsWrapper
} from '../../../Style/ProductGroupLandingStyles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function ProductGroupDetailsTabs({
    longDescription,
    ingredients,
    useInstructions
}) {
    const ingredientsIdx = longDescription ? 1 : 0;
    const useInstructionsIdx = longDescription && ingredients ? 2 : ingredients || longDescription ? 1 : 0;
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function renderLongDescription() {
        return (
            <TabPanel value={value} index={0} dir={theme.direction}>
                {longDescription}
            </TabPanel>
        )
    }

    function renderIngredients() {
        return (
            <TabPanel value={value} index={ingredientsIdx} dir={theme.direction}>
                {ingredients}
            </TabPanel>
        )
    }

    function renderUseInstructions() {
        return (
            <TabPanel value={value} index={useInstructionsIdx} dir={theme.direction}>
                {useInstructions}
            </TabPanel>
        )
    }

    // This method is to counteract the <SwipeableViews /> returning an empty tab if render function returns null,
    // thus throwing off the alignment of the content to the tabs
    const views = [];
    if (longDescription) views.push(renderLongDescription);
    if (ingredients) views.push(renderIngredients)
    if (useInstructions) views.push(renderUseInstructions)

    return (
        <ProductGroupDetailsTabsContainer>
            <ProductGroupDetailsPositionedImg1 src={science_pattern} alt="" />
            <ProductGroupDetailsPositionedImg2 src={science_pattern} alt="" />
            <ProductGroupDetailsTabsWrapper>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {longDescription && <Tab label="Description" {...a11yProps(0)} />}{ }
                        {ingredients && <Tab label="Ingredients" {...a11yProps(ingredientsIdx)} />}
                        {useInstructions && <Tab label="How to Use" {...a11yProps(useInstructionsIdx)} />}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {views.map(view => view())}
                </SwipeableViews>
            </ProductGroupDetailsTabsWrapper>
        </ProductGroupDetailsTabsContainer>
    );
}