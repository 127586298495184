import { SET_FIREWORKS_SEEN, SET_FIREWORKS_UNSEEN } from './types';

export const setFireworksSeen = () => {
    return async (dispatch) => {
        dispatch({ type: SET_FIREWORKS_SEEN });
    }
}

export const setFireworksUnseen = () => {
    return async (dispatch) => {
        dispatch({ type: SET_FIREWORKS_UNSEEN });
    }
}