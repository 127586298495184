import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import FontAwesome from "react-fontawesome";
import ReactGA from 'react-ga';
import { States } from './states';
import {
    Nav,
    MetaTags,
    FixedContinueBtn,
    OrderSummary,
    FormError,
    ValueProps,
    SummaryTestimonials
} from '../Common';
import GoogleAutocomplete from './GoogleMuiAutocomplete';
import { fetchAddresses } from '../../data';
import { ValuePropsHR as HR, CardBrandImg } from '../../Style';
import Countdown from '../Common/Connected/Countdown';
import * as Sentry from '@sentry/browser';
import { isValidPhoneNumber } from 'react-phone-number-input';
import StripeForm from './StripeForm';
import { Elements } from 'react-stripe-elements';
import * as images from '../../assets';
import {
    clearCreateOrderState,
    createOrder,
    formatPhone,
    getLanding,
    getTax,
    marginForCountdown,
    validateCity,
    validateNames,
    validateEmail,
    validateStreetAddress,
    validateWorkEmail,
    validateZip,
    clearOrdersState,
    isZipFromTexas
    // formatDevEmail
} from '../../actions';
import { getGrandTotal, getSubtotalFromManifestBundles, formatTotalToGetTax } from '../../helpers/pricing'
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import Cartstack from "../../analytics/Cartstack"
import { CheckoutBody as Body } from '../../Style';
import { defaultZipcodes, campaigns } from '../../data';
import { Logger } from '../../helpers/Logger';
import { featureFlags, experiments } from '../../config';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import { Autocomplete } from '@material-ui/lab';
import { Body1 } from '../../Style/DesignSystem/Typography';
import {
    visa,
    mastercard,
    amex,
    discover
} from '../../assets/card_logos';
import { stateAbrMap } from './StateAbrMap';
import mockCheckoutData from '../../data/mock/MockCheckoutData';
import trackPurchase from '../../analytics/TrackPurchase';
import CreatingOrderModal from '../Common/Modals/CreatingOrderModal';
import addToCart from '../../analytics/addToCart';
const logger = new Logger();
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

const statesOptions = () => {
    const states = States.map(s => (
        <option key={s.iso} value={s.iso}>
            {s.name}
        </option>
    ));
    return states;
}

const statesMap = States.map(s => { return s.iso });
const stateNames = States.map(s => { return s.name.toLowerCase() });

class Checkout extends Component {
    constructor(props) {
        super(props);
        const mui_checkout = featureFlags.material_checkout_form_override;
        const checkout_summary = featureFlags.checkout_summary_override;

        this.state = {
            checkout_summary,
            mui_checkout,
            fields: {
                customer: {
                    firstName: {
                        name: "firstName",
                        type: "text",
                        label: "First Name",
                        placeholder: mui_checkout ? "" : "Jane",
                        validation: validateNames,
                        isRequired: true
                    },
                    lastName: {
                        name: "lastName",
                        type: "text",
                        label: "Last Name",
                        placeholder: mui_checkout ? "" : "Smith",
                        validation: validateNames,
                        isRequired: true
                    },
                },
                shipping: {
                    line1: {
                        name: "line1",
                        type: "text",
                        label: "Street Address 1",
                        placeholder: mui_checkout ? "" : "Address",
                        validation: validateStreetAddress,
                        isRequired: true
                    },
                    line2: {
                        name: "line2",
                        type: "text",
                        label: "Suite/Apt",
                        placeholder: mui_checkout ? "" : "Apt, suite, etc.",
                        width: 188
                    },
                    city: {
                        name: "city",
                        type: "text",
                        label: "City",
                        validation: validateCity,
                        placeholder: mui_checkout ? "" : "City",
                        isRequired: true,
                        width: 184
                    },
                    state: {
                        name: "state",
                        type: "select",
                        label: "State",
                        options: statesOptions,
                        placeholder: mui_checkout ? "" : "State",
                        isRequired: true
                    },
                    zipcode: {
                        name: "zipcode",
                        type: "text",
                        label: "Zipcode",
                        isRequired: true,
                        placeholder: mui_checkout ? "" : "Postal Code",
                        width: 168
                    }
                }
            },
            state: "",
        };

        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateAll = this.validateAll.bind(this);
        this.validateLine2 = this.validateLine2.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.validateAndSetZip = this.validateAndSetZip.bind(this);
        this.validateState = this.validateState.bind(this);
        this.setStateCode = this.setStateCode.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.back = this.back.bind(this);
        this.next = this.next.bind(this);
        this.onContinueClick = this.onContinueClick.bind(this);
        this.postTax = this.postTax.bind(this);
        this.setToEmployer = this.setToEmployer.bind(this);
        this.setToFetch = this.setToFetch.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.setExperiments = this.setExperiments.bind(this);
        this.completeOrder = this.completeOrder.bind(this);
        this.setCartStack = this.setCartStack.bind(this);
        this.createUser = this.createUser.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signUp = this.signUp.bind(this);
        this.setUser = this.setUser.bind(this);
        this.handleSignInSuccess = this.handleSignInSuccess.bind(this)
        this.handleSignInError = this.handleSignInError.bind(this)
        this.handleSignUpError = this.handleSignUpError.bind(this)
        this.signUpFlow = this.signUpFlow.bind(this)
        this.setOrderError = this.setOrderError.bind(this)

        this.stripe = React.createRef();
    }

    setCartStack() {
        try {
            const { manifestBundles, coupon, newCoupon, sdCredit, cartStackTotal, signUpData, firstName } = this.state
            const { utm_campaign, utm_content } = signUpData

            // set firstName
            if (firstName && window._cartstack) {
                Cartstack.setDataItem("firstName", firstName)
            }

            if (!cartStackTotal) {
                const { subtotalCents, error } = getSubtotalFromManifestBundles(manifestBundles);
                if (error || !subtotalCents || !window._cartstack) {
                    logger.error(error, "Checkout unable to set cartstack total")
                    return
                }

                const res = getGrandTotal(subtotalCents, null, coupon, newCoupon, undefined, sdCredit);
                if (res.error) {
                    logger.error(res.error, "Checkout unable to set cartstack total")
                    return
                }

                const total = res.total.toFixed(2)
                Cartstack.clearCartItems()
                Cartstack.setCartTotal(total)
                if (res.discountDollars) {
                    Cartstack.setAttribute("discountDollars", res.discountDollars.toFixed(2));
                }

                if (res.discountCredit) {
                    Cartstack.setAttribute("discountCredit", res.discountCredit.toFixed(2));
                }

                if (res.subtotalDollars) {
                    Cartstack.setAttribute("subtotalDollars", res.subtotalDollars.toFixed(2));
                }

                if (newCoupon) {
                    Cartstack.setAttribute("coupon", newCoupon.code)
                    Cartstack.setDataItem("coupon", newCoupon.code)
                }
                if (utm_campaign) {
                    Cartstack.setAttribute("utm_campaign", utm_campaign)
                    Cartstack.setDataItem("utm_campaign", utm_campaign)
                }
                if (utm_content) {
                    Cartstack.setAttribute("utm_content", utm_content)
                }
                if (res.discountDollarsEmployeePerk) {
                    Cartstack.setAttribute("discountDollarsEmployeePerk", res.discountDollarsEmployeePerk.toFixed(2))
                }

                Object.values(manifestBundles).forEach(bundle => {
                    Object.values(bundle.productGroups).forEach(productGroup => {
                        Cartstack.setCartItem({
                            'quantity': productGroup.articles,
                            'productID': productGroup.groupName,
                            'productName': `${productGroup.brandName} ${productGroup.displayName}`,
                            'productDescription': productGroup.description,
                            'productURL': `https://supplydrop.com/products/${productGroup.groupName}`,
                            'productImageURL': `${productGroup.imageUrl}?fm=jpg&w=180`,  // Contentful image resize to 180 and jpg format
                            'imageWidth': '180',
                            'productPrice': (productGroup.pricePerArticle / 100.0).toFixed(2),
                            'customFields': {
                                'productType': productGroup.productType
                            }
                        });
                    })
                })

                this.setState({ cartStackTotal: total })
            }
        } catch (err) {
            logger.error(err, "Checkout cartstack total failed to be set ")
        }
    }

    componentDidMount() {
        try {
            window.sd_optimize.registerListener(this.setExperiments)
            window.dataLayer.push({ 'event': 'optimize.activate' });
        } catch (err) {
            logger.error(err);
        }

        if (featureFlags.use_mock_data && !prod) {
            this.setSignUpData(mockCheckoutData);
        } else {
            if (this.props.location && this.props.location.state) {
                this.setSignUpData(this.props.location.state.signUpData);
            } else {
                logger.error(new Error("Landed on checkout without signUpData"))
                this.props.history.push("/");
            }

            if (!this.state.unauthedId) {
                Auth.currentCredentials().then(data => {
                    this.setState({ unauthedId: data._identityId });
                }).catch(err => {
                    Sentry.captureEvent(new Error('Auth.currentCredentials failed'))
                    Sentry.captureException(err);
                })
            }
        }
        Auth.configure({
            authenticationFlowType: 'USER_SRP_AUTH'
        });

        this.handleResize();

        const { fields } = this.state;
        fields.shipping.zipcode.validation = this.validateAndSetZip;
        fields.shipping.state.validation = this.validateState;
        fields.shipping.line2.validation = this.validateLine2;

        this.setState({ fields })

        this.refs.main.scrollIntoView();
    }

    setExperiments(activeExperiments) {
        try {
            const mui_checkout = activeExperiments[experiments.mui_checkout];
            if (mui_checkout && parseInt(mui_checkout)) {
                this.setState({ mui_checkout, checkout_summary: mui_checkout })
            }
        } catch (err) {
            logger.error(err)
        }
    }

    componentDidUpdate() {
        const { order, customer, refreshTotals, signUpData, awaitingZipcodes, spaceTop, street_number, route, line1 } = this.state;
        const { tax, createOrderError, createOrderSuccess, landingData, landingError, creatingOrder } = this.props;
        if (tax !== undefined && tax !== this.state.tax) {
            this.setState({ tax, refreshTotals: true });
        }

        if (creatingOrder !== this.state.creatingOrder) {
            if (creatingOrder && !this.state.creatingOrder) {
                window.scrollTo(0, 0)
            }
            this.setState({ creatingOrder })
        }

        if (refreshTotals)
            this.setState({ refreshTotals: false })

        if (createOrderError && !this.state.createOrderError) {
            this.setOrderError(createOrderError)
            this.props.clearCreateOrderState();
        }
        if (createOrderSuccess && !this.state.createOrderSuccess && order.source && customer) {
            this.setState({ createOrderSuccess })
            this.completeOrder(createOrderSuccess)
        }

        if (landingData && !this.state.landingData) {
            const { dropoffZipcodes, signupsOpen, coupon } = landingData;
            Object.assign(signUpData, { dropoffZipcodes, signupsOpen, coupon })

            this.setState({ landingData: true, signUpData })

            this.setState({ zipcodes: dropoffZipcodes, signupsOpen, coupon })
        }
        if (this.state.zipcodes && awaitingZipcodes) {
            this.onContinueClick();
            this.setState({ awaitingZipcodes: false })
        }

        if (landingError && this.state.landingError) {
            this.setState({ zipcodes: defaultZipcodes })
        }

        const element = document.getElementById("countdown-alert")
        if (element && spaceTop !== element.offsetHeight)
            this.setState({ spaceTop: element.offsetHeight })

        if (this.state.mui_checkout && featureFlags.google_form && street_number && route && line1 !== (street_number + " " + route)) {
            this.setState({ line1: (street_number + " " + route) })
        }

        this.setCartStack()
    }

    async completeOrder(createOrderSuccess) {
        const { productGroupsMap, newCoupon, sdCredit, order, customer, signUpData } = this.state;
        await trackPurchase(createOrderSuccess.order, customer, productGroupsMap, newCoupon, sdCredit);
        const updatedSignUpData = Object.assign(signUpData, { order, customer, completedOrder: createOrderSuccess });
        this.props.history.push("/ordercomplete", { signUpData: updatedSignUpData })
    }

    async postTax(zipcode, state, manifestBundles, coupon, newCoupon, sdCredit) {
        const { subtotalCents, error } = getSubtotalFromManifestBundles(manifestBundles);
        if (!error && subtotalCents) {
            const res = getGrandTotal(subtotalCents, undefined, coupon, newCoupon, undefined, sdCredit);
            if (!res.error) {
                const total = formatTotalToGetTax(res.total);
                const { amount } = total;
                if (amount || amount === 0) {
                    this.props.getTax({
                        amount,
                        state,
                        postal_code: zipcode,
                    })
                } else {
                    if (total.error)
                        logger.error(total.error, new Error('unable to get tax on /checkout'), null, null, { subtotalCents, newCoupon, manifestBundles, total, sdCredit })
                    else
                        logger.error(new Error('formatTotalToGetTax() failing and not returning error'))
                }
            }
        } else {
            if (error)
                logger.error(error, new Error('getSubtotalFromManifestBundles() failed'))

            await this.props.clearOrdersState();
            this.setState({ tax: 0, refreshTotals: true })
        }

    }

    handleResize(e) {
        const { isMobile, isXS } = this.state;
        if (window.innerWidth < 992 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 991 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 551 && !isXS) {
            this.setState({ isXS: true })
        }
        if (window.innerWidth >= 551 && isXS) {
            this.setState({ isXS: false })
        }
    }

    handleInputChange(name, value) {
        const { userExistsError, workEmailError } = this.state;
        if ((name === "displayNumber" || name === "email") && userExistsError)
            this.setState({ userExistsError: false })

        if (name === "workEmail" && workEmailError)
            this.setState({ workEmailError: undefined })

        if (name === 'state')
            this.setStateCode(value)
        else
            this.setState({ [name]: value, [`${name}Invalid`]: false, [`${name}ErrorMessage`]: null, createOrderError: undefined })
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        this.props.clearCreateOrderState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        if (this.props.creatingOrder) {
            this.props.clearCreateOrderState();
        }
        try {
            window.sd_optimize.unregisterListener()
        } catch (err) {
            logger.error(err)
        }

    }

    validateField(name, value, validation) {
        try {
            const isValid = (value || (!value && name === "line2")) ? validation(value, true) : false;
            this.setState({ [`${name}Invalid`]: !isValid });
            if (!isValid) {
                this.setErrorMessage(name, value)
            }
            return isValid;
        }
        catch (err) {
            Sentry.captureException(err);
            console.error(err);
            return false;
        }
    }

    setErrorMessage(name, value) {
        const { fields } = this.state;
        let errorMessage, field;
        switch (name) {
            case "firstName":
            case "lastName":
                if (!value) {
                    errorMessage = fields.customer[name].label + " is required"
                } else {
                    errorMessage = fields.customer[name].label + " cannot include numbers or special characters"
                }
                break;
            case "line1":
            case "line2":
            case "city":
            case "zipcode":
                field = fields.shipping[name].label;
                break;
            case "state":
                if (value && statesMap.includes(value.toUpperCase()) && value.length === 2)
                    errorMessage = "We're currently only serving TX"
                else
                    errorMessage = "Please enter valid state"
                break;
            case "email":
                field = "Email"
                break;
            case "workEmail":
                field = "Work email"
                break;
            case "phone":
                field = "Phone";
                break;
            default:
                break;
        }

        if (field && !errorMessage) {
            if (!value) {
                errorMessage = field + " is required"
            } else {
                errorMessage = field + " format is invalid"
            }
        }
        this.setState({ [`${name}ErrorMessage`]: errorMessage })
    }

    setStateCode(value) {
        const { zipcode, zipcodeInvalid, manifestBundles, coupon, newCoupon, sdCredit } = this.state;
        if (value.length > 2 && stateNames.includes(value.toLowerCase())) {
            value = stateAbrMap[value.toLowerCase()]
        }
        const stateInvalid = !this.validateField('state', value, this.validateState)
        if (value && zipcode && !zipcodeInvalid && !stateInvalid && value !== this.state.state) {
            this.postTax(zipcode, value, manifestBundles, coupon, newCoupon, sdCredit)
        }
        this.setState({ state: value, stateInvalid });
    }

    validateLine2(value) {
        let { campaignShortName, line2Invalid, isFetch } = this.state;
        if (isFetch) {
            let formattedLine2 = "";
            if (!value.startsWith(campaignShortName)) {
                value = campaignShortName
            } else {
                formattedLine2 = value.split(campaignShortName)[1];
            }
            line2Invalid = !formattedLine2;
            this.setState({ line2: value, formattedLine2, line2Invalid })
            return !line2Invalid;
        } else {
            this.setState({ line2: value });
            return true
        }
    }

    validateAndSetZip(zipcode, isOnBlur) {
        const { state, manifestBundles, coupon, newCoupon, rc, sdCredit, stateInvalid } = this.state;
        let isValid = validateZip(zipcode);
        if (featureFlags.block_outside_texas && isValid && !rc) isValid = isZipFromTexas(zipcode);
        if (isValid) {
            if (isValid && state && !stateInvalid && isOnBlur) {
                this.postTax(zipcode, state, manifestBundles, coupon, newCoupon, sdCredit);
            }
        }
        this.setState({ zipcodeInvalid: !isValid, zipcode })
        return (isValid);
    }

    validateState(value) {
        const { rc } = this.state;
        if (!featureFlags.block_outside_texas || rc === "sd4me") {
            return !!value && statesMap.includes(value.toUpperCase());
        } else {
            const isZipUnsupported = value && value.toUpperCase() !== "TX";
            this.setState({ isZipUnsupported, isStateInvalid: !isZipUnsupported });
            return !isZipUnsupported;
        }
    }

    validatePhone(displayNumber) {
        const phone = formatPhone(displayNumber)
        const phoneInvalid = !isValidPhoneNumber(phone);
        this.setState({ phone, phoneInvalid, displayNumber })
        return !phoneInvalid;
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { zipcode, rc, coupon, newCoupon, manifestBundles, utm_content, utm_campaign, zipcodes, isEmployeePerk, sdCredit } = signUpData

            if (prod)
                addToCart(manifestBundles)

            if (!zipcodes)
                this.props.getLanding()

            if (zipcode) {
                marginForCountdown();
                const firstTwo = zipcode.substring(0, 2);
                if (firstTwo === "78") this.setState({ state: "TX" })
                this.postTax(zipcode, "TX", manifestBundles, coupon, newCoupon, sdCredit)
            }
            if (utm_campaign) {
                if (utm_campaign.includes('fetch')) {
                    this.setToFetch(utm_content, manifestBundles, coupon, newCoupon);
                } else if (utm_campaign.includes('employer') || isEmployeePerk) {
                    this.setToEmployer(utm_content);
                }
            } else if (rc && rc.toLowerCase() === "sd4me") {
                this.setState({ rc });
            }
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    setToFetch(utm_content, manifestBundles, coupon, newCoupon) {
        const { shippingAddress } = fetchAddresses[utm_content];
        const { line1, line2, city, zipcode, state } = shippingAddress;
        this.postTax(zipcode, state, manifestBundles, coupon, newCoupon)
        this.setState({ line1, line2, city, zipcode, state, campaignShortName: utm_content.toUpperCase(), isFetch: true })
    }

    setToEmployer(utm_content) {
        const employerName = campaigns[utm_content].displayName;
        this.setState({ employerName, isEmployeePerk: true })
    }

    back() {
        const { signUpData } = this.state;
        const pathname = '/recommendation';
        this.props.history.push(pathname, { signUpData })
    }

    next(customer, source) {
        const {
            order,
            coupon,
            interview,
            unauthedId,
            utm_campaign,
            rc,
            newCoupon
        } = this.state;

        interview.phone = customer.phone;
        customer.interview = interview;
        if (newCoupon) {
            order.coupon = newCoupon.code;
        } else if (coupon) {
            order.coupon = coupon.code;
        }

        if (utm_campaign) {
            customer.campaignCode = utm_campaign
        } else if (rc !== undefined) {
            customer.campaignCode = rc
        }

        order.customer = customer;
        order.source = source;
        this.props.createOrder(order, unauthedId);
        this.setState({ order });
    }

    async onContinueClick() {
        try {
            this.setState({ createOrderError: undefined })
            this.setState({ loading: true })
            const isValid = this.validateAll();
            if (isValid) {
                const { source, error } = await this.stripe.current.state.stripe.createSource({
                    type: 'card'
                })
                if (source)
                    this.createUser(source);
                else {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-PaymentMethodNotProvided',
                        label: 'PaymentMethodNotProvided',
                    })
                    this.setState({ loading: false, stripeError: error.message ? error.message : error })
                }
            } else {
                this.setState({ loading: false })
            }

            ReactGA.event({
                category: 'CheckoutUIEvents',
                action: 'PlaceOrderClicked',
                label: isValid ? 'Valid' : 'Not Valid',
            })
        } catch (error) {
            ReactGA.event({
                category: 'CheckoutUIEvents',
                action: 'PlaceOrderClicked',
                label: "Error",
            })
            console.error(error);
            this.setState({ loading: false })
            Sentry.captureException(error);
        }
    }

    validateAll() {
        const {
            fields,
            zipcode,
            zipcodeInvalid,
            email,
            emailInvalid,
            workEmail,
            isZipUnsupported,
            userExistsError,
            isFetch,
            isEmployeePerk,
            employerSlug,
            line2,
            displayNumber
        } = this.state;
        let isValid = true;
        try {
            for (let i = 0; i < Object.values(fields).length; i++) {
                const set = Object.values(fields)[i];
                for (let j = 0; j < Object.entries(set).length; j++) {
                    const name = Object.entries(set)[j][0], value = Object.entries(set)[j][1];
                    const { isRequired, validation } = value;
                    if (isRequired) {
                        const fieldValid = this.validateField(name, this.state[name], validation)
                        if (!fieldValid) {
                            isValid = false;
                            ReactGA.event({
                                category: 'CheckoutUIEvents',
                                action: 'ValidationFailure-' + name,
                                label: this.state[name]
                            })
                        }

                    }
                }
            }
            if (isFetch) {
                const fetchValid = this.validateLine2(line2);
                if (!fetchValid) {
                    isValid = false
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-Fetch',
                        label: line2
                    })
                }

            } else if (isEmployeePerk) {
                const res = validateWorkEmail(workEmail, employerSlug)
                const workEmailInvalid = !res.workEmailValid;
                if (workEmailInvalid) {
                    isValid = false;
                    const { workEmailError } = res;
                    this.setState({ workEmailInvalid, workEmailError })
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-WorkEmail-' + employerSlug,
                    })
                }
            }
            const phoneInvalid = !displayNumber || !this.validatePhone(displayNumber)
            this.setState({ phoneInvalid })
            if (!zipcode || zipcodeInvalid || !email || phoneInvalid || emailInvalid || isZipUnsupported || userExistsError) {
                isValid = false;
                if (!zipcode) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-ZipcodeNotProvided',
                        label: 'ZipcodeNotProvided',
                    })
                }

                if (zipcodeInvalid) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-ZipcodeInvalid',
                        label: 'Zipcode invalid ' + zipcode,
                    })
                }

                if (!email) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-EmailNotProvided',
                        label: 'EmailNotProvided',
                    })
                }

                if (phoneInvalid) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-PhoneInvalid',
                    })
                }

                if (emailInvalid) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-EmailInvalid',
                    })
                }

                if (isZipUnsupported) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-ZipcodeUnsupported',
                        label: 'Zipcode not supported ' + zipcode,
                    })
                }

                if (userExistsError) {
                    ReactGA.event({
                        category: 'CheckoutUIEvents',
                        action: 'ValidationFailure-UserExists',
                    })
                }
            }
            return isValid;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            return false;
        }
    }

    getRandomString() {
        const randomValues = new Uint8Array(30);
        const crypto = window.crypto || window.msCrypto;
        crypto.getRandomValues(randomValues);
        return Array.from(randomValues).map(this.intToHex).join('');
    }

    intToHex(num) {
        return num.toString(16).padStart(2, '0');
    }

    signIn(email, password) {
        return Auth.signIn(email.toLowerCase(), password);
    }

    formatCustomerForOrderCall() {
        const {
            firstName,
            lastName,
            line1,
            zipcode,
            city,
            line2,
            deliveryInstructions,
            employer,
            isEmployeePerk,
            workEmail,
            refCust,
            email,
            displayNumber
        } = this.state;

        // ensure delivery address state is all caps e.g. TX vs tx
        const state = this.state.state && this.state.state.toUpperCase()
        const phone = formatPhone(displayNumber);

        const customer = {
            email: email.toLowerCase(),
            firstName,
            lastName,
            phone,
            shipping: {
                name: `${firstName} ${lastName}`,
                address: line1,
                state,
                zipcode,
                city,
            }
        };
        if (line2) customer.shipping.address += `, ${line2}`;
        if (deliveryInstructions) customer.shipping.deliveryInstructions = deliveryInstructions;
        if (isEmployeePerk && employer) {
            customer.workEmail = workEmail;
            customer.employerId = employer.employerId;
        }
        if (refCust) {
            customer.referringCustomerId = refCust
        }
        this.setState({ customer })
        return customer
    }

    signUp(email, password) {
        const { displayNumber, firstName, lastName } = this.state;
        const phone = formatPhone(displayNumber);
        return Auth.signUp({
            username: email.toLowerCase(),
            password,
            attributes: {
                email: email.toLowerCase(),
                phone_number: phone,
                given_name: firstName,
                family_name: lastName
            }
        })
    }

    setUser(email) {
        Auth.currentAuthenticatedUser().then(data => {
            Analytics.setUser(data.username, { email: email.toLowerCase() });
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    setOrderError(createOrderError) {
        try {
            let stripeError = createOrderError.response.data.error;
            const message = stripeError.split(";")[0];
            logger.error(message, new Error('createOrderError'))
            if (message)
                this.setState({ createOrderError: message, loading: false });
            else
                throw new Error("unexpected error schema")
        } catch (err) {
            this.setState({ createOrderError: "An unexpected error has occured. Please ensure all fields are entered correctly, and try again." });
        } finally {
            this.setState({ loading: false })
        }
    }

    handleSignInSuccess(email, source) {
        this.setUser(email);
        const customer = this.formatCustomerForOrderCall();
        this.next(customer, source);
    }

    handleSignInError(signInErr) {
        ReactGA.event({
            category: 'CheckoutUIEvents',
            action: 'SignInFailure',
        })
        logger.error(signInErr, new Error("Checkout signIn error"))
        this.setState({ userExistsError: "We are not currently able to process your order", loading: false })
    }

    handleSignUpError(signUpError) {
        logger.error(signUpError, new Error("Checkout signUp error"));

        this.setState({ userExistsError: "A user with that phone number or email address already exists", loading: false })
        const emailRef = ReactDOM.findDOMNode(this.refs.email)
        window.scrollTo(0, emailRef.offsetTop - 60);
        ReactGA.event({
            category: 'CheckoutUIEvents',
            action: 'SignupFailureDuplicateUserInner',
        })
    }

    handleCreateUserError(error) {
        logger.error(error, new Error("Checkout: Unable to create user"))
        // Error with the SD code, not the amplify code...
        this.setState({ userExistsError: "An unexpected error has occured. Please ensure all fields are entered correctly, and try again.", loading: false })
        ReactGA.event({
            category: 'CheckoutUIEvents',
            action: 'SignupFailureInterrnalError',
        })
    }

    signUpFlow(source) {
        const { email } = this.state;
        const password = this.getRandomString();
        this.signUp(email, password).then(() => {
            this.signIn(email, password).then(() => {
                this.handleSignInSuccess(email, source);
            }).catch(signInError => {
                this.handleSignInErr(signInError)
            })
        }).catch(signUpError => {
            this.handleSignUpError(signUpError)
        }).finally(() => {
            this.setState({ password })
        })
    }

    async createUser(source) {
        const { email, password } = this.state;
        try {
            if (password) {
                this.signIn(email, password).then(() => {
                    this.handleSignInSuccess(email);
                }).catch(() => {
                    this.signUpFlow(source);
                })
            } else {
                this.signUpFlow(source);
            }
        } catch (error) {
            this.handleCreateUserError(error);
        }
    }

    renderMobileValueProps() {
        const { isMobile, checkout_summary } = this.state;
        if (isMobile && !checkout_summary) {
            return (
                <Fragment>
                    <HR />
                    <ValueProps />
                    <SummaryTestimonials isMobile={isMobile} />
                </Fragment>
            )
        }
    }

    renderCountdown() {
        return <Countdown history={this.props.history} zipcode={this.state.zipcode} setZipcode={(zipcode) => this.setState({ zipcode })} signUpData={this.state.signUpData} />
    }

    renderCustomerForm() {
        const { fields } = this.state;
        const { customer } = fields;
        return (
            <div className="checkout-section">
                {this.renderInputs(customer)}
                {this.renderEmail()}
                {this.renderPhoneNumber()}
                {this.renderWorkEmail()}
                {this.renderAccountExistsError()}
                {this.renderEmployeePerkDisclaimer()}
                {this.renderTextMessageDisclaimer()}
            </div>
        )
    }

    renderBillingForm() {
        return (
            <div className="input-wrapper flex-row flex-wrap" ref="stripe">
                <Elements>
                    <StripeForm
                        handleInputChange={this.handleInputChange}
                        validateCardName={this.validateField}
                        isXS={this.state.isXS}
                        mui_checkout={this.state.mui_checkout}
                        ref={this.stripe}
                    />
                </Elements>
                {this.renderStripeError()}
                <div className="security-container">
                    {this.renderSecurityCopy()}
                    {this.renderCardLogos()}
                </div>
            </div>
        )
    }

    renderSecurityCopy() {
        return (
            <Body1><FontAwesome name="lock" className="billing-lock" />All transactions are secure and encrypted.</Body1>
        )
    }

    renderCardLogos() {
        return (
            <Fragment>
                <CardBrandImg src={visa} alt="Visa" />
                <CardBrandImg src={mastercard} alt="Mastercard" />
                <CardBrandImg src={amex} alt="Amex" />
                <CardBrandImg src={discover} alt="Discover" />
            </Fragment>
        )
    }

    renderShippingForm() {
        const { fields, isFetch } = this.state;
        if (isFetch) {
            return this.renderFetchFields();
        } else {
            const { shipping } = fields;
            const rows = this.renderInputs(shipping);
            return (
                <div className="checkout-section">
                    {rows}
                    {this.renderDeliveryInstructions()}
                </div>
            )
        }
    }

    renderDeliveryInstructions() {
        const { isXS, deliveryInstructions, mui_checkout } = this.state;
        if (mui_checkout) {
            return (
                <TextField
                    type="text"
                    name="delivery-instructions"
                    id="checkout-delivery-instructions"
                    inputProps={{
                        "test-id": "delivery-instructions"
                    }}
                    multiline
                    fullWidth={isXS}
                    style={{
                        margin: isXS ? "1%" : 4,
                        width: isXS ? "98%" : 456,
                        maxWidth: "100%"
                    }}
                    label="Delivery Instructions"
                    placeholder="Gate code is 123, leave on the porch."
                    value={deliveryInstructions}
                    onChange={e => this.handleInputChange('deliveryInstructions', e.currentTarget.value)}
                    variant="outlined"
                />
            )
        } else {
            return (
                <Fragment>
                    <FormError copy={this.state.isZipUnsupported ? "Supply Drop does not currently deliver to your area" : null} />
                    <div className="modal-input-container">
                        <label className="modal-label">Delivery Instructions</label>
                    </div>
                    <textarea
                        className="modal-input"
                        type="text"
                        name="delivery-instructions"
                        id="delivery-instructions"
                        placeholder="Delivery instructions"
                        value={deliveryInstructions}
                        onChange={e => this.handleInputChange('deliveryInstructions', e.currentTarget.value)}
                    />
                </Fragment>
            )
        }
    }

    renderFetchFields() {
        const { line2Invalid, firstName, lastName, city, state, zipcode, line1, line2, isXS, mui_checkout } = this.state;

        const renderInput = () => {
            if (mui_checkout) {
                return (
                    <div className="checkout-input-container half space-top">
                        <TextField
                            error={line2Invalid}
                            helperText={line2Invalid && "Invalid Fetch code"}
                            type="text"
                            id="fetch-code"
                            inputProps={{
                                "test-id": "fetch-code",
                                className: line2Invalid ? "invalid" : ""
                            }}
                            name="fetch-code"
                            label="Fetch Code"
                            value={line2}
                            style={{
                                width: isXS ? "100%" : 224
                            }}
                            onChange={e => this.validateLine2(e.currentTarget.value)}
                            onBlur={e => this.validateLine2(e.currentTarget.value)}
                            variant="outlined"
                        />
                    </div>
                )
            } else {
                return (
                    <div className="modal-input-container space-top">
                        <label className="modal-label inline-block">Fetch Code</label>
                        {/* <span id="fetch-info-icon"><img src={images.info} className="interview-icon interview-info-icon" alt="Info" /></span> */}
                        <input
                            className={`modal-input${line2Invalid ? ' invalid' : ''}`}
                            type="text"
                            id="fetch-code"
                            test-id="fetch-code"
                            name="fetch-code"
                            label="Fetch Code"
                            value={line2}
                            onChange={e => this.validateLine2(e.currentTarget.value)}
                            onBlur={e => this.validateLine2(e.currentTarget.value)}
                        />
                    </div>
                )
            }
        }
        return (
            <Fragment>
                {renderInput()}
                <div className="checkout-confirm-details space-top">
                    <p>
                        <u>Delivery Address</u><br /><br />
                        {firstName} {lastName}<br />
                        {line1}<br />
                        {line2}<br />
                        {city}, {state} {zipcode}<br /><br />
                    </p>
                </div>
            </Fragment>
        )
    }

    renderTextMessageDisclaimer() {
        return (
            <p className="mobile-note space-top">
                *You will receive occasional <b>text messages</b> from us to help tune your orders. Unsubscribe at any time (although that will limit the magic of our service).
            </p>
        )
    }

    renderEmployeePerkDisclaimer() {
        const { employer, isEmployeePerk } = this.state;

        if (isEmployeePerk && employer) {
            const { displayName } = employer;
            return (
                <p className="mobile-note space-top">
                    By clicking Place Order you confirm you are currently employed by {displayName} and that at any time you are no longer employed by {displayName} or {displayName} no longer supplements your Supply Drop subscription, that you will be responsible for the entire cost of your plan.
                </p>
            )
        }
    }

    renderMaterialInput(id, values) {
        const { isXS } = this.state;
        const { type, label, validation, name, ref } = values;
        let width = values.width;
        if (isXS) {
            width = "100%"
        } else if (!width) {
            width = 224
        }
        let input;
        const isInvalid = this.state[`${id}Invalid`];
        const errorMessage = this.state[`${id}ErrorMessage`];
        if (featureFlags.google_form && id === "line1") {
            return (
                <div className="checkout-input-container">
                    <GoogleAutocomplete
                        key={id}
                        id={id}
                        setValue={this.handleInputChange}
                        error={isInvalid}
                        label={label}
                        isXS={isXS}
                    />
                </div>
            )
        } else if (type !== "select") {
            const halfSize = id !== "line1" && id !== "firstName" && id !== "lastName" && id !== "email";
            input = (
                <div key={id} className={`checkout-input-container${halfSize ? " half" : ""}`}>
                    <TextField
                        key={id}
                        error={isInvalid}
                        helperText={isInvalid && errorMessage}
                        type={type}
                        id={id}
                        inputProps={{
                            "test-id": id,
                            maxLength: id === 'firstName' || id === 'lastName' ? "30" : null,
                            className: isInvalid ? "invalid" : ""
                        }}
                        autoComplete="on"
                        name={name}
                        label={label}
                        style={{
                            width
                        }}
                        value={this.state[id] || ''}
                        onChange={(e) => this.handleInputChange(id, e.target.value)}
                        onBlur={(e) => this.validateField(id, e.target.value, validation)}
                        ref={ref ? ref : null}
                        variant="outlined"
                    />
                </div>
            )
        } else {
            input = (
                <div key={id} className="checkout-input-container half">
                    <FormControl variant="outlined" style={{ width: isXS ? "100%" : "88px" }}>
                        <Autocomplete
                            id="state"
                            selectOnFocus
                            disableClearable
                            value={this.state[id] || ''}
                            inputValue={this.state[id] || ''}
                            onInputChange={(event, newInputValue) => {
                                this.setStateCode(newInputValue);
                            }}
                            name="state"
                            options={statesMap}
                            renderInput={(params) => {
                                const inputProps = { className: isInvalid ? "invalid" : "" }
                                const selectParams = Object.assign(inputProps, params)
                                return (
                                    <TextField
                                        {...selectParams}
                                        variant="outlined"
                                        label="State"
                                        error={isInvalid}
                                        helperText={isInvalid && errorMessage}
                                    />
                                )
                            }
                            }
                        >
                        </Autocomplete>
                    </FormControl>
                </div >
            )
        }
        return input
    }

    renderClassicInput(id, values) {
        const { type, label, validation, placeholder, name, ref, options } = values
        if (id !== "state") {
            return (
                <div key={label} className="modal-input-container">
                    <label className="modal-label">{label}</label>
                    <input
                        autoFocus={id === 'firstName'}
                        key={id}
                        className={`modal-input${this.state[`${id}Invalid`] ? ' invalid' : ''}`}
                        type={type}
                        id={id}
                        test-id={id}
                        autoComplete="on"
                        name={name}
                        maxLength={(id === "firstName" || id === "lastName") && "30"}
                        placeholder={placeholder}
                        value={this.state[id] || ''}
                        onChange={(e) => this.handleInputChange(id, e.target.value)}
                        onBlur={(e) => this.validateField(id, e.target.value, validation)}
                        ref={ref ? ref : null}
                    />
                </div>
            )
        } else {
            return (
                <div key={label} className="modal-input-container">
                    <label className="modal-label">{label}</label>
                    <select
                        name="state"
                        id="state"
                        test-id={id}
                        className={`modal-input${this.state[`${id}Invalid`] ? ' invalid' : ''}`}
                        value={this.state[id]}
                        onChange={e => this.setStateCode(e.currentTarget.value)}
                    >
                        {options()}
                    </select>
                </div>
            )
        }
    }

    renderInputs(fields) {
        try {
            return Object.entries(fields).map(entry => {
                const id = entry[0], values = entry[1];
                if (this.state.mui_checkout) {
                    return this.renderMaterialInput(id, values)
                } else {
                    return this.renderClassicInput(id, values)
                }
            })
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            return [];
        }
    }

    renderEmail() {
        const { isEmployeePerk, employer, mui_checkout } = this.state;
        const email = {
            'email': {
                type: "text",
                label: isEmployeePerk && employer ? "Personal Email" : "Email",
                name: "email",
                validation: validateEmail,
                placeholder: mui_checkout ? "" : "jane@smith.com",
                isRequired: true,
                ref: "email",
                width: 260
            }
        }
        return this.renderInputs(email);
    }

    renderWorkEmail() {
        const { isEmployeePerk } = this.state;
        if (isEmployeePerk) {
            const workEmail = {
                workEmail: {
                    type: "text",
                    label: "Work Email",
                    name: "work email",
                    validation: validateEmail,
                    isRequired: true,
                    ref: "workEmail",
                    width: 260
                }
            }
            return (
                <Fragment>
                    {this.renderInputs(workEmail)}
                    {this.renderWorkEmailError()}
                </Fragment>
            )
        }
    }

    renderPhoneNumber() {
        const { phoneInvalid, isXS, phoneErrorMessage, mui_checkout } = this.state;
        if (mui_checkout) {
            return (
                <div className="checkout-input-container">
                    <TextField
                        inputProps={{
                            "test-id": "phone",
                            className: phoneInvalid ? "invalid" : ""
                        }}
                        autoComplete="home tel-national"
                        error={phoneInvalid}
                        helperText={phoneInvalid && phoneErrorMessage}
                        id="phone"
                        label="Mobile Phone"
                        type="tel"
                        style={{ width: isXS ? "100%" : 188 }}
                        onChange={(e) => this.handleInputChange('displayNumber', e.target.value)}
                        onBlur={(e) => this.validateField('phone', e.target.value, this.validatePhone)}
                        variant="outlined"
                    />
                </div>
            )
        } else {
            return (
                <div className="modal-input-container">
                    <label className="modal-label">Mobile Number</label>
                    <input
                        test-id={"phone"}
                        autoComplete="home tel-national"
                        className={`modal-input${phoneInvalid ? ' invalid' : ''}`}
                        placeholder="(512) 555-1234"
                        id="phone"
                        type="tel"
                        onChange={(e) => this.handleInputChange('displayNumber', e.target.value)}
                        onBlur={(e) => this.validateField('phone', e.target.value, this.validatePhone)}
                    />
                </div>
            )
        }
    }

    renderDeliveryHeader() {
        const { isFetch, mui_checkout } = this.state;
        if (isFetch) {
            return (
                <Fragment>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span id="fetch-shipping-logo"><img src={images.fetch_logo} alt="Fetch" /></span><span className="checkout-stage-name">Delivery Info</span>
                    </div>
                    <hr className={`checkout-hr${mui_checkout && " sd-mui-checkout"}`} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="checkout-stage-container">
                        <div className="checkout-stage-number">1</div>
                        <span className="checkout-stage-name">Deliver To</span>
                    </div>
                    <hr className="checkout-hr" />
                </Fragment>
            )
        }
    }

    renderCreatingOrderModal() {
        const { creatingOrder } = this.props;
        if (creatingOrder) return <CreatingOrderModal />
    }

    renderBillingHeader() {
        return (
            <Fragment>
                <div className="checkout-stage-container">
                    <div className="checkout-stage-number">2</div>
                    <span className="checkout-stage-name">Billing</span>
                </div>
                <hr className="checkout-hr" />
            </Fragment>
        )
    }

    renderMain() {
        const {
            manifestBundles,
            coupon,
            isEmployeePerk,
            newCoupon,
            sdCredit,
            isMobile,
            creatingSource,
            loading,
            tax,
            refreshTotals,
            refCust,
            spaceTop,
            checkout_summary
        } = this.state;

        if (manifestBundles) {
            return (
                <Fragment>
                    {this.renderCountdown()}
                    <Nav spaceTop={spaceTop} back={this.back} />
                    <Body checkout_summary={checkout_summary}>
                        <div className="checkout-container">
                            <div className="checkout-main">
                                {this.renderDeliveryHeader()}
                                {this.renderCustomerForm()}
                                {this.renderShippingForm()}
                                {this.renderBillingHeader()}
                                {this.renderBillingForm()}
                                {this.renderOrderError()}
                            </div>
                        </div>
                        {this.renderDesktopOrderSummary()}
                    </Body>
                    {this.renderOrderSummaryModal()}
                    {this.renderCreatingOrderModal()}
                    <FixedContinueBtn
                        text="Place Order"
                        showSnackBarCustomerReferral={false}
                        showTerms={true}
                        showCustomerRef={!!refCust}
                        tax={tax}
                        loading={creatingSource || loading}
                        disabled={loading}
                        manifestBundles={manifestBundles}
                        next={this.onContinueClick}
                        coupon={coupon}
                        newCoupon={newCoupon}
                        sdCredit={sdCredit}
                        isEmployeePerk={isEmployeePerk}
                        isMobile={isMobile}
                        refreshTotals={refreshTotals}
                        showReferral={refCust ? true : false}
                        checkout_summary={checkout_summary}
                    />
                </Fragment>
            )
        }
    }

    renderDesktopOrderSummary() {
        const {
            isMobile,
            manifestBundles,
            coupon,
            isEmployeePerk,
            employer,
            tax,
            newCoupon,
            sdCredit,
            refCust,
            checkout_summary
        } = this.state;
        if (!isMobile) {
            return (
                <OrderSummary
                    isMobile={isMobile}
                    checkout_summary={checkout_summary}
                    manifestBundles={manifestBundles}
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    isEmployeePerk={isEmployeePerk}
                    employer={employer}
                    tax={tax}
                    showValueProps={true}
                    showReferral={refCust ? true : false}
                    viewModal={() => this.setState({ showOrderSummaryModal: true })}
                />
            )
        }
    }

    renderMobileOrderSummary() {
        const {
            isMobile,
            manifestBundles,
            coupon,
            isEmployeePerk,
            employer,
            tax,
            newCoupon,
            sdCredit,
            refCust,
            checkout_summary
        } = this.state;
        if (isMobile && checkout_summary) {
            return (
                <OrderSummary
                    isMobile={isMobile}
                    checkout_summary={checkout_summary}
                    manifestBundles={manifestBundles}
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    isEmployeePerk={isEmployeePerk}
                    employer={employer}
                    tax={tax}
                    showValueProps={true}
                    showReferral={refCust ? true : false}
                    viewModal={() => this.setState({ showOrderSummaryModal: true })}
                />
            )
        }
    }

    renderOrderSummaryModal() {
        const {
            isMobile,
            manifestBundles,
            coupon,
            isEmployeePerk,
            employer,
            tax,
            newCoupon,
            sdCredit,
            refCust,
            checkout_summary,
            showOrderSummaryModal
        } = this.state;
        if (showOrderSummaryModal) {
            return (
                <OrderSummary
                    checkout_summary={checkout_summary}
                    manifestBundles={manifestBundles}
                    coupon={coupon}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    isEmployeePerk={isEmployeePerk}
                    employer={employer}
                    tax={tax}
                    showValueProps={!isMobile}
                    showReferral={refCust ? true : false}
                    isModal={true}
                    close={() => this.setState({ showOrderSummaryModal: false })}
                />
            )
        }
    }

    renderStripeError() {
        return <FormError copy={this.state.stripeError} />
    }

    renderWorkEmailError() {
        return <FormError copy={this.state.workEmailError} />
    }

    renderAccountExistsError() {
        if (this.state.userExistsError)
            return <FormError copy={this.state.userExistsError} />
    }

    renderOrderError() {
        const { createOrderError } = this.state;
        if (createOrderError) {
            return <FormError copy={createOrderError} />
        }
    }

    render() {
        const { checkout_summary } = this.state;
        return (
            <Fragment>
                <MetaTags title="Supply Drop - Checkout" />
                <div ref="main" />
                <div className={`has-fixed-continue with-terms${checkout_summary && " white"}`}>
                    {this.renderMain()}
                    {this.renderMobileValueProps()}
                    {this.renderMobileOrderSummary()}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { getTaxError, tax, createOrderSuccess, creatingOrder, createOrderError, landingData, gettingLandingData, landingError, shipment } = state.order;
    return { tax, getTaxError, createOrderSuccess, creatingOrder, createOrderError, landingData, gettingLandingData, landingError, shipment };
}

export default connect(mapStateToProps, {
    getTax,
    clearCreateOrderState,
    clearOrdersState,
    createOrder,
    getLanding
})(Checkout);
