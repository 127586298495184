//#region 
import React from 'react'
import { FacebookShare, ShareTwitter, LinkedinShare, InstagramShare } from '../Social';
import { FooterSocial } from '../../../Style/Footer';
//#endregion

//#region main
export default function SocialLinks({ newFooter }) {
    return (
        <FooterSocial newFooter={newFooter}>
            <FacebookShare shareProfile={true} urlToShare={"https://www.facebook.com/GetSupplyDrop/"} />
            <ShareTwitter shareProfile={true} urlToShare={"https://twitter.com/supplydrop"} />
            <InstagramShare shareProfile={true} urlToShare={"https://www.instagram.com/supplydrophq/"} />
            <LinkedinShare shareProfile={true} urlToShare={"http://www.linkedin.com/company/getsupplydrop"} />
        </FooterSocial>
    )
}
//#endregion