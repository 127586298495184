import React from 'react';
import { ProductGroupImgWrapper, ProductGroupImg } from '../../../Style/ProductGroupLandingStyles';

export default function ProductGroupDetailsImg({ productGroup }) {
    const { imageUrl, brandName, displayName } = productGroup
    return (
        <ProductGroupImgWrapper>
            <ProductGroupImg src={imageUrl} alt={brandName + " " + displayName} />
        </ProductGroupImgWrapper>
    )
}