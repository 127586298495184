import React from 'react';
import { HashLink } from 'react-router-hash-link';

const MenuItem = ({
    id,
    pathname,
    hash,
    title,
    dashboardData
}) => {
    return (
        <HashLink className="row menu-nav-row" to={{pathname, hash, state: {dashboardData}}}>
            <div className="menu-icon" id={id}></div>
            <div className="row menu-nav-link">{title}</div>
        </HashLink>
    )
}

export default MenuItem;