import React, { Component } from 'react';
import Menu from './Subcomponents/Menu';
import {
    MetaTags,
    PhoneBanner,
    DashboardFAQ
} from '../Common';
// import Footer from '../Common/Footer'
import { connect } from 'react-redux';
import '../../Style/dashboard.css';
import Analytics from "../../analytics";
import {
    createGlobalAuthError,
    getFAQs,
    getMagicLink,
    getUser
} from '../../actions'
import PhoneVerification from '../Common/Connected/PhoneVerification';
import * as Sentry from '@sentry/browser';
import { signIn } from './SignIn';
import { signOut } from '../../helpers/SignOut';

class FAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardData: {}
        }
        this.renderFAQs = this.renderFAQ.bind(this);
        this.renderFAQs = this.renderAllFAQs.bind(this);
        this.handleSignedInUser = this.handleSignedInUser.bind(this);
        this.getDashboardData = this.getDashboardData.bind(this);
    }

    componentDidMount() {
        signIn(this.props.location.search, this.props.history).then(user => {
            this.handleSignedInUser(user);
            this.getDashboardData();
        }).catch(({ error, magicLinkParams }) => {
            console.error(error)
            if(error && error.name === 'AuthError')
                this.props.createGlobalAuthError('signed in user Auth error')
            if (magicLinkParams) {
                const { u, s, channel } = magicLinkParams;
                this.props.getMagicLink(null, window.location.pathname, channel, u);
                this.setState({ magicLinkPath: `u=${u}&s=${s}` })
            } else
                this.props.history.push('/login')
        })
    }

    componentDidUpdate() {
        const {
            user,
            FAQs,
            FAQError,
            signedInAuthErr,
            nonSignedInAuthErr,
            history,
            magicLink,
            magicLinkErr,
            gettingMagicLink
        } = this.props

        const { dashboardData, globalError, magicLinkPath } = this.state;

        if (user && !this.state.user) {
            const { customer } = user;
            dashboardData.user = user;
            this.setState({ user, customer, dashboardData })
        }

        if (FAQs && !this.state.FAQs) {
            dashboardData.FAQs = FAQs;
            this.setState({ FAQs, dashboardData });
        }
        if (FAQError && !this.state.FAQError) {
            this.setState({ FAQError });
        }

        if((signedInAuthErr || nonSignedInAuthErr) && !globalError) {
            this.setState({ globalError: true });
            signOut(history, true)
        }

        if ((gettingMagicLink || magicLink) && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push(`/magiclink?${magicLinkPath}`);
        }
        
        if (magicLinkErr && magicLinkPath && !this.state.gettingMagicLink) {
            this.setState({ gettingMagicLink: true })
            this.props.history.push("/login");
        }
    }

    handleSignedInUser(user) {
        Analytics.setUser(user.username);
        const { location } = this.props;
        if (user.attributes && !user.attributes.phone_number_verified && (!location.state || !location.state.dashboardData || !location.state.dashboardData.verified)) {
            this.setState({ isUnverified: true })
        }
        this.setState({ signedIn: true })
    }

    getDashboardData() {
        try {
            const { location } = this.props;
            if (location && location.state && location.state.dashboardData) {
                const { dashboardData } = location.state;
                const { user, FAQs } = dashboardData;

                if (user)
                    this.setState({ user })
                else
                    this.props.getUser();

                if (FAQs)
                    this.setState({ FAQs })
                else
                    this.props.getFAQs();

                this.setState({ dashboardData });
            } else {
                throw new Error("No location state data");
            }
        } catch (err) {
            if (err.message !== "No location state data") {
                console.error(err);
                Sentry.captureException(err);
            }
            this.props.getUser();
            this.props.getProductGroups();
        }
    }

    renderPhoneBanner() {
        const { isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && customer && !limitExceeded) {
            return <PhoneBanner next={() => this.setState({ showVerificationModal: true })} />
        }
    }

    renderPhoneModal() {
        const { showVerificationModal, isUnverified, customer, limitExceeded } = this.state;
        if (isUnverified && showVerificationModal && customer && !limitExceeded) {
            return (
                <PhoneVerification
                    isModal={true}
                    limitExceeded={() => this.setState({ limitExceeded: true, showVerificationModal: false })}
                    email={customer.email}
                    phone={customer.phone}
                    setToVerified={() => this.setState({ isUnverified: false })}
                    close={() => this.setState({ showVerificationModal: false })}
                />
            )
        }
    }

    renderAllFAQs() {
        const { FAQs, FAQError, isUnverified } = this.state;
        if (FAQs && !FAQError) {
            const FAQsMap = [];
            const FAQObj = {};
            Object.entries(FAQs.FAQs).forEach(entry => {
                if (entry[1].category in FAQObj) {
                    FAQObj[entry[1].category].push(entry);
                }
                else {
                    FAQObj[entry[1].category] = [entry]
                }
            })

            Object.entries(FAQObj).forEach(entry => {
                if (entry[0] === "Account Management" || entry[0] === "My Supply Drop") {
                    FAQsMap.push(
                        <div>
                            <div className="faq-header">
                                <div className="faq-header-text">{entry[0]}</div>
                            </div>
                            {this.renderFAQ(entry[1])}
                            <div className="faq-spacing"></div>
                        </div>
                    )
                }
            })

            let style = isUnverified ? { paddingTop: 40 } : null;

            return (
                <div className='faq-container' style={style}>
                    {FAQsMap}
                </div>
            )

        }


    }

    renderFAQ(FAQArray) {
        const FAQMap = [];
        if (FAQArray) {
            FAQArray.forEach(entry => {
                const title = entry[0];
                const bodyHtml = entry[1].bodyHtml;
                FAQMap.push(<DashboardFAQ title={title} bodyHtml={bodyHtml} />);

            })
            return (FAQMap)
        }
        else {
            return null
        }
    }

    render() {
        const { dashboardData, isUnverified } = this.state;
        return (
            <div className="no-cloud">
                <MetaTags title="Supply Drop - Dashboard - FAQs" />
                <Menu history={this.props.history} dashboardData={{ ...dashboardData, verified: !isUnverified }} />
                <div className="dashboard-body-container">
                    {this.renderPhoneBanner()}
                    {this.renderPhoneModal()}
                    {this.renderAllFAQs()}
                </div>
                {/* <Footer /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        user,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    } = state.user;

    const {
        FAQs,
        FAQError,
        gettingFAQs
    } = state.FAQs;
    
    const {
        signedInAuthErr,
        nonSignedInAuthErr
    } = state.globalErrors;

    return {
        user,
        FAQs,
        FAQError,
        gettingFAQs,
        signedInAuthErr,
        nonSignedInAuthErr,
        magicLink,
        magicLinkErr,
        gettingMagicLink
    }
}

export default connect(mapStateToProps, {
    createGlobalAuthError,
    getFAQs,
    getMagicLink,
    getUser
})(FAQs);