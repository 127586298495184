import React, { Component, Fragment } from 'react';
import '../../../Style/fixedContinueBtn.css'
import { ContinueButton } from './'
import { getGrandTotal, getDiscountString, round } from '../../../helpers/pricing';
import {
    OrderSummary,
    DocModal
} from '../'
import {
    DiscountsCol,
    TotalsCol,
    TotalText,
    DiscountedSubtotalText,
    DiscountText,
    FixedBarData,
    FixedBarCol
} from '../../../Style/FixedBar';
import { FlexRowCenter, BlackTextUnderlineLink } from '../../../Style';
import { Snackbar, IconButton, SnackbarContent } from '@material-ui/core';
import { theme } from '../../../Style/DesignSystem/Theme'
import CloseIcon from '@material-ui/icons/Close';
import {
    CustomerReferralInfo,
} from '../../Common';
import { getItemCountFromManifestBundles } from '../../../actions';

// TODO: Rename this and all references to FixedContinueBar
export class FixedContinueBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOrderSummary: false,
            subtotal: 0,
            showDialog: false,
            doc: undefined,
            setSnackbarOpen: false,
        }
        this.toggleOrderSummary = this.toggleOrderSummary.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.setItemCount = this.setItemCount.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
        this.renderSnackbar = this.renderSnackbar.bind(this);
    }

    componentDidMount() {
        const { manifestBundles } = this.props;
        if (manifestBundles) {
            this.setTotals();
            this.setItemCount(manifestBundles);
        }
    }

    componentDidUpdate() {
        const { manifestBundles, tax } = this.props;

        if (this.props.refreshTotals) {
            this.setTotals();
            this.setItemCount(manifestBundles);
        }

        if (manifestBundles && !this.state.manifestBundles) {
            this.setState({ manifestBundles })
            this.setTotals();
            this.setItemCount(manifestBundles);
        }

        if (tax !== undefined && tax !== this.state.tax) {
            this.setState({ tax })
            this.setTotals(tax);
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    setTotals(tax) {
        const { manifestBundles, coupon, newCoupon, sdCredit, employeePerkCoupon, showSnackBarCustomerReferral, maxSdCredit } = this.props;
        if (manifestBundles) {
            let subtotalCents = 0;
            this.setState({ setSnackbarOpen: false })
            Object.values(manifestBundles).forEach(bundle => {
                subtotalCents += bundle.subtotal;
            })
            //showSnackBarCustomerReferral is true from Dashboard, Recommendation and Prediction pages 
            if (showSnackBarCustomerReferral && newCoupon && newCoupon.discount && newCoupon.discount.subtotalThresholdCents) {
                if (subtotalCents < newCoupon.discount.subtotalThresholdCents) {
                    this.setState({ setSnackbarOpen: true })
                    setTimeout(() => {
                        this.setState({ setSnackbarOpen: false });
                    }, 6000);
                } else {
                    this.setState({ setSnackbarOpen: false })
                }
            }

            const { total, error, label, discountDollars, discountCredit, subtotalDollars,  discountDollarsEmployeePerk} = getGrandTotal(subtotalCents, tax, coupon, newCoupon, employeePerkCoupon, sdCredit, undefined, maxSdCredit)
            if (!error) {
                this.setState({ total, label, discountCredit, discountDollars, subtotalDollars, newCoupon, sdCredit, discountDollarsEmployeePerk });
            }
        }
    }

    handleSnackbarClose() {
        this.setState({ setSnackbarOpen: false })
    }

    renderSnackbar() {
        const { setSnackbarOpen, subtotalDollars } = this.state;
        const { newCoupon } = this.props;

        if (newCoupon && newCoupon.discount && newCoupon.discount.subtotalThresholdCents) {
            const additionalAmountNeeded = round(newCoupon.discount.subtotalThresholdCents / 100 - subtotalDollars).toFixed(2);

            const discountAmount = newCoupon.discount.amountOffCents / 100
            const snackbarCopy = `To get your $${discountAmount} credit add $${additionalAmountNeeded}. `
            return (
                    <Snackbar
                        style={{
                            position: "fixed", //update to fixed
                            bottom: 97,
                            zIndex: 1,
                            marginRight: "auto",
                            marginLeft: "auto",
                        }}
                        // anchorOrigin={{
                        //     vertical: "bottom",
                        //     horizontal: "center",
                        // }}
                        open={setSnackbarOpen}
                        autoHideDuration={3000}
                    >
                    <SnackbarContent
                        style={{
                            backgroundColor: theme.palette.warning.main,
                            color: theme.palette.secondary.contrastText
                        }}
                        message={<div><span id="client-snackbar">{snackbarCopy}</span>
                            <BlackTextUnderlineLink id="client-snackbar" onClick={() => this.setState({showInfo: true})}>Details.</BlackTextUnderlineLink>
                            </div>}
                        action={
                            <Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                                        <CloseIcon fontSize="small" />
                                </IconButton>
                            </Fragment>
                        }
                    />
                    </Snackbar>
            )
        }
    }

    renderCustomerReferralDetails() {
		const { showInfo } = this.state;
        if(showInfo) {
            return (
                <CustomerReferralInfo
                    close={() => this.setState({showInfo: false})}
                    next={() => this.setState({showInfo: false})}
                />
            )
        }
    }

    setItemCount(manifestBundles) {
        if (manifestBundles) {
            const itemCount = getItemCountFromManifestBundles(manifestBundles);
            this.setState({ itemCount })
        }
    }

    toggleOrderSummary() {
        const { showOrderSummary } = this.state;
        if (showOrderSummary)
            this.removeBodyNoScroll();
        else
            this.appendBodyNoScroll();

        this.setState({ showOrderSummary: !showOrderSummary })
    }

    handleKeydown(e) {
        const { showOrderSummary } = this.state;
        if (e.keyCode && (e.keyCode === 13 || e.keyCode === 27)) {
            if (showOrderSummary) {
                this.toggleOrderSummary()
            }
        }
    }

    appendBodyNoScroll() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('no-scroll');
    }

    removeBodyNoScroll() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('no-scroll');
    }

    renderOrderSummary() {
        const { showOrderSummary } = this.state;
        const {
            employer,
            manifestBundles,
            tax,
            coupon,
            isEmployeePerk,
            newCoupon,
            sdCredit,
            showReferral,
            checkout_summary,
            employeePerkCoupon,
            usesRemaining,
            maxSdCredit
        } = this.props;
        if (showOrderSummary) {
            return (
                <OrderSummary
                    manifestBundles={manifestBundles}
                    close={this.toggleOrderSummary}
                    isModal={true}
                    tax={tax}
                    coupon={coupon}
                    employer={employer}
                    employeePerkCoupon={employeePerkCoupon}
                    isEmployeePerk={isEmployeePerk}
                    newCoupon={newCoupon}
                    sdCredit={sdCredit}
                    showReferral={showReferral}
                    checkout_summary={checkout_summary}
                    usesRemaining={usesRemaining}
                    maxSdCredit={maxSdCredit}
                />
            )
        }
    }

    renderCta() {
        const { cta, text, next, manifestBundles, disabled, loading, isDashboard } = this.props;
        if (cta) {
            return cta();
        } else if (!isDashboard) {
            return <ContinueButton next={next} loading={loading} disabled={!Object.entries(manifestBundles).length || disabled} fixed={true} text={text} />
        }
    }

    renderSkipAll() {
        const { skipAll } = this.props;
        if (skipAll) {
            return (
                <div className="fixed-continue-col">
                    {skipAll}
                </div>
            )
        }
    }

    renderTermsAndPrivacy() {
        const { showTerms, showCustomerRef } = this.props;
        if (showTerms) {
            if (showCustomerRef) {
                return (
                    <div className="fixed-terms-and-privacy">By clicking "Place Order" you agree to our <span onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</span>, <span onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</span> and referral program <span onClick={() => this.showDialog('Referral_Terms_and_Conditions')}>Terms and Conditions</span>.</div>
                )
            } else {
                return (
                    <div className="fixed-terms-and-privacy">By clicking "Place Order" you agree to our <span onClick={() => this.showDialog('Privacy_Policy')}>Privacy Policy</span> and <span onClick={() => this.showDialog('Terms_of_Use')}>Terms of Use</span>.</div>
                )
            }
        }
    }

    renderDialog() {
        const { doc, showDialog } = this.state;
        if (showDialog && !!doc) {
            return <DocModal doc={doc} hide={this.hideDialog} />
        }
    }

    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }

    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }

    renderDiscounts() {
        const {
            newCoupon,
            discountCredit,
            subtotalDollars,
            discountDollarsEmployeePerk
        } = this.state;
        const { usesRemaining } = this.props;
        if (newCoupon || discountCredit || discountDollarsEmployeePerk) {
            let showDiscountString = true;
            if (subtotalDollars) {
                if (newCoupon && newCoupon.discount && newCoupon.discount.subtotalThresholdCents) {
                    if (subtotalDollars < newCoupon.discount.subtotalThresholdCents / 100) {
                        showDiscountString = false
                    } 
                }
                const discountString = newCoupon ? getDiscountString(newCoupon, true, subtotalDollars,usesRemaining) : "";
                //capture employee perk amount off and sdCredits here
                const discountDollarsOff = (discountDollarsEmployeePerk ? discountDollarsEmployeePerk : 0) + (discountCredit ? discountCredit : 0)
                
                return (
                    <FixedBarCol as={DiscountsCol}>
                        {(showDiscountString === true && discountString) ? <FixedBarData as={DiscountText}>{discountString} </FixedBarData> : null}
                        {discountDollarsOff ? <FixedBarData as={DiscountText}>${(discountDollarsOff).toFixed(2)} off</FixedBarData> : null}
                        {((showDiscountString && discountString) || discountDollarsOff > 0) && <FixedBarData as={DiscountedSubtotalText}>${subtotalDollars.toFixed(2)}</FixedBarData>}
                    </FixedBarCol>
                )
            }
        }
    }

    renderTotalsAndDiscount() {
        const { itemCount, total } = this.state;
        if (itemCount !== undefined) {
            return (
                <FlexRowCenter onClick={this.toggleOrderSummary}>
                    {this.renderDiscounts()}
                    <FixedBarCol as={TotalsCol}>
                        <FixedBarData as={TotalText}>{itemCount} Items</FixedBarData>
                        <FixedBarData as={TotalText}>${total.toFixed(2)}</FixedBarData>
                    </FixedBarCol>
                </FlexRowCenter>
            )
        }
    }

    render() {
        const { manifestBundles, showTerms, showCustomerRef } = this.props;
        const { total } = this.state;
        if (!manifestBundles || total === undefined) return <div />
        else return (
            <Fragment>
                {this.renderDialog()}
                {this.renderOrderSummary()}
                {this.renderSnackbar()}
                {this.renderCustomerReferralDetails()}
                <div className={`fixed-continue-btn-container${showTerms ? showCustomerRef ? " with-ref-terms" : " with-terms" : ""}`}>
                    <div className="fixed-continue-btn">
                        <div className="fixed-continue-col text-center">
                            {this.renderTotalsAndDiscount()}
                            {this.renderTermsAndPrivacy()}
                        </div>
                        <div className="fixed-continue-col text-center">
                            {this.renderCta()}
                        </div>
                        {this.renderSkipAll()}
                    </div>
                </div>
            </Fragment>
        )
    }
}
