import React from 'react';
import { householdPatternedBackground } from '../../../Style';
import {
    ProductGroupDescriptionSectionContainer as Container,
    ProductGroupQuoteHeading as Heading,
 } from '../../../Style/ProductGroupLandingStyles'

export default function ProductGroupDescriptionSection({ productGroup }) {
    const { description, highlights } = productGroup;
    const quote = highlights && highlights.length ? highlights[0] : description;
    if (quote) {
        return (
            <Container style={{ background: householdPatternedBackground("#E5F2FF") }}>
                <Heading>{quote}</Heading>
            </Container>
        )
    } else {
        return (
            <div />
        )
    }
}