import React, { Component, Fragment } from 'react';
import { formatProductType, getBundlesSearchString } from '../../../actions';
import {
    MobileMenuContainer as Container,
    ModalContainer as Modal,
    MobileMenuDrawer as Drawer,
    DesktopMenuProductsLink as ProductsLink,
    MobileMenuLink as StyledLink,
    MobileMenuLinkContainer as LinkContainer,
    NavCTA as Button,
    NavCTAContainer as BtnContainer,
    SlideInRight,
    MobileMenuHR as HR
} from '../../../Style';
import { Hamburger } from '../';
import { bundles } from '../../../data';
import { signOut } from '../../../helpers/SignOut';
import { Link } from 'react-router-dom';

export class MobileMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.toggleBundles = this.toggleBundles.bind(this);
        this.toggleAboutUs = this.toggleAboutUs.bind(this);
        this.showBundleProducts = this.showBundleProducts.bind(this);
        this.navToProductType = this.navToProductType.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        window.addEventListener('keydown', this.handleKeydown, false);
        window.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    componentDidUpdate() {
        const { showBundles, showBundleId } = this.state;
        const { isShowingMenu } = this.props;

        if (!isShowingMenu && (showBundles || showBundleId))
            this.setState({ showBundles: false, showBundleId: false, })
    }

    navToProductType(bundleId, productType) {
        this.props.history.push({ pathname: `/products/bundles/${bundleId}`, search: getBundlesSearchString(productType) })
    }

    handleClickOutside(e) {
        const onClickId = e.target.id
        if (onClickId === "menu-modal-container") {
            this.props.toggleMenu();
        }
    }

    handleKeydown(e) {
        const { isShowingMenu, toggleMenu } = this.props;

        if (e.keyCode && e.keyCode === 27 && isShowingMenu)
            toggleMenu();
    }

    toggleBundles() {
        const { showBundles } = this.state;
        this.setState({ showBundles: !showBundles })
    }

    toggleAboutUs() {
        const { showAboutUs } = this.state;
        this.setState({ showAboutUs: !showAboutUs })
    }

    showBundleProducts(bundleId) {
        this.setState({ showBundleId: bundleId, showBundles: false })
    }

    renderMenu() {
        const { isShowingMenu, buttonText, buttonClick, isSignedIn } = this.props;
        if (isShowingMenu) {
            return (
                <Modal id="menu-modal-container">
                    <Drawer as={SlideInRight}>
                        {this.renderMainLinks()}
                        {this.renderAboutUsLinks()}
                        {this.renderProductLinks()}
                        {this.renderBundlesLinks()}
                        <BtnContainer isMobile={true}>
                            <Button id={isSignedIn ? "mobile-nav-cta-signed-in" : "mobile-nav-cta"} onClick={buttonClick}>{buttonText ? buttonText : isSignedIn? "My Dashboard" : "Get Started"}</Button>
                        </BtnContainer>
                        {this.renderSignInLink()}
                    </Drawer>
                </Modal>
            )

        }
    }

    renderBundlesLinks() {
        const { showBundles } = this.state;
        if (showBundles) {
            return (
                <SlideInRight>
                    <LinkContainer>
                        <StyledLink grayed={true} as={ProductsLink} onClick={() => this.setState({ showBundles: false })}><span style={{ fontSize: 14, paddingRight: 8 }} className="fa fa-chevron-left" />Back</StyledLink>
                    </LinkContainer>
                    {Object.entries(bundles).map(entry => {
                        const bundleId = entry[0], bundle = entry[1];
                        const { title } = bundle;
                        return (
                            <LinkContainer>
                                <StyledLink as={ProductsLink} onClick={() => this.showBundleProducts(bundleId)}>{title} <span style={{ fontSize: 14, paddingLeft: 8 }} className="fa fa-chevron-right" /></StyledLink>
                            </LinkContainer>
                        )
                    })}
                </SlideInRight>
            )
        }
    }

    renderProductLinks() {
        const { showBundleId } = this.state;
        if (showBundleId) {
            const bundle = bundles[showBundleId];
            const { productTypes } = bundle;
            const productTypesArr = [];
            productTypes.forEach(productType => {
                if(productType !== "DryerSheets" && productType !== "TrashBagsCompost") {
                    productTypesArr.push(
                        <LinkContainer>
                            <StyledLink as={ProductsLink} onClick={() => this.navToProductType(showBundleId, productType)}>{formatProductType(productType)}</StyledLink>
                        </LinkContainer>
                    )
                }
            })
            return (
                <SlideInRight>
                    <LinkContainer>
                        <StyledLink grayed={true} as={ProductsLink} onClick={() => this.setState({ showBundleId: false, showBundles: true })}><span style={{ fontSize: 14, paddingRight: 8 }} className="fa fa-chevron-left" />Back</StyledLink>
                    </LinkContainer>
                    {productTypesArr}
                    <LinkContainer>
                        <StyledLink as={ProductsLink}>
                            <Link to={{ pathname: `/products/bundles/${showBundleId}`, search: window.location.search }}>View All</Link>
                        </StyledLink>
                    </LinkContainer>
                </SlideInRight>
            )
        }
    }

    renderAboutUsLinks() {
        const { showAboutUs } = this.state;
        const { search } = window.location;
        if (showAboutUs) {
            return (
                <SlideInRight>
                    <LinkContainer>
                        <StyledLink grayed={true} as={ProductsLink} onClick={() => this.setState({ showAboutUs: false })}><span style={{ fontSize: 14, paddingRight: 8 }} className="fa fa-chevron-left" />Back</StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink><Link to={{ pathname: `/about`, search }}>About Us</Link></StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink><Link to={{ pathname: `/science`, search }}>Our Science</Link></StyledLink>
                    </LinkContainer>
                </SlideInRight>
            )
        }
    }

    renderSignInLink() {
        const { isSignedIn, history } = this.props;
        const copy = isSignedIn ? "Logout" : <div onClick={() => history.push('/login')}>Already have an account?<br /><u>Login.</u></div>
        return (
            <StyledLink as={ProductsLink} onClick={isSignedIn ? () => signOut(null) : null}>{copy}</StyledLink>
        )
    }

    renderMainLinks() {
        const { showDoc } = this.props;
        const { showBundles, showBundleId, showAboutUs } = this.state;
        if (!showBundles && !showBundleId && !showAboutUs) {
            return (
                <Fragment>
                    <LinkContainer>
                        <StyledLink><Link to={{ pathname: "/", search: window.location.search }} title="Home">Home</Link></StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink as={ProductsLink} onClick={this.toggleBundles}>Products<span style={{ fontSize: 14, paddingLeft: 8 }} className="fa fa-chevron-down" /></StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink as={ProductsLink} onClick={this.toggleAboutUs}>About<span style={{ fontSize: 14, paddingLeft: 8 }} className="fa fa-chevron-down" /></StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink href={window.location.search ? window.location.search +"&#faqs-contact-us" : "/#faqs-contact-us"} onClick={() => this.props.toggleMenu()} title="Contact">Contact </StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink><Link to="https://blog.supplydrop.com" title="Blog">Blog</Link></StyledLink>
                    </LinkContainer>
                    <HR />
                    <LinkContainer>
                        <StyledLink href={window.location.search ? window.location.search+"&#faqs" : "/#faqs"} onClick={() => this.props.toggleMenu()}>FAQs</StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink as={ProductsLink} onClick={() => showDoc("Terms_of_Use")}>Terms of Use</StyledLink>
                    </LinkContainer>
                    <LinkContainer>
                        <StyledLink as={ProductsLink} onClick={() => showDoc("Privacy_Policy")}>Privacy Policy</StyledLink>
                    </LinkContainer>
                </Fragment>
            )
        }
    }

    render() {
        return (
            <Container>
                <Hamburger toggleMenu={this.props.toggleMenu} />
                {this.renderMenu()}
            </Container>
        )
    }
}
