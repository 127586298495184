import React from 'react';
import { CheckoutSummaryHeaderBlock as Main, OrderSummaryHeaderTitle } from '../../../Style/OrderSummaryStyles'
import ProductLineItem from './OrderSummaryProductLineItem';

export default function CheckoutSummaryTitleSection({ hoveredProductGroup }) {
    const renderCopy = () => {
        if(hoveredProductGroup) {
            return (
                <ProductLineItem productGroup={hoveredProductGroup} />
            )
        } else {
            return <OrderSummaryHeaderTitle className="no-margin">Order Summary</OrderSummaryHeaderTitle>
        }
    }
    return (
        <Main>
            {renderCopy()}
        </Main>
    )
}