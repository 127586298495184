import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import OrderSummaryProductImg from './OrderSummaryProductImg';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1200, min: 757 },
        items: 5,
    },
    nextdown: {
        breakpoint: { max: 757, min: 0 },
        items: 5,
    },
}

export default function OrderSummaryProductCarousel({
    productGroups,
    showProductDetails,
    clearProductDetails,
    showProductDetailsModal
}) {
    return (
        <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={4000}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "nextdown"]}
            containerClass="order-summary-carousel-container"
        >
            {productGroups.map(productGroup => <OrderSummaryProductImg
                productGroup={productGroup}
                showProductDetails={showProductDetails}
                clearProductDetails={clearProductDetails}
                showProductDetailsModal={showProductDetailsModal}
            /> )}
        </Carousel>
    )
}