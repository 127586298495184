//#region imports
import React, { Component } from 'react';
import { DocModal } from '..';
import { FooterWrapper, FooterContainer } from '../../../Style/Footer';
import SocialLinks from './SocialLinks';
import Policies from './FooterPolicies';
import FooterCopy from './FooterCopy'
import { featureFlags } from '../../../config';
import NewFooter from './NewFooter'
//#endregion

//#region main
export default class Footer extends Component {
    //#region constructor
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            doc: undefined
        }

        this.showDialog = this.showDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.handleTermsClick = this.handleTermsClick.bind(this);
        this.handlePrivacyPolicyClick = this.handlePrivacyPolicyClick.bind(this);
    }
    //#endregion

    //#region state functions
    showDialog(doc) {
        this.setState({ showDialog: true, doc })
    }
    
    hideDialog() {
        this.setState({ showDialog: false, doc: undefined })
    }
    //#endregion
    
    //#region event handlers
    handleTermsClick() {
        this.showDialog('Terms_of_Use')
    }

    handlePrivacyPolicyClick() {
        this.showDialog('Privacy_Policy')
    }
    //#endregion

    //#region render functions
    renderDialog() {
        const { doc, showDialog } = this.state;
        if (showDialog && !!doc) {
            return <DocModal doc={doc} hide={this.hideDialog} />
        }
    }

    render() {
        const { showDialog } = this.state;
        const { landing, history } = this.props;
        let gitSha = '';
        if (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT !== 'production') {
            gitSha = `Build: ${process.env.REACT_APP_GIT_SHA}`;
        }
        if (featureFlags.newFooter) return <NewFooter history={history} landing={landing}/>
        return (
            <FooterContainer showDialog={showDialog} landing={landing}>
                {this.renderDialog()}
                <FooterWrapper>
                    <SocialLinks />
                    <Policies showTerms={this.showTerms} showPrivacyPolicy={this.showPrivacyPolicy} />
                    <FooterCopy />
                    {gitSha}
                </FooterWrapper>
            </FooterContainer>
        )
    }
    //#endregion
}
//#endregion