import React, { Fragment } from 'react';

import {
	amber_a,
	jessie_and_sam,
	rob_h,
	robert_m,
	victoria_n
} from '../assets/testimonials';

export const supplyDropTestimonials = {
	title: "What Texans are saying",
	entries: [
		{
			quote: "“Thank you for being available! I don’t have a car and grocery delivery is so far out. I am so relieved to see your ad and join up”",
			src: amber_a,
			name: "Amber A."
		},
		{
			quote: (<Fragment>“It feels great supporting a local company during these times, and being supported in return. Thank you for all you do for our Austin Texas community <span role="img" aria-label="heart">♥️</span>”</Fragment>),
			src: jessie_and_sam,
			name: "Jessie & Sam"
		},
		{
			quote: (<Fragment>“I <span role="img" aria-label="heart">♥️</span> you! Like family already!”</Fragment>),
			src: victoria_n,
			name: "Victoria N."
		},
		{
			quote: "“I am loving this service! It’s so helpful that you recommend what we need each month and if I need to make changes, I can do it up to the last minute. I also appreciate the transparency on the pricing and product options. Looking forward to my next Supply Drop!”",
			src: robert_m,
			name: "Robert M."
		},
		{
			quote: "“Supply Drop has been an absolute life saver! The service is so easy to use and the experience has been great. I never worry about being out of the products we use every day.”",
			src: rob_h,
			name: "Rob H."
		}
	]
}
