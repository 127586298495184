import React from 'react';
import { Logger } from '../../../../helpers/Logger';
import { bundles } from '../../../../data';
import { Subtitle1 } from '../../../../Style/DesignSystem/Typography';
import {
    SuggestedProductGroupsContainer as Container,
    SuggestedProductGroupContainer as Body
} from '../../../../Style/Manifest';
import { SuggestedProductGroup } from './SuggestedProductGroup';
const logger = new Logger();

export const SuggestedProductGroups = (props) => {
    try {
        const { isMobile, productGroupsMap, bundleShortName, rounderSet, selectedProductTypes, showOtherBrands } = props;
        const { title, productTypes } = bundles[bundleShortName];
        const displayProductGroupNames = [];
        productTypes.filter(productType => !selectedProductTypes[productType]).forEach(productType => {
            if(rounderSet[productType]) {
                displayProductGroupNames.push(rounderSet[productType].preferred.groupName)
            }
        });

        const displayProductGroups = displayProductGroupNames.slice(0, 3).map(groupName => {
            return productGroupsMap[groupName]
        })

        return (
            <Container>
                <Subtitle1>Suggestions for {title}:</Subtitle1>
                <Body>
                    {displayProductGroups.map(productGroup => {
                        const { groupName, productType } = productGroup;
                        return (
                            <SuggestedProductGroup
                                key={groupName + "suggested-product-group"}
                                productGroup={productGroup}
                                isMobile={isMobile}
                                showOtherBrands={() => showOtherBrands(productType, bundleShortName)}
                            />
                        )
                    })}
                </Body>
            </Container>
        )
    } catch (err) {
        logger.error(err);
        return <div />
    }

}