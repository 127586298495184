import React from 'react';
import {
    LandingTwoUpSection as Section,
    LandingSection,
    LandingSectionCol as Col
} from '../../../Style';
import ProductGroupDetailsBody from './ProductGroupDetailsBody'
import ProductGroupDetailsImg from './ProductGroupDetailsImg';

export default function ProductGroupDetails({ productGroup, coupon }) {
    const body = <ProductGroupDetailsBody productGroup={productGroup} coupon={coupon} />
    const img = <ProductGroupDetailsImg productGroup={productGroup} />

    return (
        <LandingSection as={Section}>
            <Col productGroupDetails={true}>{img}</Col>
            <Col productGroupDetails={true}>{body}</Col>
        </LandingSection>
    )
}