import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import {
    Landing,
    BundleDescriptionSection,
    BundleProductTypeSection,
    Loading
} from '../Common';
import { BundleProductsContainer } from '../../Style';
import { bundles, bundleMap } from '../../data';
import { getProductGroups, getLanding, postProspect, formatProductType, parseSearchParams } from '../../actions';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Analytics from "../../analytics";
import { defaultZipcodes } from '../../data';
import { Logger } from '../../helpers/Logger';
import { featureFlags } from '../../config';
const logger = new Logger();

class BundleLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.renderBody = this.renderBody.bind(this);
        this.bundleProductGroups = this.bundleProductGroups.bind(this);
        this.showProductPage = this.showProductPage.bind(this);
        this.setHero = this.setHero.bind(this);
        this.setFromSearchParams = this.setFromSearchParams.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.focusOnProductType = this.focusOnProductType.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onGetStartedClick = this.onGetStartedClick.bind(this);
        this.onDashboardClick = this.onDashboardClick.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.productType = React.createRef();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    componentDidMount() {
        const { match: { params }, location } = this.props;
        const { search } = location;
        const { bundleId } = params;
        if (bundles[bundleId]) {
            if (!location.state || !location.state.bundledProductGroups)
                this.props.getProductGroups();
            else
                this.setState({ bundledProductGroups: location.state.bundledProductGroups })

            const bundle = bundles[bundleId];
            this.setHero(bundle);
            this.setState({ bundle, bundleId })

        }

        Auth.currentAuthenticatedUser().then(data => {
            this.setState({ isSignedIn: true });
            this.props.getUser();
            Analytics.setUser(data.username);
            this.props.getLanding(search);
            if (search) this.handleSearch(search, true)
        }).catch(err => {
            this.props.getLanding(search);
            if (search) this.handleSearch(search)
            this.setIdentityBreadcrumb();
        })

        this.handleResize(null, true);
    }

    handleResize() {
        const { isMobile, isTablet } = this.state;
        if (window.innerWidth < 768 && !isMobile) {
            this.setState({ isMobile: true })
        }
        if (window.innerWidth >= 767 && isMobile) {
            this.setState({ isMobile: false })
        }
        if (window.innerWidth < 992 && !isTablet) {
            this.setState({ isTablet: true })
        }
        if (window.innerWidth >= 991 && isTablet) {
            this.setState({ isTablet: false })
        }
    }

    componentDidUpdate() {
        const { productGroups, productGroupsError, landingError, landingData, user } = this.props;
        const { bundleId } = this.state;

        if (user && !this.state.user) {
            this.setState({ user, customerName: user?.customer?.firstName })
        }

        if (landingError && this.state.landingError) {
            this.setState({ landingError });
            Sentry.captureException(landingError);
        }

        if (landingData && !this.state.landingData) {
            this.setState({ landingData, ...landingData })
        }

        if (productGroups && !this.state.productGroups) {
            this.bundleProductGroups(productGroups);
            this.setState({ productGroups })
        }

        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError })
        }

        const { match: { params }, location: { search } } = this.props;
        if (bundleId && params.bundleId !== bundleId) {
            const bundle = bundles[params.bundleId];
            this.setHero(bundle);
            this.setState({ bundleId: params.bundleId, bundle, isShowingMenu: false })
        }

        if (search && search !== this.state.search)
            this.setFromSearchParams(search);

        if (this.productType) {
            if (this.productType.current) {
                this.focusOnProductType();
            }
        }
    }

    handleSearch(search) {
        this.setFromSearchParams(search)
    }

    toggleMenu() {
        const { isShowingMenu } = this.state;
        this.setState({ isShowingMenu: !isShowingMenu });
    }

    setFromSearchParams(search) {
        const { searchData, error } = parseSearchParams(search);
        if (!error) {
            const { utm_campaign, utm_content, utm_source, employer, fbclid, rc, refCust, productType } = searchData;

            const referrer = searchData.referrer ? searchData.referrer : document.referrer;

            this.setState({ utm_campaign, utm_content, utm_source, employerSlug: employer, fbclid, rc, refCust, referrer, selectedProductType: productType, search });

            this.props.postProspect({
                campaignId: utm_campaign,
                referrer,
                attributes: {
                    employer,
                    fbclid,
                    rc,
                    refCust,
                    utm_content,
                    utm_source,
                }
            })
        } else {
            logger.error(error)
        }
    }

    setIdentityBreadcrumb() {
        Auth.currentCredentials().then(creds => {
            if (creds && creds._identityId) {
                Analytics.setUser(creds._identityId);
            }
        }).catch(err => {
            Sentry.captureException(err);
        })
    }

    setHero(bundle) {
        try {
            const hero = {
                header: `All of your ${bundle.header ? bundle.header.toLowerCase() : "essentials"} delivered when you need them`,
                heroImg: {
                    src: bundle.hero,
                    alt: bundle.title,
                },
                subheader: "Home supplies curated and delivered to your door every month. In stock and delivered within 5 business days or less!"
            }

            this.setState({ hero })
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }

    bundleProductGroups(productGroups) {
        try {
            const bundledProductGroups = {};
            productGroups.forEach(group => {
                const { productType } = group;
                const bundleId = bundleMap[productType];
                if (!bundledProductGroups[bundleId])
                    bundledProductGroups[bundleId] = {};

                if (!bundledProductGroups[bundleId][productType])
                    bundledProductGroups[bundleId][productType] = [];

                if (group.available)
                    bundledProductGroups[bundleId][productType].push(group)
            })

            this.setState({ bundledProductGroups })
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            this.setState({ productGroupsError: err })
        }
    }

    focusOnProductType() {
        try {
            const { offsetTop } = this.productType.current
            window.scrollTo(0, offsetTop - 60);
        } catch (err) {
            Sentry.captureException(err);
            console.error(err);
        } finally {
            this.productType = null;
            this.setState({ selectedProductType: null })
        }
    }

    showProductPage(productGroup) {
        const { productGroupsMap, signUpData } = this.state;
        this.props.history.push({ pathname: `/products/${productGroup.groupName}`, state: {
            selectedProductGroup: productGroup,
            productGroupsMap,
            signUpData
        }, search: window.location.search })
    }

    onGetStartedClick() {
        const {
            coupon,
            dropoffZipcodes,
            employer,
            employerId,
            employerSlug,
            isEmployeePerk,
            isSignedIn,
            landingData,
            newCoupon,
            productGroups,
            productGroupsMap,
            rc,
            refCust,
            referrer,
            signupsOpen,
            utm_campaign,
            utm_content,
            utm_source,
            zipcode,
            zipcodes
        } = this.state;

        if (isSignedIn) {
            this.onDashboardClick()
        } else {
            const signUpData = {
                coupon,
                employer,
                employerId,
                employerSlug,
                isEmployeePerk,
                newCoupon,
                productGroups,
                productGroupsMap,
                rc,
                refCust,
                referrer,
                signupsOpen,
                utm_campaign,
                utm_content,
                utm_source,
                zipcode,
                zipcodes: zipcodes ? zipcodes : dropoffZipcodes ? dropoffZipcodes : defaultZipcodes
            };

            const pushInterviewFlow = (featureFlags.isUngated && landingData && signupsOpen);
            const pathname = pushInterviewFlow ? '/start/1' : '/our-promise';
            this.props.history.push({
                pathname,
                search: window.location.search,
                state: { signUpData }
            });
        }
    }

    onDashboardClick() {
        this.props.history.push('/dashboard');
    }

    renderBundleProductTypes() {
        const { bundleId, bundledProductGroups, selectedProductType } = this.state;
        if (bundledProductGroups && bundleId) {
            try {
                const sections = [];
                Object.entries(bundledProductGroups[bundleId]).forEach(entry => {
                    const productType = entry[0], productGroups = entry[1];
                    if (productGroups && productGroups.length) {
                        const formattedProductType = formatProductType(productType);
                        sections.push(
                            <div key={productType} ref={selectedProductType && selectedProductType === productType ? this.productType : null}>
                                <BundleProductTypeSection formattedProductType={formattedProductType} productGroups={productGroups} onclick={this.showProductPage} />
                            </div>
                        )
                    }
                })
                return (
                    <BundleProductsContainer>
                        {sections}
                    </BundleProductsContainer>
                )
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);
            }
        } else {
            return (
                <div style={{ position: 'relative', height: 300 }}>
                    <Loading />
                </div>
            )
        }
    }

    renderBody() {
        const { bundle, bundleId } = this.state;
        if (bundle) {
            const { description, title, icon } = bundle;
            return (
                <Fragment>
                    <BundleDescriptionSection
                        key={bundleId}
                        description={description}
                        title={title}
                        icon={icon}
                    />
                    {this.renderBundleProductTypes()}
                </Fragment>
            )
        }
    }

    render() {
        const {
            customerName,
            loading,
            bundle,
            hero,
            isShowingMenu,
            isMobile,
            isTablet,
            newCoupon,
            utm_campaign,
            utm_content,
            isSignedIn
        } = this.state;
        if (hero) {
            const {
                heroImg,
                header,
                subheader
            } = hero;
            return (
                <Landing
                    body={bundle ? this.renderBody : null}
                    ctaCopy="Get Started"
                    customerName={customerName}
                    handleCTAClick={this.onGetStartedClick}
                    header={header}
                    heroImg={heroImg}
                    history={this.props.history}
                    isMobile={isMobile}
                    isShowingMenu={isShowingMenu}
                    isTablet={isTablet}
                    isSignedIn={isSignedIn}
                    loading={loading}
                    newCoupon={newCoupon}
                    subheader={subheader}
                    toggleMenu={this.toggleMenu}
                    utm_campaign={utm_campaign}
                    utm_content={utm_content}
                />
            )
        } else {
            return <div />
        }
    }
}

const mapStateToProps = (state) => {
    const { productGroups, productGroupsError } = state.product;
    const { landingData, gettingLandingData, landingError } = state.order;
    const { postProspectSuccess, postProspectError, postingProspect, user } = state.user;
    return {
        productGroups,
        productGroupsError,
        postProspectSuccess,
        postProspectError,
        postingProspect,
        landingData,
        gettingLandingData,
        landingError,
        user
    }
}

export default connect(mapStateToProps, {
    getProductGroups,
    getLanding,
    postProspect,
})(BundleLanding);
