import React, { Fragment } from 'react';
import {
    abj_publication,
    ainno_publication,
    cbs_publication,
    fb_publication,
    fb_stars
} from '../../../assets/publications'
import {
    PublicationsContainer as Container,
    PublicationsWrapper as Wrapper,
    PublicationsBody as Body,
    PublicationsText as Text,
    PublicationsHeartsImg as StarsImg,
    PublicationsClickableCard as ClickableCard
} from '../../../Style/PublicationsStyles';
import { CardCarousel } from '../Miscellaneous/CardCarousel';
import { TestimonialCard } from '../../../Style/Testimonials';

function Card({ publication }) {
    const { logo, body, alt, site } = publication;
    return (
        <TestimonialCard>
            <ClickableCard href={site} target="_blank">
                <div className="text-center"><img src={logo} alt={alt} /></div>
                {body}
            </ClickableCard>
        </TestimonialCard>
    )
}

function DesktopPublication({ publication }) {
    const { logo, body, alt, site } = publication;
    return (
        <Body href={site} target="_blank">
            <div><img src={logo} alt={alt} /></div>
            {body}
        </Body>
    )
}

const publications = [
    {
        logo: abj_publication,
        alt: "Austin Business Journal",
        body: <Text>“Based on the answers Supply Drop comes up with a formula for what households need in the future”</Text>,
        site: "https://www.bizjournals.com/austin/news/2020/10/29/supply-drop-emerges-from-stealth-already-funded-an.html?b=1604000589%5E21806946"
    },
    {
        logo: ainno_publication,
        alt: "Austin Inno",
        body: <Text>“That’s what Supply Drop is designed to do – take your list of needs and learn and confirm how often you want to have them delivered.”</Text>,
        site: "https://www.bizjournals.com/austin/inno/stories/startups-to-watch/2021/01/08/21-austin-startups-to-watch-in-2021.html"
    },
    {
        logo: cbs_publication,
        alt: "CBS Austin",
        body: <Text>“Automate the purchase and delivery of household items.”</Text>,
        site: "https://cbsaustin.com/news/local/austin-based-company-supply-drop-offers-alternative-to-grocery-shopping"
    },
    {
        logo: fb_publication,
        alt: "Facebook Reviews",
        body: (
            <Fragment>
                <Text>Customer Reviews</Text>
                <div className="text-center">
                    <StarsImg src={fb_stars} alt="Five Starts on Facebook!" />
                </div>
            </Fragment>
        ),
        site: "https://facebook.com/getsupplydrop"
    },
]

export default function Publications({ isTablet, fill }) {
    if (isTablet) {
        const cards = publications.map(publication => <Card publication={publication} />)
        return <CardCarousel cards={cards} style={{ backgroundColor: fill }} id="news" />
    } else {
        return (
            <Container fill={fill} id="news">
                <Wrapper>
                    {publications.map(p => <DesktopPublication publication={p} />)}
                </Wrapper>
            </Container>
        )
    }
}