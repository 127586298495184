import React from 'react';
import { coupon_checkmark } from '../../../assets/miscellaneous';
import { getCouponSpan } from '../../../actions';
import {
    CouponAlertContainer as Container,
    CouponAlertBody as Body,
    FlexCol
} from '../../../Style';

export const CouponAlert = ({
    coupon,
    utm_content,
    utm_campaign,
    isEmployeePerk,
    spaceBottom
}) => {
    const copy = getCouponSpan({ coupon, utm_campaign, utm_content, isEmployeePerk })
    if (copy) {
        return (
            <Container spaceBottom={spaceBottom} className="animated-fast slideInDown">
                <FlexCol>
                    <img alt="coupon" src={coupon_checkmark} />
                </FlexCol>
                <Body>
                    {copy}
                </Body>
            </Container>
        )
    }
    return <div />;
}
