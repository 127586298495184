import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0074D2",
            dark: "#0049A0",
            light: "#5DA2FF",
            contrastText: "#FCFCFC",
            active_contained: "rgba(0,73,160, .7)",
            hover: "rgba(0, 116, 210, 0.04)",
            selected: "rgba(0, 116, 210, 0.08",
            active_outlined: "rgba(0, 116, 210, 0.24)",
            outlined_stroke: "rgba(0, 116, 210, 0.5)",
        },
        secondary: {
            main: "#6CD293",
            dark: "#00A664",
            light: "#9FFFC4",
            contrastText: "#323232",
            active_contained: "rgba(0,166,100,0.7)",
            hover: "rgba(108, 210, 147, 0.04",
            selected: "rgba(108, 210, 147, 0.08)",
            active_outlined: "rgba(108, 210, 147, 0.24)",
            outlined_stroke: "rgba(0, 166, 100, 0.5)",
        },
        actions: {
            border: "#DFE0EB",
            hover: "rgba(0, 0, 0, 0.04)",
            selected: "rgba(0, 0, 0, 0.08)",
            disabled_background: "rgba(0, 0, 0, 0.12)",
            outlined_stroke: "rgba(0, 0, 0, 0.23)",
            disabled_text: "rgba(0, 0, 0, 0.26)"
        },
        other: {
            primary: "#183041",
            secondary: "#465967",
            disabled: "rgba(24, 48, 65, 0.5)",
            divider: "#DFE0EB",
            backdrop: "rgba(24, 48, 65, 0.65)",
            snackbar_background: "#323232",
            active_rotating_color: "#FFB400"
        },
        info: {
            main: "#2196F3",
            dark: "#0B79D0",
            light: "#64B6F7",
            dark_text: "#0d3d61",
            light_bg: "#E9F4FE",
            contrast_text: "#FFFFFF"
        },
        error: {
            main: "#F44336",
            dark: "#E31B0C",
            light: "#F88078",
            dark_text: "#621C16",
            light_bg: "#feeceb",
            contrast_text: "#FFFFFF"
        },
        warning: {
            main: "#FF9800",
            dark: "#C77700",
            light: "#FFB547",
            dark_text: "#663d00",
            light_bg: "#fff5e5",
            contrast_text: "rgba(0,0,0,87)"
        },
        success: {
            main: "#4CAF50",
            dark: "#3B873E",
            light: "#7BC67E",
            dark_text: "#1e4620",
            light_bg: "#edf7ed",
            contrast_text: "#FFFFFF"
        }
    },
    typography: {
        h1: {
            fontFamily: "Amaranth, sans-serif",
            fontiSze: 56,
            lineHeight: "120%",
            letterSpacing: "-1%",
            '@media (max-width: 991px)': {
                fontSize: 46

            }
        },
        h2: {
            fontFamily: "Amaranth, sans-serif",
            fontSize: 46,
            letterSpacing: "-1%",
            lineHeight: "120%",
            '@media (max-width: 991px)': {
                fontSize: 40
            }
        },
        h3: {
            fontFamily: "Amaranth, sans-serif",
            fontSize: 40,
            letterSpacing: "0.5%",
            lineHeight: "120%",
            '@media (max-width: 991px)': {
                lineHeight: "130%",
                fontSize: 34
            }
        },
        h4: {
            fontFamily: "Amaranth, sans-serif",
            fontSize: 34,
            letterSpacing: "0.25%",
            lineHeight: "130%",
            '@media (max-width: 991px)': {
                fontSize: 28
            }
        },
        h5: {
            fontFamily: "Muli, sans-serif",
            fontWeight: "bold",
            fontSize: 24,
            letterSpacing: "0.15%",
            lineHeight: "140%",
            '@media (max-width: 991px)': {
                fontSize: 20
            }
        },
        h6: {
            fontFamily: "Muli, sans-serif",
            textTransform: "uppercase",
            fontSize: 18,
            letterSpacing: "0.5%",
            lineHeight: "140%",
            '@media (max-width: 991px)': {
                fontSize: 16
            }
        },
        subtitle1: {
            fontFamily: "Muli, sans-serif",
            fontWeight: "bold",
            fontSize: 18,
            lineHeight: "140%",
            letterSpacing: "1%"
        },
        subtitle2: {
            fontFamily: "Muli, sans-serif",
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: "140%",
            letterSpacing: "1%"
        },
        body1: {
            fontFamily: "Muli, sans-serif",
            fontSize: 16,
            lineHeight: "140%",
            letterSpacing: "1%"
        },
        body2: {
            fontFamily: "Muli, sans-serif",
            fontWeight: "bold",
            fontSize: 14,
            lineHeight: "140%",
            letterSpacing: "1%"
        },
        button: {
            fontFamily: "Muli, sans-serif",
            fontWeight: "bold",
            fontSize: 16,
            lineHeight: "160%",
            letterSpacing: "1.25%",
            textTransform: "uppercase"
        },
        caption: {
            fontFamily: "Muli, sans-serif",
            fontSize: 12,
            lineHeight: "140%",
            letterSpacing: "1%"
        },
        overline: {
            fontFamily: "Muli, sans-serif",
            fontSize: 14,
            lineHeight: "250%",
            letterSpacing: "8%",
            textTransform: "uppercase"
        }
    }
})