import styled from 'styled-components';

export const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export const flexSpaceBetween = {
    display: 'flex',
    justifyContent: 'space-between'
}

export const flexSpaceAround = {
    display: 'flex',
    justifyContent: 'space-around'
}

export const flexCol = {
    display: "flex",
    flexDirection: "column"
}

export const FlexCol = styled.div`
    ${{ ...flexCol, ...flexCenter }}
`

export const flexWrap = {
    display: 'flex',
    flexWrap: 'wrap'
}

export const flexStart = {
    display: 'flex',
    justifyContent: 'flex-start'
}

export const FlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
`

export const flexRow = {
    display: 'flex',
    flexDirection: 'row'
}

export const FlexRowCenter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const alignItemsCenter = {
    display: 'flex',
    alignItems: 'center'
}

export const FlexColReverse = styled.div`
    display: flex;
    flex-direction: column-reverse;
`

export const FlexColCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`

export const FlexRowStart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
`