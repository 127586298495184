import ReactGA from 'react-ga';
import keys from '../config';
import Analytics from './'
import * as Sentry from '@sentry/browser';
const { facebookPixel, pinterestPixel, redditPixel } = Analytics;
const prod = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT === 'production');

export default function(order, customer, productGroupsMap, coupon) {
    try {
        const { orderId } = order;
        const line_items = [];
        const { gtag } = window;

        // enhanced ecomm
        let total = order.totalCents / 100.0;
        let tax = 0;

        order.taxes.forEach(t => {
            tax += t.amountCents / 100.0
        })

        order.productGroupEntries.forEach(productGroup => {
            const { groupName, articleCount } = productGroup;
            const { productType, pricePerArticle, brandName, displayName, description } = productGroupsMap[groupName];
            const priceDollars = pricePerArticle / 100;

            ReactGA.plugin.execute('ec', 'addProduct', {
                id: groupName,
                name: groupName,
                price: priceDollars,
                brand: brandName,
                variant: displayName,
                quantity: articleCount,
                category: productType,
                creative: description,
            });
            
            line_items.push({
                product_name: brandName + " " + displayName,
                product_id: groupName,
                product_price: priceDollars,
                product_quantity: articleCount
            })
        })

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
            id: orderId,
            affiliation: customer.campaignCode,
            coupon: coupon ? coupon.code : "",
            shipping: 0.0,
            tax,
            revenue: total
        });

        // Ad conversion pixel tracking FB, Reddit etc
        if (prod) {
            facebookPixel('track', 'Purchase', { value: total, currency: 'USD' });
            redditPixel('track', 'Purchase');
            pinterestPixel('track', 'checkout', {
                value: total,
                order_quantity: line_items.length,
                line_items
            })
        }

        // Google ad-words conversion tracking
        if (gtag) {
            gtag('event', 'conversion', {
                'send_to': keys.gaAdConversionTrackingId,
                'value': total,
                'currency': 'USD',
                'transaction_id': orderId
            });
        }

        // Cart stack - track order complete
        if (keys.cartStackSiteID) {
            try {
                var cartStackUpdate = [];
                cartStackUpdate.push(['setSiteID', keys.cartStackSiteID]);
                cartStackUpdate.push(['setAPI', 'confirmation']);
                window.cartstack_updatecart(cartStackUpdate);
            } catch (err) {
                console.error("Cart Stack: error updating cart stack confirmation: ", err)
                Sentry.captureException(err);
            }
        }

        // send goal event 
        ReactGA.event({
            category: 'OrderStatus',
            action: 'OrderComplete',
            label: 'AllQuestions'
        })
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
}