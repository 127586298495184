import { getItemCountFromManifestBundles } from '../actions';
import { getSubtotalFromManifestBundles } from '../helpers/pricing';
import Analytics from './'
import { Logger } from '../helpers/Logger'
const logger = new Logger();
const { facebookPixel, pinterestPixel, redditPixel } = Analytics;

export default async function (manifestBundles) {
    facebookPixel('track', 'InitiateCheckout')

    redditPixel('track', 'AddToCart')

    try {
        const { subtotalCents, error } = getSubtotalFromManifestBundles(manifestBundles);
        if (error)
            throw error;
        else {
            const order_quantity = getItemCountFromManifestBundles(manifestBundles)
            pinterestPixel('track', 'addtocart', {
                value: subtotalCents / 100,
                order_quantity,
                currency: 'USD'
            })
        }
    } catch (err) {
        logger.error(err, new Error("Could not call pinterest addtocart"))
    }
}