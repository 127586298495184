import styled, { css } from 'styled-components';
import { colors } from './Colors';
import { autoMarginLR } from './Common';

export const TestimonialCard = styled.div`
    ${{ ...autoMarginLR }}
    background: ${colors.pureWhite};
    box-shadow: 0px 15px 35px rgba(43, 64, 78, 0.095505), 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 20px;
    max-width: 100%;
    width: 285px;
    border-radius: 20px;
    padding: 15px;
    margin-top: 45px;
    margin-bottom: 30px;
    ${props => props.isWide && css`
        width: 300px;
    `}
`

export const TestimonialImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 25px;
    margin-right: 12px;
`

export const TestimonialAuthor = styled.span`
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
`

export const TestimonialTitle = styled.h4`
    text-align: left;
    font-size: 18px;
    line-height: 140%;
    ${props => props.checkout_summary && css`
        padding-left: 32px;
        margin: 20px 0 0 0;
    `}
`

export const TestimonialQuote = styled.p`
    color: ${colors.bodyText};
    font-size: 14px;
    line-height: 140%;
`

export const TestimonialBody = styled.div`
    margin: 20px auto;
    width: 302px;
`

export const TestimonialCardName = styled.h6`
    color: ${colors.textGreen};
    font-size: 16px;
    margin: 12px 0;
`

export const TestimonialCardP = styled.p`
    color: ${colors.titleText};
    font-weight: 600;
`

export const TestimonialCarouselSubtitleContainer = styled.div`
    ${{ ...autoMarginLR }}
    max-width: 720px;
    width: 100%;
    text-align: center;
    & > p {
        font-size: 18px;
    }
`