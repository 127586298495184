import interview from './MockInterview';

export default {
    firstName: "FirstName",
    lastName: "LastName",
    email: "mockdata@supplydrop.com",
    billing: {
        address: "301 Chicon St, Unit B",
        city: "Austin",
        name: "FirstName LastName",
        state: "TX",
        zipcode: "78702"
    },
    shipping: {
        address: "301 Chicon St, Unit B",
        city: "Austin",
        name: "FirstName LastName",
        state: "TX",
        zipcode: "78702",
        deliveryInstructions: "Delivery Instructions"
    },
    status: "Active",
    phone: "+15125550100",
    nickname: "Mock User",
    interview,
    paymentSource: {
        id: "src_mockData",
        brand: "Visa",
        expirationMonth: 4,
        expirationYear: 2044,
        last4: "4242"
    }
}