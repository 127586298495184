import React, { Component } from 'react'
import { InterviewQuestion } from '../../Common';
import * as images from '../../../assets';
import { marginForCountdown } from '../../../actions';

class LaundryFrequency extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image: {
                src: images.laundryFrequency,
                alt: "Laundry"
            },
            laundryFrequency: 1,
            infoCopy: "The amount of laundry you do helps us calculate how much detergent you’ll need each month."
        }

        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({ isModal: this.props.isModal });

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({signUpData})
            this.setStateForKeyValue(signUpData);
            const { interview } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);

                this.setState({ interview })

                if(interview.laundry && interview.laundry.frequency !== undefined)
                    this.setState({laundryFrequency: interview.laundry.frequency})
            } else {
                this.back()
            }

            if (signUpData.zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { close, history } = this.props;
        if (close) {
            close()
        } else {
            const { signUpData } = this.state;
            history.push('/kitchen/3', { signUpData })
        }
    }

    next() {
        const {
            laundryFrequency,
            interview,
            signUpData
        } = this.state;
        const { isModal } = this.props;

        interview.laundry = { frequency: laundryFrequency }
        if (isModal)
            this.props.next();
        else {
            signUpData.interview = interview;
            this.props.history.push('/preference', { signUpData })
        }
    }

    decrement() {
        let { laundryFrequency } = this.state;
        if (laundryFrequency > 0) laundryFrequency--;
        this.setState({ laundryFrequency })
    }

    increment() {
        let { laundryFrequency } = this.state;
        laundryFrequency++;
        this.setState({ laundryFrequency })
    }

    render() {
        const { infoCopy, image, laundryFrequency, signUpData } = this.state;
        const { history, close, isModal } = this.props;
        const counter = {
            increment: this.increment,
            decrement: this.decrement,
            min: 0,
            count: laundryFrequency
        }
        return (
            <InterviewQuestion
                key="LaundryFrequency"
                id="LaundryFrequency"
                next={this.next}
                back={this.back}
                history={history}
                close={close}
                image={image}
                isModal={isModal}
                infoCopy={infoCopy}
                title="Supply Drop - Personalization - Laundry"
                counter={counter}
                question="How many loads of laundry do you do every week?"
                zipcode={this.state.zipcode}
                setZipcode={(zipcode) => this.setState({ zipcode })}
                signUpData={signUpData}
            />
        )
    }
}

export default LaundryFrequency;