//#region imports
import React from 'react';
import {
    FooterText,
    FooterMainText,
} from '../../../Style/Footer';
//#endregion

//#region subcomponents
export const Trademarks = newFooter =>
    <FooterText newFooter={newFooter} trademarks={true}>© {new Date().getFullYear()} Supply Drop Online, Inc. <br />Supply Drop, supplydrop.com, and the Supply Drop parachuting box, are trademarks of Supply Drop Online, Inc. Protected by U.S. Pat. 10,891,587</FooterText>

export const AntiHoarding = newFooter =>
    <FooterText newFooter={newFooter}><b>COVID19 Anti-Hoarding Rules are Active:</b> Supply Drop is here to get you what you need when you need it. We do not allow orders that appear to be hoarding. If an order triggers our hoarding alerts (recommended quantities are adjusted by large percentage or attempts to game the system), we may cancel it.</FooterText>
//#endregion

//#region main
export default function FooterCopy({ newFooter }) {
    return (
        <FooterMainText>
            <Trademarks newFooter={newFooter} />
            <AntiHoarding newFooter={newFooter} />
        </FooterMainText>
    )
}
//#endregion