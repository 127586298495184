import {
    autoMarginLR,
    autoMarginTB,
    colors,
    flexCenter,
    flexSpaceBetween,
    flexCol,
    interviewMaxWidth,
    mediaQueries,
    noCloud,
    singleShadow,
    w100,
    flexWrap,
    flexStart
} from './';
import styled, { css } from 'styled-components'
import { tightSpacing } from './Common';
import { flexRow } from './Flex';
import { landing_pattern_bg_conversational, landing_pattern_bg_household, localeur_dining } from '../assets';

const landingItemMarginBottom = "52px";

export function conversationalPatternedBackground(color) {
    return `${color} url(${landing_pattern_bg_conversational})`;
}

export function householdPatternedBackground(color) {
    return `${color} url(${landing_pattern_bg_household})`;
}

export const LandingMain = styled.main`
    ${{ ...flexSpaceBetween, ...flexCol, ...noCloud }}
    margin: auto;
    min-height: 100vh;
    overflow: hidden;
`

export const LandingContainer = styled.div`
    margin-top: 90px;
`

export const LandingNav = styled.nav`
    ${{ ...flexCenter, ...w100 }}
    box-shadow: ${singleShadow};
    background: ${colors.pureWhite};
    padding-top: 0;
    z-index: 1001;
    height: 90px;
    position: fixed;
    top: 0;
    ${mediaQueries("md")`
        top: auto;
        box-shadow: 0px -15px 35px rgba(43, 64, 78, 0.095505), 0px -5px 15px rgba(0, 0, 0, 0.0490829);
    `};
    ${props => (props.spaceTop !== undefined) && !isNaN(props.spaceTop) && css`
        top: ${props.spaceTop}px !important;
    `}
`

export const LandingRow = styled.div`
    ${{ ...flexWrap }}
`

export const LandingHeadline = styled.h1`
    font-family: Amaranth, sans-serif;
    ${mediaQueries(375)`
        font-size: 36px;
    `};
`

export const LandingHeadlineDetail = styled.div`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  text-align:center;
  color: #183041;
  opacity: 0.8;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  ${mediaQueries(767)`
       font-size: 18px;
  `};
  ${mediaQueries(374)`
      font-size: 16.56px;
  `};
`

export const LandingSection = styled.section`
    ${{ ...flexWrap, ...autoMarginLR }}
    width: 100%;
    max-width: 1500px;

    
    border-top: 1px solid ${colors.borderGray};
    min-height: 300px;
    ${props => props.noBorder && css`
        border: none;
    `}
    ${mediaQueries("md")`
        flex-direction: column;
    `};
    ${props => props.isReverse && css`
        flex-direction: row-reverse;
        ${mediaQueries("md")`
            flex-direction: column-reverse;
        `}
    `}
`

export const LandingSectionBody = styled.div`
    ${{ ...autoMarginLR }}
    padding-bottom: 44px;
    padding-top: 44px;
    width: 100%;
    max-width: ${interviewMaxWidth};
    ${mediaQueries("md")`
        width: 90%;
    `};
    ${mediaQueries("lg")`
        max-width: 900px;
    `};
    ${props => props.noPadding && css`
        padding-top: 0px;
    `}
`

export const LandingHIWSectionBody = styled(LandingSectionBody)`
    max-width: 1170px;
    position: relative;
    ${mediaQueries("lg")`
        max-width: 100%;
        padding: 20px;
        margin-top: 60px;
        margin-bottom: 160px;
    `}
`

export const LandingBundle = styled.div`
    text-align: center;
    width: 25%;
    display: inline-block;
    margin-bottom: ${landingItemMarginBottom};
    cursor: pointer;
    ${mediaQueries("md")`
        width: 100%;
        display: block;
    `};
`

export const LandingBundleImg = styled.img`
    height: 150px;
    width: 150px;
    margin-bottom: 40px;
    ${mediaQueries("md")`
        height: 100px;
        width: 100px;
        margin-bottom: 20px;
    `}
`

export const LandingH2 = styled.h2`
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
`

export const CTAContainer = styled.div`
    text-align: left;
    ${mediaQueries("md")`
        text-align: center;
    `}
`

export const LandingCTA = styled.button`
    ${{ ...autoMarginLR }}
    background: ${colors.btnBlue.idle};
    color: ${colors.contrastText};
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    height: 45px;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    & > a {
        color: ${colors.contrastText};
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        background-color: ${colors.primary.dark};
    }
    &:active {
        background-color: ${colors.primary.active_contained};
    }
    text-transform: uppercase;
`

export const LandingHIW = styled.div`
    ${{ ...flexCol, ...flexStart }};
    width: 300px;
    cursor: pointer;
    padding-top: 24px;
    ${mediaQueries("md")`
        padding-top: 0;
        width: 100%;
        display: block;
    `};
`

export const LandingHIWConnector = styled(LandingHIW)`
    width: 6%;
    vertical-align: top;
    padding-top: 85px;
    ${mediaQueries("sm")`
        display: none;
    `}
    ${mediaQueries("md")`
        display: none;
    `}
`

export const LandingHIWTitle = styled.h4`
    font-size: 18px;
    text-align: left;
    line-height: 120%;
    text-align: center;
`

export const LandingHIWImg = styled.img`
    height: 100px;
    width: auto;
    margin-bottom: 20px;
    ${mediaQueries("md")`
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        display: block;
    `}
`

export const LandingHIWArrow = styled.img`
    width: 100%;
    position: static;
    top: 82px;
`

export const LandingFAQsContainer = styled.div`
    ${{ ...autoMarginLR }}
    max-width: 1110px;
    width: 100%;
    margin-bottom: 60px;
    padding-top: 16px;  
`

export const LandingFAQsBody = styled.div`
    height: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    ${mediaQueries("lg")`
        height: auto;
        display: block;
    `}
`

export const LandingFAQBody = styled.div`
    max-width: 520px;
    margin-bottom: 60px;  
`

export const LandingFAQTitle = styled.h4`
    margin: 30px 0 15px 0;
`

export const Hero = styled.div`
    ${w100}
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 !important;
    position: relative;
    min-height: 580px;
    display: block;
    border-bottom: 5px solid #C9CBDA;
    transition: all .5s ease-in;
    ${mediaQueries("sm")`
        min-height: 340px;
    `}
`

export const HeroBlockContainer = styled.section`
    ${{ ...autoMarginLR }}
    max-width: 1500px;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    ${mediaQueries("sm")`
        padding-bottom: 0px;
    `}
`

export const HeroTextContainer = styled.div`
    ${{ ...autoMarginLR }}
    max-width: 414px;
    padding-left: 0px;
    padding-right: 0px;
`

export const BundleHeroContainer = styled.section`
    ${{ ...autoMarginLR, ...flexCenter }}
    margin-top: auto;
    margin-bottom: 0;
    max-width: 1500px;
    width: 100%;
    height: 100%;
    padding: 20px 0 0 20px;
    position: relative;
    ${mediaQueries("md")`
        flex-direction: column;
        padding: 90px 20px 0;
    `}
    ${props => props.isScience && css`
        margin-top: 120px;
    `}
`

export const HeroBlock = styled.div`
    width: 540px;
    background: ${colors.pureWhite};
    border-radius: 8px;
    padding: 20px 33px 33px;
    transition: all .5s ease-in;
    ${mediaQueries("sm")`
        position: static;
        box-shadow: none;
        margin: auto;
        width: 100%;
        padding: 0px 12px 33px 12px;
    `};
    ${props => props.hasCoupon && css`
        top: 2px;
    `}
`

export const LandingSubheader = styled.h5`
    font-family: 'Amaranth';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: ${tightSpacing};
    text-align: center;
    text-transform: none;
`

export const LandingTwoUpSection = styled.section`
    ${{ ...flexCenter }};
    border: none;
    padding: 20px;
    width: 100%;
    position: relative;
`

export const LandingSectionCol = styled.div`
    width: 50%;
    padding: 12px;
    z-index: 10;
    ${mediaQueries("md")`
        width: calc(100% + 40px);
        text-align: center;
    `}
    ${props => props.copySection && mediaQueries("md")`
        max-width: 100%;
        width: auto;
        margin-right: auto;
        margin-left: auto;
    `}
    ${props => props.isOverflowY && css`
        max-width: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: -20px;
        ${{ ...props.overflowContainerStyles }};
    `}
    ${props => props.isOverflowX && css`
        overflow-x: visible;
        margin-right: -40px;
    `}
    ${props => props.imgContainerStyles && css`
        ${{ ...props.imgContainerStyles }}
    `}
    ${props => props.bodyContainerStyle && css`
        ${{ ...props.bodyContainerStyle }}
    `}
    ${props => props.productGroupDetails && css`
        ${mediaQueries("md")`
            width: 100%;
            padding: 0;
        `}
    `}
`

export const LandingSectionP = styled.p`
    ${props => props.isHero && css`
        ${{ ...autoMarginLR }}
        max-width: 414px;
        weight: 400;
        font-size: 22px;
        text-align: center;
        ${mediaQueries("md")`
            font-size: 18px;
        `}
    `}
`

export const LandingSectionImg = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    ${props => props.isOverflowY && css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    `}
    ${props => props.overflowStyles && css`
        ${{ ...props.overflowStyles }}
    `}
    ${props => props.isOverflowX && css`
        max-width: none;
    `}
`

export const HouseholdGroupingContainer = styled.div`
    ${{ ...flexRow, ...flexWrap, ...flexStart }};
    max-width: 50vw;
    ${mediaQueries("md")`
        max-width: 100%;
    `}
`

export const ScienceCarouselSection = styled.div`
    padding: 30px;
`

export const ScienceHeroImgContainer = styled.div`
    height: 100%;
    width: 50%;
    position: relative;
    ${mediaQueries("md")`
        ${{ ...autoMarginLR }};
        margin-top: 20px;
        width: 330px;
        height: 215px;
    `}
`

export const ScienceHeroImg = styled.img`
    height: 700px;
    position: absolute;
    top: -220px;
    right: -190px;
    z-index: 0;
    ${mediaQueries("md")`
        top: -54px;
        right: 0;
    `}
`

export const SciencePredictedSubcopy = styled.div`
    margin-top: 20px;
    font-weight: bold;
`

export const HomeBlockCTA = styled.button`
    position: relative;
    z-index: 2;
    background-color: ${colors.primary.main};
    height: 68px;
    color: ${colors.contrastText};
    min-width: 203px;
    padding: 0 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 4px;
    border: none;
    font-size: 22px;
    cursor: pointer;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    ${mediaQueries("md")`
        height: 60px;
    `}
    &:hover {
        background-color: ${colors.primary.dark};
    }
    &:active {
        background-color: ${colors.primary.active_contained};
    }
    & > a {
        color: ${colors.contrastText};
        &:hover {
            text-decoration: none;
        }
    }
`

export const ScienceHoneycomb = styled.img`
    position: absolute;
    top: 20px;
    left: -320px;
    ${mediaQueries("md")`
        display: none;
    `}
`
export const ScienceHexagons = styled.img`
    position: absolute;
    right: -240px;
    bottom: -200px;
    ${mediaQueries("md")`
        height: 273px;
        width: auto;
        right: 0;
        bottom: -120px;
    `}
    ${props => props.isPersonalizedPlan && css`
        left: -230px;
        bottom: -440px;
        right: auto;
        transform: rotate(-79deg);
        ${mediaQueries("md")`
            display: none;
        `}
    `}
`

export const ScienceStructure = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    ${mediaQueries("md")`
        left: -200px;
    `}
    ${props => props.isTailoredSection && css`
        top: -200px;
        ${mediaQueries("md")`
            display: none;
        `}
    `}
`

export const SciencePattern = styled.img`
    position: absolute;
    top: 0px;
    left: -200px;
    transform: rotate(-90deg);
    ${mediaQueries("md")`
        transform: rotate(0deg);
        left: 20px;
        top: -80px;
    `}
    ${props => props.isSecond && css`
        top: auto;
        bottom: 40px;
        ${mediaQueries("md")`
            display: none;
        `}
    `}
`

export const HomeHeroPattern = styled.img`
    position: absolute;
    display: block;
    top: -112px;
    left: -40px;
    width: auto;
    ${mediaQueries("md")`
        display: none;
    `}
`

export const ScienceAtom = styled.img`
    position: absolute;
    top: -100px;
    right: 0;
    ${mediaQueries("md")`
        left: -100px;
        top: -20px;
    `}
`

export const LocaleurCoverImg = styled.div`
    height: 693px;
    width: 100%;
    background-image: url(${localeur_dining});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

export const RemoteSubtext = styled.p`
    ${{ ...autoMarginLR }};
    text-align: center;
    max-width: 305px;
`

export const InvestorContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    padding-bottom: 10px;
    ${mediaQueries("am")`
        width: 100%;
        padding-top: 10px;
    `}
`

export const InvestorImage = styled.img`
    padding-left: 20px;
    ${mediaQueries("lg")`
        padding-bottom: 15px;
        padding-left: 0px;
    `}
`

export const AboutUsHeadingContainer = styled.div`
    border-top: 1px solid #EDEDED; 
    padding: 20px;
    width: 100%;
    max-width: 1500px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    ${mediaQueries("sm")`
        padding-left: 12px;
    `}
`
export const HIWWrapper = styled.div`
    ${{ ...flexRow, ...flexSpaceBetween }};
    align-items: baseline;
    ${mediaQueries("md")`
        flex-direction: column;
    `}
`

export const BrandImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto 20px;
`

export const BrandImg = styled.img`
    ${{ ...autoMarginTB }};
    height: auto;
    max-height: 100%;
    width: 180px;
`

export const HIWHoneycomb = styled.img`
    position: absolute;
    transform: scaleX(-1);
    right: -490px;
    top: 0;
    ${mediaQueries("lg")`
        top: auto;
        bottom: -490px;
        right: 112px;
    `}
    ${mediaQueries("sm")`
        right: 0px;
    `}
`

export const CBSAustinImg = styled.img`
    width: 400px;
    ${mediaQueries("sm")`
        width: 300px;
    `}
`

export const OurPromiseHero = styled.img`
    position: absolute;
    bottom: 0px;
    left: 0px;
    @media (max-height: 667px) {
        width: 50%;
    }
`

export const OurPromiseH3 = styled.h3`
    font-family: "Amaranth", sans-serif;
    font-size: 28px;
    letter-spacing: 0.25%;
    line-height: 130%; 
`