import React, { Component, Fragment } from 'react';
import { Nav, ValueProps, ContinueButton, MetaTags, SDPartnerLogos } from '../Common';
import { connect } from 'react-redux';
import { getLanding, getProductGroups, parseSearchParams } from '../../actions';
import { Logger } from '../../helpers/Logger';
import { featureFlags } from '../../config';
import { OutageBanner } from '../Common/Alerts/OutageBanner';
import { icons_hero_our_promise } from '../../assets/landing/OurPromise';
import {
    OurPromiseHero as Img
} from '../../Style';
import { 
    Heading4
} from '../../Style/DesignSystem/Typography'
const logger = new Logger()

class OurPromise extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signUpData: {}
        };
        this.handleKeydown = this.handleKeydown.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setProductGroupsMap = this.setProductGroupsMap.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    handleKeydown(e) {
        if (e.keyCode && e.keyCode === 13)
            this.next();
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.signUpData)
            this.setSignUpData(this.props.location.state.signUpData)
        else {
            if (!this.props.gettingLanding)
                this.props.getLanding(window.location.search)
            if (!this.props.gettingProductGroups)
                this.props.getProductGroups()
        }
    }

    componentDidUpdate() {
        const {
            landingData,
            landingError,
            productGroups,
            productGroupsError
        } = this.props;

        if (landingData && !this.state.landingData)
            this.setState({ landingData })

        if (landingError && !this.state.landingError) {
            this.setState({ landingError })
            logger.error(landingError)
        }
        if (productGroups && (!this.state.productGroups || !this.state.productGroupsMap))
            this.setProductGroupsMap(productGroups);

        if (productGroupsError && !this.state.productGroupsError) {
            this.setState({ productGroupsError })
            logger.error(productGroupsError)
        }

    }

    setProductGroupsMap(productGroups) {
        const productGroupsMap = {};
        productGroups.forEach(productGroup => {
            productGroupsMap[productGroup.groupName] = productGroup;
        })
        this.setState({ productGroups, productGroupsMap })
    }

    setSignUpData(signUpData) {
        try {
            const { landingData, productGroups } = signUpData;
            if (!landingData && !this.props.gettingLanding)
                this.props.getLanding(window.location.search)
            if (!productGroups && !this.props.gettingProductGroups)
                this.props.getProductGroups()
            else if (productGroups)
                this.setProductGroupsMap(productGroups)

            this.setStateForKeyValue(signUpData);

            this.setState({ signUpData })
        } catch (err) {
            logger.error(err)
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push({ pathname: '/', search: window.location.search, state: { signUpData } })
    }

    next() {
        let { signUpData, landingData, productGroups, productGroupsMap } = this.state;

        if (!signUpData) {
            signUpData = Object.assign(signUpData, parseSearchParams(window.location.search))
        }

        if (landingData && !signUpData.landingData)
            signUpData = Object.assign(signUpData, landingData)

        if(productGroups && !signUpData.productGroups)
            signUpData.productGroups = productGroups

        if(productGroupsMap && !signUpData.productGroupsMap)
            signUpData.productGroupsMap = productGroupsMap

        const { utm_campaign, approved } = signUpData;

        const pathname = approved || (utm_campaign && utm_campaign.includes('fetch')) || (landingData && landingData.signupsOpen && featureFlags.isUngated) ? '/start/1' : '/check-availability';
        this.props.history.push({ pathname, search: window.location.search, state: { signUpData } });
    }

    renderBody() {
        return (
            <div className="hiw-container">
                <ValueProps isOurPromise={true}/>
            </div>
        )
    }

    renderLogos() {
        const { utm_campaign } = this.state;
        if (utm_campaign) {
            if (utm_campaign.includes('fetch')) {
                return <SDPartnerLogos style={{ marginBottom: 32 }} />
            }
        }
    }

    render() {
        const { zipcode } = this.state;
        return (
            <Fragment>
                <MetaTags
                    title="Supply Drop - Our Promise"
                    description="What makes Supply Drop magical?"
                    robots="follow"
                />
                <OutageBanner />
                <Nav spaceTopLg={zipcode} back={this.back} />
                <div className="interview-inner fadeInRight animated-fast text-center">
                    <Heading4 id="hiw-header">Our Promise</Heading4>
                    {this.renderLogos()}
                    {this.renderBody()}
                    <ContinueButton text="Continue" next={this.next} pressText="press" keyText="enter" />
                </div>
                <Img src={icons_hero_our_promise} alt="our-promise"></Img>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { landingData, gettingLandingData, landingError } = state.order;
    const { productGroups, productGroupsError, gettingProductGroups } = state.product;
    return { landingData, gettingLandingData, landingError, productGroups, gettingProductGroups, productGroupsError }
}

export default connect(mapStateToProps, {
    getLanding,
    getProductGroups
})(OurPromise);