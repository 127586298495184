import { combineReducers } from 'redux';
import interviewReducer from './interviewReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';
import productReducer from './productReducer';
import FAQReducer from './FAQReducer'
import globalErrorReducer from './globalErrorReducer'
import fireworksReducer from './fireworksReducer'

export default combineReducers({
    interview: interviewReducer,
    order: orderReducer,
    user: userReducer,
    product: productReducer,
    FAQs: FAQReducer,
    globalErrors: globalErrorReducer,
    fireworks: fireworksReducer,
});
