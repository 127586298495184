import React from 'react';
import * as Sentry from '@sentry/browser';
import 'url-search-params-polyfill';
import { parsePhoneNumber } from 'react-phone-number-input'
import { Logger } from '../helpers/Logger';
import { campaigns } from '../data'
const logger = new Logger();

// TODO: Move this to helpers
export const formatProductType = (productType) => {
    try {
        let baseChar = 0;
        let newType = '';
        // there's gotta be a more graceful way to do this
        switch (productType) {
            case "TrashBagsKitchen":
                newType = "Kitchen Trash Bags";
                break;
            case "TrashBagsSmall":
                newType = "Small Trash Bags";
                break;
            case "TrashBagsOutdoor":
                newType = "Outdoor Trash Bags";
                break;
            case "TrashBagsCompost":
                newType = "Compost Trash Bags";
                break;
            case "TrashBagsRecycling":
                newType = "Recycling Trash Bags";
                break;
            case "FaceMasksSurgical":
                newType = "Face Masks";
                break;
            default:
                for (let i = 1; i < productType.length; i++) {
                    if (productType[i] === productType[i].toUpperCase()) {
                        if (newType.length) newType += ' ';
                        newType += productType.substring(baseChar, i);
                        baseChar += productType.substring(baseChar, i).length;
                    }
                }
                if (productType.length > newType.length) {
                    newType += ` ${productType.substring(newType.length, productType.length)}`
                }
                break;
        }
        return newType;
    } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        return productType;
    }
}

export const getUnits = (articleType, articles, capitalizeFirst) => {
    const lastChar = articleType.slice(-1).toLowerCase();
    if (articles !== 1) {
        if (lastChar === 'x' || lastChar === 's' || lastChar === 'o') {
            articleType += "es";
        } else {
            articleType += 's';
        }
    }

    if (capitalizeFirst)
        return articleType.charAt(0).toUpperCase() + articleType.slice(1)
    else
        return articleType.toUpperCase();
}

export function getConsumptionEstimateMonths(articlesPerDay, articles, proposedArticles, isDashboard) {
    try {
        if (articlesPerDay && articles !== undefined && proposedArticles !== undefined) {
            if (articles === proposedArticles || isDashboard) {
                const articlesPerMonth = articlesPerDay * 30;
                const months = (articles / articlesPerMonth)
                return months
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    } catch (err) {
        logger.error(err, new Error('Could not getConsumptionEstimateMonths()'))
        return 0;
    }
}

export const formatInterview = (interview) => {
    try {
        const {
            bathrooms,
            dishwashingFrequency,
            dishwashingProducts,
            cookingFrequency,
            family,
            housekeepingFrequency,
            laundryFrequency,
            laundryPreferences,
            pets,
            scentPreferences,
            tierPreferences,
            trash,
            trashPickups
        } = interview

        const formattedInterview = {
            bathrooms,
            dishwashing: {
                frequency: dishwashingFrequency,
                products: dishwashingProducts
            },
            laundry: {
                frequency: laundryFrequency,
                preferences: laundryPreferences
            },
            family,
            pets,
            scentPreferences,
            cookingFrequency,
            housekeeping: housekeepingFrequency,
            trashPreferences: trash.length ? trash : [{
                type: "Kitchen",
                bags: 1,
                frequency: trashPickups
            }],
            tierPreferences
        }
        return { interview: formattedInterview }
    } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        return { error }
    }
}

export const marginForCountdown = (remove) => {
    const body = document.getElementsByTagName('body')[0];
    if (!body.classList.contains('countdown-margin') && !remove)
        body.classList.add('countdown-margin');
    else if (body.classList.contains('countdown-margin') && remove)
        body.classList.remove('countdown-margin');
}

export async function bundleProductsForCart(products) {
    const kitchenTypes = [
        "dishsoap",
        "dishwasherdetergent",
        "surfacecleaner",
        "handsoap"
    ]
    let total = 0;
    const everydayRows = [];
    const handwipes = [];
    const kitchenRows = [];

    Object.values(products).forEach(product => {
        const { selected } = product;
        if (selected.articles) {
            const { productType } = selected;
            total += parseFloat(selected.pricePerArticle * selected.articles / 100);
            if (kitchenTypes.includes(productType.toLowerCase()))
                kitchenRows.push(selected)
            else if (selected.productType.toLowerCase() === "handwipes")
                handwipes.push(selected)
            else
                everydayRows.push(selected)
        }
    })
    const sorted = [...everydayRows, ...handwipes, ...kitchenRows]
    return { sorted, total };
}

export const setCancelText = (question, need) => {
    let copy, formattedNeed;
    let questionsLeft = 0;
    if (question === 'onCooking') {
        questionsLeft = 3;
    } else if (question === 'onDishwashingFrequency') {
        questionsLeft = 2;
    } else {
        questionsLeft = 1;
    }

    switch (need) {
        case "needsKitchenFlow":
            formattedNeed = 'kitchen & cleaning supplies';
            break;
        case "needsLaundry":
            formattedNeed = 'laundry supplies';
            break;
        case "needsCompost":
        case "needsKitchen":
        case "needsRecycling":
        case "needsOutdoor":
        case "needsSmall":
            let trashType = need.replace("needs", "");
            formattedNeed = `${trashType.toLowerCase()} trash bags`;
            break;
        default:
            formattedNeed = "supplies"
            break;
    }
    copy = `Only ${questionsLeft} qustion${questionsLeft > 1 ? "s" : ""} left before we have your recommendation for ${formattedNeed}!`
    return copy;
}

export const sortEveryday = (productGroups) => {
    const everydayGroups = productGroups.filter(group => !group.productType.includes("TrashBags"));
    everydayGroups.sort((a, b) => {
        if (a.productType > b.productType) return -1;
        if (a.productType < b.productType) return 1;
        return 0;
    })
    productGroups.forEach(group => {
        if (group.productType.includes("TrashBags")) {
            everydayGroups.push(group)
        }
    })
    return everydayGroups;
}

export function formatPhone(value) {
    try {
        // Assume US country code
        if (value.trim().startsWith("1")) {
            value = "+" + value
        } else if (!value.trim().startsWith("+1")) {
            value = "+1" + value
        }

        let phoneNumber = parsePhoneNumber(value)
        if (phoneNumber === undefined || !phoneNumber.isValid()) {
            return value
        }
        return phoneNumber.number;
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        return;
    }
}

export function reverseFormatPhone(value) {
    try {
        let num = '';
        num = `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
        return num;
    } catch (err) {
        Sentry.captureException(err);
    }
}

export function getCadenceString(int) {
    let title;
    switch (int) {
        case 1:
            title = 'Monthly';
            break;
        case 2:
            title = 'Bi-Monthly';
            break;
        default:
            title = 'Quarterly';
            break;
    }
    return title
}

export function getCadenceMonths(weeks) {
    try {
        let int;
        const fractionalMonths = weeks / 4;
        if (fractionalMonths < 1.5)
            int = 1
        else if (fractionalMonths < 2.5)
            int = 2
        else
            int = 3

        const title = getCadenceString(int);
        return { cadenceMonths: { title, int } }
    } catch (err) {
        Sentry.captureException(err)
        console.error(err);
        return { error: err }
    }
}

export function getCouponVal(coupon, durationRepeats) {
    const customError = `Unable to getCouponVal for ${JSON.stringify(coupon)}`
    try {
        if (coupon) {
            const { percentDiscount, discount } = coupon;
            let couponVal;
            if (discount) {
                const { amountOffCents, percentOff } = discount;
                //if it is a repeating coupon over multiple orders
                if (amountOffCents && durationRepeats) {
                    couponVal = `$${(amountOffCents / 100 * durationRepeats).toFixed(2)}`
                } else if (amountOffCents && percentOff) {
                    couponVal = `${percentOff}% (up to $${(amountOffCents / 100).toFixed(2)})`
                } else {
                    couponVal = amountOffCents ? `up to $${(amountOffCents / 100).toFixed(2)}` : `${percentOff}%`
                }
            } else {
                couponVal = `${percentDiscount}%`
            }
            if (couponVal)
                return { couponVal }
            else
                throw customError;
        } else {
            return 0;
        }
    } catch (err) {
        Sentry.captureEvent(customError)
        Sentry.captureException(err);
        return { error: customError }
    }
}

export function getCreditVal(credit) {
    const customError = `Unable to getCreditVal for ${JSON.stringify(credit)}`
    try {
        const creditVal = `$${(credit / 100).toFixed(2)}`

        if (creditVal)
            return { creditVal }
        else
            throw customError;
    } catch (err) {
        Sentry.captureEvent(customError)
        Sentry.captureException(err);
        return { error: customError }
    }
}

export function getCouponDuration(coupon, newCoupon) {
    try {
        if (newCoupon) {
            const { duration } = newCoupon;
            const { type, repeats } = duration;
            switch (type) {
                case 'Forever':
                    return ' all deliveries'
                case 'Once':
                    return ' their first delivery';
                case 'Repeating':
                    return ` their first ${repeats} deliveries`;
                default:
                    Sentry.captureException(new Error(`Unknown coupon durationm: ${type}`))
                    return '';
            }
        } else if (coupon) {
            return ' their first delivery';
        } else {
            return '';
        }
    } catch (err) {
        Sentry.captureEvent(err)
        Sentry.captureException(new Error('Unable to set coupon durationString'));
        return '';
    }
}

export function getCouponSpan({
    coupon,
    utm_campaign,
    utm_content,
    isEmployeePerk
}) {
    const durationRepeats = coupon?.duration?.repeats;
    const { couponVal } = getCouponVal(coupon, durationRepeats);
    const isCustomerReferral = utm_campaign?.includes("customer_referring_") && couponVal
    
    const durationString = getCouponDuration(null, coupon);

    if (isEmployeePerk) {
        if (campaigns[utm_content])
            return <span>All <b>{campaigns[utm_content].displayName} employees</b> receive {couponVal} off{durationString}</span>
    } else if (utm_campaign && utm_campaign.includes("fetch")) {
        return <span>All <b>Fetch Residents</b> receive {couponVal} off{durationString}!</span>
    } else {
        if (isCustomerReferral) {
            return <span>Your special discount of {couponVal} (over {durationRepeats} qualified orders) will be applied at checkout! </span>
        } else {
            return <span>Your special discount of {couponVal} will be applied at checkout!</span>
        }
    }
}

export function formatDevEmail(email) {
    // TODO: Get this production-ready on a different branch
    if (email.contains('+')) {
        const arr = email.split('+');
        return arr[0] + '+' + Date.now() + arr[1]
    } else {
        const arr = email.split('@');
        return arr[0] + '+' + Date.now() + '@' + arr[1]
    }
}

export function formatStringForId(string) {
    try {
        return string.toLowerCase().split(" ").join("-");
    } catch (err) {
        logger.error(err);
        return "";
    }
}

export function parseSearchParams(search) {
    try {
        // convert URL search params into a JS object
        const searchData = paramsToObject(new URLSearchParams(search).entries())

        if (searchData && searchData.utm_campaign && searchData.utm_campaign === "waitlist%C2%A0")
            searchData.utm_campaign = "waitlist";

        return { searchData }
    } catch (error) {
        return { error }
    }
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}

export function getBundlesSearchString(productType) {
    const { search } = window.location;
    if (productType) {
        const { searchData } = parseSearchParams(search);
        if (searchData && searchData.productType) {
            searchData.productType = productType;
            let searchString = '';
            Object.entries(searchData).forEach(entry => {
                searchString += `${searchString ? "&" : "?"}${entry[0]}=${entry[1]}`;
            })
            return searchString;
        } else {
            return search ? search + `&productType=${productType}` : `?productType=${productType}`;
        }
    } else {
        return search;
    }
}

export function formatBadgeName(badge) {
    let formattedBadge = "";
    try {
        switch (badge) {
            case "SupplyDropPick":
                formattedBadge = "Premium Product"
                break;
            case "EcoFriendly":
                formattedBadge = "Eco-Friendly"
                break;
            case "NationallyRecognizedBrandLead":
                formattedBadge = "National Brand";
                break;
            case "SocialGood":
                formattedBadge = "Socially Conscious";
                break;
            default:
                formattedBadge = badge.replace(/([A-Z])/g, ' $1').trim()
                break;
        }
    } catch (err) {
        logger.error(err)
    }
    return formattedBadge
}