// Utility functions for using the Cartstack JS API : https://help.cartstack.com/article/37-overview
// ensures that attribute/data values are set only once
export default class Cartstack {
    static setAttribute(name, value) {
        if (!window._cartstack) {
            console.error("cartstackSetAttribute: Cartstack not available")
            return
        }
        Cartstack.removeElements('setAttribute', name)
        window._cartstack.push(['setAttribute', { [name]: value }]);
    }

    static setDataItem(name, value) {
        if (!window._cartstack) {
            console.error("cartstackSetDataItem: Cartstack not available")
            return
        }
        Cartstack.removeElements('setDataItem', name)
        window._cartstack.push(['setDataItem', { [name]: value }]);
    }

    static setCartTotal(total) {
        if (!window._cartstack) {
            console.error("cartstackSetCartTotal: Cartstack not available")
            return
        }
        Cartstack.removeElements('setCartTotal');
        window._cartstack.push(['setCartTotal', total]);
    }

    static clearCartItems() {
        if (!window._cartstack) {
            console.error("cartstackClearCartItems: Cartstack not available")
            return
        }
        Cartstack.removeElements('setCartItem')
    }

    static removeElements(name, value) {
        var i = 0;
        while (i < window._cartstack.length) {
            var element = window._cartstack[i];
            var elementName = element.length > 0 ? element[0] : '';
            var elementObj = element.length > 1 ? element[1] : {};
            if (elementName === name && (value === undefined || elementObj.hasOwnProperty(value))) {
                window._cartstack.splice(i, 1);
            } else {
                ++i;
            }
        }
    }

    static setCartItem(value) {
        if (!window._cartstack) {
            console.error("cartstackSetCartTotal: Cartstack not available")
            return
        }
        
        window._cartstack.push(['setCartItem', value]);
    }
}