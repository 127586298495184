import React from 'react';
import {SingleChoiceModal} from './SingleChoiceModal'

export const CustomerReferralInfo = (props) => {
        const { close } = props;
        const moreInfoCopy = 
        <div>
            <p>Save more the more you refer!</p>
            <p>Here is an example of what you could save if you share with 3 friends in the same month</p>
            <p>
                1 friend - $30 over three months, $10 a month<br/> 
                2 friends - $60 over three months, $20 a month<br/> 
                3 friends - $90 over three months, $30 a month<br/> 
                4 friends - No problem, the list goes on.<br/> 
            </p>
            <p>You will only recieve the discount for as long as your friends get their 3 orders with Supply Drop</p>
        </div>
        

        return(
                <SingleChoiceModal
                    title="Supply Drop Referral Program"
                    body={moreInfoCopy}
                    buttonCopy="Got it, thanks!"
                    close={close}
                    next={close}
                />
        )
}



