import React from 'react';
import { bundles, bundleMap } from '../../../data'
import { FlexColCenter, FlexRowStart } from '../../../Style';
import { Overline } from '../../../Style/DesignSystem/Typography';
import { ProductGroupLandingDetailImg, ProductGroupBundleRowContainer } from '../../../Style/ProductGroupLandingStyles';

export default function ProductGroupBundleRow({ productType }) {
    const bundleShortName = bundleMap[productType]
    const bundle = bundleShortName ? bundles[bundleShortName] : null;
    if (bundle) {
        const { title, icon } = bundle;
        return (
            <ProductGroupBundleRowContainer>
                <Overline className="text-left">Part Of</Overline>
                <FlexRowStart>
                    <ProductGroupLandingDetailImg src={icon} alt={title} />
                    <FlexColCenter><p>{title}</p></FlexColCenter>
                </FlexRowStart>
            </ProductGroupBundleRowContainer>
        )
    } else {
        return <div />
    }
}