import React, { Component, Fragment } from 'react';
import {
    LandingMain as Main,
    LandingContainer
} from '../../../Style'
import { MetaTags, LandingNav, Hero } from '../';
import Footer from '../Footer'

export class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderHelmet() {
        const { metadata } = this.props;
        if (metadata) {
            const { title, description, robots } = metadata;
            return (
                <MetaTags
                    title={title}
                    description={description}
                    robots={robots}
                />
            )
        }
    }

    renderHero() {
        const {
            ctaCopy,
            customerName,
            handleCTAClick,
            header,
            heroImg,
            history,
            isEmployeePerk,
            isMobile,
            isSignedIn, 
            isTablet,
            newCoupon,
            utm_campaign,
            utm_content
        } = this.props;
        return (
            <Hero
                ctaCopy={ctaCopy}
                customerName={customerName}
                coupon={newCoupon}
                header={header ? header : "All of your essentials delivered when you need them!"}
                heroImg={heroImg}
                handleCTAClick={handleCTAClick}
                history={history}
                isEmployeePerk={isEmployeePerk}
                isMobile={isMobile}
                isSignedIn={isSignedIn}
                isTablet={isTablet}
                utm_campaign={utm_campaign}
                utm_content={utm_content}
                imgContainerStyles={{
                    marginTop: 'auto'
                }}
            />
        )
    }

    renderBody() {
        const { body } = this.props;
        if (body) {
            return body();
        }
    }

    renderModal() {
        const { modal } = this.props;
        if (modal)
            return modal();
    }

    renderNav() {
        const {
            handleCTAClick,
            history,
            isSignedIn,
            isTablet,
            isMobile,
            toggleMenu,
            isShowingMenu
        } = this.props;
        return (
            <LandingNav
                history={history}
                isSignedIn={isSignedIn}
                isTablet={isTablet}
                isMobile={isMobile}
                toggleMenu={toggleMenu}
                isShowingMenu={isShowingMenu}
                next={handleCTAClick}
            />
        )
    }

    render() {
        return (
            <Fragment>
                {this.renderHelmet()}
                <Main>
                    {this.renderModal()}
                    {this.renderNav()}
                    <LandingContainer>
                        {this.renderHero()}
                        {this.renderBody()}
                    </LandingContainer>
                </Main>
                <Footer history={this.props.history} />
            </Fragment>
        )
    }
}