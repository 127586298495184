import mockProductGroups from './MockProductGroups'
import rounder from './MockRounder';
import interview from './MockInterview';
import { featureFlags } from '../../config';

const productGroupsMap = {}; 
mockProductGroups.forEach(productGroup => {
    productGroupsMap[productGroup.groupName] = productGroup;
})

export default {
    "newCoupon": {
        "code": "50-up-to-30-dec",
        "description": "50% off your first delivery (up to $30)",
        "discount": {
            "type": "PercentCappedDiscount",
            "percentOff": 50,
            "amountOffCents": 3000
        },
        "duration": {
            "type": "Once"
        }
    },
    "productGroups": mockProductGroups,
    productGroupsMap,
    "rc": "50-up-to-30-dec",
    "referrer": "http://localhost:3000/?rc=50-up-to-30-dec",
    "signupsOpen": true,
    "zipcodes": [
        "78702",
        "78762",
        "78741",
        "78721",
        "78701",
        "78722",
        "78704",
        "78712",
        "78773",
        "78723",
        "78742",
        "78705",
        "78778",
        "78799",
        "78711",
        "78774",
        "78769",
        "78768",
        "78767",
        "78714",
        "78703",
        "78751",
        "78713",
        "78746",
        "78764",
        "78744",
        "78772",
        "78765",
        "73301",
        "78760",
        "73344",
        "78756",
        "78745",
        "78731",
        "78763",
        "78783",
        "78752",
        "78779",
        "78757",
        "78761",
        "78716",
        "78710",
        "78749",
        "78748",
        "78766",
        "78718",
        "78715",
        "78709",
        "78708",
        "78735",
        "78759",
        "78738",
        "78758",
        "78726",
        "78727",
        "78733",
        "78747",
        "78739",
        "78753",
        "78750",
        "78729",
        "78717",
        "78613",
        "78630",
        "78664",
        "78665",
        "78680",
        "78681",
        "78682",
        "78683",
        "78728",
        "78660",
        "78691"
    ],
    interview,
    "manifestBundles": {
        "everyday": {
            "productGroups": [

            ],
            "subtotal": 2643
        },
        "kitchen": {
            "productGroups": [

            ],
            "subtotal": 0
        },
        "laundry": {
            "productGroups": [

            ],
            "subtotal": 0
        },
        "defense": {
            "productGroups": [

            ],
            "subtotal": 0
        }
    },
    rounder,
    "order": {
        "products": {
            "trash-bags-kitchen-glad-forceflex-drawstring": {
                "articleCount": 40,
                "productChangeReason": "NoReason"
            },
            "toilet-paper-charmin-ultra-soft-mega": {
                "articleCount": 6,
                "productChangeReason": "NoReason"
            },
            "paper-towels-bounty-select-a-size-double": {
                "articleCount": 6,
                "productChangeReason": "NoReason"
            },
            "facial-tissue-kleenex-anti-viral": {
                "articleCount": 1,
                "productChangeReason": "NoReason"
            }
        }
    },
    utm_campaign: featureFlags.mock_fetch ? "fetch_atx_sdn" : "",
    utm_content: featureFlags.mock_fetch ? "sdn" : "",
}