import React from 'react';
import { BodyL } from '../../../Style';
import { Heading1 } from '../../../Style/DesignSystem/Typography';
import ProductGroupPriceSection from './ProductGroupPriceSection';
import ProductGroupBundleRow from './ProductGroupBundleRow';
import ProductGroupBadgesRow from './ProductGroupBadgesRow';

export default function ProductGroupDetailsBody({ productGroup, coupon }) {
    const {
        brandName,
        displayName,
        description,
        pricePerArticle,
        articleType,
        productType,
        badges,
        displayUnits
    } = productGroup;

    const title = brandName + " " + displayName;
    return (
        <div>
            <Heading1 className="text-left">{title}</Heading1>
            <BodyL className="text-left">{description}</BodyL>
            <ProductGroupPriceSection
                pricePerArticle={pricePerArticle}
                articleType={articleType}
                displayUnits={displayUnits}
                coupon={coupon}
            />
            <ProductGroupBundleRow productType={productType} />
            {badges && badges.length && <ProductGroupBadgesRow badges={badges} />}
        </div>
    )
}