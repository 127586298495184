import React from 'react';
import * as images from '../assets/bundles';
import { BundleDescriptionSectionBody as Body } from '../Style';

export const bundles = {
    everyday: {
        title: "Everyday Essentials",
        header: "Everyday essentials",
        productTypes: [
            "ToiletPaper",
            "PaperTowels",
            "FacialTissue",
            "TrashBagsCompost",
            "TrashBagsKitchen",
            "TrashBagsOutdoor",
            "TrashBagsRecycling",
            "TrashBagsSmall"
        ],
        displayProductTypes: [
            "Toilet Paper",
            "Paper Towels",
            "Facial Tissue",
            "Trash Bags",
        ],
        icon: images.everyday_bundle_icon,
        hero: images.everyday_bundle_hero,
        description: <Body>The supplies you use everyday. Your first delivery includes a starter pack of toilet paper, paper towels and kitchen trash bags. You can also enjoy hassle free monthly delivery of facial tissue, hand wipes and other types of trash bags.</Body>
    },
    kitchen: {
        title: "Kitchen & Cleaning",
        header: "Kitchen & Cleaning essentials",
        productTypes: [
            "HandSoap",
            "SurfaceCleaner",
            "DishSoap",
            "DishwasherDetergent",
        ],
        displayProductTypes: [
            "Hand Soap",
            "Surface Cleaner",
            "Dish Soap",
            "Dishwasher Detergent",
        ],
        icon: images.kitchen_bundle_icon,
        hero: images.kitchen_bundle_hero,
        description: <Body>Those easy to forget essential supplies for the kitchen and cleaning.<br />We currently offer hand soap, dish soap and dishwasher detergent in a variety of options to fit your preferences.</Body>
    },
    laundry: {
        title: "Laundry",
        header: "Laundry essentials",
        productTypes: [
            "Bleach",
            "DryerSheets",
            "LaundryDetergent"
        ],
        displayProductTypes: [
            "Bleach",
            "Dryer Sheets",
            "Laundry Detergent"
        ],
        icon: images.laundry_bundle_icon,
        hero: images.laundry_bundle_hero,
        description: <Body>Never hassle with laundry supplies again! We ensure you always have laundry detergent and bleach so you can start and finish any load of laundry.</Body>
    },
    defense: {
        title: "Defense",
        header: "Defense essentials",
        productTypes: [
            "HandWipes",
            "HandSanitizer",
            "FaceMasksSurgical"
        ],
        displayProductTypes: [
            "Hand Wipes",
            "Hand Sanitizer",
            "Face Masks"
        ],
        icon: images.defense_bundle_icon,
        hero: images.defense_bundle_hero,
        description: <Body>Everything you need to go out safely, delivered contactless to your door! Face masks, hand wipes, and hand sanitizer in sizes that are easy to travel with.</Body>
    }
}
