import React, { Component, Fragment } from 'react';
import {
    getOrder,
    getUser,
    reverseFormatPhone,
    getUnits,
    getProductGroups,
    createGlobalAuthError
} from '../../actions';
import { connect } from 'react-redux';
import { Nav, Loading } from '../Common';
import { ProductLineItem } from '../Common/Dashboard';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import '../../Style/receipt.css'
import {
    LineItemPrice,
    ReceiptHeader as TH,
    LoadingContainer
 } from '../../Style/Receipt';
import { SubtotalLineItem } from '../Common/Dashboard/SubtotalLineItem';
import { signOut } from '../../helpers/SignOut';

class Receipt extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.setLocationData = this.setLocationData.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { orderId } = params;
        Auth.currentAuthenticatedUser().then(() => {
            this.setLocationData(orderId);
        }).catch(err => {
            console.error(err)
            this.props.history.push('/login', {
                pathname: `/dashboard/order/${orderId}`
            })
        })
    }

    componentDidUpdate() {
        const {
            user,
            userError,
            order,
            getOrderError,
            productGroups,
            productGroupsError,
            signedInAuthErr,
            nonSignedInAuthErr,
            history
        } = this.props;

        const { globalError } = this.state;

        if (user && !this.state.user)
            this.setState({ user, customer: user.customer });

        if (userError && !this.state.userError)
            this.setState({ userError });

        if (order && !this.state.order)
            this.setState({ order });

        if (getOrderError && !this.state.getOrderError)
            this.setState({ getOrderError });

        if (productGroups && !this.state.productGroups) {
            this.setState({ productGroups });
            this.mapProductGroups(productGroups);
        }

        if (productGroupsError && !this.state.productGroupsError)
            this.setState({ productGroupsError });

        if((signedInAuthErr || nonSignedInAuthErr) && !globalError) {
            this.setState({ globalError: true });
            signOut(history, true)
        }
    }

    setLocationData(orderId) {
        try {
            const { location: { state } } = this.props;
            if (state) {
                // Foreach required value:
                // if {value} is present in location, set
                // else if props.value, set
                // else get value from api

                // customer
                if (state.customer)
                    this.setState({ customer: state.customer })
                else if (this.props.customer)
                    this.setState({ user: this.props.user, customer: this.props.user.customer })
                else
                    this.props.getUser();

                // order
                if (state.order)
                    this.setState({ order: state.order })
                else if (this.props.order)
                    this.setState({ order: this.props.order })
                else
                    this.props.getOrder(orderId, true)

                // productGroupsMap
                if (state.productGroupsMap)
                    this.setState({ productGroupsMap: state.productGroupsMap })
                else if (this.props.productGroups)
                    this.setState({ productGroups: this.props.productGroups })
                else
                    this.props.getProductGroups();
            } else {
                const error = new Error('no location state');
                error.name = 'MissingData'
                throw error;
            }
        } catch (err) {
            if (err.name !== 'MissingData') {
                console.error(err)
                Sentry.captureEvent(err);
                Sentry.captureException(new Error('Could not set location data'));
            }
            this.props.getOrder(orderId, true)
            this.props.getUser()
            this.props.getProductGroups()
        }
    }

    mapProductGroups(productGroups) {
        try {
            const productGroupsMap = {};
            productGroups.forEach(productGroup => {
                productGroupsMap[productGroup.groupName] = productGroup;
            })
            this.setState({ productGroupsMap });
        } catch (err) {
            Sentry.captureEvent(err);
            Sentry.captureException(new Error('Could not map productGroups'))
        }
    }

    renderCustomerInfo() {
        const { customer, order } = this.state;
        if (customer && order) {
            const { firstName, lastName, shipping, phone, email } = customer;
            const { orderId, expectedDeliveryDate, expectedShipDate } = order;
            const { address, city, state, zipcode } = shipping;
            const formattedPhone = reverseFormatPhone(phone);
            return (
                <Fragment>
                    <div className="receipt-top">
                        <div className="receipt-copy">
                            <b>Order #: {orderId}</b><br />
                            Order Date: {moment(expectedShipDate).format('ll')}<br />
                            Delivery Date: {moment(expectedDeliveryDate).format('ll')}
                        </div>
                        <div className="receipt-copy">
                            <b>Deliver to</b><br />
                            {firstName} {lastName}<br />
                            {formattedPhone}<br />
                            {email}<br />
                            {address}<br />
                            {city}, {state} {zipcode}
                        </div>
                        <div className="receipt-copy">
                            <b>Return address</b><br />
                            Supply Drop<br />
                            301 Chicon St, Ste. B<br />
                            Austin, TX 78702
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    renderTable() {
        const { order, customer, productGroupsMap } = this.state;
        if (order && customer && productGroupsMap) {
            const { productGroupEntries, taxes, discounts, subtotalCents, totalCents, fees } = order
            let feeCents = 0;
            let taxCents = 0;

            if(fees && fees.length) {
                fees.forEach(fee => {
                    feeCents += fee.amountCents;
                })
            }
            if(taxes && taxes.length) {
                taxes.forEach(tax => {
                    taxCents += tax.amountCents;
                })
            }
            let discountCents = 0, discountSmallAdj = 0, discountString = 'Discount';
            let discountTwoCents = 0, discountTwoSmallAdj = 0, discountTwoString = 'Discount';
            if (discounts && discounts.length) {
                discountCents = discounts[0].amountCents;
                discountSmallAdj = discounts[0].smallOrderAdjustment;
                discountString = discounts[0].description;
            }
            if (discounts && discounts.length > 1) {
                discountTwoCents = discounts[1].amountCents;
                discountTwoSmallAdj = discounts[1].smallOrderAdjustment;
                discountTwoString = discounts[1].description;
            }
            const rows = [];
            productGroupEntries.forEach(productGroupEntry => {
                const { groupName, products, articleType, pricePerArticleCents } = productGroupEntry;
                const productGroupDetails = productGroupsMap[groupName];
                const { imageUrl, displayUnits, brandName, displayName } = productGroupDetails;

                products.forEach(product => {
                    const { productId, articlesPerPackage, quantity } = product;
                    const priceCents = pricePerArticleCents * articlesPerPackage * quantity;
                    const units = getUnits(displayUnits ? displayUnits : articleType, (articlesPerPackage * quantity), true)

                    const productLineItem = {
                        imageUrl,
                        brandName,
                        displayName,
                        articlesPerPackage,
                        quantity,
                        productId,
                        priceCents,
                        units
                    };

                    rows.push(<ProductLineItem {...productLineItem} />)
                })
            })

            const totalsRows = [];
            if (subtotalCents !== undefined && !isNaN(subtotalCents))
                totalsRows.push(<SubtotalLineItem name="Subtotal" value={`${(subtotalCents / 100).toFixed(2)}`} />)

            if (discountCents && !isNaN(discountCents )) {
                if (discountSmallAdj && !isNaN(discountSmallAdj)) {
                    totalsRows.push(<SubtotalLineItem isDiscount={true} name={discountString} value={`-${(Math.abs(discountCents + discountSmallAdj) / 100).toFixed(2)}`} />)
                } else {
                    totalsRows.push(<SubtotalLineItem isDiscount={true} name={discountString} value={`-${(Math.abs(discountCents) / 100).toFixed(2)}`} />)
                }
            }
            if (discountTwoCents && !isNaN(discountTwoCents )) {
                if (discountTwoSmallAdj && !isNaN(discountTwoSmallAdj)) {
                    totalsRows.push(<SubtotalLineItem isDiscount={true} name={discountTwoString} value={`-${(Math.abs(discountTwoCents + discountTwoSmallAdj) / 100).toFixed(2)}`} />)
                } else {
                    totalsRows.push(<SubtotalLineItem isDiscount={true} name={discountTwoString} value={`-${(Math.abs(discountTwoCents) / 100).toFixed(2)}`} />)
                }
            }

            if (feeCents && !isNaN(feeCents))
                totalsRows.push(<SubtotalLineItem name="Fees" value={`${(feeCents / 100).toFixed(2)}`} />)

            if (taxCents !== undefined && !isNaN(taxCents))
                totalsRows.push(<SubtotalLineItem name="Tax" value={`${(taxCents / 100).toFixed(2)}`} />)
            // Refunds are a little more involved
            // TODO: (Separate branch) Surface refunds
            // if (refundCents && !isNaN(refundCents))
            //     totalsRows.push(this.renderSubtotalRow("Refund", `-${(Math.abs(refundCents) / 100).toFixed(2)}`))

            if (totalCents >=0 && !isNaN(totalCents))
                totalsRows.push(<SubtotalLineItem name="Total" value={(totalCents / 100).toFixed(2)} />);

            return (
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <TH className="receipt-table-header">Item</TH>
                            <TH className="receipt-table-header"></TH>
                            <TH className="receipt-table-header">Quantity</TH>
                            <LineItemPrice as={TH}>Price</LineItemPrice>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                        {totalsRows}
                    </tbody>
                </table>
            )
        }
    }

    renderError() {
        const { getOrderError, getUserError, productGroupsError } = this.state;
        if (getOrderError || getUserError || productGroupsError) {
            return (
                <div className="receipt-error">
                    <b>Something went wrong.</b><br />
                    We are not able to show the receipt for this order at the moment. Please try again at another time.
                </div>
            )
        }
    }

    renderLoading() {
        const {
            customer,
            order,
            getOrderError,
            getUserError,
            productGroupsMap,
            productGroupsError
        } = this.state;
        if (!(customer && order && productGroupsMap) && !getOrderError && !getUserError && !productGroupsError) {
            return (
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            )
        }
    }


    render() {
        const pathname="/dashboard/orders";
        return (
            <div className="no-cloud no-cloud-white">
                <div className="receipt-container">
                    <Nav back={() => this.props.history.push(pathname)} pathname={pathname} />
                    {this.renderCustomerInfo()}
                    {this.renderError()}
                    {this.renderLoading()}
                    {this.renderTable()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, getUserError, gettingUser } = state.user;
    const { gettingOrder, order, getOrderError } = state.order;
    const { productGroups, productGroupsError } = state.product;
    const { signedInAuthErr, nonSignedInAuthErr } = state.globalErrors;
    return {
        user,
        getUserError,
        gettingUser,
        gettingOrder,
        order,
        getOrderError,
        productGroups,
        productGroupsError,
        signedInAuthErr,
        nonSignedInAuthErr
    }
}

export default connect(mapStateToProps, {
    getUser,
    getOrder,
    getProductGroups,
    createGlobalAuthError
})(Receipt);
