export default {
    contactAt: "2020-04-27",
    deliverAt: "2020-05-01",
    modified: true,
    salesTaxCents: 266,
    shipAt: "2020-04-30",
    status: "Predicted",
    viewed: true,
    products: {
        DishSoap: {
            articleCount: 1,
            groupName: "dish-soap-palmolive-ultra-strength-original-scent",
            moreThanAMonth: false,
            productChangeReason: "None",
            quantityChangeReason: "None"
        },
        ToiletPaper: {
            articleCount: 8,
            groupName: "toilet-paper-caboo-super-soft-bamboo-double-rolls",
            moreThanAMonth: false,
            productChangeReason: "TooExpensive",
            quantityChangeReason: "None"
        },
        PaperTowels: {
            articleCount: 4,
            groupName: "paper-towels-caboo-sustainable-bamboo",
            moreThanAMonth: false,
            productChangeReason: "None",
            quantityChangeReason: "None"
        },
        SurfaceCleaner: {
            articleCount: 0,
            groupName: "surface-cleaner-clorox-clean-up-and-bleach-original",
            moreThanAMonth: false,
            productChangeReason: "TooExpensive",
            quantityChangeReason: "None"
        }
    }
}