import React, { Component } from 'react';
import '../../../../Style/manifest-bundle.css';
import { ManifestBundleProduct, ManifestBundleHeader } from './';
import {
    ManifestBundleBody,
    ManifestBundleHR as HR,
    ManifestBundleProductContainer as Container,
    ManifestBundleProductMain as Main
} from '../../../../Style/Manifest';
import { sortEveryday } from '../../../../actions';
import { Button } from '@material-ui/core';
import { SuggestedProductGroups } from './SuggestedProductGroups';

export class ManifestBundle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    renderHeader() {
        const { title, bundleShortName, isDashboard } = this.props;
        return (
            <ManifestBundleHeader
                title={title} Î
                bundleShortName={bundleShortName}
                isDashboard={isDashboard}
            />
        )
    }

    renderAddProductsBtn() {
        const { title, productGroups, showOtherBrands, bundleShortName, viewBundleProducts, hasAllBundleTypes, rounderSet, productGroupsMap, isMobile } = this.props;
        if (!hasAllBundleTypes) {
            const bundleLength = Object.keys(productGroups).length;
            const hasProducts = !!bundleLength;
            const rows = [];
            if (!hasProducts) {
                rows.push(
                    <SuggestedProductGroups
                        selectedProductTypes={Object.keys(productGroups)}
                        bundleShortName={bundleShortName}
                        rounderSet={rounderSet}
                        productGroupsMap={productGroupsMap}
                        onclick={viewBundleProducts}
                        isMobile={isMobile}
                        showOtherBrands={showOtherBrands}
                    />
                )
            }
            rows.push(
                <Container className="bundle-product">
                    <Main>
                        <Button
                            onClick={viewBundleProducts}
                            variant="contained"
                            color="primary"
                        >
                            View more {title}
                        </Button>

                    </Main>
                </Container>
            )
            return rows;
        }
    }

    renderProducts() {
        const {
            showOtherBrands,
            rounderSet,
            showDescription,
            onQuantityChange,
            bundleShortName,
            isDashboard,
            groupNameToSkip,
            groupNameToRemove,
            undoSkip,
            undoRemove,
            skipRemovedProductGroup
        } = this.props;

        let { productGroups } = this.props;
        const rows = [];
        if (bundleShortName === "everyday") {
            productGroups = sortEveryday(productGroups);
        }
        productGroups.forEach((productGroup, i) => {
            const last = i === productGroups.length - 1;
            const {
                groupName,
                productType
            } = productGroup
            const onlyOffering = !rounderSet[productType] || !Object.values(rounderSet[productType].additional).length;
            rows.push(
                <ManifestBundleProduct
                    key={"manifest-bundle-product-" + groupName}
                    productGroup={productGroup}
                    bundleShortName={bundleShortName}
                    showDescription={showDescription}
                    onlyOffering={onlyOffering}
                    onQuantityChange={onQuantityChange}
                    showRemove={true}
                    last={last}
                    showOtherBrands={() => showOtherBrands(productType, bundleShortName)}
                    isDashboard={isDashboard}
                    isProductGroupSkipped={groupNameToSkip === groupName}
                    isProductGroupRemoved={groupNameToRemove === groupName}
                    undoSkip={undoSkip}
                    undoRemove={undoRemove}
                    skipRemovedProductGroup={skipRemovedProductGroup}
                />
            )
            if (!last)
                rows.push(<HR key={"hr of " + groupName} isDashboard={isDashboard} />)
        })
        return rows;
    }

    render() {
        return (
            <ManifestBundleBody>
                {this.renderHeader()}
                {this.renderProducts()}
                {this.renderAddProductsBtn()}
            </ManifestBundleBody>
        )
    }
}