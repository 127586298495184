import React from 'react';
import {
    BetterWayContainer,
    BetterWayImg,
    BetterWayImgLeft,
    BetterWayImgRight,
    BetterWayMain,
    BetterWayH4,
    BetterWayH4Container,
    HomeBlockCTA as CTA,
} from '../../../Style';
import {
    better_way_left,
    better_way_right,
} from '../../../assets/science';

export const BetterWay = ({
    isRelative,
    isSignedIn,
    ctaCopy,
    handleCTAClick, 
    isScience
}) => {
    const buttonCopy = isSignedIn ? "My Dashboard" : ctaCopy;
    return (
        <BetterWayContainer isRelative={isRelative}>
            <BetterWayImg as={BetterWayImgLeft} isScience={isScience} isRelative={isRelative} src={better_way_left} alt="A better way" />
            <BetterWayMain>
                <BetterWayH4Container><BetterWayH4>The better way to get your home essentials, saving you time in more ways than one.</BetterWayH4></BetterWayH4Container>
                <CTA test-id="our-promise" onClick={handleCTAClick}>{buttonCopy}</CTA>
            </BetterWayMain>
            <BetterWayImg as={BetterWayImgRight} isScience={isScience} isRelative={isRelative} src={better_way_right} alt="A better way" />
        </BetterWayContainer>
    )
}