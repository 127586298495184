import React, { Component, Fragment } from 'react';
import {
    ContinueButton,
    Counter,
    MetaTags,
    Nav,
    ProgressBar,
    SingleChoiceModal,
    TopRow,
} from '..';
import Feedback from '../Connected/Feedback';
import Countdown from '../Connected/Countdown';
import InterviewQuestions from '../../Interview/InterviewQuestions'

export class InterviewQuestion extends Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentWillMount() {
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown, false);
    }

    componentDidUpdate() {
        const { spaceTop } = this.state;
        const element = document.getElementById("countdown-alert")
        if (element && spaceTop !== element.offsetHeight)
            this.setState({ spaceTop: element.offsetHeight })
    }

    handleKeydown(e) {
        const { showInfo } = this.state;
        const { showProduct } = this.props;
        const element = document.getElementById("delivery-zipcode-input") 
        const zipcodeElementSelected = element && document.activeElement === element;
        if (e.keyCode && e.keyCode === 13 && !showProduct && !showInfo && !zipcodeElementSelected) {
            this.props.next();
        }
        if (e.keyCode && (e.keyCode === 13 || e.keyCode === 27)) {
            if (showInfo) {
                this.setState({ showInfo: false })
            }
            if (showProduct) {
                this.props.closeProductDetails();
            }
        }
    }

    renderInfo() {
        const { showInfo } = this.state;
        const { infoCopy } = this.props;
        if (showInfo) {
            return <SingleChoiceModal
                title="Household Information"
                body={infoCopy}
                buttonCopy="Go Back"
                close={() => this.setState({ showInfo: false })}
                next={() => this.setState({ showInfo: false })}
            />
        }
    }

    renderImage() {
        const { image, isModal } = this.props;
        if (image) {
            return (
                <div className={isModal ? "interview-modal-img" : "interview-header-img"}><img src={image.src} alt={image.alt} /></div>
            )
        }
    }

    renderNotification() {
        const { notification } = this.props;
        if (notification)
            return notification();
    }

    renderCountdown() {
        const { zipcode, isModal, setZipcode, signUpData, history } = this.props;
        if (!isModal) return <Countdown history={history} setZipcode={setZipcode} zipcode={zipcode} spaceTop={true} signUpData={signUpData} />
    }

    renderBody() {
        const { counter, body, disabled, back, next, isModal, question, continueText, cancelText } = this.props;
        let selection;
        if (counter) {
            const { increment, decrement, min, max, count } = counter;
            selection = <Counter type="interview" increment={increment} decrement={decrement} min={min} count={count} max={max} />
        } else if (body) {
            selection = body();
        }
        return (
            <Fragment>
                {this.renderImage()}
                <p className="interview-copy">{question}</p>
                {selection}
                <ContinueButton next={next} disabled={disabled} back={back} isModal={isModal} text={continueText} cancelText={cancelText} pressText="press" keyText="enter" />
            </Fragment>
        )
    }

    renderTopRow() {
        const { isModal, back, close } = this.props;
        if (isModal) {
            return <TopRow back={back} showInfo={() => this.setState({ showInfo: true })} close={close} />
        }
    }

    renderHeaderUp() {
        const { isModal, back, id } = this.props;
        const { spaceTop } = this.state;
        if (!isModal) {
            const questionsSum = Object.keys(InterviewQuestions).length;
            const index = InterviewQuestions[id] ? InterviewQuestions[id].index : 0;
            return (
                <Fragment>
                    <Nav spaceTop={spaceTop} back={back} showInfo={() => this.setState({ showInfo: true })} />
                    <ProgressBar progress={(100 / questionsSum) * index} />
                    {this.renderCountdown()}
                </Fragment>
            )
        }
    }

    renderFeedback() {
        const { isModal } = this.props;
        if (!isModal) return <Feedback question={window.location.pathname} />
    }

    renderMain() {
        const { isModal } = this.props;
        const { showInfo } = this.state;
        if (isModal && !showInfo) {
            return (
                <div id="non-account-modal-container" className="modal-container transparent interview-question-modal-container">
                    <div className="interview-question-modal">
                        {this.renderTopRow()}
                        <div className="interview-modal-body">
                            {this.renderBody()}
                        </div>
                    </div>
                </div>
            )
        } else if (!isModal) {
            return (
                <div className="interview-outer">
                    {this.renderTopRow()}
                    {this.renderNotification()}
                    <div className="interview-inner fadeInRight animated-fast">
                        {this.renderBody()}
                    </div>
                    {this.renderFeedback()}
                </div>
            )
        }
    }

    render() {
        const { title } = this.props;
        return (
            <Fragment>
                <MetaTags title={title} />
                {this.renderInfo()}
                {this.renderHeaderUp()}
                {this.renderMain()}
            </Fragment>
        )
    }
}
