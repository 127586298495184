import styled, { css } from 'styled-components'
import {
    colors
} from './'
import {
    flexCol,
    flexCenter,
    flexStart,
    flexRow,
    flexSpaceAround,
    alignItemsCenter
} from './Flex';
import { page_divider, page_divider_colored, page_divider_gray } from '../assets/landing/'

// Styled Components

export const breakpoints = {
    sm: 767,
    md: 991,
    lg: 1199,
}

export const mediaQueries = key => {
    return style => `@media (max-width: ${isNaN(key) ? breakpoints[key] + 'px' : key + 'px'}) { ${style} }`
}

export const circle = (size) => {
    return {
        width: size,
        height: size,
        borderRadius: '50px'
    }
}

export const mobileComponentMaxWidth = '95%';

export const noCloud = {
    background: colors.pureWhite,
    minHeight: '100vh',
    height: '100%',
    width: '100%'
}

export const NavLogo = styled.img`
    height: 35px;
    padding-left: 15px;
    margin-top: 6px;
`

// Modifiers
export const singleShadow = '0px 5px 15px rgba(0, 0, 0, 0.0490829)'

export const doubleShadow = `0px -15px 35px rgba(43, 64, 78, 0.095505), 0px -5px 15px rgba(0, 0, 0, 0.0490829)`;

export const w100 = {
    width: '100%'
}
export const H100 = styled.div`
    height: 100%;
`
export const fixedTop = {
    position: 'fixed',
    top: 0
}

export const autoMarginLR = {
    marginLeft: 'auto',
    marginRight: 'auto',
}

export const autoMarginTB = {
    marginTop: 'auto',
    marginBottom: 'auto',
}

export const tightSpacing = '-0.01em';

export const FAQBody = styled.div`
    font-size: 16px;
    line-height: 160%;
    padding-top: 15px;
    letter-spacing: ${tightSpacing};
    margin-left: 15px;
`

export const FAQContainer = styled.div`
    width: 48%;
    padding: 1%;
    ${mediaQueries("lg")`
        margin-left: auto;
        margin-right: auto;
        width: 520px;
        max-width: 100%;
    `}
`

export const DashboardFAQsContainer = styled.div`
    ${{ ...flexCol }}
`

export const PredictionHeaderWrapper = styled.div`
    max-width: 95%;
    margin-top: -48px;
    width: 890px;
`

export const CheckoutBody = styled.div`
    ${{ ...flexRow, ...autoMarginLR }};
    max-width: 95vw;
    width: 920px;
    background: ${colors.pureWhite};
    border: 1px solid ${colors.borderLight};
    box-sizing: border-box;
    box-shadow: ${doubleShadow};
    border-radius: 8px;
    padding: 28px;
    margin-top: 20px;
    margin-bottom: 40px;
    ${mediaQueries("md")(`
        width: 600px;
    `)};
    ${props => props.withBanner && css`
        margin-top: 0;
    `}
    ${props => props.recommendation && css`
        > div {
            ${{ ...autoMarginLR }}
        }
    `}
    ${props => props.checkout_summary && css`
        background: none;
        border: none;
        box-shadow: none;
    `}
`

export const BannerSpacing = styled.div`
    display: none;
    ${props => props.show && css`
        display: block;
        height: 40px;
    `}
`

export const DashboardFAQTitle = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    margin: 0px 15px;
    padding-top: 40px;
`

export const CadenceAlertContainer = styled.div`
    ${{ ...flexCenter }}
    margin-top: 24px;
    margin-bottom: 24px;
`

export const CadenceCalendarContainer = styled.div`
    padding-top: 5px;
    height: 68px;
    width: 68px;
    position: relative;
    ${props => props.containerStyles && css`
        ${{ ...props.containerStyles }};
        ${'' /* margin-top: 8px; */}
        &:hover {
            cursor: default;
        }
    `}
`

export const CadenceAlertBody = styled.div`
    ${{ ...flexCol, ...flexCenter }}
    text-align: left;
    margin-left: 15px;
    font-size: 16px;
    color: ${colors.bodyText};
`

export const CadenceAlertWrapper = styled.div`
    ${{ ...flexStart }}
    width: 500px;
    width: 560px;
    ${mediaQueries("md")`
        width: 500px;
    `}
`

export const CadenceLink = styled.div`
    text-decoration-line: underline;
    cursor: pointer;
    color: ${colors.bodyText};
    width: 100%;
    text-align: left;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
`

export const CadenceCalendarCircle = styled.div`
    ${{ ...flexCenter, ...circle(35) }}
    box-shadow: ${singleShadow};
    align-items: center;
    background: ${colors.btnGreen.idle};
    position: absolute;
    right: 0px;
    bottom: 0px;
    ${props => props.hasArrow && css`
        height: 28px;
        width: 28px;
        flex-direction: column;
        padding: 2px;
    `}
`

export const CadenceMonths = styled.div`
    font-weight: bold;
    font-size: 19px;
    color: ${colors.cloudWhite};
    padding-bottom: 2px;
    ${props => props.hasArrow && css`
        font-size: 12px;
        line-height: 1;
        color: #000000;
    `}
`

export const ValuePropsContainer = styled.div`
    ${{ ...autoMarginLR }};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: 48px;
    max-width: ${mobileComponentMaxWidth};
    ${props => (props.isCheckout || props.isLanding || props.isOurPromise) && css`
        flex-direction: column;
        margin-bottom: 24px;
        width: 302px;
        min-height: 215px;
    `}
    ${props => (props.isLanding || props.checkout_summary) && css`
        margin-top: 0;
    `}
    ${props => props.checkout_summary && css`
        flex-direction: column;
    `}
    ${mediaQueries("md")(`
        flex-direction: column;
        min-height: 215px;
        width: 302px;
        margin-top: 0px;
        margin-bottom: 24px;
    `)};
`

export const TestimonialContainer = styled.div`
    margin-top: 48px;
    width: 302px;
`

export const ValuePropsHR = styled.hr`
    margin: 8px auto;
    width: 302px;
    ${mediaQueries("md")(`
        max-width: ${mobileComponentMaxWidth}
    `)};
    ${props => props.hideHR && css`
        display: none;
    `}
`

export const ValueProp = styled.div`
    width: 33%;
    ${{ ...flexStart }};
    margin-top: 24px;
    ${mediaQueries("md")`
        width: 100%;
    `}
    ${props => (props.isCheckout || props.isLanding || props.isOurPromise ) && css`
        width: 100%;
    `}
    ${props => props.checkout_summary && css`
        flex-direction: row-reverse;
        justify-content: space-between;
    `}
`

export const ValuePropsImg = styled.img`
    height: 40px;
    width: 40px;
    margin-right: 15px;
    ${props => props.bestPricesExperiment && css`
        margin-bottom: auto;
    `}
    ${props => props.isLanding && css`
        width: 60px;
        height: 60px;
    `}
    ${props => props.isOurPromise && css`
        width: 40px;
        height: 40px;
    `}
`

export const ValuePropsTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: ${colors.titleText};
    width: 100%;
    text-align: left;
    ${props => props.isLanding && css`
        font-size: 18px;
    `}
    ${props => props.isLanding && css`
        font-size: 16px;
    `}
`

export const ValuePropsSubtext = styled.div`
    font-size: 14px;
    ${props => props.isLanding && css`
        font-size: 16px;
    `}
    ${props => props.isOurPromise && css`
        font-size: 16px;
        text-align: left;
    `}
    ${props => props.checkout_summary && css`
        max-width: 190px;
    `}
`

export const ModalContainer = styled.div`
    ${{ ...flexCenter }}
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.60);
    z-index: 10000;
    align-items: center;
    padding: 18px;
    transition: opacity 1s ease-in;
`

export const ModalBody = styled.div`
    background-color: ${colors.pureWhite};
    border: 1px solid ${colors.borderLight};
    border-radius: 8px;
    box-shadow: ${doubleShadow};
    max-height: 95vh;
    max-width: 95vw;
    min-height: 200px;
    padding: 35px 35px;
    pointer-events: auto;
    position: relative;
    overflow-y: auto;
    width: 460px;
    z-index: 10001;
    text-align: left;
`

export const ModalHR = styled.hr`
    border-style: none;
`

export const ModalTitle = styled.p`
    font-weight: bold;
    line-height: 20px;
    color: ${colors.titleText};
    text-align: left;
    font-size: 18px;
`

export const BtnPrimary = styled.button`
    background: ${colors.btnBlue.idle};
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 148px;
    height: 55px;
    padding: 0px 12px;
    margin: 10px auto;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    color: ${colors.btnBlue.text};
    cursor: pointer;
    border: none;
    &:hover {
        background: ${colors.btnBlue.hover};
    }
    &:active {
        background: ${colors.btnBlue.active}
    }
`

export const BtnCancel = styled.button`
     background: ${colors.btnWhite.main};
     color: ${colors.btnWhite.text};
     box-sizing: border-box;
     margin-right: 30px;
     font-size: 16px;
     line-height: 21px;
`

export const TextareaPrimary = styled.textarea`
    background-color: ${colors.inputBackground};
    color: ${colors.bodyText};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    flex: none;
    order: 2;
    width: 100%;
    height: 200px;
    resize: none;
    font-size: 16px;
    padding: 10px 10px 0px 10px;
    outline: 0;
    font-family: Muli;
`
export const AccountP = styled.p`
    font-size: 14px;
    margin: 0;
    word-wrap: break-word;
`

export const FailedPaymentText = styled.p`
    font-size: 12px;
    margin-top: -20px;
    padding-left: 5px;
    color: red;
`

export const BundleHero = styled.div`
    background: linear-gradient(4.63deg, #FFFFFF 0.98%, #F5F5F5 96.26%);
    ${'' /* margin: auto 0 0; */}
    position: relative;
    min-height: 500px;
    display: block;
    border-bottom: 5px solid #C9CBDA;
    transition: all .5s ease-in;
    ${props => props.noBorder && css`
        border-bottom: none;
    `}
`

export const HeroImg = styled.div`
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    min-height: 400px;
    width: 600px;
    max-width: 100%;
    ${mediaQueries("md")`
        width: 100%;
        min-height: 250px;
        display: inline;
    `}
`

export const HeroCopyContainer = styled.div`
    ${{ ...flexCol, ...flexCenter }}
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
    max-width: 100%;
    ${props => !props.isNew && css`
        height: 400px;
        ${mediaQueries("md")`
            height: 320px;
        `}
    `}
    ${mediaQueries("md")`
        min-height: 320px;
    `}
`

export const HeroHeader = styled.h1`
    text-align: left;
    font-size: 40px;
    width: 600px;
    max-width: 100%;
    word-wrap: normal;
    color: ${colors.titleText};
    ${mediaQueries('md')`
        text-align: center;
        padding: 12px;
        margin-left: auto;
        margin-right: auto;
        font-size: 34px;
    `}
    ${props => props.isNew && css`
        font-family: 'Amaranth';
        font-size: 56px;
        ${mediaQueries("md")`
            font-size: 47px;
            text-align: left;
            max-width: 320px;
        `}
    `}
`

export const HeroBody = styled.div`
    ${{ ...autoMarginLR }}
    letter-spacing: ${tightSpacing};
    text-align: center;
    color: ${colors.bodyText};
    margin-bottom: 40px;
    width: 600px;
    max-width: 100%;
    ${props => props.isNew && css`
        font-family: Muli;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
    `}
    ${mediaQueries("md")`
        width: 293px;
    `}
`

export const BundleLandingProductGroups = styled.div`
    ${{ ...flexRow, ...flexCenter, ...autoMarginLR, ...w100 }};
    flex-wrap: wrap;
`

export const BundleLandingProductGroupContainer = styled.div`
    border: 3px solid ${colors.borderLight};
    border-radius: 8px;
    width: 350px;
    height: auto;
    padding: 15px;
    margin: 12px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.productHover};
    }
    ${mediaQueries("md")`
        width: 170px;
        margin: 8px;
    `}
`

export const BundleLandingProductGroupImg = styled.img`
    height: auto;
    width: 100%;
`
export const BundleLandingProductGroupLink = styled.div`
    color: ${colors.btnBlue.idle};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    ${mediaQueries("md")`
        font-size: 12px;
    `}
`
export const BundleLandingProductGroupP = styled.p`
    margin-top: 15px;
    margin-bottom: 0;
    line-height: 140%;
		font-size: 16px;
		height: 70px;
    color: ${colors.titleText};
    ${mediaQueries("md")`
        font-size: 12px;
    `}
`

export const BundleDescriptionSectionContainer = styled.div`
    border-top: 5px solid ${colors.borderDark};
    border-bottom: 2px solid ${colors.borderLight};
`

export const BundleDescriptionSectionWrapper = styled.div`
    ${{ ...autoMarginLR }}
    text-align: center;
    max-width: 725px;
    padding: 20px 20px 40px;
`
export const BundleDescriptionSectionImgWrapper = styled.div`
    ${{ ...flexCol, ...flexCenter }}
`
export const BundleDescriptionSectionImg = styled.img`
    width: 50px;
    height: 50px;
`
export const BundleDescriptionSectionTitle = styled.h2`
    font-family: 'Muli', sans-serif;
    color: ${colors.titleText};
    font-weight: normal;
    font-size: 36px;
    line-height: 72px;
    letter-spacing: ${tightSpacing};
    margin-bottom: 0;
    margin-left: 15px;
    ${mediaQueries("md")`
        font-size: 26px;
    `}
`
export const BundleDescriptionSectionBody = styled.section`
    font-size: 18px;
    color: ${colors.bodyText};
`

export const BundleProductTypeSectionContainer = styled.div`

`

export const BundleProductTypeSectionTitle = styled.h3`
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 30px;
    letter-spacing: ${tightSpacing};
    color: ${colors.titleText};
    font-weight: normal;
    text-align: center;
    ${mediaQueries("md")`
        font-size: 22px;
    `}
`

export const BundleProductsContainer = styled.div`
    margin-bottom: 40px;
`

export const CouponAlertContainer = styled.div`
    ${{ ...autoMarginLR, ...flexSpaceAround }}
    background: ${colors.btnGreen.light};
    border: 2px solid ${colors.btnGreen.idle};
    border-radius: 8px;
    min-height: 74px;
    width: 348px;
    cursor: default;
    padding: 15px;
    margin-bottom: 30px;
    margin-top: 20px;
    ${props => props.spaceBottom && css`
        margin-top: -20px;
        margin-bottom: 12px;
    `}
    ${mediaQueries("md")`
        width: 307px;
    `}
`

export const CouponAlertBody = styled.div`
  line-height: 140%;
  color: ${colors.btnGreen.text};
  text-align: left;
  padding-left: 8px;
`

export const H2Amaranth = styled.h2`
    font-family: Amaranth;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: ${tightSpacing};
    text-align: center;
    ${props => props.bodySection && css`
        text-align: ${props.rightAlign ? 'right' : 'left'};
    `}
`

export const BodyL = styled.div`
    font-size: 18px;
    text-align: left;
    line-height: 28.8px;
    max-width: 550px;
    ${mediaQueries("md")`
        max-width: 100%;
    `}
    ${props => props.isHero && css`
        text-align: center;
        margin: 30px auto;
        padding: 0 8px;
    `}
`

export const BetterWayContainer = styled.div`
    min-height: 500px;
    ${props => props.isRelative && css`
        position: relative;
    `}
`

export const BetterWayImg = styled.img`
    position: absolute;
    bottom: 72px;
    max-width: 100%;
    ${props => props.isScience && css`
        bottom: 200px;
    `}  
    ${props => props.isRelative && css`
        bottom: 0;
    `}
    ${mediaQueries(1220)`
        width: auto;
    `}
`
export const BetterWayImgLeft = styled.img`
    left: 0;
    max-width: 100%;
    ${mediaQueries(1220)`
        height: 220px;
    `}
    ${mediaQueries("md")`
        height: auto;
        bottom: 20px;
    `}
    ${props => props.isScience && css`
        ${mediaQueries("md")`
            bottom: 200px;
        `}
        ${mediaQueries("sm")`
            bottom: 265px;
        `}
    `}  
    ${props => props.isRelative && css`
        left: -20px;
        ${mediaQueries("md")`
            bottom: 0px;
            max-width: calc(100% + 64px);
        `}
    `}
`

export const BetterWayImgRight = styled.img`
    right: 0;
    ${mediaQueries(1220)`
        height: 240px;
    `}
    ${mediaQueries("md")`
        display: none;
    `}
    ${props => props.isRelative && css`
        right: -20px;
    `}
`

export const BetterWayMain = styled.div`
    ${{ ...flexCenter, ...autoMarginLR, ...alignItemsCenter, ...flexCol }}
    height: 100%;
    ${mediaQueries("md")`
        margin-bottom: 40px;
        margin-top: -40px;
    `}
`

export const BetterWayH4Container = styled.div`
    ${{ ...autoMarginLR }}
    width: 485px;
    max-width: 100%;
    text-align: center;
`

export const BetterWayH4 = styled.h4`
    ${{ ...autoMarginLR }}
`

export const RaisedSection = styled.div`
    position: relative;
`

export const LandingSectionMainImgLabel = styled.div`
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    ${mediaQueries("md")`
        margin-top: 0;
    `}
`

export const PageDivider = styled.div`
    background-image: url(${page_divider});
    background-repeat: repeat-x;
    z-index: 100;
    height: 180px;
    width: 100%;
    margin-top: -108px;
    transform: rotate(180deg);
    ${props => props.isReverse && css`
        margin-top: 0;
        margin-bottom: -108px;
        transform: rotate(0);
    `}
    ${props => props.isColored && css`
        background-image: url(${page_divider_colored});
    `}
    ${props => props.isGray && css`
        background-image: url(${page_divider_gray});
    `}
`

export const ConsumptionEstimateText = styled.div`
    color: ${colors.textGreen};
    font-weight: 700;
    font-size: 14px;
    padding-left: 8px;
`

export const ConsumptionEstimateContainer = styled.div`
    ${{ ...flexRow, ...flexStart }}
`

export const CadenceCalendarArrow = styled.svg`
    &:hover {
        cursor: default;
        {& > path {
            fill: #1E4620 !important;
        }}
    }
`

export const DashboardNameHeader = styled.h2`
    font-size: 24px;
    font-family: 'Muli', sans-serif;
    display: block;
    color: ${colors.titleText};
    font-weight: bold;
    margin-top: 40px;
    text-align: left;
`

export const DashboardNameHeaderContainer = styled.div`
    width: 920px;
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
    ${mediaQueries(1200)`
        width: 500px;
    `}
`

export const DashboardNameHeaderSection = styled.div`
    margin-left: 240px;
    ${props => props.isUnverified && css`
        margin-left: 0px;
        margin-top: 80px;
        margin-bottom: 20px;
    `}
    ${mediaQueries("md")`
        margin-left: 15px;
    `}
`

export const SocialShareContainer = styled.div`
    margin: 20px 0px;
    ${mediaQueries("lg")`
        margin: 10px 0px;
    `}
`

export const TextCenter = styled.div`
    text-align: center;
    width: 100%;
`

export const ValuePropImgWrapper = styled.div`
    ${{ ...autoMarginTB }};
`

export const CardBrandImg = styled.img`
    height: 26px;
    width: auto;
    margin-right: 4px;
`

export const BlackTextUnderlineLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
`

export const FilledPageDividerContainer = styled(PageDivider)`
    background-image: none;
`