import React, { Fragment } from 'react';
import { Body1 } from '../../../Style/DesignSystem/Typography';
import {
    ProductGroupPricingContainer as Container,
    ProductGroupPriceWrapper as PriceWrapper,
    ProductGroupPriceHeading as Heading,
    ProductGroupPriceBody as Body,
    ProductGroupPriceDollarSign as DollarSign,
    ProductGroupDiscountTextBody as DiscountTextBody,
    ProductGroupPriceTextWrapper as TextWrapper,
    ProductGroupDisplayUnitsWrapper as DisplayUnitsWrapper
} from '../../../Style/ProductGroupLandingStyles';



export default function ProductGroupPriceSection({
    pricePerArticle,
    articleType,
    displayUnits,
    coupon
}) {
    const percentOff = coupon?.discount?.percentOff;

    function PriceBlock({ price, strikethrough, withBackground }) {
        return (
            <PriceWrapper withBackground={withBackground}>
                <Heading strikethrough={strikethrough}><DollarSign>$</DollarSign>{price.toFixed(2)}</Heading>
                <Body>Per {articleType}</Body>
            </PriceWrapper>
        )
    }

    const renderPreDiscountPrice = () => {
        if (percentOff) {
            return (
                <PriceBlock strikethrough={true} price={pricePerArticle / 100} />
            )
        }
    }

    const renderDisplayUnits = () => {
        if (displayUnits) {
            return (
                <DisplayUnitsWrapper>
                    <Body1 className="no-margin">{displayUnits}</Body1>
                </DisplayUnitsWrapper>
            )
        }
    }

    const renderText = () => {
        return (
            <TextWrapper>
                {renderDisplayUnits()}
                {renderDiscountText()}
            </TextWrapper>
        )
    }

    const renderDiscountText = () => {
        if (percentOff) {
            return (
                <DiscountTextBody>
                    <Heading>Save {percentOff}%</Heading>
                    <Body1>When you add to your first delivery!</Body1>
                </DiscountTextBody>

            )
        }
    }

    const renderCustomersPrice = () => {
        const price = percentOff ? parseFloat(pricePerArticle / 100) * ((100 - percentOff) / 100) : (pricePerArticle / 100);
        return (
            <PriceBlock withBackground={true} price={price} />
        )
    }

    return (
        <Fragment>
            <Container>
                {renderPreDiscountPrice()}
                {renderCustomersPrice()}
            </Container>
            {renderText()}
        </Fragment>
    )
}