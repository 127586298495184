export default [
    {
        "groupName": "dryer-sheets-bounce-outdoor-fresh",
        "productType": "DryerSheets",
        "pricePerArticle": 7,
        "articleType": "Sheet",
        "brandName": "Bounce",
        "displayName": "Outdoor Fresh ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/5xt0allySeaPNgFxBkqMUG/0ac159ca6884469869cf3778310f2f6a/bounce-outdoor-fresh-sheets.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Proven to prevent wrinkles and static, Bounce Outdoor Fresh dryer sheets leave clothes soft and smelling fresh. \n",
        "possibleArticleCounts": [
            34,
            68
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.12903225806451613,
        "articles": 34,
        "proposedArticles": 34,
        "cappedArticleCounts": [
            34,
            68
        ]
    },
    {
        "groupName": "hand-sanitizer-lxr-biotech-advanced-spray",
        "productType": "HandSanitizer",
        "pricePerArticle": 575,
        "articleType": "Bottle",
        "brandName": "LXR Biotech ",
        "displayName": "Advanced Spray ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3gB0JmyDq5IpYPpE4LT7J9/725b8f5b002d953052113ed03ac12e91/biotech-hand-sanitizer__1_.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Hand Sanitizer spray that kills 99.9% of germs. World Health Organization compliant formula containing 78% Isopropyl Alcohol, made in the USA.  \n \n",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "1.93 oz Bottle",
        "articlesPerDay": 0.03225806451612903,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-sanitizer-soapbox-light-citrus-scent",
        "productType": "HandSanitizer",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Light Citrus Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6YJNv0cNDZyrq8L6gCRzXh/1c08c588373e4e73ae2999d6ea2e8347/Soapbox-hand-sanitizer-light-citrus.png",
        "badges": [
            "SupplyDropPick",
            "SensitiveSkin",
            "SocialGood"
        ],
        "description": "The luxury of Soapbox is combined with the effectiveness of 70% alcohol to create a uniquely wonderful hand sanitizer. The light citrus scent replaces the normally harsh alcohol smell of most hand sanitizers. The moisturizing aloe and vitamin E leave your hands feeling soft and not sticky. Convenient travel size container kills 99.9% of germs.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "4 oz Bottle",
        "articlesPerDay": 0.016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-mrs-myers-basil",
        "productType": "HandSoap",
        "pricePerArticle": 388,
        "articleType": "Bottle",
        "brandName": "Mrs. Meyers",
        "displayName": "Basil",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1DyMkYTbDUQlSpDIBkp5yF/f4decbe5f272ffe42558a87f506c0deb/msmeyersbasilhandsoap.png",
        "badges": [
            "EcoFriendly",
            "NationallyRecognizedBrandLead"
        ],
        "description": "Hard working Basil scented hand soap made  with essential oils, aloe vera, and other thoughtfully chosen ingredients. ",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.010967741935483872,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-soapbox-soothing-moisture-lavender-&-marshmallow-root",
        "productType": "HandSoap",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Soothing Moisture, Lavender & Marshmallow Root",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4Zus2ZglewYBwDnbAdcD99/c78ec8397cc7c47f8a1b883fac5092a6/soapbox-lavendar-and-marshmellow-root.png",
        "badges": [
            "SupplyDropPick",
            "SensitiveSkin",
            "SocialGood"
        ],
        "description": "Paired with shea butter and aloe vera, Soapbox’s unique soothing moisture formula will leave your skin clean and moisturized, while the calming lavender and sweet marshmallow root scent leave your skin (and mood) refreshed. \n",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle",
        "articlesPerDay": 0.010967741935483872,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-puracy-natural-lavender-vanilla",
        "productType": "HandSoap",
        "pricePerArticle": 599,
        "articleType": "Bottle",
        "brandName": "Puracy",
        "displayName": "Natural, Lavender and Vanilla",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/tY6fNTMfO7ezZLXqnRkBD/0e6ed391e42cd517085135f786e4abc3/a197d77d2105b656e7fa86fc435a948e.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Non-toxic, plant-based hand soap. Extra gentle for sensitive skin with a lovely light scent. Over 20,000 Five-Star reviews.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": "hand-soap-puracy-natural-lavender-vanilla-refill",
        "isRefill": false,
        "displayUnits": "12 oz Bottle",
        "articlesPerDay": 0.010967741935483872,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-puracy-natural-cucumber-mint",
        "productType": "HandSoap",
        "pricePerArticle": 599,
        "articleType": "Bottle",
        "brandName": "Puracy ",
        "displayName": "Natural, Cucumber & Mint ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7MNrKsGsJZ17UFI9bThJSX/f116526028389dfa988e199ae7f75013/puracy-handsoap-cucumber-and-mint.png",
        "badges": [
            "EcoFriendly",
            "SensitiveSkin",
            "SupplyDropPick"
        ],
        "description": "Non-toxic, plant-based hand soap. Extra gentle for sensitive skin with a lovely light scent. Over 20,000 Five-Star reviews.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle",
        "articlesPerDay": 0.010967741935483872,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-soapbox-deep-moisture-coconut-milk-and-sandlewood",
        "productType": "HandSoap",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Deep Moisture, Coconut Milk & Sandalwood",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4MoqoKcBLoUblLCRikYA66/8bc0231bd6547694f20f3dbbaa966637/soapbox-coconut-milk-and-sandlewood.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SocialGood",
            "SensitiveSkin"
        ],
        "description": "Paired with shea butter and aloe vera, Soapbox's unique deep moisture formula will leave your skin clean and moisturized, while the naturally sweet coconut milk and cedar-like sandalwood to leave your skin smelling wonderful.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle",
        "articlesPerDay": 0.010967741935483872,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "hand-soap-soapbox-nourishing-moisture-vanilla-&-lily-blossom",
        "productType": "HandSoap",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Nourishing Moisture, Vanilla & Lily Blossom ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/EM2yUQg4CE0B9lKPnXYR4/bb3428278c0eb406c2d35f82069cdd15/soapbox-vanilla-and-lily-blossom.png",
        "badges": [
            "SupplyDropPick",
            "SensitiveSkin",
            "SocialGood"
        ],
        "description": "Paired with shea butter and aloe vera, Soapbox’s unique nourishing moisture formula uses B-vitamin rich vanilla and the aroma of lily blossom to leave hands clean and moisturized. \n\n",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle"
    },
    {
        "groupName": "hand-soap-dial-gold",
        "productType": "HandSoap",
        "pricePerArticle": 235,
        "articleType": "Bottle",
        "brandName": "Dial",
        "displayName": "Gold Antibacterial",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3VA4pvXl8nYKPOQaoFRxed/44221945acb1097a4a09723e266114e2/e6f949cd3910ee6396e96601e86ac1f6.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Dial's Gold Antibacterial hand soap gets your hands clean of dirt and germs while leaving them soft. With its gentle scent and good value, Dial Gold Antibacterial is a trusted home favorite.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "7.5 oz Bottle",
        "articlesPerDay": 0.01741935483870968,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "facial-tissue-puffs-cube-plus-lotion",
        "productType": "FacialTissue",
        "pricePerArticle": 204,
        "articleType": "Box",
        "brandName": "Puffs",
        "displayName": "Cube, Plus Lotion",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/doJYRK5imKsB6jANDWcf7/115cf8ace6b5656e019d4da0ba534b8d/6390dfe66d0b221cc89d1d380540a709.png",
        "badges": [
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "America’s #1 lotion tissue helps soothe irritated noses. Puffs Plus Lotion is dermatologist tested to be gentle on sensitive skin.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.00870967741935484,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "facial-tissue-caboo-soft-sustainable-bamboo",
        "productType": "FacialTissue",
        "pricePerArticle": 265,
        "articleType": "Box",
        "brandName": "Caboo",
        "displayName": "Soft & Sustainable Bamboo",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6apItfEqd9aEX9oGmDMiuY/6b53569418a00350a62caf22cd90fa68/7948f4dba87427055c188cdc9fd1ee3d.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Eco-friendly facial tissue made from 100% bamboo & sugarcane pulp. Contains 50% less lint than traditional tissues. Gentle on noses and the planet; Caboo tree free facial tissues are free of inks, dyes and fragrances designed for anyone with sensitive skin and/or allergies.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.005483870967741936,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "facial-tissue-kleenex-anti-viral",
        "productType": "FacialTissue",
        "pricePerArticle": 199,
        "articleType": "Box",
        "brandName": "Kleenex",
        "displayName": "Anti-Viral",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/5sd1EeagUqz1vLDpOnTXpg/51c0e22099ed63fd91d895822a5c6d36/kleenex-anti-viral.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "The Kleenex tissue you trust with a moisture activated layer that effectively kills 99.9% of cold and flu viruses within the tissue. \n",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0067741935483870966,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "facial-tissue-kleenex-ultrasoft-cube",
        "productType": "FacialTissue",
        "pricePerArticle": 199,
        "articleType": "Box",
        "brandName": "Kleenex",
        "displayName": "UltraSoft Cube",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7fW5CGJfbRdWFi0uvtGXaW/4681e312934c5461e90c31b7cd1009b9/01210dfc85af75a148f60004cdf83167.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "The most trusted brand in facial tissue, Kleenex UltraSoft’s 3-ply tissues provide ultimate softness and dependable strength in a convenient cube box.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0067741935483870966,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "facial-tissue-kleenex-ultrasoft-flat",
        "productType": "FacialTissue",
        "pricePerArticle": 214,
        "articleType": "Box",
        "brandName": "Kleenex",
        "displayName": "Trusted Care Flat",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3hGjVuRBSiVuwEKwbWCI2O/9a7b7813ebb31e7517d8f0b8fe2374f8/f10dc07cc51e80701b97115a229be0e7.png",
        "badges": [
            "Value",
            "NationallyRecognizedBrandLead"
        ],
        "description": "The most trusted brand in facial tissue, Kleenex Trusted Care 2-ply tissues provide ultimate softness and dependable strength in a full size flat box.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0032258064516129032,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "groupName": "dish-soap-seventh-generation-free-&-clear",
        "productType": "DishSoap",
        "pricePerArticle": 475,
        "articleType": "Bottle",
        "brandName": "Seventh Generation ",
        "displayName": "Free & Clear ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6qwfugPdXUQDuaxsnrvd2Z/cfaef2400cb30bd955477f0e84cfdaec/seventh-generation-dish-soap.png",
        "badges": [
            "SensitiveSkin",
            "EcoFriendly"
        ],
        "description": "Free & Clear plant based dish soap that is biodegradable and hypoallergenic, meaning all of the cleaning power you’d expect but free of petroleum based ingredients or phosphates. \n",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "25 oz Bottle",
        "articlesPerDay": 0.0022580645161290325,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "dish-soap-mrs-meyers-lemon-verbena",
        "productType": "DishSoap",
        "pricePerArticle": 398,
        "articleType": "Bottle",
        "brandName": "Mrs. Meyer's",
        "displayName": "Lemon Verbena",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7gp7BjWhZIdeMBhWNexXzw/7d2e6fb5968e3b1af21dfe26e78249f2/Mrs-meyers-dish-soap-lemon-verneba.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick"
        ],
        "description": "Plant-based ingredients and essential oils are combined in this concentrated formula to tackle tough grease and dirty dishes while being gentle on your hands. The light, refreshing scent of Lemon Verbena ensures a more enjoyable job of doing the dishes.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "16 oz Bottle",
        "articlesPerDay": 0.0035483870967741938,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "laundry-detergent-puracy-natural-liquid-free-and-clear",
        "productType": "LaundryDetergent",
        "pricePerArticle": 1499,
        "articleType": "Bottle",
        "brandName": "Puracy",
        "displayName": "Natural Liquid, Free and Clear",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/hsW60t60RNTFvmharANJ3/bc3e449e9ce5d85e51801cd432d9404e/1a0b03f5f5ab13eddc62c9857939d4a9.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Non-toxic, plant-based laundry detergent. Safe enough to use around your children and pets without sacrificing cleaning power. Great for sensitive skin & 10x concentrated to last. One 24 ounce bottle lasts 96 full loads; that’s over 6 times the cleaning power of most national brands.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "24 oz Bottle, each bottle is 96 loads",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "laundry-detergent-tide-high-efficiency-2x-original-scent",
        "productType": "LaundryDetergent",
        "pricePerArticle": 825,
        "articleType": "Bottle",
        "brandName": "Tide",
        "displayName": "High Efficiency 2x, Original Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/68F68sQwZQXJ9zITmlGmfX/2ff91b60a4c5566a15b49ee96cae47fd/8b772849441e7251265d6c8a11a2830d.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Original Scent, High Efficiency laundry detergent that’s capable of 6X more cleaning power in half the time of other detergents. Good for 32 loads of laundry.",
        "possibleArticleCounts": [
            1,
            2,
            3
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "50 oz Bottle, each bottle is 32 loads",
        "articlesPerDay": 0.004032258064516129,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3
        ]
    },
    {
        "groupName": "laundry-detergent-tide-high-efficiency-free-gentle",
        "productType": "LaundryDetergent",
        "pricePerArticle": 825,
        "articleType": "Bottle",
        "brandName": "Tide",
        "displayName": "High Efficiency, Free & Gentle",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3u4N5skEG665H2Zc7QWfWG/b204d28a8bd78f1daaafffdcb19bb362/Tide-free___gentle-white.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Free & Gentle, High Efficiency laundry detergent that’s capable of 6X more cleaning power in half the time of other detergents. Good for 30 loads of laundry and free of Dyes and Perfumes. ",
        "possibleArticleCounts": [
            1,
            2,
            3
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "46 oz Bottle, each bottle is 30 loads",
        "articlesPerDay": 0.004451612903225806,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3
        ]
    },
    {
        "groupName": "dishwasher-detergent-seventh-generation-powerful-clean-lemon-scent",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 521,
        "articleType": "Bottle",
        "brandName": "Seventh Generation ",
        "displayName": "Powerful Clean, Lemon Scent Gel",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4SPhRmptWFR0DIly35ufzl/699646b6d4bf23b47dbb8ab0e22f84bd/seventh-generation-dishwasher-gel.png",
        "badges": [
            "EcoFriendly",
            "SensitiveSkin"
        ],
        "description": "Plant-based enzyme dishwasher gel that delivers a powerful and trusted clean without chlorine bleach, dyes, or synthetic fragrances. EPA Safer Choice certified.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "42 oz Bottle",
        "articlesPerDay": 0.0032258064516129032,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "trash-bags-outdoor-glad-strong-lawn-and-leaf-quick-tie",
        "productType": "TrashBagsOutdoor",
        "pricePerArticle": 41,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "Strong Lawn & Leaf, Quick-Tie",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6TXtRZWWmVXvOTSFQqST2B/1cf116cbff95ff30e9f0f71cf7e60064/ac45c039b92f8488f4ccd3375a5c148c.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "This 39 gallon strong trash bag is perfect for cleaning yard debris, after a party or anytime you have a job a regular trash bag can’t handle. This Glad trash bag has a quick-tie closure for convenient and easy use.",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null
    },
    {
        "groupName": "trash-bags-outdoor-glad-forceflex-plus-drawstring",
        "productType": "TrashBagsOutdoor",
        "pricePerArticle": 36,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "ForceFlex Plus, Drawstring",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/TkhnsyvYaD4MSnLqU1tsQ/fdd44fe55bb7bcbe53a3b3ed92f254ef/glad-forceflex-plus-large-trash-bags.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Glad ForceFlexPlus 30 gallon trash bags are perfect for cleaning yard debris, after a party or anytime you have a job a regular trash bag can’t handle. Equipped with Leakguard + Ripguard technology to prevent leaks and tearing. Strong drawstrings make sealing and transporting easy.",
        "possibleArticleCounts": [
            25,
            50
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articles": 25,
        "proposedArticles": 25,
        "cappedArticleCounts": [
            25,
            50
        ]
    },
    {
        "groupName": "trash-bags-recycle-glad-blue-drawstring",
        "productType": "TrashBagsRecycling",
        "pricePerArticle": 20,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "Blue Drawstring",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/5Re5bhowpYnSZU9lvKF4hd/44700479ea367d687db1ce219624f7c5/glad-recycling-kitchen.png",
        "badges": [
            "EcoFriendly",
            "NationallyRecognizedBrandLead"
        ],
        "description": "These 13 gallon recycling bags are the go to for all of your recycling needs, proven to be strong and easy to close with a 3 ply drawstring. \n \n",
        "possibleArticleCounts": [
            45,
            90
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articles": 45,
        "proposedArticles": 45,
        "cappedArticleCounts": [
            45,
            90
        ]
    },
    {
        "groupName": "trash-bags-small-glad-febreze-fresh-quick-tie",
        "productType": "TrashBagsSmall",
        "pricePerArticle": 13,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "Febreze Fresh, Quick Tie ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2yQNF2K3tSaBWZwSWokP24/7a69e0187656c54cf629e2b5bb47c4c4/glad-febreeze-small-trash-bags.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "These 4 gallon trash bags are perfect for small trash cans and are convenient for on the go demand with quick tie flaps to ensure durable closure. Febreze Fresh and equipped with Glad’s Odor Shield technology means no unwanted odor.  \n\n",
        "possibleArticleCounts": [
            26,
            52
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articles": 26,
        "proposedArticles": 26,
        "cappedArticleCounts": [
            26,
            52
        ]
    },
    {
        "groupName": "trash-bags-kitchen-glad-forceflex-drawstring",
        "productType": "TrashBagsKitchen",
        "pricePerArticle": 20,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "ForceFlex, Drawstring",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6equ0Y0taqOe0z2LLjtERM/e00535c0efcaf0215ecbf103140ed882/2a9cb9b4e8ef0359de1eebbeaf78acb7.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick"
        ],
        "description": "Best Reviews top pick for kitchen trash bags, Glad ForceFlex Odor Shield kitchen trash bags with Leakguard and Ripguard technology eliminate the worry of odor or tearing associated with standard kitchen bags. Convenient Grips-the-Can drawstring closure.",
        "possibleArticleCounts": [
            40,
            80,
            100
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.12903225806451613,
        "articles": 40,
        "proposedArticles": 40,
        "cappedArticleCounts": [
            40,
            80,
            100
        ]
    },
    {
        "groupName": "trash-bags-compost-glad-lemon",
        "productType": "TrashBagsCompost",
        "pricePerArticle": 20,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "Lemon",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1OZY633OR6EwUkJudN1juF/0e53636ef485bf9fbc0e71e1081423a7/compostable_bags.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "EcoFriendly"
        ],
        "description": "Glad's 2.6 gallon compostable trash bags are strong and guaranteed to hold in trash odors with OdorShield technology. These bags meet the requirements of municipal compost programs.",
        "possibleArticleCounts": [
            20,
            40
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articles": 20,
        "proposedArticles": 20,
        "cappedArticleCounts": [
            20,
            40
        ]
    },
    {
        "groupName": "paper-towels-bounty-select-a-size-double",
        "productType": "PaperTowels",
        "pricePerArticle": 166,
        "articleType": "Roll",
        "brandName": "Bounty",
        "displayName": "Select a Size Double",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4YtBD2p7BDXAls9NSwAJf9/c4be29f799a4d8ab0bd8a684b8f50bc2/bounty-single-roll-double-select-a-size.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "The Quicker Picker Upper and #1 bestselling paper towel in the US, Bounty Select a Size gives you the confidence to handle any size mess. ",
        "possibleArticleCounts": [
            6,
            12
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0732258064516129,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            6,
            12
        ]
    },
    {
        "groupName": "paper-towels-bounty-select-a-size-doubles-plus",
        "productType": "PaperTowels",
        "pricePerArticle": 208,
        "articleType": "Roll",
        "brandName": "Bounty ",
        "displayName": "Select a Size Doubles Plus ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/25bQxjrY2QJ094oH3s8CVI/db847ee5f6656b0ceba55c499bf048e7/bounty-2-5.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "The Quicker Picker Upper and #1 bestselling paper towel in the US, Bounty Select a Size gives you the confidence to handle any size mess. \n\n",
        "possibleArticleCounts": [
            2,
            4,
            6,
            8,
            10,
            12
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.05838709677419355,
        "articles": 2,
        "proposedArticles": 2,
        "cappedArticleCounts": [
            2,
            4,
            6,
            8,
            10
        ]
    },
    {
        "groupName": "toilet-paper-cottonelle-ultra-clean-care-mega",
        "productType": "ToiletPaper",
        "pricePerArticle": 104,
        "articleType": "Roll",
        "brandName": "Cottonelle",
        "displayName": "Ultra CleanCare Mega",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6QezIhqbPm9FqYHaDh6v0m/b3a27419f41c0192a98483d908c21d74/cottonelle-cleancare-1248.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Cottonelle CleanCare toilet paper’s Cleaning Ripples provide texture for an ultra clean feel while providing exceptional softness and comfort.",
        "possibleArticleCounts": [
            6,
            9,
            12,
            15,
            18,
            21,
            24,
            27,
            30
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0996774193548387,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            6,
            9,
            12,
            15,
            18,
            21,
            24,
            27,
            30
        ]
    },
    {
        "groupName": "toilet-paper-cottonelle-ultra-cleancare-double-rolls",
        "productType": "ToiletPaper",
        "pricePerArticle": 69,
        "articleType": "Roll",
        "brandName": "Cottonelle",
        "displayName": "Ultra CleanCare Double Rolls",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/26qMW8PmXmJzPHRFmVsDSj/f1fa486de06d4d37cb4184b2034917de/c21dad2e00bf6b4abf88ee9fd64616e2.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Cottonelle CleanCare toilet paper’s Cleaning Ripples provide texture for an ultra clean feel while providing exceptional softness and comfort.",
        "possibleArticleCounts": [
            4,
            8,
            12,
            16,
            20,
            24,
            28
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.1993548387096774,
        "articles": 8,
        "proposedArticles": 8,
        "cappedArticleCounts": [
            4,
            8,
            12,
            16,
            20,
            24,
            28
        ]
    },
    {
        "groupName": "toilet-paper-charmin-ultra-strong-mega",
        "productType": "ToiletPaper",
        "pricePerArticle": 108,
        "articleType": "Roll",
        "brandName": "Charmin",
        "displayName": "Ultra Strong Mega",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6hdqxoRSbBIx8wBrylE89i/41dd516efe16ea32449c7adf883a2a04/charmin-ultra-strong-624.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick"
        ],
        "description": "Enjoy the strength and softness of the #1 toilet paper brand in the US. Ultra Strong is Charmin’s strongest 2-ply toilet paper while still providing Charmin’s quilted softness. Roto-Rooter approved to be septic-safe.\n",
        "possibleArticleCounts": [
            6,
            8,
            9,
            12,
            14,
            15,
            16,
            17,
            18,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.10161290322580645,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            6,
            8,
            9,
            12,
            14,
            15,
            16,
            17,
            18,
            20,
            21,
            22
        ]
    },
    {
        "groupName": "toilet-paper-charmin-ultra-strong-doubles",
        "productType": "ToiletPaper",
        "pricePerArticle": 69,
        "articleType": "Roll",
        "brandName": "Charmin",
        "displayName": "Ultra Strong Doubles",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7JR9Gei93PruYOxPxDChPn/132983d35bab916c014f1250d0a7ad40/26a9ec395c06a99303e21f206f1cdab4.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick"
        ],
        "description": "Enjoy the strength and softness of the #1 toilet paper brand in the US. Ultra Strong is Charmin’s strongest 2-ply toilet paper while still providing Charmin’s quilted softness.",
        "possibleArticleCounts": [
            4,
            6,
            8,
            10,
            12,
            14,
            16,
            18,
            20,
            22,
            24,
            26,
            28,
            30
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.20290322580645162,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            4,
            6,
            8,
            10,
            12,
            14,
            16,
            18,
            20,
            22,
            24,
            26
        ]
    },
    {
        "groupName": "toilet-paper-charmin-ultra-soft-mega",
        "productType": "ToiletPaper",
        "pricePerArticle": 108,
        "articleType": "Roll",
        "brandName": "Charmin ",
        "displayName": "Ultra Soft Mega ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7wdMJ6Aa9Umw9qSxZN8RMB/f037cdbcd9efa1367f043c7c3f5dd33e/charmin-ultra-soft-6-rolls__1_.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick"
        ],
        "description": "Enjoy the softness of the #1 toilet paper brand in the US. Ultra Soft is Charmin’s softest 2-ply toilet paper while still providing Charmin’s proven strength. Roto-Rooter approved to be septic-safe.\n",
        "possibleArticleCounts": [
            6,
            9,
            12,
            15,
            18,
            21,
            24,
            27,
            30
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.11,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            6,
            9,
            12,
            15,
            18,
            21,
            24,
            27,
            30
        ]
    },
    {
        "groupName": "toilet-paper-caboo-super-soft-bamboo-double-rolls",
        "productType": "ToiletPaper",
        "pricePerArticle": 150,
        "articleType": "Roll",
        "brandName": "Caboo",
        "displayName": "Super Soft Bamboo Double Rolls",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7hCerG4R3vrujtL4LinKnP/6ac9b6ec80212030680a236774192457/655f1bb6f329e5bb1061eb58d30cbfdb.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Eco-friendly 2-ply tree free toilet paper made from 100% bamboo & sugarcane pulp is free of inks, dyes and fragrances. Designed for anyone with sensitive skin and/or allergies.",
        "possibleArticleCounts": [
            4,
            8,
            12,
            16,
            20,
            24,
            28
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.1361290322580645,
        "articles": 4,
        "proposedArticles": 4,
        "cappedArticleCounts": [
            4,
            8,
            12,
            16,
            20
        ]
    },
    {
        "groupName": "toilet-paper-angel-soft-double-rolls",
        "productType": "ToiletPaper",
        "pricePerArticle": 58,
        "articleType": "Roll",
        "brandName": "Angel Soft",
        "displayName": "Double Rolls",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/5hYWVlFFdnkRa4Ak17mUv8/eb505dae5b1b77eb1b28b8f2c391d46f/6721af6a5b862fc1675db39cf73fbf5f.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "This #1 best-selling toilet paper is designed for an ideal balance of softness and strength with a lightly quilted pattern. Angel Soft 2-ply toilet paper is septic safe and Sustainable Forestry Initiative (SFI) certified.",
        "possibleArticleCounts": [
            4,
            8,
            12,
            16,
            20,
            24,
            28
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.1338709677419355,
        "articles": 4,
        "proposedArticles": 4,
        "cappedArticleCounts": [
            4,
            8,
            12,
            16,
            20
        ]
    },
    {
        "groupName": "toilet-paper-angel-soft-mega-rolls",
        "productType": "ToiletPaper",
        "pricePerArticle": 83,
        "articleType": "Roll",
        "brandName": "Angel Soft",
        "displayName": "Mega",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4Wc7gsNkxg2pCJsXd5eYdy/7c1601503a214878c36e4893306f6ab3/angelsoft-6-24.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "This #1 best-selling toilet paper is designed for an ideal balance of softness and strength with a lightly quilted pattern. Angel Soft 2-ply toilet paper is septic safe and Sustainable Forestry Initiative (SFI) certified.",
        "possibleArticleCounts": [
            6,
            12,
            18,
            24,
            30
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.08258064516129032,
        "articles": 6,
        "proposedArticles": 6,
        "cappedArticleCounts": [
            6,
            12,
            18,
            24,
            30
        ]
    },
    {
        "groupName": "paper-towels-viva-signature-cloth-choose-a-sheet-double",
        "productType": "PaperTowels",
        "pricePerArticle": 166,
        "articleType": "Roll",
        "brandName": "Viva",
        "displayName": "Signature Cloth Choose a Sheet Double",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3rmlCZxwXuMj6NxTKmORhp/f2a6960b0cbf834305a3544d5952f2e3/viva-signature-cloth-1-2.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SensitiveSkin",
            "SupplyDropPick"
        ],
        "description": "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.08774193548387098,
        "articles": 3,
        "proposedArticles": 3,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ]
    },
    {
        "groupName": "paper-towels-viva-signature-cloth-choose-a-sheet",
        "productType": "PaperTowels",
        "pricePerArticle": 166,
        "articleType": "Roll",
        "brandName": "Viva",
        "displayName": "Signature Cloth Choose a Sheet",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4nAJOqfB3m81IBvV8uZhlP/da435ead71957bf6748385342c1f9cce/594abc0d56a6d401651c551c25ed178f.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0996774193548387,
        "articles": 3,
        "proposedArticles": 3,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ]
    },
    {
        "groupName": "paper-towels-bounty-select-a-size",
        "productType": "PaperTowels",
        "pricePerArticle": 167,
        "articleType": "Roll",
        "brandName": "Bounty",
        "displayName": "Select a Size",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/xJePbEtB1g5YxpBLA4bei/59dbf3b5ebafb2b9d0449ce5b7727b8c/f67922247e8b43d134d070313d8582cb.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "The Quicker Picker Upper and #1 bestselling paper towel in the US, Bounty Select a Size gives you the confidence to handle any size mess.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.1167741935483871,
        "articles": 4,
        "proposedArticles": 4,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ]
    },
    {
        "groupName": "paper-towels-bounty-printed-full-sheet",
        "productType": "PaperTowels",
        "pricePerArticle": 150,
        "articleType": "Roll",
        "brandName": "Bounty",
        "displayName": "Printed Full Sheet",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6mf3GuQzhQ6xijYuucaQOe/15c2cb0c870e412d6879f357eac10818/55a9d8d6364b1224fa14255b52a672cc.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "The Quicker Picker Upper and #1 bestselling paper towel in the US, Bounty Select a Size gives you the confidence to handle any size mess.",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null
    },
    {
        "groupName": "paper-towels-bounty-essentials",
        "productType": "PaperTowels",
        "pricePerArticle": 97,
        "articleType": "Roll",
        "brandName": "Bounty",
        "displayName": "Essentials",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/50rdkp2JmHepjroVY3EzBh/09b1f1381b13c655f82b7479dfae006e/a2306fdf6d035e5484e6fdf341cdb5d2.png",
        "badges": [
            "Value"
        ],
        "description": "Description for Essentials",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null
    },
    {
        "groupName": "paper-towels-caboo-sustainable-bamboo",
        "productType": "PaperTowels",
        "pricePerArticle": 295,
        "articleType": "Roll",
        "brandName": "Caboo",
        "displayName": "Sustainable Bamboo",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6iy98VK7gVENFId5uYlLc2/9d512ed734b6d46daa3d719786dc9fea/6d4b205dabe441cf73705772b8d6a1f8.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Strong 2-ply eco-friendly paper towels made from 100% bamboo & sugarcane pulp. Super-absorbent, durable & made to work effectively at cleaning up heavy spills, kitchen messes and all your cleaning needs.",
        "possibleArticleCounts": [
            2,
            4,
            6,
            8,
            10,
            12
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.12903225806451613,
        "articles": 4,
        "proposedArticles": 4,
        "cappedArticleCounts": [
            2,
            4,
            6,
            8,
            10,
            12
        ]
    },
    {
        "groupName": "surface-cleaner-microban-citrus-scent",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 435,
        "articleType": "Bottle",
        "brandName": "Microban",
        "displayName": "Antibacterial, Citrus Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1n6Iss3MAicjwQcQp797cb/1969050e206fbc2c29aa9fe58e65260a/Microban-surface-cleaner-multi-purpose.png",
        "badges": [

        ],
        "description": "Microban 24 hour Milti-Purpose Cleaner kills 99.9% of bacteria, germs and viruses and keeps working throughout the day to keep surfaces clean and protected. Citrus scented and bleach free, suitable for high touch areas. ",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-seventh-generation-professional-lemongrass-citrus",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 12,
        "articleType": "Wipe",
        "brandName": "Seventh Generation",
        "displayName": "Lemongrass Citrus",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/ZonU0ZsxAfCcyhB02675w/7c47534c5fddaa725d447ae9fe4e5e60/image__3_.png",
        "badges": [
            "EcoFriendly"
        ],
        "description": "These powerful disinfecting wipes kill the cold and flu virus and 99.9% of germs botanically. Made in the USA and great for all non porus surfaces around the house like counter tops, appliances, toys, door knobs and more.",
        "possibleArticleCounts": [
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 2.2580645161290325,
        "articles": 2,
        "proposedArticles": 2,
        "cappedArticleCounts": [
            2
        ]
    },
    {
        "groupName": "dishwasher-detergent-cascade-action-pacs-with-dawn-fresh-scent",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 1352,
        "articleType": "Box",
        "brandName": "Cascade",
        "displayName": "Action Pacs with Dawn, Fresh Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/64MdUAFun2rzYQF8vzgagN/13709d56fc6fecef0fb890676db1be32/cascade-og-60-pack-box.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SupplyDropPick"
        ],
        "description": "Trusted leader for dishwasher detergents. Cascade Complete Action Pacs are formulated with Dawn, America’s leading dish soap, to leave dishes clean and residue free.\n",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "60 count Box",
        "articlesPerDay": 0.0022580645161290325,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-clorox-clean-up-disinfectant-cleaner-with-bleach",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 430,
        "articleType": "Bottle",
        "brandName": "Clorox",
        "displayName": "Clean-Up Disinfectant Cleaner with Bleach ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7GQTL4sSXSz56WtvOBa7AX/acf6fb8ae51a7c47090300184b3a9326/clorox-clean-up-disinfectant-cleaner-with-bleach.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Powered by Bleach and designed with kitchen and bathroom surfaces in mind, Clorox Clean-up Disinfectant Cleaner with Bleach kills 99.9% of bacteria while removing stains, dirt, and grease.\n\n",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dishwasher-detergent-cascade-complete-gel-fresh-scent-fl-oz",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 10,
        "articleType": "Ounce",
        "brandName": "Cascade ",
        "displayName": "Complete Gel, Fresh Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7D2xaxTywkiTYXSBT13aox/879cb58acdc17029fbeff4219cb2f4f4/e6bee093ba35d2708d8f9042db47894b.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Trusted leader in dishwasher gel detergents. Cascade Complete is formulated with Dawn, America’s leading dish soap, to leave dishes clean and residue free.",
        "possibleArticleCounts": [
            75
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "1 oz",
        "articlesPerDay": 0.13064516129032258,
        "articles": 75,
        "proposedArticles": 75,
        "cappedArticleCounts": [
            75
        ]
    },
    {
        "groupName": "dishwasher-detergent-cascade-complete-gel-fresh-scent",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 8,
        "articleType": "Bottle",
        "brandName": "Cascade",
        "displayName": "Complete Gel, Fresh Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7D2xaxTywkiTYXSBT13aox/879cb58acdc17029fbeff4219cb2f4f4/e6bee093ba35d2708d8f9042db47894b.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Trusted leader in dishwasher gel detergents. Cascade Complete is formulated with Dawn, America’s leading dish soap, to leave dishes clean and residue free.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "1 oz",
        "articlesPerDay": 0.0029032258064516127,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-method-all-purpose-french-lavender",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Method ",
        "displayName": "All Purpose, French Lavender",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4zpqOkbdb65Y1F5CWhvjQr/81d7793c47c7ea1a16457c52b4ecbfd8/method-all-purpose-french-lavender.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "All purpose cleaner that uses non toxic, plant based powergreen® technology to cut through grease and grime, leaving nothing behind but the pleasant scent of french lavender. \n",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "28 oz Bottle",
        "articlesPerDay": 0.001935483870967742,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-puracy-disinfecting-surface-free-and-clear",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 800,
        "articleType": "Bottle",
        "brandName": "Puracy",
        "displayName": "Natural, Disinfecting, Free & Clear",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1BTU0yO4IAV6Na2ycF1Ri1/23f6bbc028d8bdce91d111833cfcae79/Puracy-surface-cleaner-disinfecting-free-and-clear.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Hydrogen peroxide solution that is non-toxic and biodegradable while still killing 99.9% of germs. This surface cleaner not only disinfects but also cleans and deodorizes while being safe to use around your family and pets. Great for use on most surfaces but take caution on porous surfaces like wood, soapstone and marble due to the hydrogen peroxide.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "25 oz Bottle",
        "articlesPerDay": 0.001935483870967742,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "masks-armbrust-usa-made-disposable-surgical-mask-american-blue",
        "productType": "FaceMasksSurgical",
        "pricePerArticle": 60,
        "articleType": "Mask",
        "brandName": "Armbrust",
        "displayName": "USA-Made, 3-layer Disposable Surgical Mask in American Blue",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6wfS1oPtwh0RYh5ItD5PvU/9c76cf15ac90a38127fd77cbdefc64eb/Armbrust-face-mask-surgical-solo-mask-made-in-usa-500x500.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Made in America, FDA Registered 3-layer disposable surgical masks that are tested at 99.2% bacterial filtration and rated ASTM Level 2. These stylish yet comfortable American Denim blue masks are of the highest quality to protect you and your family. ",
        "possibleArticleCounts": [
            50,
            100,
            150,
            200
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "Mask (50 masks per pkg)",
        "articlesPerDay": 0.4838709677419355,
        "articles": 50,
        "proposedArticles": 50,
        "cappedArticleCounts": [
            50,
            100,
            150,
            200
        ]
    },
    {
        "groupName": "surface-cleaner-fantastik-disinfectant",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 430,
        "articleType": "Bottle",
        "brandName": "Fantastik",
        "displayName": "Disinfectant",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2BLY50TqWYISkd3jeg4rJd/77a3af0b3f8ee861859323a967beaa04/Fantastik-surface-cleaner-disinfectant-degreaser.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "Multi-Surface cleaner that kills 99.9% of bacteria, the common cold virus, and the flu virus. Fantastik is effective on all surfaces in your home with no need to rinse.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-pledge-antibacterial-cleaner-fresh-citrus",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 750,
        "articleType": "Bottle",
        "brandName": "Pledge",
        "displayName": "Multi-Surface Antibacterial Cleaner, Fresh Citrus",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3S5JxcMsHaYzGk6hfce5Xs/9ee3615585c5f271b8dac908b31ed0df/Pledge-surface-cleaner-antibcacterial-fresh-citrus.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Kills 99.9% of bacteria. This aerosol multi-surface cleaner not only disinfects but also cleans and deodorizes with a fresh citrus scent, while being gentle on surfaces and tough on bacteria.  ",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "9.7 oz Aerosol Spray",
        "articlesPerDay": 0.003870967741935484,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-mrs-myers-multi-surface-lemon-verbena",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 398,
        "articleType": "Bottle",
        "brandName": "Mrs. Meyer's",
        "displayName": "Multi-Surface, Lemon Verbena",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3H1FW462E338OKAF4by7WY/0612320f40373e50b8b4d9692f38edca/Mrs.meyers.surface-cleaner-clean-day-lemon-verbena.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "EcoFriendly"
        ],
        "description": "This plant-based spray is great for non-porous surfaces throughout the house. With strong cleaning power and a wonderful scent, it's a go to for quick cleaning that leaves your home smelling great.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "16 oz Bottle",
        "articlesPerDay": 0.003870967741935484,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dish-soap-puracy-natural-citrus-&-sea-salt",
        "productType": "DishSoap",
        "pricePerArticle": 599,
        "articleType": "Bottle",
        "brandName": "Puracy ",
        "displayName": "Natural, Citrus & Sea Salt ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6zrp20LKwkTiJvdjg80hXR/5e245b2b1e7aabf9963ccfc03a14e7bf/puracy-dish-soap-bottle-citrus-sea-salt.png",
        "badges": [
            "SensitiveSkin",
            "EcoFriendly",
            "SupplyDropPick"
        ],
        "description": "Non-toxic, plant-based dish soap. Safe enough to use around your children and pets without sacrificing cleaning power. Safe for sensitive skin. Business Insider “best dish soap you can buy”.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": "dish-soap-puracy-natural-citrus-&-sea-salt-refill",
        "isRefill": false,
        "displayUnits": "16 oz Bottle",
        "articlesPerDay": 0.0035483870967741938,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "dish-soap-puracy-natural-citrus-&-sea-salt-refill",
        "productType": "DishSoap",
        "pricePerArticle": 2099,
        "articleType": "Bag",
        "brandName": "Puracy",
        "displayName": "Natural, Citrus & Sea Salt Refill",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4tO1RpXCeTEF7joWu96juK/c938c2bf916351092505101881f3fc45/puracy-dish-soap-refill-citrus-sea-salt.png",
        "badges": [
            "EcoFriendly",
            "SensitiveSkin",
            "SupplyDropPick"
        ],
        "description": "Non-toxic, plant-based dish soap refill bag. Safe enough to use around your children and pets without sacrificing cleaning power. Safe for sensitive skin. Business Insider “best dish soap you can buy”.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": true,
        "displayUnits": "64 oz Bag",
        "articlesPerDay": 0.000967741935483871,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dish-soap-puracy-natural-green-tea-&-lime-refill",
        "productType": "DishSoap",
        "pricePerArticle": 2099,
        "articleType": "Bag",
        "brandName": "Puracy",
        "displayName": "Natural, Green Tea & Lime Refill",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/5cFMFqmy52Iy6VCVHFT4DA/ca42952126220753c38a44c78f260786/puracy-dish-soap-refill-green-tea-lime.png",
        "badges": [
            "EcoFriendly",
            "SensitiveSkin",
            "SupplyDropPick"
        ],
        "description": "Non-toxic, plant-based dish soap refill bag. Extra gentle for sensitive skin with a lovely light scent. Over 20,000 Five-Star reviews.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": true,
        "displayUnits": "64 oz Bag",
        "articlesPerDay": 0.000967741935483871,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dish-soap-puracy-natural-green-tea-and-lime",
        "productType": "DishSoap",
        "pricePerArticle": 599,
        "articleType": "Bottle",
        "brandName": "Puracy",
        "displayName": "Natural, Green Tea & Lime",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1eBrJaoNW0xS0u5xAeNFOI/50f67dafaf57489d53532d30bc063446/e35a428d2cfbbd22b6e7d35f6d82492b.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Non-toxic, plant-based dish soap. Safe enough to use around your children and pets without sacrificing cleaning power. Safe for sensitive skin. Business Insider “best dish soap you can buy”.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": "dish-soap-puracy-natural-green-tea-&-lime-refill",
        "isRefill": false,
        "displayUnits": "16 oz Bottle",
        "articlesPerDay": 0.0035483870967741938,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "hand-soap-puracy-natural-lavender-vanilla-refill",
        "productType": "HandSoap",
        "pricePerArticle": 2099,
        "articleType": "Bag",
        "brandName": "Puracy",
        "displayName": "Natural, Lavender & Vanilla Refill ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/79XeFsa9FICvSFEBqojzhJ/ae2a233c0492103ca4d9255057c78388/puracy-dish-soap-refill-lavender-vanilla.png",
        "badges": [
            "SensitiveSkin",
            "EcoFriendly",
            "SupplyDropPick"
        ],
        "description": "Non-toxic, plant-based hand soap refill bag. Extra gentle for sensitive skin with a lovely light scent. Over 20,000 Five-Star reviews.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": true,
        "displayUnits": "64 oz Bag",
        "articlesPerDay": 0.001935483870967742,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-puracy-natural-multi-surface-green-tea-and-lime",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 599,
        "articleType": "Bottle",
        "brandName": "Puracy",
        "displayName": "Natural Multi-Surface, Green Tea & Lime",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/43qteFE7kuzshLmPDrR0oQ/8069fd22deba97df36a1bdf798513f77/f7b74c5b72b046fceb19c6118ca8a827.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Non-toxic, plant-based general-purpose surface cleaner. Safe enough to use around your children and pets without sacrificing cleaning power. Safe for sensitive skin with a gentle scent. The New York Times “Best All-Purpose Cleaner”. ",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "25 oz Bottle",
        "articlesPerDay": 0.001935483870967742,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-lysol-all-purpose-disinfecting-lemon-breeze",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 430,
        "articleType": "Bottle",
        "brandName": "Lysol ",
        "displayName": "All Purpose Disinfecting, Lemon Breeze",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/0IkV5qpUOZlweLjgE42Nt/ecffe11fd0fde358adc11e73173f31f5/lysol-all-purpose-cleaner-complete-clean.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Lysol All Purpose Disinfecting Cleaner helps achieve clean and healthy surfaces by eliminating viruses, bacteria, soap scum, and tough grease. Kills 99.9% of germs and provides lasting lemon breeze freshness.\n",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-formula-409-antibacterial-kitchen-lemon-scent",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 291,
        "articleType": "Bottle",
        "brandName": "Formula 409",
        "displayName": "Antibacterial Kitchen, Lemon Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/8Vrn6zbFvkYa16F44hovJ/4590117e1a146027898803bb846c09fa/6219077224de05fa9d742834246dbba4.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "With the power of an antibacterial formula that kills over 99% of germs, combined with a fresh lemon scent, Formula 409’s non-bleach Formula is effective on all surfaces inside and outside of your home without any abrasive ingredients.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "surface-cleaner-fantastik-all-purpose",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 256,
        "articleType": "Bottle",
        "brandName": "Fantastik",
        "displayName": "All Purpose",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2aCx0KB5FARx8AwuMtNKCu/a8e86fb87f44b42a1621cb0ac3404520/82f9e3f09602382180fa9881e71c11d2.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "Value"
        ],
        "description": "Multi purpose cleaner that kills 99.9% of bacteria, the common cold virus, and the flu virus. Fantastik is effective on exterior surfaces in your kitchen and bathroom at a great price.",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle"
    },
    {
        "groupName": "surface-cleaner-clorox-clean-up-and-bleach-original",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 349,
        "articleType": "Bottle",
        "brandName": "Clorox",
        "displayName": "Clean-up + Bleach Original",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2HFzy6Ceb0VZXlnfjuJikX/41818100d87363118978b3c5dabfb743/02de2a1ad0b719ea015a6ffed313cff7.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "Powered by Bleach and designed with kitchen and bathroom surfaces in mind, Clorox Clean-up Cleaner + Bleach kills 99.9% of bacteria while removing stains, dirt, and grease.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "32 oz Bottle",
        "articlesPerDay": 0.0016129032258064516,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "hand-soap-softsoap-soothing-aloe-vera",
        "productType": "HandSoap",
        "pricePerArticle": 130,
        "articleType": "Bottle",
        "brandName": "Softsoap",
        "displayName": "Soothing Aloe Vera",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/1RX2fJWD7eyIqZAmTZiWtY/69c3b510af1908bd7a98333ab08c6690/2ad2374da200a2ed324ffe4df7008ea8.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "The leading brand in the US, Softsoap offers a gentle cleaning formula that leaves hands feeling fresh and soft.",
        "possibleArticleCounts": [
            1,
            2,
            3,
            4
        ],
        "status": "Legacy",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "7.5 oz Bottle",
        "articlesPerDay": 0.01741935483870968,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2,
            3,
            4
        ]
    },
    {
        "groupName": "hand-soap-soapbox-invigorating-moisture-meyer-lemon-&-tea-leaves",
        "productType": "HandSoap",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Invigorating Moisture, Meyer Lemon & Tea Leaves",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2CI46SVrLUXNID7YdNKW3H/415ac55b7766888c4dabd08cb1a75502/soapbox-meyer-lemon-and-tea-leaves.png",
        "badges": [
            "SupplyDropPick",
            "SensitiveSkin",
            "SocialGood"
        ],
        "description": "Paired with shea butter and aloe vera, Soapbox’s unique invigorating moisture formula uses the natural astringent properties of meyer lemon balanced with the fresh notes of tea leaves. \n",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle"
    },
    {
        "groupName": "hand-soap-soapbox-gentle-moisture-sea-minerals-and-blue-iris",
        "productType": "HandSoap",
        "pricePerArticle": 499,
        "articleType": "Bottle",
        "brandName": "Soapbox",
        "displayName": "Gentle Moisture, Sea Minerals and Blue Iris",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2BLYjMuBFpZP4e6gZsCcvR/1dc4c4a06fa5c05d6080227dade675f8/760519038ae60a65a3c28ebba525095a.png",
        "badges": [
            "NationallyRecognizedBrandLead",
            "SensitiveSkin",
            "SocialGood"
        ],
        "description": "Soapbox uses the moisturizing power of shea butter and aloe vera and the natural detoxifying properties of sea minerals to help your hands feel their best after each wash.",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "12 oz Bottle"
    },
    {
        "groupName": "disinfecting-wipes-clorox-crisp-lemon",
        "productType": "SurfaceCleaner",
        "pricePerArticle": 9,
        "articleType": "Wipe",
        "brandName": "Clorox",
        "displayName": "Crisp Lemon Disinfecting Wipes",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/3TMDCupwnSo5x6AMjjeYhT/a963264216f1f5a4c0cb462cd44ee295/clorox-disinfecting-wipes-crisp-lemon.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "All purpose, bleach free, lemon scented wipes that kill 99.9% of household germs leaving household surfaces disinfected. Safe on wood, sealed granite, stainless steel, or any non-food contact surface.\n",
        "possibleArticleCounts": [
            35,
            70
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "35 count Wipes",
        "articlesPerDay": 2.2580645161290325,
        "articles": 70,
        "proposedArticles": 70,
        "cappedArticleCounts": [
            35,
            70
        ]
    },
    {
        "groupName": "dishwasher-detergent-puracy-natural-packs-free-clear",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 1599,
        "articleType": "Box",
        "brandName": "Puracy",
        "displayName": "Natural Packs, Free & Clear",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/VllnEjzsf2rXxF2J7tgeC/f0f4a3fa9add6de5a52402c5af4d535f/fb995f1524094095e8b581f439dace5e.png",
        "badges": [
            "EcoFriendly",
            "SupplyDropPick",
            "SensitiveSkin"
        ],
        "description": "Non-toxic, plant-based dishwasher detergent packs with enzymes that safely break down the toughest caked-on food while leave dishes sparkling. Hypoallergenic, pet safe and made in the USA.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "50 count Box",
        "articlesPerDay": 0.0025806451612903226,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dishwasher-detergent-finish-powerball-super-charged",
        "productType": "DishwasherDetergent",
        "pricePerArticle": 1053,
        "articleType": "Box",
        "brandName": "Finish",
        "displayName": "Powerball, Super Charged",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/2J9q9G1G2xmvbZiT0KeDJp/566ad5832eefad5aad2e49a0b1338cb2/ed36083464554daeca8a333204ef5c00.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Best selling dishwasher detergent in tab form, trusted for a deep clean and to leave your glassware spotless.",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "54 count Box",
        "articlesPerDay": 0.0022580645161290325,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "dish-soap-palmolive-ultra-strength-original-scent",
        "productType": "DishSoap",
        "pricePerArticle": 300,
        "articleType": "Bottle",
        "brandName": "Palmolive",
        "displayName": "Ultra Strength, Original Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/6bubZi2exApzNITAVtwbm6/79f760837b1e3120073973566324fad1/3440781b522ef255b7ee787aa2cb10c4.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Maximum strength formula designed to clean more with less. Palmolive Ultra Strength doesn’t leave behind residue while leaving your hands soft. Best Advisor 2019 Top 5 for Best Dish Soaps.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "20 oz Bottle",
        "articlesPerDay": 0.0029032258064516127,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "dish-soap-palmolive-essential-clean-original-scent",
        "productType": "DishSoap",
        "pricePerArticle": 140,
        "articleType": "Bottle",
        "brandName": "Palmolive",
        "displayName": "Essential Clean, Original Scent",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/4YWQ0MK3fJKfJSN3UUWWGZ/d8208c3ccdd7d21a411b670436cb6090/9a78193c7ca9f3c58a8a1dd517eff700.png",
        "badges": [
            "Value"
        ],
        "description": "Cleaning power of a great brand at a great price. Refreshing original fragrance. Palmolive Essential Clean doesn’t leave behind residue while leaving your hands soft.",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "14 oz Bottle"
    },
    {
        "groupName": "dish-soap-dawn-ultra-original",
        "productType": "DishSoap",
        "pricePerArticle": 262,
        "articleType": "Bottle",
        "brandName": "Dawn",
        "displayName": "Ultra Original",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/019e01nOqVjYftV6nSBALe/b8da775b94a5eaabb0ae918c03c928bb/267ce29121ac6b0b8b10fc22618ce7de.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "America’s best selling dish soap. Contains 3X more cleaning ingredients per drop than non-Ultra Dawn. Best Advisor 2019 Best Pick for Dish Soaps.",
        "possibleArticleCounts": [
            1,
            2
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "19.4 oz Bottle",
        "articlesPerDay": 0.0029032258064516127,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1,
            2
        ]
    },
    {
        "groupName": "bleach-clorox-concentrated-disinfecting",
        "productType": "Bleach",
        "pricePerArticle": 220,
        "articleType": "Bottle",
        "brandName": "Clorox",
        "displayName": "Concentrated Disinfecting ",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/7kTEZjQG40LI53bFT5aLEm/91804bf90ec2fc59ec086b247bed953b/clorox-disinfecting-bleach.png",
        "badges": [
            "NationallyRecognizedBrandLead"
        ],
        "description": "America’s #1 bleach brand. Concentrated formula means using less to clean more! Kills 99.9% of household germs leaving household surfaces disinfected. Removes 70% more stains than detergent alone. Safe to use in standard and HE washing machines. \n\n\n",
        "possibleArticleCounts": [
            1
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": "30 oz Bottle",
        "articlesPerDay": 0.0029032258064516127,
        "articles": 1,
        "proposedArticles": 1,
        "cappedArticleCounts": [
            1
        ]
    },
    {
        "groupName": "hand-wipes-handzies-free-and-clear",
        "productType": "HandWipes",
        "pricePerArticle": 52,
        "articleType": "Wipe",
        "brandName": "Handzies",
        "displayName": "Free & Clear",
        "imageUrl": "https://images.ctfassets.net/qhwoiitgc21a/yWSfG2Mx68QvsCW2WFmVE/a90671ad79d142d7a1bf968a6f6097bd/handzies.png",
        "badges": [
            "SupplyDropPick"
        ],
        "description": "Hand washing at a sink is always best but when you are on the go, use Handzies to provide you a “soap and water” approach to cleaning up. All natural, no harsh alcohol and biodegradable individual towelettes.",
        "possibleArticleCounts": [
            24,
            48
        ],
        "status": "Active",
        "available": true,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null,
        "articlesPerDay": 0.7741935483870968,
        "articles": 24,
        "proposedArticles": 24,
        "cappedArticleCounts": [
            24,
            48
        ]
    },
    {
        "groupName": "trash-bags-kitchen-glad-quick-tie",
        "productType": "TrashBagsKitchen",
        "pricePerArticle": 14,
        "articleType": "Bag",
        "brandName": "Glad",
        "displayName": "Quick-Tie",
        "imageUrl": "",
        "badges": [

        ],
        "description": "Description for Quick-Tie",
        "possibleArticleCounts": [

        ],
        "status": "Disabled",
        "available": false,
        "replaceWith": null,
        "isRefill": false,
        "displayUnits": null
    }
]