import React, { Component } from 'react';
import * as images from '../../../assets';
import { InterviewQuestion } from '../../Common';
import { marginForCountdown } from '../../../actions';

class DishwashingFrequency extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dishwashing: {
                frequency: 1
            },
            infoCopy: (
                <div>
                    The amount of times you run the dishwasher has a direct impact on how much soap and detergent you use.
                </div>
            ),
            image: {
                src: images.dishwashingFrequency,
                alt: "Dishwasher"
            }
        };

        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.setSignUpData = this.setSignUpData.bind(this);
        this.setStateForKeyValue = this.setStateForKeyValue.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.interview && !this.state.interview) {
            this.setState({ interview: this.props.interview });
        }


        this.setState({ isModal: this.props.isModal });

        if (this.props.location && this.props.location.state)
            this.setSignUpData(this.props.location.state.signUpData);
    }

    setSignUpData(signUpData) {
        try {
            this.setState({ signUpData })
            this.setStateForKeyValue(signUpData);
            const { interview } = signUpData;
            if (interview) {
                this.setStateForKeyValue(interview);

                this.setState({ interview })
            } else {
                this.back();
            }

            if (signUpData.zipcode)
                marginForCountdown();
        } catch (err) {
            console.error(err);
        }
    }

    setStateForKeyValue(data) {
        try {
            Object.entries(data).forEach(entry => {
                const key = entry[0], value = entry[1];
                if (value !== undefined) {
                    this.setState({ [key]: value })
                }
            })
        } catch (err) {
            console.error(err);
        }
    }

    back() {
        const { signUpData } = this.state;
        this.props.history.push('/kitchen/1', { signUpData })
    }

    next() {
        const {
            dishwashing,
            isModal,
            interview,
            signUpData
        } = this.state;

        dishwashing.products = [];
        interview.dishwashing = dishwashing;
        if (isModal) {
            this.props.next({ dishwashing })
        } else {
            signUpData.interview = interview;
            this.props.history.push('/kitchen/3', { signUpData });
        }
    }

    decrement() {
        let { dishwashing } = this.state;
        if (dishwashing.frequency > 0) dishwashing.frequency--;
        this.setState({ dishwashing })
    }

    increment() {
        let { dishwashing } = this.state;
        dishwashing.frequency++;
        this.setState({ dishwashing })
    }

    render() {
        const { dishwashing, isModal, image, infoCopy, signUpData } = this.state;
        const { history, close } = this.props;
        const counter = {
            increment: this.increment,
            decrement: this.decrement,
            min: 0,
            count: dishwashing.frequency
        }
        return (
            <InterviewQuestion
                key="DishwashingFrequency"
                id="DishwashingFrequency"
                back={isModal ? this.props.back : this.back}
                next={this.next}
                history={history}
                image={image}
                question="How often do you run the dishwasher each week?"
                title="Supply Drop - Personalization - Kitchen & Cleaning"
                isModal={isModal}
                close={close}
                counter={counter}
                infoCopy={infoCopy}
                zipcode={this.state.zipcode}
                setZipcode={(zipcode) => this.setState({ zipcode })}
                signUpData={signUpData}
            />
        );
    }
}


export default DishwashingFrequency;