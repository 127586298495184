export function setGoogleOptimizeVariant(experiment) {
    if(window.google_optimize && window.google_optimize.get(experiment) !== undefined && window.google_optimize.get(experiment) !== null) {
        if(!window.sd_google_optimize)
            window.sd_google_optimize = {};

        window.sd_google_optimize[experiment] = parseInt(window.google_optimize.get(experiment));
    }
}

export function hasGoogleOptimizeExperiment(key) {
    return !!window.sd_google_optimize && window.sd_google_optimize[key]
}