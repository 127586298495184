import styled from 'styled-components';
import { mediaQueries } from '../Common';
import { colors } from '../Colors'

export const Heading1 = styled.h1`
    font-family: "Amaranth", sans-serif;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -1%;
    ${mediaQueries("md")`
        font-size: 46px;
    `}
`

export const Heading2 = styled.h2`
    font-family: "Amaranth", sans-serif;
    font-size: 46px;
    letter-spacing: -1%;
    line-height: 120%;
    ${mediaQueries("md")`
        font-size: 40px;
    `}
`

export const Heading3 = styled.h3`
    font-family: "Amaranth", sans-serif;
    font-size: 40px;
    letter-spacing: 0.5%;
    line-height: 120%;
    ${mediaQueries("md")`
        line-height: 130%;
        font-size: 34px;
    `}
`

export const Heading4 = styled.h4`
    font-family: "Amaranth", sans-serif;
    font-size: 34px;
    letter-spacing: 0.25%;
    line-height: 130%;
    font-weight: 400;
    ${mediaQueries("md")`
        font-size: 28px !important;
    `}
`

export const Heading5 = styled.h5`
    font-family: "Muli", sans-serif;
    font-weight: bold; 
    font-size: 24px;
    letter-spacing: 0.15%;
    line-height: 140%;
    text-transform: capitalize;
    color: ${colors.titleText};
    ${mediaQueries("md")`
        font-size: 20px;
    `}
`

export const Heading6 = styled.h6`
    font-family: "Muli", sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.5%;
    line-height: 140%;
    ${mediaQueries("md")`
        font-size: 16px;
    `}
`

export const Subtitle1 = styled.p`
    font-family: "Muli", sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const Subtitle2 = styled.p`
    font-family: "Muli", sans-serif;
    color: ${colors.titleText};
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const BodyL = styled.p`
    font-family: "Muli", sans-serif;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const Body1 = styled.p`
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const Body2 = styled.p`
    font-family: "Muli", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const ButtonText = styled.span`
    font-family: "Muli", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 1.25%;
    text-transform: uppercase;
`

export const Caption = styled.p`
    font-family: "Muli", sans-serif;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 1%;
    color: ${colors.titleText};
`

export const Overline = styled.p`
    font-family: "Muli", sans-serif;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 8%;
    text-transform: uppercase;
`