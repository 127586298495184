import styled, { css } from 'styled-components'
import { mediaQueries, colors } from './'

export const FooterWrapper = styled.div`
    width: 100%;
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    padding-bottom: 12px;
    padding-top: 12px;
    ${props => props.newFooter && css`
        width: 1200px;
        max-width: calc(100vw - 120px);
        ${mediaQueries("md")`
            max-width: calc(100vw - 60px);
        `}
    `}
`

export const FooterContainer = styled.div`
    position: relative;
    z-index: 1000;
    margin-top: 0px;
    background: #FCFCFC;
    padding-top: 12px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.0490829);
    ${props => props.showDialog && css`
        z-index: 1100 !important;
    `}
    ${mediaQueries("sm")`
        ${props => props.landing && css`
            margin-bottom: 84px;
        `}
    `}
`

export const FooterMainText = styled.div`
    display: flex;
    line-height: 140%;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 615px;
    ${mediaQueries("sm")`
        max-width: 80%;
    `}
`

export const FooterText = styled.div`
    font-size: 12px;
    padding-bottom: 16px;
    ${mediaQueries("sm")`
        font-size: 8px; 
    `}
    ${props => props.newFooter && css`
        font-size: 14px !important;
        ${props.trademarks && css`
            ${mediaQueries("sm")`
                font-size: 12px !important; 
            `}
        `}
    `}
`

export const FooterPoliciesContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    ${mediaQueries("sm")`
        width: 80%;
    `};
    ${props => props.newFooter && css`
        width: 372px;
        margin-left: 0;
        justify-content: space-between;
        ${mediaQueries("sm")`
            width: 100%;
            max-width: 500px;
            margin-left: auto;
            margin-bottom: 20px;
        `};
    `}
`

export const FooterPolicy = styled.div`
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: -0.1px;
    color: ${colors.primary.main};
    cursor: pointer;
    margin-top: 20px;
    &:hover {
        text-decoration: underline;
    }
    ${props => props.newFooter && css`
        margin-top: 4px;
        margin-bottom: 4px;
    `}
`

export const FooterSocial = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    width: 25%;
    ${mediaQueries("sm")`
        width: 70%;
    `};
    ${props => props.newFooter && css`
        ${mediaQueries("sm")`
            width: 100%;
            max-width: 500px;
            margin-left: auto;
            margin-bottom: 20px;
            justify-content: space-between;
        `};
    `}
    
`
