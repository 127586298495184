import axios from 'axios';
import {
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    UPDATE_SOURCE_SUCCESS,
    UPDATE_SOURCE_FAIL,
    UPDATING_CUSTOMER,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    UPDATING_STATUS,
    UPDATING_SOURCE,
    CLEAR_CUSTOMER_STATE,
    CLEAR_CUSTOMER,
    NEW_MAGIC_LINK_FAIL,
    NEW_MAGIC_LINK_SUCCESS,
    GETTING_MAGIC_LINK,
    USER_DOESNT_EXIST,
    POST_PROSPECT_FAIL,
    POST_PROSPECT_SUCCESS,
    POSTING_PROSPECT,
    GETTING_USER,
    ENROLL_CUSTOMER_SUCCESS,
    ENROLL_CUSTOMER_ERROR,
    ENROLLING_CUSTOMER
} from './types';
import keys, { featureFlags } from '../config';
import getSignature from '../helpers/aws4-utl';
import { getAuthHeaders, handleApiError } from '../helpers/apiHelper';
import awsKeys from '../aws-config';
import mockUser from '../data/mock/MockUser';
const url = keys.apiUrl;
const isMock = featureFlags.use_mock_data;

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: GETTING_USER })
        if (isMock) {
            dispatch({ type: GET_USER_SUCCESS, payload: mockUser })
        } else {
            getAuthHeaders().then(headers => {
                axios.get(`${url}/customer?new=1`, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: GET_USER_SUCCESS, payload: res.data })
                        else
                            dispatch(handleApiError(res.error, GET_USER_FAIL))
                    })
                    .catch(err => {
                        dispatch(handleApiError(err, GET_USER_FAIL))
                    })
            }).catch(headerErr => {
                dispatch(handleApiError(headerErr, GET_USER_FAIL))
            })
        }
    }
}

export const updateCustomer = (customer) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_CUSTOMER })
        if (isMock) {
            dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: true })
        } else {
            getAuthHeaders().then(headers => {
                axios.put(`${url}/customer/`, customer, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: res.data })
                        else
                            dispatch(handleApiError(res.error, UPDATE_CUSTOMER_FAIL));
                    }).catch(err => {
                        dispatch(handleApiError(err, UPDATE_CUSTOMER_FAIL));
                    })
            }).catch(err => {
                dispatch(handleApiError(err, UPDATE_CUSTOMER_FAIL));
            })
        }
    }
}

export const updateSource = (sourceId) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_SOURCE })
        if (isMock) {
            dispatch({ type: UPDATE_SOURCE_SUCCESS, payload: true })
        } else {
            getAuthHeaders().then(headers => {
                axios.put(`${url}/customer/source/`, { sourceId }, headers)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: UPDATE_SOURCE_SUCCESS, payload: res.data })
                        else
                            dispatch(handleApiError(res.error, UPDATE_SOURCE_FAIL));
                    }).catch(err => {
                        dispatch(handleApiError(err, UPDATE_SOURCE_FAIL));
                    })
            }).catch(err => {
                dispatch(handleApiError(err, UPDATE_SOURCE_FAIL));
            })
        }
    }
}

export const updateCustomerStatus = (status) => {
    return (dispatch) => {
        dispatch({ type: UPDATING_STATUS })
        getAuthHeaders().then(headers => {
            axios.put(`${url}/customer/status/${status}/`, {}, { headers: headers })
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: UPDATE_STATUS_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, UPDATE_STATUS_FAIL));
                }).catch(err => {
                    dispatch(handleApiError(err, UPDATE_STATUS_FAIL));
                })
        }).catch(err => {
            dispatch(handleApiError(err, UPDATE_STATUS_FAIL));
        })
    }
}

export const getMagicLink = (email, path, channel, username, phone) => {
    return async (dispatch) => {
        dispatch({ type: GETTING_MAGIC_LINK })
        let body;
        if (email)
            body = { cognitoPoolId: awsKeys.Auth.userPoolId, email: email.toLowerCase(), path, channel };
        else if (username)
            body = { cognitoPoolId: awsKeys.Auth.userPoolId, username, path, channel }
        else if (phone)
            body = { cognitoPoolId: awsKeys.Auth.userPoolId, phone, path, channel }
        if (!body)
            dispatch(handleApiError("No idenitification received", NEW_MAGIC_LINK_FAIL));
        else {
            getSignature('POST', body, '/magiclink').then(req => {
                axios.request(req.request)
                    .then(res => {
                        if (res.status === 200)
                            dispatch({ type: NEW_MAGIC_LINK_SUCCESS, payload: res.data })
                        else
                            dispatch(handleApiError(res.error, NEW_MAGIC_LINK_FAIL))
                    }).catch(err => {
                        if (err.response && err.response.status === 404)
                            dispatch(handleApiError(err, USER_DOESNT_EXIST))
                        else
                            dispatch(handleApiError(err, NEW_MAGIC_LINK_FAIL))
                    })
            }).catch(err => {
                dispatch(handleApiError(err, NEW_MAGIC_LINK_FAIL))
            })
        }
    }
}

export const postProspect = (body) => {
    return async (dispatch) => {
        dispatch({ type: POSTING_PROSPECT });
        getSignature('POST', body, '/prospect').then(req => {
            axios.request(req.request)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: POST_PROSPECT_SUCCESS, payload: res.data });
                    else
                        dispatch(handleApiError(res.error, POST_PROSPECT_FAIL))
                }).catch(err => {
                    dispatch(handleApiError(err, POST_PROSPECT_FAIL))
                })
        }).catch(err => {
            dispatch(handleApiError(err, POST_PROSPECT_FAIL))
        })
    }
}

export const enrollCustomer = (body) => {
    return (dispatch) => {
        dispatch({ type: ENROLLING_CUSTOMER })
        getAuthHeaders().then(headers => {
            axios.post(`${url}/customer/enroll`, body, headers)
                .then(res => {
                    if (res.status === 200)
                        dispatch({ type: ENROLL_CUSTOMER_SUCCESS, payload: res.data })
                    else
                        dispatch(handleApiError(res.error, ENROLL_CUSTOMER_ERROR))
                })
                .catch(err => {
                    dispatch(handleApiError(err, ENROLL_CUSTOMER_ERROR))
                })
        }).catch(headerErr => {
            dispatch(handleApiError(headerErr, ENROLL_CUSTOMER_ERROR))
        })
    }
}

export const clearCustomerState = (all) => {
    return (dispatch) => {
        if (all)
            dispatch({ type: CLEAR_CUSTOMER })
        else
            dispatch({ type: CLEAR_CUSTOMER_STATE })
    }
}
