//#region imports
import React, { useState } from 'react';
import { DocModal } from '..';
import { FooterWrapper, FooterContainer } from '../../../Style/Footer';
import SocialLinks from './SocialLinks';
import Policies from './FooterPolicies';
import { AntiHoarding, Trademarks } from './FooterCopy'
import FooterPageLinks from './FooterPageLinks'
import { parachute } from '../../../assets/outdated'
import styled from 'styled-components';
import { FlexRowStart, mediaQueries } from '../../../Style';
//#endregion

//#region styles
export const AntiHoardingWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    `
export const TrademarksWrapper = styled.div`
    margin: 20px auto;
    `
const LinksWrapper = styled(FlexRowStart)`
    ${mediaQueries("md")`
        flex-direction: column;
    `}
`
const Parachute = styled.img`
    height: 57px;
    width: 45px;
    display: inline;
    ${mediaQueries("md")`
        margin-bottom: 40px;
    `}
`
//#endregion

//#region main
export default function Footer({ landing, history }) {
    //#region init state
    const [doc, setDoc] = useState();

    const gitSha = (process.env.REACT_APP_SUPPLY_DROP_ENVIRONMENT !== 'production')
        ? `Build: ${process.env.REACT_APP_GIT_SHA}`
        : ""
    //#endregion

    //#region event handlers
    const handleTermsClick = () =>
        setDoc('Terms_of_Use')

    const handlePrivacyPolicyClick = () =>
        setDoc('Privacy_Policy')
    //#endregion

    //#region render functions
    const renderDialog = () =>
        doc && <DocModal doc={doc} hide={() => setDoc()} />

    const Logo = () =>
        <Parachute alt="" src={parachute} />

    return (
        <FooterContainer showDialog={!!doc} landing={landing}>
            {renderDialog()}
            <FooterWrapper newFooter={true}>
                <LinksWrapper>
                    <Logo />
                    <FooterPageLinks history={history} />
                </LinksWrapper>
                <AntiHoardingWrapper>
                    <AntiHoarding />
                </AntiHoardingWrapper>
                <LinksWrapper>
                    <Policies showTerms={handleTermsClick} showPrivacyPolicy={handlePrivacyPolicyClick} newFooter={true} />
                    <SocialLinks newFooter={true} />
                </LinksWrapper>
                <TrademarksWrapper>
                    <Trademarks />
                </TrademarksWrapper>
                {gitSha}
            </FooterWrapper>
        </FooterContainer>
    )
    //#endregion
}
//#endregion