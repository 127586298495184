import * as Sentry from '@sentry/browser';
import React from 'react';
import moment from 'moment';

export const getDeliveryDate = (shipment) => {
    try {
        let cutoff, delivery;
        shipment.orderWindows.sort((a, b) => {
            if (moment(a.createdEndInclusive).isBefore(b.createdEndInclusive)) return -1
            if (moment(b.createdEndInclusive).isBefore(a.createdEndInclusive)) return 1
            return 0
        })

        for (let i = 0; i < shipment.orderWindows.length; i++) {
            const { createdEndInclusive, deliveryDate } = shipment.orderWindows[i];
            if (moment.utc(createdEndInclusive).isAfter(moment.utc())) {
                cutoff = moment.utc(createdEndInclusive);
                delivery = deliveryDate
                break;
            }
        }

        return { cutoff, deliveryDate: delivery }
    } catch (error) {
        Sentry.captureException(error);
        return { error }
    }
}

export function getDeliveryTrackingLinks(deliveryMethod, deliveryTracking, label) {
    var trackingDeliveries = getDeliveryTrackingDetails(deliveryMethod, deliveryTracking)
    return trackingDeliveries.map((tracking) => {
        return (<span> | <a target="_blank" rel="noopener noreferrer" href={tracking.url}>{label || tracking.trackingCode}</a></span>)
    })
}

export function getDeliveryTrackingDetails(deliveryMethod, deliveryTracking) {
    if (deliveryMethod === "UPSGround" && !!deliveryTracking) {
      return [{
        trackingCode: deliveryTracking,
        url: `https://www.ups.com/track?loc=null&tracknum=${encodeURIComponent(deliveryTracking)}&requester=WT/trackdetails`
      }]
    }

    return [];
}