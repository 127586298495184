export default {
    items: [
        {
            amount: 546,
            currency: "usd",
            description: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
            groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
            parent: "sku_GRJnwcjPHdWdg2",
            quantity: 3,
            type: "sku"
        },
        {
            amount: 672,
            currency: "usd",
            description: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
            groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
            parent: "sku_GRJXEhMTKL0eCy",
            quantity: 6,
            type: "sku"
        },
        {
            amount: 222,
            currency: "usd",
            description: "Sales tax",
            type: "tax"
        }
    ],
    order: {
        status: "PaymentSucceeded",
        type: "Initial",
        createdAt: "2020-04-01T13:43:07.958Z",
        expectedDeliveryDate: "2020-04-03",
        expectedShipDate: "2020-04-02",
        orderId: "1",
        products: [
            {
                articleType: "Roll",
                articlesPerPackage: 6,
                badges: ["NationallyRecognizedBrandLead", "SupplyDropPick", "SensitiveSkin"],
                brand: "Cottonelle",
                description: "Cottonelle CleanCare toilet paper’s Cleaning Ripples provide texture for an ultra clean feel while providing exceptional softness and comfort.",
                displayName: "Ultra CleanCare Mega",
                group: "toilet-paper-cottonelle-ultra-clean-care-mega",
                groupName: "toilet-paper-cottonelle-ultra-clean-care-mega",
                imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/6QezIhqbPm9FqYHaDh6v0m/b3a27419f41c0192a98483d908c21d74/cottonelle-cleancare-1248.png",
                packages: 1,
                pricePerArticle: 112,
                productId: "8f6a8067-e7c9-f105-b1e6-c8a98920b4a8",
                purchaseDescription: "Toilet Paper, Cottonelle, 6 Rolls, Ultra CleanCare Mega Rolls",
                stripePriceCents: 112,
                type: "ToiletPaper"
            },
            {
                articleType: "Roll",
                articlesPerPackage: 1,
                badges: ["NationallyRecognizedBrandLead", "SensitiveSkin", "SupplyDropPick"],
                brand: "Viva",
                description: "Soft and durable, Signature Cloth Choose a Sheet Viva paper towels have a texture that sets them apart from other paper towels with the strength to tackle any size job.",
                displayName: "Signature Cloth Choose a Sheet Double",
                group: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                groupName: "paper-towels-viva-signature-cloth-choose-a-sheet-double",
                imageUrl: "https://images.ctfassets.net/qhwoiitgc21a/3rmlCZxwXuMj6NxTKmORhp/f2a6960b0cbf834305a3544d5952f2e3/viva-signature-cloth-1-2.png",
                packages: 3,
                pricePerArticle: 182,
                productId: "cbf23dcf-008d-4f0a-60ca-dd65244337f6",
                purchaseDescription: "Paper Towels, Viva, 1 roll, 110 sheets, Signature Cloth, Choose a Sheet",
                stripePriceCents: 182,
                type: "PaperTowels"
            }
        ]
    }
}