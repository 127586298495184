import styled, { css } from 'styled-components';

function flexPercentage(numerator) {
    return (100 * numerator/12).toFixed(2) + "%";
}

export const Row = styled.div`
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
`

export const Col = styled.div`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    /* Currently only using xs variant */
    ${props => props.xs && css`
        flex: 0 0 ${flexPercentage(props.xs)};
        max-width: ${flexPercentage(props.xs)};
    `}
`

export const Alert = styled.div`
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    ${props => props.variant && css`
        ${props.variant === "danger" && `
            background-color: #f8d7da;
            border-color: #f5c6cb;
        `}
        ${props.variant === "warning" && `
            background-color: #fff3cd;
            border-color: #ffeeba;
        `}
    `}
`