import React, { Component, Fragment } from 'react';
import {
    CardElement,
    injectStripe
} from 'react-stripe-elements';
import StripeInput from './StripeInput';
import { TextField } from '@material-ui/core'

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderForm() {
        return (this.props.mui_checkout) ?
            (
                <TextField
                    name="ccnumber"
                    variant="outlined"
                    style={{ width: this.props.isXS ? "100%" : 456 }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        id: "stripe-frame",
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardElement
                        },
                    }}
                />
            )
            :
            (
                <Fragment>
                    <label className="modal-label">Credit Card</label>
                    <CardElement id="stripe-frame" className="stripe-input" />
                </Fragment>
            )
    }

    render() {
        return (
            <div className="modal-input-container full" >
                { this.renderForm()}
            </div>
        )
    }
}

export default injectStripe(StripeForm)
