import React from 'react';
import * as images from '../../../assets';
import { formatBadgeName } from '../../../actions';
import { Overline } from '../../../Style/DesignSystem/Typography';
import { FlexColCenter, FlexRowStart } from '../../../Style/'
import { ProductGroupBundleRowContainer, ProductGroupLandingDetailImg } from '../../../Style/ProductGroupLandingStyles'

export default function ProductGroupBadgesRow({ badges }) {
    const badgeRows = [];
    badges.forEach(badge => {
        const badgeImg = images[badge];
        if (badgeImg) {
            const formattedBadgeName = formatBadgeName(badge)
            badgeRows.push(
                <FlexRowStart>
                    <ProductGroupLandingDetailImg src={badgeImg} alt={formattedBadgeName} />
                    <FlexColCenter><p>{formattedBadgeName}</p></FlexColCenter>
                </FlexRowStart>
            )
        }

    })
    if (badgeRows.length) {
        return (
            <ProductGroupBundleRowContainer>
                <Overline className="text-left">This Product Is</Overline>
                <FlexRowStart>{badgeRows}</FlexRowStart>
            </ProductGroupBundleRowContainer>
        )
    } else {
        return <div />
    }
}